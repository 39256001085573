import {
    CaretDownIcon,
    CaretSortIcon,
    CaretUpIcon,
    CheckCircledIcon,
    CrossCircledIcon,
    InfoCircledIcon,
    StopwatchIcon,
} from '@radix-ui/react-icons'
export const companyProductsTable = 'company_products'
export const companiesTable = 'companies'
export const procurementDepartment = 'procurement'
export const procurementEventType = 'company'
export const taskEventType = 'task'

export const PROCUREMENT_PRIVATE_EVENT_URL = '/companyEventsPrivate'
export const PROCUREMENT_PRIVATE_EVENT_ELEMENT = 'company_id'

// export const ANALYTICS_RANGE_SELECTOR_OPTIONS = [
//     {
//         label: 'Today',
//         Value: ['0', '0'],
//         onClick: ({ target }: any) => handleRangeToPresent(target, subDays, 'Today'),
//     },
//     {
//         label: 'Yesterday',
//         Value: ['1', '0'],
//         onClick: ({ target }: any) => handleRangeToPresent(target, subDays, 'Yesterday'),
//     },
//     {
//         label: 'Last 7 days',
//         Value: ['7', '0'],
//         onClick: ({ target }: any) => handleRangeToPresent(target, subDays, 'Last 7 days'),
//     },
//     {
//         label: 'Last 30 days',
//         Value: ['30', '0'],
//         onClick: ({ target }: any) => handleRangeToPresent(target, subDays, 'Last 30 days'),
//     },
//     {
//         label: 'Last 90 days',
//         Value: ['3', '0'],
//         onClick: ({ target }: any) => handleRangeToPresent(target, subMonths, 'Last 90 days'),
//     },
//     {
//         label: 'Last 365 days',
//         Value: ['1', '0'],
//         onClick: ({ target }: any) => handleRangeToPresent(target, subYears, 'Last 365 days'),
//     },
//     {
//         label: 'Last month',
//         Value: ['1', '0'],
//         onClick: ({ target }: any) => handleRangePrevious(target, 'Last month'),
//     },
//     {
//         label: 'Last 3 months',
//         Value: ['3', '0'],
//         onClick: ({ target }: any) => handleRangePrevious(target, 'Last 3 months'),
//     },
//     {
//         label: 'Last 6 months',
//         Value: ['6', '0'],
//         onClick: ({ target }: any) => handleRangePrevious(target, 'Last 6 months'),
//     },
//     {
//         label: 'Week to date',
//         Value: ['6', '0'],
//         onClick: ({ target }: any) => handleRangeToDate(target, startOfWeek, 'Week to date'),
//     },
//     {
//         label: 'Month to date',
//         Value: ['6', '0'],
//         onClick: ({ target }: any) => handleRangeToDate(target, startOfWeek, 'Month to date'),
//     },
//     {
//         label: 'Quarter to date',
//         Value: ['6', '0'],
//         onClick: ({ target }: any) => handleRangeToDate(target, startOfWeek, 'Quarter to date'),
//     },
// ]

//company tasks
const date = new Date()
date.setHours(0, 0, 0, 0)
export const DATE_TODAY = date

export const COMPANY_PRODUCT_FIELD_OPTIONS = [
    {
        value: 'sku',
        label: 'Shopify Sku',
        icon: StopwatchIcon,
    },
    {
        value: 'product_type',
        label: 'Product Type',
        icon: CheckCircledIcon,
    },
    {
        value: 'status',
        label: 'Listing Status',
        icon: CheckCircledIcon,
    },
    {
        value: 'weblinks',
        label: 'Web Links',
        icon: CheckCircledIcon,
    },
    {
        value: 'supplier_sku',
        label: 'Supplier Sku',
        icon: CheckCircledIcon,
    },
    {
        value: 'upc',
        label: 'UPC',
        icon: CheckCircledIcon,
    },
    {
        value: 'cost',
        label: 'Cost',
        icon: CheckCircledIcon,
    },
    {
        value: 'shipping_fee',
        label: 'Shipping Fee',
        icon: CheckCircledIcon,
    },
    {
        value: 'list_price',
        label: 'List Price',
        icon: CheckCircledIcon,
    },
    {
        value: 'lowest_competitor_price',
        label: 'Lowest Price',
        icon: CheckCircledIcon,
    },
    {
        value: 'lowest_competitor_link',
        label: 'Lowest Comp. Link',
        icon: CheckCircledIcon,
    },
    {
        value: 'notes',
        label: 'Notes',
        icon: CheckCircledIcon,
    },
    {
        value: 'related_variants_group_id',
        label: 'Related Variants Group',
        icon: CheckCircledIcon,
    },
]
export const TASK_STATUS_OPTIONS = [
    {
        value: 'OPEN',
        label: 'Open',
        icon: StopwatchIcon,
    },
    {
        value: 'COMPLETED',
        label: 'Completed',
        icon: CheckCircledIcon,
    },
]
export const TASK_DATE_OPTIONS = [
    {
        value: 'overdue',
        label: 'Overdue',
        icon: CrossCircledIcon,
    },
    {
        value: 'due_today',
        label: 'Due Today',
        icon: StopwatchIcon,
    },
    {
        value: 'custom',
        label: '',
        icon: StopwatchIcon,
    },
]

//companies

export const REQUIRED_LISTING_FIELDS = ['has_edi', 'has_api', 'accept_logo', 'sells_to_canada', 'shipping_terms']

export const COMPANY_RESOURCE_VIEW_TABS = ['activity', 'notes', 'emails', 'tasks', 'products', 'contacts', 'files']

export const COMPANY_SORT_OPTIONS = {
    overall_score: [
        {
            value: undefined,
            label: 'Score',
            icon: CaretSortIcon,
        },
        {
            value: { field: 'overall_score_v2', direction: 'DESC' },
            label: 'Score',
            icon: CaretUpIcon,
        },
        {
            value: { field: 'overall_score_v2', direction: 'ASC' },
            label: 'Score',
            icon: CaretDownIcon,
        },
    ],
    name: [
        {
            value: undefined,
            label: 'Name',
            icon: CaretSortIcon,
        },
        {
            value: { field: 'name', direction: 'ASC' },
            label: 'Name',
            icon: CaretUpIcon,
        },
        {
            value: { field: 'name', direction: 'DESC' },
            label: 'Name',
            icon: CaretDownIcon,
        },
    ],
}
export const PROCUREMENT_PRODUCT_SORT_OPTIONS = [
    {
        value: null,
        label: 'Sort By (none)',
        icon: CaretUpIcon,
    },
    {
        value: { field: 'list_price', direction: 'DESC' },
        label: 'List Price (highest)',
        icon: CaretUpIcon,
    },
    {
        value: { field: 'list_price', direction: 'ASC' },
        label: 'List Price (lowest)',
        icon: CaretDownIcon,
    },
    {
        value: { field: 'created_at', direction: 'ASC' },
        label: 'Created Date (oldest)',
        icon: CaretUpIcon,
    },
    {
        value: { field: 'created_at', direction: 'DESC' },
        label: 'Created Date (newest)',
        icon: CaretDownIcon,
    },
]

export const LEAD_TYPE_OPTIONS = [
    {
        tag: 'lead_type',
        value: 'Unqualified',
        label: 'Unqualified',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lead_type',
        value: 'Unqualified - Follow Up',
        label: 'Unqualified - Follow Up',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lead_type',
        value: 'New',
        label: 'New',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lead_type',
        value: 'High Priority',
        label: 'High Interest',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lead_type',
        value: 'Low / Medium Priority',
        label: 'Low / Medium Interest',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lead_type',
        value: 'Staging',
        label: 'Staging',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lead_type',
        value: 'Active',
        label: 'Active',
        icon: InfoCircledIcon,
    },
]

export const LIFECYCLE_STATUS_OPTIONS = [
    {
        tag: 'lifecycle_status',
        value: 'No Contact',
        label: 'No Contact',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Contacted',
        label: 'Contacted',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Need to Submit Dealer Form',
        label: 'Need to Submit Dealer Form',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Awaiting Dealer Forms',
        label: 'Awaiting Dealer Forms',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Submitted Dealer Forms',
        label: 'Submitted Dealer Forms',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Awaiting Price List / Intake',
        label: 'Awaiting Price List / Intake',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Calculating Margins',
        label: 'Calculating Margins',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Bad Margins / Unlistable',
        label: 'Bad Margins / Unlistable',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Listing',
        label: 'Listing',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Waiting to be Listed',
        label: 'Waiting to be Listed',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Listed',
        label: 'Listed',
        icon: InfoCircledIcon,
    },
]

export const defaultCompanyFilterParams = {
    id: undefined,
    name: undefined,
    domain: undefined,
    lead_type: [],
    lifecycle_status: [],
    score: undefined,
    search: undefined,
    assigned_to: [],
    lastCursor: [],
}
export const defaultTaskFilterParams = {
    id: undefined,
    due_at: undefined,
    company_id: undefined,
    search: '',
    lastCursor: undefined,
    user_ids: [],
    statuses: [],
    due_at_options: [],
}

export const defaultCompanyLogo = 'https://fpdash-bucket.s3.us-east-1.amazonaws.com/default-company-logo.jpeg'
