import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSections } from '../../redux/sections'

export default function DescriptionScreen() {
    const dispatch = useDispatch()
    const { description } = useSelector((state) => state).sections
    const handleChange = ({ target }) => {
        const goLower = (el) => {
            let fontWeight = ''
            if (el.style.fontWeight && el.style.fontWeight !== '400') {
                fontWeight = el.style.fontWeight
            }
            el.removeAttribute('style')
            el.removeAttribute('class')
            if (fontWeight) el.style.fontWeight = fontWeight
            if (el.children.length > 0) {
                Array.from(el.children).forEach((child) => goLower(child))
            }
        }
        Array.from(target.children).forEach((child) => goLower(child))
        dispatch(setSections({ description: target.innerHTML.replaceAll('\n', '') }))
    }
    useEffect(() => {
        if (description) {
            document.querySelector('.js-richtext').innerHTML = description
        }
    }, [])
    return (
        <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col items-center shadow-small'>
            <p className='dark:text-offwhite text-center dark:font-normal font-bold mb-[16px] font-bai'>
                You can add text or paste text and images copied from websites into the box below.
            </p>
            <div
                className='js-richtext dark:text-offwhite dark:bg-darkness dark:border-blue w-full h-[200px] max-w-[1000px] mx-auto overflow-auto p-[16px] rounded-[4px] border border-grey leading-[1.3] focus:outline-none'
                contentEditable='true'
                onInput={handleChange}
            ></div>
            {description && (
                <div className='bg-white description-preview description-preview--full'>
                    <div className='pdp__description__heading'>PRODUCT OVERVIEW</div>
                    <div className='pdp__description__left' dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>
            )}
        </div>
    )
}
