import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import FiltersMenu from '../custom_components/FiltersMenu'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { TailSpin } from 'react-loading-icons'
import {
    DayMap,
    MonthMap,
    closePopout,
    dayMap,
    formatMoney,
    monthMap,
    parseResObject,
    useDebounce,
    vFetch,
} from '../helpers'
import { PurchaseOrderEditInit } from './purchaseOrders.types'
import PurchaseOrdersList from './components/PurchaseOrdersList'

const options: any = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'America/Chicago',
}
const timeFormatter = new Intl.DateTimeFormat('en-US', options)

export default function PurchaseOrdersScreen() {
    const navigate = useNavigate()
    const searchRef: any = useRef()
    const [startDate, setStartDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7)
    )
    const [endDate, setEndDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999)
    )
    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderEditInit[]>([])
    const [allPurchaseOrders, setAllPurchaseOrders] = useState<PurchaseOrderEditInit[]>([])
    const [selected, setSelected] = useState<string[]>([])
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(true)
    const [orderSearch, setOrderSearch] = useState('')
    const [searchResults, setSearchResults] = useState<PurchaseOrderEditInit[]>([])
    const [showSearchResults, setShowSearchResults] = useState(false)
    const debouncedSearch = useDebounce(orderSearch, 500)

    const abortController = new AbortController()
    useEffect(() => {
        if (orderSearch) {
            setShowSearchResults(true)
            vFetch(
                `/orders/purchase-orders/search?usingLike=1&order_name=${orderSearch
                    .replaceAll('#', '')
                    .toLowerCase()}&supplier=${orderSearch}`,
                {
                    abortController,
                    cb: (res: any) => {
                        if (res.success) setSearchResults(res.purchase_orders || [])
                    },
                }
            )
        }
        return () => abortController.abort()
    }, [debouncedSearch])

    const filters: any[] = []
    const filterProps = {
        startDate: startDate,
        setStartDate: setStartDate,
        endDate: endDate,
        setEndDate: setEndDate,
        items: allPurchaseOrders,
    }

    useEffect(() => {
        const listenToWindow = (e: any) => {
            if (!searchRef.current.contains(e.target)) setShowSearchResults(false)
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    function refresh() {
        setLoading(true)
        vFetch(
            `/orders/purchase-orders?page=${page}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
            {
                cb: (res: any) => {
                    if (res.success) setPurchaseOrders(res.purchase_orders.map((order: any) => parseResObject(order)))
                    setLoading(false)
                },
            }
        )
    }

    useEffect(() => {
        refresh()
    }, [page])

    useEffect(() => {
        setPage(0)
        refresh()
    }, [startDate, endDate])

    return (
        <>
            {loading && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Purchase Orders</h1>
            <div className='flex items-center justify-between gap-x-[8px] h-[50px]'>
                <h2 className='font-bold text-[24px] font-bai dark:text-offwhite'>
                    {startDate
                        .toDateString()
                        .split(' ')
                        .map((dateItem) =>
                            dayMap[dateItem as keyof DayMap]
                                ? `${dayMap[dateItem as keyof DayMap]}, `
                                : monthMap[dateItem as keyof MonthMap]
                                  ? `${monthMap[dateItem as keyof MonthMap]}`
                                  : Number(dateItem) < 32
                                    ? `${Number(dateItem)},`
                                    : dateItem
                        )
                        .join(' ')}
                    {startDate.toDateString() !== endDate.toDateString()
                        ? endDate
                              .toDateString()
                              .split(' ')
                              .map((dateItem) =>
                                  dayMap[dateItem as keyof DayMap]
                                      ? ` - ${dayMap[dateItem as keyof DayMap]}, `
                                      : monthMap[dateItem as keyof MonthMap]
                                        ? `${monthMap[dateItem as keyof MonthMap]}`
                                        : Number(dateItem) < 32
                                          ? `${Number(dateItem)},`
                                          : dateItem
                              )
                              .join(' ')
                        : ''}
                </h2>
                <button
                    className='py-[2px] px-[16px] dark:bg-accent bg-blue rounded-[4px] dark:text-darkaccent text-white uppercase font-bold'
                    onClick={() => navigate('/orders/purchase-orders/new')}
                >
                    + New PO
                </button>
            </div>
            {<FiltersMenu filters={filters} ex={filterProps} />}

            <div className='flex flex-col gap-[16px]'>
                <div ref={searchRef} className='w-fit relative'>
                    <input
                        className='px-[4px] py-[2px] w-[200px] dark:text-offwhite rounded bg-[whitesmoke] dark:bg-darkaccent focus:outline-none'
                        placeholder='Search'
                        type='text'
                        value={orderSearch}
                        onChange={({ target }) => setOrderSearch((prev) => target.value)}
                        onFocus={() => setShowSearchResults(true)}
                    />
                    {showSearchResults && (
                        <div className='absolute top-[100%] left-0 z-[5] bg-white shadow-small dark:shadow-cool rounded-[4px] dark:bg-darkaccent p-[8px] pb-[0] w-[400px]'>
                            {searchResults.map((sr, index) => (
                                <Link
                                    key={sr.id}
                                    className={`flex gap-[8px] pb-[8px] ${
                                        index !== searchResults.length - 1 ? 'border-b dark:border-lightgrey' : ''
                                    }`}
                                    to={`/orders/purchase-orders/${sr.id}`}
                                >
                                    <p className='dark:text-offwhite font-bold'>{sr.order_name}</p>
                                    <p className='dark:text-offwhite font-bold'>{sr.supplier}</p>
                                    <p className='dark:text-offwhite font-light ml-auto'>
                                        {timeFormatter.format(new Date(sr.created_at))}
                                    </p>
                                </Link>
                            ))}
                            {searchResults.length === 0 && (
                                <div className={`flex gap-[8px] pb-[8px]`}>
                                    <p className='dark:text-offwhite font-bold'>No results</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <PurchaseOrdersList purchaseOrders={purchaseOrders} selected={selected} setSelected={setSelected} />

                <div className='sticky bottom-[16px] flex flex-col gap-[8px] justify-center items-center'>
                    {selected.length > 0 && (
                        <div className='grid gap-[8px] bg-white dark:bg-darkaccent dark:border-[1px] dark:border-darkgrey rounded shadow-small py-[8px] px-[16px] [&>*]:text-[14px] [&>*]:font-semibold dark:text-offwhite'>
                            <span className='text-center'>{selected.length} Selected</span>
                            <div className='flex gap-[8px] items-center justify-between '>
                                {/* <button
                                    onClick={() => {
                                        window.alert(
                                            "This doesn't do anything yet, but if you would like this screen to allow changing the status of POs, the devs will hop on that :)"
                                        )
                                    }}
                                    className='border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black'
                                >
                                    Receive
                                </button>
                                <button
                                    onClick={() => {
                                        window.alert(
                                            "This doesn't do anything yet, but if you would like this screen to allow changing the status of POs, the devs will hop on that :)"
                                        )
                                    }}
                                    className='border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black'
                                >
                                    Archive
                                </button> */}
                            </div>
                        </div>
                    )}
                    <div className='flex gap-[16px] items-center justify-center bg-white dark:bg-darkaccent dark:border-[1px] border-darkgrey shadow-small rounded p-[8px] pointer-events-none'>
                        {page > 0 && (
                            <div
                                className='grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer hover:bg-[lightgrey] dark:hover:bg-darkaccent'
                                onClick={() => {
                                    setPage(page - 1)
                                }}
                            >
                                <BiLeftArrow className='fill-darkgrey dark:fill-accent' />
                            </div>
                        )}
                        <div className='font-bold dark:text-offwhite'>Page {page + 1}</div>
                        {purchaseOrders.length >= 50 && (
                            <div
                                className='grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer hover:bg-[lightgrey] dark:hover:bg-darkaccent'
                                onClick={() => {
                                    setPage(page + 1)
                                }}
                            >
                                <BiRightArrow className='fill-darkgrey dark:fill-accent' />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
