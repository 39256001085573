import { useEffect, useState } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { vFetch } from '../helpers'


export default function TasksModule() {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const [tasks, setTasks] = useState([])
    useEffect(() => {
        setTasks([])
        if (user) {
            vFetch(`/tasks/all?user=${user.user_id}&endDate=${new Date().toISOString().split('T')[0]}&status=OPEN`, {
                cb: (res) => {
                    if (res.success) {
                        setTasks(res.tasks)
                    }
                },
            })
        }
    }, [user])
    return (
        <div className='shadow-small rounded-[4px] dark:bg-darkaccent p-[8px] overflow-auto'>
            <Link to='/tasks/manager' className='text-fire font-bold mb-[8px] flex gap-[4px] items-center'>
                Open Tasks <BiLinkExternal />
            </Link>
            <div className='max-h-[280px] overflow-auto'>
                {tasks.map((t) => (

                    <Link
                        key={t.id}
                        to={t.task_type !== "Sales" ? (
                            (`/orders/${t.order_id}?navigated_from=task=${t.id}`)
                        ) : (
                            t.draft_order_id ? (
                                (`/draftOrders/${t.draft_order_id}?navigated_from=task=${t.id}`)
                            ) : (
                                (`/tasks/${t.id}?navigated_from=task=${t.id}`)
                            )
                        )

                        }

                        className='text-darkgrey dark:text-offwhite text-[12px] uppercase font-bold p-[8px] block w-full border-b border-lightgreay dark:border-darkgrey'
                    >
                        <span className='dark:text-accent'>{t.id}</span> /{' '}
                        <span className='dark:text-accent'>{t.order_name || t.draft_order_name}</span> - {t.task_type} (
                        {t.topic})
                        <br />
                        <span className='text-[16px] normal-case text-blue dark:text-offwhite'>{t.title}</span>
                    </Link>
                ))}
                {tasks.length === 0 && <p className='text-white'>You're all caught up!</p>}
            </div>
        </div>
    )
}
