import { useContext, useRef, useState } from 'react'
import { MdDoubleArrow } from 'react-icons/md'
import Input from './Input'
import { useCompanyDetailQuery, useCreateAlert, useProductAlertsQuery } from '../api/useQueries'
import { ProcurementContext, ProcurementContextType } from '../helpers'
import { ProductAlert } from './lists/ProductAlert'

export function CompanyProductHeader({ showNewAlert, setShowNewAlert }: any) {
    const alertInputContainerRef = useRef<HTMLInputElement>(null)
    const [showAlerts, setShowAlerts] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [newAlert, setNewAlert] = useState('')
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const { mutate: createAlertMutation, isPending: createAlertPending } = useCreateAlert(selectedCompany.id)

    const handleCreateAlert = () => {
        createAlertMutation(
            {
                affects: 'products',
                message: newAlert,
                company_id: selectedCompany.id,
            },
            {
                onSuccess: () => {
                    setNewAlert('')
                    setShowNewAlert(false)
                },
            }
        )
    }
    const { data: alertsData } = useProductAlertsQuery(selectedCompany.id)
    const companyQuery = useCompanyDetailQuery(selectedCompany.id, selectedCompany)
    const company = companyQuery.data?.company || {}

    const companyDetails = [
        {
            label: 'Shipping',
            details: [
                {
                    label: 'Shipping Details',
                    detail: company?.shipping_details,
                },
                {
                    label: 'Shipping Terms',
                    detail: company?.shipping_terms,
                },
                {
                    label: 'US Ships From',
                    detail: company?.us_ships_from,
                },
                {
                    label: 'Canada Ships From',
                    detail: company?.canada_ships_from,
                },
            ],
        },
    ]
    function ProductDetail({ details }: { details: any }) {
        return (
            <div
                className={`p-[4px] px-[8px] flex justify-between rounded-[4px] border border-darkgrey bg-lightgrey/10 font-bold text-[14px]`}
            >
                <p>{details.label}</p>
                {details.details.map((detail: any) => {
                    return (
                        <p>
                            {detail.label} - {detail.detail}
                        </p>
                    )
                })}
            </div>
        )
    }

    return (
        <div className=' flex flex-col gap-[3px] mt-[-4px] w-full'>
            {showNewAlert && (
                <div
                    ref={alertInputContainerRef}
                    className={`flex items-center gap-[8px] my-[8px] ${
                        createAlertPending ? 'opacity-[0.5] pointer-events-none' : ''
                    }`}
                >
                    <Input
                        id='alert'
                        name='alert'
                        type='text'
                        placeholder='Type your alert message'
                        value={newAlert}
                        onChange={({ target }) => setNewAlert(target.value)}
                    />
                    <button
                        onClick={() => setShowNewAlert(false)}
                        className='w-fit shrink-0 text-[14px] leading-[1] py-[8px] px-[8px] rounded-[4px] bg-darkgrey dark:bg-darkgrey text-white dark:text-offwhite font-bold uppercase'
                    >
                        CANCEL
                    </button>
                    <button
                        onClick={handleCreateAlert}
                        className='w-fit shrink-0 text-[14px] leading-[1] py-[8px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase'
                    >
                        CREATE ALERT
                    </button>
                </div>
            )}
            <div className='flex flex-col gap-[8px]'>
                <div className='flex flex-col gap-[3px]'>
                    <div
                        onClick={() => setShowDetails(showDetails ? false : true)}
                        className={`cursor-pointer p-[4px] px-[8px] flex justify-between rounded-[4px] border border-darkgrey bg-lightgrey/10 font-bold text-[14px]`}
                    >
                        <div className='flex justify-between w-full items-center'>
                            <p>Details</p>
                            <MdDoubleArrow
                                className={`w-[16px]  h-[16px] ${showDetails ? '-rotate-90' : 'rotate-90'}`}
                            />
                        </div>
                    </div>
                    {showDetails && (
                        <div className='flex flex-col gap-[3px]'>
                            {companyDetails?.map((details: any) => <ProductDetail details={details} />)}
                        </div>
                    )}
                    <div
                        onClick={() => setShowAlerts(showAlerts ? false : true)}
                        className={`cursor-pointer p-[4px] px-[8px] flex justify-between rounded-[4px] border border-fire bg-fire/10 font-bold text-[14px]`}
                    >
                        <div className='flex justify-between w-full items-center'>
                            <p>Alerts</p>
                            <MdDoubleArrow
                                className={`w-[16px]  h-[16px] ${showAlerts ? '-rotate-90' : 'rotate-90'}`}
                            />
                        </div>
                    </div>
                    {showAlerts && (
                        <div className='flex flex-col gap-[3px]'>
                            {alertsData?.alerts?.map((alert: any) => <ProductAlert alert={alert} />)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
