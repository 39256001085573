import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { procurementPrivateEventQueries } from './privateEventQueryKeys'
import * as service from './service'

//company detail

export function useGetPrivateEvents({
    privateEventUrl,
    element,
    id,
}: {
    privateEventUrl: string
    element: string
    id: number
}): any {
    const query = useQuery({
        ...procurementPrivateEventQueries.privateEvents.list({ privateEventUrl, element, id }),
        enabled: Boolean(id),
    })

    return query
}

export function useCreatePrivateEvent() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ privateEventUrl, id, user_id }: { privateEventUrl: string; id: number; user_id: number }) => {
            return service.createPrivateEvent({ privateEventUrl, id, user_id })
        },
        onError: (error, variables, context) => {
            console.log(error)
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: procurementPrivateEventQueries.privateEvents._def })
        },
    })
}
