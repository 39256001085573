import { PersonIcon } from '@radix-ui/react-icons'
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'
import { vFetch } from '../../helpers'
import { UserInit } from '../../users/users.types'
import { CompanyProductType } from '../components/CompanyProduct'
import { EventType } from '../components/lists/EmailList'
import { CompanyTaskType } from '../components/modals/quickActions/CreateTask'

export const dateFormatterOptions: any = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
}

export const createdAtSort = (a: any, b: any) => {
    if (new Date(a.created_at || a.delivered_at).getTime() > new Date(b.created_at || b.delivered_at).getTime()) {
        return -1
    }
    return 1
}

export const getFilterBarUsersActiveFirst = (user: UserInit, users: UserInit[]): object[] => {
    const activeUserList = users.filter((userInsert) => {
        if (userInsert.roles.includes('suspended')) {
            return
        }
        if (user.id === userInsert.id) {
            return
        }
        return userInsert
    })

    const filteredUsers = [...activeUserList].map((userInsert: any) => {
        return {
            value: userInsert.id,
            label: userInsert.user_id,
            icon: PersonIcon,
        }
    })
    filteredUsers.unshift({
        value: user.id,
        label: user.user_id,
        icon: PersonIcon,
    })
    return filteredUsers
}
export const getFilterBarUsers = (users: UserInit[]) => {
    if (!users) {
        return [{}]
    }
    const activeUserList = users.filter((userInsert) => {
        if (userInsert.roles.includes('suspended')) {
            return
        }
        return userInsert
    })

    const filteredUsers = [...activeUserList].map((userInsert: any) => {
        return {
            value: userInsert.id,
            label: userInsert.user_id,
            icon: PersonIcon,
        }
    })

    return filteredUsers
}

export const formatDate = (date: Date) => {
    return (
        new Intl.DateTimeFormat('en-US', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'America/Chicago',
        }).format(date) + ' CT'
    )
}
export const formatDateSimple = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }).format(date)
}
export const formatDateWithYear = (date: Date) => {
    return (
        new Intl.DateTimeFormat('en-US', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            year: 'numeric',
            timeZone: 'America/Chicago',
        }).format(date) + ' CT'
    )
}
export const formatDateDayMonthYear = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'America/Chicago',
    }).format(date)
}

export const ProcurementContext = createContext<any>(null)
export type ProcurementContextType = {
    loading: boolean
    view: string
    activeModal?: string
    toggleModal: (modalName?: string, company?: any) => void
    lastViewedCompany: string
    handleChangeView: (view: string) => void
    needsRefresh: boolean
    setNeedsRefresh: Dispatch<SetStateAction<boolean>>
    companies: any[]
    setCompanies: Dispatch<SetStateAction<any>>
    procurementReps: UserInit[]
    users: UserInit[]
    productTypes: string[]
    selectedCompany: any
    selectedCompanyId: any
    setSelectedCompanyId: Dispatch<SetStateAction<any>>
    getCompanies: (options?: {
        user_id?: string | number
        search?: string
        lead_type?: string
        lifecycle_status?: string
    }) => void
    setSelectedCompany: Dispatch<SetStateAction<any>>
    handleAddAssignment: (company_id: string | number, user_id: string | number) => void
    handleRemoveAssignment: (company_id: string | number, user_id: string | number) => void
    categories: CategoryType[]
    getCategories: () => void
}
export type CategoryType = {
    id?: number | string
    user_id: number | string
    name: string
    store_id?: number | string
    created_at?: string
    updated_at?: string
}
export const ProcurementProvider = ({ children }: any) => {
    const analytics = useAnalytics()
    const user = useSelector((state: any) => state.user)
    const [searchParams, setSearchParams] = useSearchParams()
    const urlParamCompanyId = searchParams.get('company_id')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [view, setView] = useState('companies')
    const [activeModal, setActiveModal] = useState<string | undefined>(undefined)
    const [needsRefresh, setNeedsRefresh] = useState(true)
    const [companies, setCompanies] = useState<any[]>([])
    const [procurementReps, setProcurementReps] = useState<any[]>([])
    const [selectedCompany, setSelectedCompany] = useState<any>(undefined)
    const [selectedCompanyId, setSelectedCompanyId] = useState<any>(undefined)
    const [users, setUsers] = useState<UserInit[]>([])
    const [productTypes, setProductTypes] = useState<string[]>([])
    const [categories, setCategories] = useState<CategoryType[]>([])

    const getCategories = async () => {
        setLoading(true)
        vFetch(
            `/procurement/categories${
                user.type === 'Admin' || user.type === 'SuperAdmin' ? '' : `?user_id=${user.id}`
            }`,
            {
                cb: (res: any) => {
                    setCategories(res.categories || [])
                },
            }
        ).then(() => {
            setLoading(false)
            setNeedsRefresh(false)
        })
    }

    const getUsers = async () => {
        vFetch(`/v2/users`, {
            cb: (res: any) => {
                setUsers(res.users)
            },
        })
    }

    const getProductTypes = async () => {
        vFetch('/v2/products/types', {
            cb: (res: any) => {
                setProductTypes(res.productTypes)
            },
        })
    }

    const getReps = async () => {
        await vFetch('/v2/users?role=procurement', {
            cb: (res: any) => {
                if (res.success) {
                    setProcurementReps(res.users)
                }
            },
        })
            .then(() => {
                setLoading(false)
                setNeedsRefresh(false)
            })
            .catch(() => {
                setLoading(false)
                setNeedsRefresh(false)
            })
    }

    const toggleModal = (modalName?: string, company?: any) => {
        setSelectedCompanyId(company?.id)
        setSelectedCompany(company)
        setActiveModal(modalName)
        if (modalName === 'company') {
            localStorage.setItem('lastViewedCompany', window.location.search)
            analytics.page()
        }
        if (!modalName) {
            if (urlParamCompanyId) {
                localStorage.setItem('lastViewedCompany', window.location.search)
            }
            navigate('/procurement')
        }
    }

    const handleChangeView = (view: string) => {
        setNeedsRefresh(true)
        setView(view)
    }

    useEffect(() => {
        getReps()
        getUsers()
        getProductTypes()
    }, [])

    useEffect(() => {
        if (needsRefresh) {
            if (view === 'my-companies') {
                setLoading(false)
            } else if (view === 'companies') {
                setLoading(false)
            } else if (view === 'tasks') {
                setLoading(false)
            } else if (view === 'categories') {
                getCategories()
                setLoading(false)
            }
        }
    }, [needsRefresh, view])

    return (
        <ProcurementContext.Provider
            value={
                {
                    loading,
                    view,
                    activeModal,
                    toggleModal,
                    handleChangeView,
                    needsRefresh,
                    setNeedsRefresh,
                    companies,
                    setCompanies,
                    procurementReps,
                    selectedCompany,
                    setSelectedCompany,
                    selectedCompanyId,
                    setSelectedCompanyId,
                    users,
                    productTypes,
                    categories,
                    getCategories,
                } as any
            }
        >
            {children}
        </ProcurementContext.Provider>
    )
}

export const CompanyContext = createContext<any>(null)
export type CompanyContextType = {
    loading: boolean
    edited: boolean
    editableCompany: any
    setEditableCompany: Dispatch<SetStateAction<any>>
    handleUpdateCompany: (update: any) => void
    showQuickModal: string | undefined
    setShowQuickModal: Dispatch<SetStateAction<string | undefined>>
    resourceView: string
    setResourceView: Dispatch<SetStateAction<string>>
    products: CompanyProductType[]
    getProducts: () => void
    setProducts: Dispatch<SetStateAction<CompanyProductType[]>>
    events: EventType[]
    getEvents: () => void
    tasks: CompanyTaskType[]
    getTasks: () => void
    contacts: any[]
    getContacts: () => void
    files: any[]
    getFiles: () => void
    emails: any[]
    getEmails: () => void
    incomingEmails: any[]
    getIncomingEmails: () => void
}
export const CompanyProvider = ({ children }: any) => {
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const [editableCompany, setEditableCompany] = useState<any>(structuredClone(selectedCompany))
    const [showQuickModal, setShowQuickModal] = useState<string | undefined>(undefined)
    const [searchParams, setSearchParams] = useSearchParams()
    const urlParamTaskId = searchParams.get('task_id')
    const view = searchParams.get('view') || 'activity'
    const [resourceView, setResourceView] = useState<any>(urlParamTaskId ? 'tasks' : view)
    const [edited, setEdited] = useState(false)
    const [products, setProducts] = useState<CompanyProductType[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (selectedCompany) {
            setLoading(false)
        }
        setEditableCompany(structuredClone(selectedCompany))
    }, [selectedCompany])

    useEffect(() => {
        if (JSON.stringify(selectedCompany) !== JSON.stringify(editableCompany)) {
            setEdited(true)
        } else {
            setEdited(false)
        }
    }, [selectedCompany, editableCompany])

    return (
        <CompanyContext.Provider
            value={
                {
                    edited,
                    loading,
                    editableCompany,
                    setEditableCompany,
                    showQuickModal,
                    setShowQuickModal,
                    resourceView,
                    setResourceView,
                    products,
                    setProducts,
                } as any
            }
        >
            {children}
        </CompanyContext.Provider>
    )
}

export const MONTH_NAME_MAP = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
}

export const ProductPollingContext = createContext<any>(null)
export const ProductPollingProvider = ({ children }: any) => {
    const user = useSelector((state: any) => state.user)
    const [appState, setAppState] = useState<any>({})
    const [selectedProductId, setSelectedProductId] = useState(-1)
    const [pendingEdits, setPendingEdits] = useState(false)
    useEffect(() => {
        const productPoller = setInterval(() => {
            vFetch('/procurement/products/state', {
                method: 'POST',
                body: JSON.stringify({
                    user_id: user.id,
                    product_id: selectedProductId,
                }),
                cb: (res: any) => {
                    setAppState(res.appState || {})
                },
                skipCatchToast: true,
                skipToast: true,
            })
        }, 1000)

        return () => clearInterval(productPoller)
    }, [selectedProductId, user.id])

    return (
        <ProductPollingContext.Provider
            value={{ appState, selectedProductId, setSelectedProductId, pendingEdits, setPendingEdits }}
        >
            {children}
        </ProductPollingContext.Provider>
    )
}
