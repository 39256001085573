import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SelectedProduct from './components/SelectedProduct'
import { STATE_CODE_LIST } from '../constants'
import './CreateTaskScreen.css'
import Calendar from '../../custom_components/Calendar'
import CustomerSearchbar from '../components/CustomerSearchbar'
import CustomerModal from '../../customers/CustomerModal'
import {
    closePopout,
    createOrderNotesArray,
    fetchActiveUsers,
    fpdashRegex,
    parseResObject,
    sendToast,
    taskIdRegex,
    timestampRegex,
    vFetch,
} from '../../helpers'
import { OrderNote } from '../../orders/components/OrderNote'
import {
    ExistingTaskTypes,
    OrderButtonPropsInit,
    TaskCreateInit,
    TaskEditInit,
    defaultTaskCreate,
    defaultTaskCustomer,
    topicMap,
} from '../tasks.types'
import { LineItemInit, OrderCustomer, OrderInit, OrderNoteInit, defaultOrder } from '../../orders/orders.types'
import { UserlistDropdown } from '../../custom_components/UserlistDropdown'
import { taskVariables } from '../TaskVariables'

export default function CreateTaskScreen() {
    const { user, settings } = useSelector<any, any>((state) => state)
    const navigate = useNavigate()
    const { priorityLevels } = taskVariables
    const [params] = useSearchParams()
    const [loading, setLoading] = useState(false)
    const [showCustomer, setShowCustomer] = useState<string[]>([])
    const [customer, setCustomer] = useState<OrderCustomer>(defaultTaskCustomer)
    const [customers, setCustomers] = useState([])

    const defaultTask = {
        ...defaultTaskCreate,
        assigned_to: [user.email.split('@')[0]],
        due_at: new Date(),
        customer: customer,
        due_at_revisions: 0,
        priority: 'Low',
        project: 'FactoryPure',
        status: 'OPEN',
        task_type: params.get('task_type') || 'Select Type',
        topic: 'Return',
    }
    const [dueAtInvalid, setDueAtInvalid] = useState(false)
    const [keys, setKeys] = useState({})

    const [task, setTask] = useState<TaskCreateInit>(defaultTask)
    const [shippingAndBillingSame, setShippingAndBillingSame] = useState(false)
    const [customerInfoAndShippingSame, setCustomerInfoAndShippingSame] = useState(false)
    const [customerInfoAndBillingSame, setCustomerInfoAndBillingSame] = useState(false)
    const [selectedCustomerId, setSelectedCustomerId] = useState<any>(null)
    const [showSearch, setShowSearch] = useState(false)
    const [search, setSearch] = useState('')
    const [newSearch, setNewSearch] = useState(false)
    const [filtersReadyToSearch, setFiltersReadyToSearch] = useState<number | null>(null)
    const [filters, setFilters] = useState([])
    const [existingTaskTypes, setExistingTaskTypes] = useState<ExistingTaskTypes>({})

    const handleChangeTask = ({ target }: MouseEvent) => {
        const inputTarget = target as HTMLInputElement
        if (inputTarget.type === 'checkbox') {
            setTask({
                ...task,
                [inputTarget.name]: inputTarget.checked,
            })
        } else if (inputTarget.name === 'task_type') {
            const linkRegex = /\w+:\d+\n{1}/
            const tasksLinks = task.order_notes?.match(linkRegex)
            let abbreviatedType = ''
            for (let word of inputTarget.value.split(' ')) {
                abbreviatedType += word.slice(0, 1).toUpperCase()
            }
            if (tasksLinks) {
                const foundTask = tasksLinks.find((m) => m.split(':')[0] === abbreviatedType)
                if (foundTask) return navigate(`/tasks/${foundTask.split(':')[1].trim()}`)
            }

            if (existingTaskTypes[inputTarget.value as keyof ExistingTaskTypes])
                navigate(
                    `/tasks/${existingTaskTypes[inputTarget.value as keyof ExistingTaskTypes]
                        ?.replace(inputTarget.value, '')
                        .replace('(', '')
                        .replace(')', '')
                        .trim()}`
                )

            setTask({
                ...task,
                [inputTarget.name]: inputTarget.value,
            })
        } else {
            setTask({
                ...task,
                [inputTarget.name]: inputTarget.value,
            })
        }
    }
    const handleChangeDueAt = (startDate: Date) => {
        setTask({ ...task, due_at: startDate })
        ;(document.getElementById('due_at_invisible') as HTMLInputElement).value = [
            task.due_at.getFullYear(),
            ((Number(task.due_at.getMonth()) + 1) / 10).toFixed(1).replace('.', ''),
            (Number(task.due_at.getDate()) / 10).toFixed(1).replace('.', ''),
        ].join('-')
    }
    const [userList, setUserList] = useState([])
    const [selectedProducts, setSelectedProducts] = useState<LineItemInit[]>([])
    const [filteredOrders, setFilteredOrders] = useState([])
    const [orderSearch, setOrderSearch] = useState('')
    const [order, setOrder] = useState<OrderInit>(defaultOrder)
    const [orderMode, setOrderMode] = useState<'orders' | 'draftOrders'>('orders')
    const [showOrderDropdown, setShowOrderDropdown] = useState(false)
    const searchInput = useRef<HTMLInputElement>(null)

    const useDebounce = (value: string = '', delay: number) => {
        const [debouncedValue, setDebouncedValue] = useState(value)
        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value.toLowerCase())
            }, delay)
            return () => {
                clearTimeout(handler)
            }
        }, [value, delay])
        return debouncedValue
    }
    const debouncedSearch = useDebounce(orderSearch, 300)
    const abortController = new AbortController()
    useEffect(() => {
        if (debouncedSearch) {
            vFetch(
                `/${orderMode}?fields=name,admin_graphql_api_id,id&search=${debouncedSearch
                    .replace('#', '')
                    .replace('D', '')
                    .replace('d', '')}`,
                {
                    abortController,
                    cb: (res: any) => {
                        if (res.success) {
                            if (res.orders) setFilteredOrders(res.orders)
                            else if (res.draft_orders) setFilteredOrders(res.draft_orders)
                        }
                    },
                }
            )
            // if (debouncedSearch && debouncedSearch.length > 0 && debouncedSearch !== order.name) {
            //     setShowOrderDropdown(true)
            // } else {
            //     setShowOrderDropdown(false)
            // }
            return () => abortController.abort()
        }
    }, [debouncedSearch, orderMode])

    const [screenSize, setScreenSize] = useState(window.visualViewport!.width)
    useEffect(() => {
        window.addEventListener('resize', () => setScreenSize(window.visualViewport!.width))
        const listenToWindow = ({ target }: MouseEvent) => {
            const t = target as HTMLElement
            if (!searchInput.current!.contains(target as HTMLElement)) {
                setShowOrderDropdown(false)
            }
        }
        window.addEventListener('click', listenToWindow)

        const listenToSearch = (e: MouseEvent) => {
            closePopout(e, ['js-create-task-screen-search'], setShowSearch)
        }
        window.addEventListener('click', listenToSearch)

        // fetches the user list for populating the "Assigned To" select element
        fetchActiveUsers(setUserList)

        if (params.get('customer_id')) {
            vFetch(`/customers/${params.get('customer_id')}`, {
                cb: (res: any) => {
                    const resCustomer = parseResObject(res.customer)
                    setCustomer(resCustomer)
                    setTask({ ...task, customer: resCustomer })
                },
            })
        }

        if (params.get('order_id')) {
            navigate(`/orders/${params.get('order_id')}`)
            if (params.get('task_type')) {
                vFetch(`/tasks?id=${params.get('order_id')}`, {
                    cb: (res: any) => {
                        const foundTask = res.tasks.find((t: TaskEditInit) => t.task_type === params.get('task_type'))
                        if (foundTask) navigate(`/tasks/${foundTask.id}`)
                    },
                })
            }
            vFetch(`/orders/${params.get('order_id')}`, {
                cb: (res: any) => {
                    if (res.success) {
                        if (res.order) {
                            const resOrder: any = parseResObject(res.order)
                            setCustomer(resOrder.customer)
                            setOrder(resOrder)
                            setTask({
                                ...task,
                                customer: resOrder.customer,
                                order_id: res.order.id,
                                order_name: res.order.name,
                                order_notes: res.order
                                    ? res.order.note
                                        ? res.order.note
                                        : ''
                                    : res.draft_order
                                      ? res.draft_order.note
                                      : '',
                            })
                        } else {
                            sendToast({ message: 'Invalid Order ID' })
                        }
                    }
                },
            })
        }
        if (params.get('draft_order_id')) {
            navigate(`/draftOrders/${params.get('draft_order_id')}`)
            if (params.get('task_type')) {
                vFetch(`/tasks?id=${params.get('draft_order_id')}`, {
                    cb: (res: any) => {
                        const foundTask = res.tasks.find((t: TaskEditInit) => t.task_type === params.get('task_type'))
                        if (foundTask) navigate(`/tasks/${foundTask.id}`)
                    },
                })
            }
            vFetch(`/draftOrders/${params.get('draft_order_id')}`, {
                cb: (res: any) => {
                    if (res.success) {
                        if (res.draft_order) {
                            const resDraftOrder: any = parseResObject(res.draft_order)

                            // const resDraftOrder: any = {}
                            // Object.keys(res.draft_order).forEach((k) =>
                            //     orderParsibleKeys.includes(k) && res.draft_order[k]
                            //         ? (resDraftOrder[k] = JSON.parse(res.draft_order[k]))
                            //         : (resDraftOrder[k] = res.draft_order[k])
                            // )
                            setOrder(resDraftOrder)
                            const resDOCustomer = resDraftOrder.customer
                            // resDOCustomer.shipping_address = res.draft_order.shipping_address
                            //     ? JSON.parse(res.draft_order.shipping_address)
                            //     : undefined
                            // resDOCustomer.billing_address = res.draft_order.billing_address
                            //     ? JSON.parse(res.draft_order.billing_address)
                            //     : undefined
                            setCustomer(resDOCustomer)
                            setTask({
                                ...task,
                                customer: resDOCustomer,
                                task_type: 'Sales',
                                topic: 'Lead',
                                draft_order_id: res.draft_order.id,
                                draft_order_name: res.draft_order.name,
                                order_notes: res.order
                                    ? res.order.note
                                        ? res.order.note
                                        : ''
                                    : res.draft_order
                                      ? res.draft_order.note
                                      : '',
                            })
                        } else {
                            sendToast({ message: 'Invalid Draft Order ID' })
                        }
                    }
                },
            })
        }
        return () => {
            window.removeEventListener('click', listenToWindow)
            window.removeEventListener('click', listenToSearch)
        }
    }, [])

    const OrderButton = ({ currentOrder }: OrderButtonPropsInit) => {
        const handleChangeOrder = () => {
            vFetch(`/tasks?order_name=${encodeURIComponent(currentOrder.name)}`, {
                cb: (res: any) => {
                    if (
                        res.success &&
                        res.tasks &&
                        res.tasks.length > 0 &&
                        res.tasks.find((t: any) => t.task_type === task.task_type)
                    ) {
                        navigate(`/tasks/${res.tasks.find((t: any) => t.task_type === task.task_type).id}`)
                    } else {
                        Object.keys(topicMap).forEach((topic) => {
                            if (topic !== 'Select Type' && res.tasks.some((task: any) => task.task_type === topic)) {
                                existingTaskTypes[topic as keyof ExistingTaskTypes] = `${topic} (${
                                    res.tasks.find((task: any) => task.task_type === topic).id
                                })`
                            } else {
                                existingTaskTypes[topic as keyof ExistingTaskTypes] = undefined
                            }
                        })
                        vFetch(`/orders/${currentOrder.id}`, {
                            cb: (oRes: any) => {
                                if (oRes.success) {
                                    setOrder(parseResObject(oRes.order))
                                    setTask({
                                        ...task,
                                        order_id: oRes.order.id,
                                        order_name: oRes.order.name,
                                        order_notes: oRes.order.note ? oRes.order.note : '',
                                    })
                                }
                            },
                        })
                    }
                },
            })
            setShowOrderDropdown(false)
        }
        return (
            <div
                onClick={handleChangeOrder}
                className='js-dropdown__option flex px-[8px] py-[2px] gap-[8px] items-center cursor-pointer hover:bg-[rgb(240,240,240)] dark:hover:bg-darkaccent'
            >
                <div
                    className={`w-[16px] h-[16px] border-[1px] border-[#cdcdcd] dark:border-[blueviolet] rounded js-dropdown__checkbox${
                        order.name === currentOrder.name ? ' js-dropdown__checkbox--checked' : ''
                    }`}
                >
                    &nbsp;
                </div>
                <p className='pointer-events-none'>{currentOrder.name}</p>
            </div>
        )
    }
    const DraftButton = ({ currentOrder }: OrderButtonPropsInit) => {
        const handleChangeOrder = () => {
            vFetch(`/tasks?draft_order_name=${encodeURIComponent(currentOrder.name)}`, {
                cb: (res: any) => {
                    if (
                        res.success &&
                        res.tasks &&
                        res.tasks.length > 0 &&
                        res.tasks.find((t: any) => t.task_type === task.task_type)
                    ) {
                        navigate(`/tasks/${res.tasks.find((t: any) => t.task_type === task.task_type).id}`)
                    } else {
                        Object.keys(topicMap).forEach((topic) => {
                            if (topic !== 'Select Type' && res.tasks.some((task: any) => task.task_type === topic)) {
                                existingTaskTypes[topic as keyof ExistingTaskTypes] = `${topic} (${
                                    res.tasks.find((task: any) => task.task_type === topic).id
                                })`
                            } else {
                                existingTaskTypes[topic as keyof ExistingTaskTypes] = undefined
                            }
                        })
                        vFetch(`/draftOrders/${currentOrder.id}`, {
                            cb: (dRes: any) => {
                                if (dRes.success) {
                                    const resDraftOrder = parseResObject(dRes.draft_order)
                                    // const resDraftOrder: any = {}
                                    // Object.keys(dRes.draft_order).forEach(
                                    //     (key) =>
                                    //     (resDraftOrder[key] =
                                    //         dRes.draft_order[key] && orderParsibleKeys.includes(key)
                                    //             ? JSON.parse(dRes.draft_order[key])
                                    //             : dRes.draft_order[key])
                                    // )
                                    setOrder(resDraftOrder)
                                    const resDOCustomer = resDraftOrder.customer
                                    // resDOCustomer.shipping_address = dRes.draft_order.shipping_address
                                    //     ? JSON.parse(dRes.draft_order.shipping_address)
                                    //     : undefined
                                    // resDOCustomer.billing_address = dRes.draft_order.billing_address
                                    //     ? JSON.parse(dRes.draft_order.billing_address)
                                    //     : undefined
                                    setCustomer(resDOCustomer)
                                    setTask({
                                        ...task,
                                        draft_order_id: dRes.draft_order.id,
                                        draft_order_name: dRes.draft_order.name,
                                        order_notes: dRes.draft_order
                                            ? dRes.draft_order.note
                                                ? dRes.draft_order.note
                                                : ''
                                            : '',
                                    })
                                }
                            },
                        })
                    }
                },
            })
            setShowOrderDropdown(false)
        }
        return (
            <div
                onClick={handleChangeOrder}
                className='js-dropdown__option flex px-[8px] py-[2px] gap-[8px] items-center cursor-pointer hover:bg-[rgb(240,240,240)] dark:hover:bg-darkaccent'
            >
                <div
                    className={`w-[16px] h-[16px] border-[1px] border-[#cdcdcd] dark:border-[blueviolet] rounded js-dropdown__checkbox${
                        order.name === currentOrder.name ? ' js-dropdown__checkbox--checked' : ''
                    } pointer-events-none`}
                >
                    &nbsp;
                </div>
                <p className='pointer-events-none'>{currentOrder.name}</p>
            </div>
        )
    }

    useEffect(() => {
        if (order.note) {
            const taskURL = order.note.match(fpdashRegex)
            if (taskURL && taskURL[0].substr(18).trim().length > 7) {
                //navigate(taskURL[0].substr(18));
            }
        }

        setOrderSearch(order.name)
        if (order.customer) {
            setTask({
                ...task,
                customer: {
                    ...order.customer,
                    shipping_address: order.shipping_address,
                },
            })
        }
        if (order.line_items) setSelectedProducts([...order.line_items])
    }, [order])
    useEffect(() => {
        setTask({
            ...task,
            customer: customer,
        })
    }, [customer])
    useEffect(() => {
        if (!(topicMap[task.task_type] as Array<string>).includes(task.topic)) {
            setTask({ ...task, topic: topicMap[task.task_type][0] })
        }
        const textAreas = document.getElementsByTagName('textarea') as any

        for (let element of textAreas) {
            const minHeight = parseInt(
                window.getComputedStyle(element, null).getPropertyValue('min-height').replace('px', '')
            )

            if (element.scrollHeight > minHeight) {
                element.style.height = '0'
                element.style.height = `${element.scrollHeight + 16}px`
            }
        }
    }, [task])
    const debouncedCustomerSearch = useDebounce(search, 300)
    const handleSearch = ({ target }: InputEvent) => {
        setSearch((target as HTMLInputElement).value)
    }
    useEffect(() => {
        if (!selectedCustomerId && (!params.get('customer_id') || search)) {
            setNewSearch(true)
            setShowSearch(true)
        }
    }, [debouncedCustomerSearch, selectedCustomerId, filtersReadyToSearch])
    useEffect(() => {
        if (
            filtersReadyToSearch !==
            filters.reduce((acc: any, cur: any) => acc + (cur.edit === false && cur.value ? 1 : 0), 0)
        ) {
            setFiltersReadyToSearch(
                filters.reduce((acc: any, cur: any) => acc + (cur.edit === false && cur.value ? 1 : 0), 0)
            )
        }
    }, [filters])
    useEffect(() => {
        if (!selectedCustomerId && newSearch) {
            setLoading(true)
            const tags = `&tags=${filters
                .filter((f: any) => f.name === 'Tagged with' && f.value)
                .map((f: any) => f.value)}`
            const notTags = `&notTags=${filters
                .filter((f: any) => f.name === 'Not tagged with' && f.value)
                .map((f: any) => f.value)}`
            const searchParam = debouncedCustomerSearch.length > 0 ? `&search=${search.toLowerCase()}` : ''
            vFetch(`/customers?limit=20${searchParam}${tags}${notTags}`, {
                abortController,
                cb: (res: any) => {
                    if (res.success) {
                        const resCustomers = res.customers.map((c: any) => parseResObject(c))
                        setCustomers(resCustomers)
                        if (newSearch) setNewSearch(false)

                        setLoading(false)
                    }
                },
            })
            return () => abortController.abort()
        }
    }, [newSearch, selectedCustomerId])
    useEffect(() => {
        if (JSON.stringify(customer) !== JSON.stringify({}))
            setShowCustomer(['Shipping Address', 'Billing Address', 'Customer Information'])
    }, [customer])
    useEffect(() => {
        if (
            shippingAndBillingSame &&
            JSON.stringify(customer.shipping_address) !== JSON.stringify(customer.billing_address)
        ) {
            setCustomer({ ...customer, billing_address: customer.shipping_address })
        }
        if (
            customerInfoAndShippingSame &&
            JSON.stringify(customer.default_address) !== JSON.stringify(customer.shipping_address)
        ) {
            setCustomer({
                ...customer,
                shipping_address: {
                    ...customer.default_address,
                    first_name: customer.first_name,
                    last_name: customer.last_name,
                },
            })
        }
        if (
            customerInfoAndBillingSame &&
            JSON.stringify(customer.default_address) !== JSON.stringify(customer.billing_address)
        ) {
            setCustomer({
                ...customer,
                billing_address: {
                    ...customer.default_address,
                    first_name: customer.first_name,
                    last_name: customer.last_name,
                },
            })
        }
    }, [shippingAndBillingSame, customerInfoAndShippingSame, customer])

    const handleChangeCustomer = ({ target }: MouseEvent) => {
        const inputTarget = target as HTMLInputElement
        if (customer.billing_address && customer.shipping_address) {
            if (inputTarget.name.includes('shipping_address')) {
                setCustomer({
                    ...customer,
                    billing_address: { ...customer.billing_address },
                    shipping_address: {
                        ...customer.shipping_address,
                        [inputTarget.name.replace('shipping_address.', '')]: inputTarget.value,
                    },
                })
            } else if (inputTarget.name.includes('billing_address')) {
                setCustomer({
                    ...customer,
                    billing_address: {
                        ...customer.billing_address,
                        [inputTarget.name.replace('billing_address.', '')]: inputTarget.value,
                    },
                    shipping_address: { ...customer.shipping_address },
                })
            } else if (inputTarget.name.includes('default_address')) {
                setCustomer({
                    ...customer,
                    shipping_address: { ...customer.shipping_address },
                    billing_address: { ...customer.billing_address },
                    default_address: {
                        ...customer.default_address,
                        [inputTarget.name.replace('default_address.', '')]: inputTarget.value,
                    },
                })
            } else if (inputTarget.name === 'first_name' || inputTarget.name === 'last_name') {
                setCustomer({
                    ...customer,
                    default_address: { ...customer.default_address, [inputTarget.name]: inputTarget.value },
                    shipping_address: { ...customer.shipping_address },
                    billing_address: { ...customer.billing_address },
                    [inputTarget.name]: inputTarget.value,
                })
            } else {
                setCustomer({
                    ...customer,
                    default_address: { ...customer.default_address },
                    shipping_address: { ...customer.shipping_address },
                    billing_address: { ...customer.billing_address },
                    [inputTarget.name]: inputTarget.value,
                })
            }
        }
    }
    const handleSubmit = () => {
        if (task.assigned_to.length == 0) {
            return sendToast({ message: 'Please assign someone to this task!' })
        }
        if (task.task_type === 'Select Type') {
            return sendToast({ message: 'Please select a task type!' })
        }
        if (task.task_type === 'Customer Service' && (!task.order_name || task.order_name.length === 0)) {
            return sendToast({ message: 'Please assign an order to this task!' })
        }
        if (dueAtInvalid) {
            return sendToast({ message: 'Invalid date. Please assign a valid due date.' })
        }
        setLoading(true)
        if (task.task_type === 'Sales' && customer && !customer.updateCustomer) {
            vFetch(`/customers`, {
                method: 'POST',
                body: JSON.stringify({ customer }),
            })
        } else if (task.task_type === 'Sales' && customer && customer.updateCustomer) {
            vFetch(`/customers/${customer.id}`, {
                method: 'PUT',
                body: JSON.stringify({ update: { tags: customer.tags } }),
            })
        }

        let taskOrderNotes = task.order_notes || ''
        let abbreviatedType = ''
        for (let word of task.task_type.split(' ')) {
            abbreviatedType += word.slice(0, 1).toUpperCase()
        }
        if (taskOrderNotes.match(fpdashRegex)) {
            taskOrderNotes = taskOrderNotes.replace(fpdashRegex, '')
        }
        if (task.task_type !== 'Sales') {
            if (
                taskOrderNotes.match(taskIdRegex) &&
                !taskOrderNotes.match(taskIdRegex)![0].includes(`${abbreviatedType}:`)
            ) {
                taskOrderNotes = `${abbreviatedType}:TASK_ID\n` + taskOrderNotes
            } else {
                taskOrderNotes = `${abbreviatedType}:TASK_ID\n\n` + taskOrderNotes
            }
        }
        const timestampIncluded = task.new_order_note.match(timestampRegex)
        let newNote = (task.order_notes ? '\n\n' : '') + task.new_order_note.trim()

        if (!timestampIncluded && newNote.trim()) {
            newNote += ` (${((new Date().getMonth() + 1) / 10).toFixed(1).replace('.', '')}-${(
                new Date().getDate() / 10
            )
                .toFixed(1)
                .replace('.', '')}-${new Date().getFullYear().toString().slice(2, 4)} ${
                new Date().getHours() === 0
                    ? '12'
                    : ((new Date().getHours() - (new Date().getHours() > 12 ? 12 : 0)) / 10).toFixed(1).replace('.', '')
            }:${(new Date().getMinutes() / 10).toFixed(1).replace('.', '')}${
                new Date().getHours() >= 12 ? 'pm' : 'am'
            }) ${user.user_id} ${abbreviatedType}\n\n`
        } else if (newNote.trim()) {
            newNote += ` ${abbreviatedType}`
        }
        task['order_notes'] = (taskOrderNotes + newNote).trim()
        taskOrderNotes = (taskOrderNotes + newNote).trim()

        vFetch(`/tasks`, {
            method: 'POST',
            body: JSON.stringify({
                task_type: task.task_type,
                title: task.title,
                topic: (document.getElementById('topic') as HTMLInputElement).value,
                order_id: task.order_id,
                order_name: task.order_name,
                draft_order_id: task.draft_order_id,
                draft_order_name: task.draft_order_name,
                line_item_information: selectedProducts,
                assigned_to: task.assigned_to,
                due_at: task.due_at,
                customer: task.customer,
                task_notes: task.task_notes,
                order_notes: task.order_notes,
                is_escalated: task.is_escalated,
                priority: task.priority,
            }),
            cb: async (res: any) => {
                if (res.success) {
                    vFetch(
                        `/${orderMode !== 'draftOrders' ? 'orders' : 'draftOrders'}/${
                            orderMode !== 'draftOrders' ? task.order_id : task.draft_order_id
                        }`,
                        {
                            method: 'POST',
                            body: JSON.stringify({
                                note: taskOrderNotes.replace(
                                    `${abbreviatedType}:TASK_ID`,
                                    `${abbreviatedType}:${res.task_id}`
                                ),
                                task_id: res.task_id,
                                task_type: task.task_type,
                            }),
                        }
                    )
                    navigate('/tasks/manager')
                }
            },
        })
    }
    useEffect(() => {
        setOrderMode(task.task_type === 'Sales' ? 'draftOrders' : 'orders')
    }, [task.task_type])

    return (
        <div className='new-task'>
            <style>{`
                .content {
                    background-color: #fafafa;
                }
            `}</style>
            <h2 className='text-[24px] tracking-[2px] uppercase font-[300] text-darkgrey dark:text-offwhite'>
                Create Task
            </h2>
            <div className='flex gap-[32px]'>
                <div className='flex flex-col gap-[8px] w-[35%]'>
                    <div className='relative mb-[16px] p-[8px] pt-[18px] bg-white dark:bg-darkaccent rounded shadow-small'>
                        <h3 className='absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-white py-[2px] px-[4px] mb-[8px] w-[fit-content]  items-center justify-between'>
                            Task Settings
                        </h3>
                        <div className='flex gap-[8px] w-[100%]'>
                            <div className='relative w-[100%]'>
                                <label
                                    className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                    htmlFor='task_title'
                                >
                                    LATEST ACTIVITY
                                </label>
                                <input
                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                    type='text'
                                    id='title'
                                    name='title'
                                    value={task.title}
                                    onChange={(e: any) => handleChangeTask(e)}
                                />
                            </div>
                            <div className='relative w-[49%]'>
                                <label
                                    className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                    htmlFor='task_due_at'
                                >
                                    DUE AT
                                </label>
                                <div className='flex relative'>
                                    <input
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        type='text'
                                        id='due_at'
                                        name='due_at'
                                        disabled
                                        style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                        value={[
                                            task.due_at.getMonth() + 1,
                                            task.due_at.getDate(),
                                            task.due_at.getFullYear(),
                                        ].join('/')}
                                    />
                                    <input
                                        className={`remove-datepicker absolute w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] py-[3px] ${
                                            dueAtInvalid ? 'bg-red text-offwhite' : 'bg-transparent text-transparent'
                                        } focus:text-black focus:dark:text-offwhite  focus:bg-white dark:focus:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent`}
                                        type='date'
                                        id='due_at_invisible'
                                        name='due_at_invisible'
                                        style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                        onBlur={({ target }) => {
                                            const splitDate = target.value.split('-')
                                            const splitMonthDays = new Date(
                                                Number(splitDate[0]),
                                                Number(splitDate[1]),
                                                0
                                            ).getDate()
                                            const newDueAt = new Date(
                                                Number(splitDate[0]),
                                                Number(splitDate[1]) - 1,
                                                Math.min(splitMonthDays, Number(splitDate[2]))
                                            )
                                            if (newDueAt.getTime()) {
                                                setDueAtInvalid(false)
                                                return setTask({
                                                    ...task,
                                                    due_at: new Date(
                                                        Number(splitDate[0]),
                                                        Number(splitDate[1]) - 1,
                                                        Math.min(splitMonthDays, Number(splitDate[2]))
                                                    ),
                                                })
                                            }

                                            setDueAtInvalid(true)
                                            sendToast({ message: `Invalid date.` })
                                        }}
                                        defaultValue={[
                                            task.due_at.getFullYear(),
                                            ((Number(task.due_at.getMonth()) + 1) / 10).toFixed(1).replace('.', ''),
                                            (Number(task.due_at.getDate()) / 10).toFixed(1).replace('.', ''),
                                        ].join('-')}
                                    />
                                    <div
                                        className={`absolute right-[4px] ${
                                            screenSize < 1400 ? 'top-[-28px]' : 'top-[4px]'
                                        }`}
                                    >
                                        <Calendar
                                            startDate={task.due_at}
                                            setStartDate={handleChangeDueAt}
                                            endDate={task.due_at}
                                            acceptsTextStartDate={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-[8px] w-[100%]'>
                            <div className='relative w-[100%]'>
                                <label
                                    className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                    htmlFor='task_type'
                                >
                                    TYPE
                                </label>
                                <select
                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                    id='task_type'
                                    name='task_type'
                                    value={task.task_type}
                                    defaultValue={''}
                                    onChange={(e: any) => handleChangeTask(e)}
                                >
                                    {Object.keys(existingTaskTypes).length
                                        ? Object.keys(existingTaskTypes).map((t) => (
                                              <option value={t}>
                                                  {existingTaskTypes[t as keyof ExistingTaskTypes]
                                                      ? existingTaskTypes[t as keyof ExistingTaskTypes]
                                                      : t}
                                              </option>
                                          ))
                                        : Object.keys(topicMap).map((t) => <option value={t}>{t}</option>)}
                                </select>
                            </div>
                            <div className='relative w-[100%]'>
                                <label
                                    className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                    htmlFor='task_topic'
                                >
                                    TOPIC
                                </label>
                                <select
                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                    id='topic'
                                    name='topic'
                                    value={task.topic}
                                    onChange={(e: any) => handleChangeTask(e)}
                                >
                                    {topicMap[task.task_type]?.map((t: string) => <option value={t}>{t}</option>)}
                                </select>
                            </div>
                            {task.task_type === 'Sales' ? (
                                <div className='relative w-[100%]'>
                                    <label
                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                        htmlFor='draft_order_name'
                                    >
                                        DRAFT NUMBER
                                    </label>
                                    <input
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        type='text'
                                        id='draft_order_name'
                                        name='draft_order_name'
                                        value={orderSearch}
                                        tabIndex={0}
                                        onChange={(e) => setOrderSearch(e.target.value)}
                                        onFocus={() => setShowOrderDropdown(true)}
                                        autoComplete='off'
                                        ref={searchInput}
                                    />
                                    {showOrderDropdown && (
                                        <div className='js-dropdown z-index-5 absolute top-[100%] max-h-[200px] overflow-auto border-[1px] border-[#cdcdcd] border-t-0 dark:border-darkgrey bg-[white] dark:text-offwhite dark:bg-darkness flex flex-col gap-[16px] left-[-1px] min-w-[100%]'>
                                            {filteredOrders.slice(0, 10).map((o) => (
                                                <DraftButton currentOrder={o} />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='relative w-[100%]'>
                                    {task.task_type === 'Select Type' ? (
                                        <>
                                            <label
                                                className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-darkgrey'
                                                htmlFor='task_order_name'
                                            >
                                                PO NUMBER
                                            </label>
                                            <input
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-grey dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                type='text'
                                                id='task_order_name'
                                                name='task_order_name'
                                                placeholder={'Type Not Selected'}
                                                onChange={(e) => setOrderSearch(e.target.value)}
                                                onFocus={() => setShowOrderDropdown(true)}
                                                autoComplete='off'
                                                ref={searchInput}
                                                disabled
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <label
                                                className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='task_order_name'
                                            >
                                                PO NUMBER
                                            </label>
                                            <input
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                type='text'
                                                id='task_order_name'
                                                name='task_order_name'
                                                value={orderSearch}
                                                onChange={(e) => setOrderSearch(e.target.value)}
                                                onFocus={() => setShowOrderDropdown(true)}
                                                autoComplete='off'
                                                ref={searchInput}
                                            />
                                        </>
                                    )}
                                    {showOrderDropdown && (
                                        <div className='js-dropdown z-index-5 absolute top-[100%] max-h-[200px] overflow-auto border-[1px] border-[#cdcdcd] border-t-0 dark:border-darkgrey bg-[white] dark:text-offwhite dark:bg-darkness flex flex-col gap-[16px] left-[-1px] min-w-[100%]'>
                                            {filteredOrders.slice(0, 10).map((o) => (
                                                <OrderButton currentOrder={o} />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div style={{ marginTop: '4px' }} className='flex gap-[8px] w-[100%]'>
                            <div className='relative w-[100%]'>
                                <label
                                    className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                    htmlFor='task_assign'
                                >
                                    Assigned To
                                </label>
                                {user.type === 'Admin' || user.type === 'SuperAdmin' ? (
                                    <UserlistDropdown
                                        users={userList}
                                        selectedUsers={task.assigned_to}
                                        setSelectedUsers={(assigned_to: string[]) => setTask({ ...task, assigned_to })}
                                    />
                                ) : (
                                    <input
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        type='text'
                                        id='assigned_to'
                                        name='assigned_to'
                                        value={`${task.assigned_to}`}
                                        disabled
                                    />
                                )}
                            </div>
                            <div className='relative w-[100%]'>
                                <label
                                    className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                    htmlFor='task_priority'
                                >
                                    Priority
                                </label>
                                <select
                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                    id='priority'
                                    name='priority'
                                    value={task.priority}
                                    onChange={(e: any) => handleChangeTask(e)}
                                >
                                    {priorityLevels.map((l: string) => (
                                        <option value={l}>{l}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex items-center justify-center border-[1px] border-blue rounded mt-auto relative w-[100%] h-fit py-[4px]'>
                                <input
                                    className='absolute top-0 left-0 w-[100%] h-[100%] appearance-none m-0 cursor-pointer checked:bg-blue peer dark:checked:bg-accent rounded'
                                    type='checkbox'
                                    id='is_escalated'
                                    name='is_escalated'
                                    checked={task.is_escalated}
                                    onChange={(e: any) => handleChangeTask(e)}
                                />
                                <label
                                    className='pointer-events-none relative z-index-2 uppercase text-[15px] peer-checked:text-white dark:peer-checked:text-black  peer-checked:extrabold dark:text-offwhite'
                                    htmlFor='task_escalated'
                                >
                                    {task.is_escalated ? 'Escalated' : 'Escalate?'}
                                </label>
                            </div>
                        </div>
                    </div>

                    {task.task_type === 'Sales' && task?.customer?.shipping_address && !customerInfoAndShippingSame && (
                        <div className='relative mb-[16px] p-[8px] pt-[18px] bg-white dark:bg-darkaccent rounded shadow-small'>
                            <h3
                                className='absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-[rgb(74,74,74)] dark:text-offwhite py-[2px] px-[4px] mb-[8px] w-[fit-content] items-center justify-between'
                                onClick={() => {
                                    showCustomer.includes('Shipping Address')
                                        ? setShowCustomer(showCustomer.filter((s) => s !== 'Shipping Address'))
                                        : setShowCustomer([...showCustomer, 'Shipping Address'])
                                }}
                                style={
                                    showCustomer.includes('Shipping Address')
                                        ? { color: 'white' }
                                        : {
                                              width: '100%',
                                              background: 'none',
                                              color: '',
                                              marginBottom: '12px',
                                              fontSize: '16px',
                                              position: 'static',
                                          }
                                }
                            >
                                Shipping
                                {shippingAndBillingSame ||
                                (!shippingAndBillingSame &&
                                    JSON.stringify(customer.shipping_address) ===
                                        JSON.stringify(customer.billing_address))
                                    ? ' & Billing '
                                    : ' '}
                                Address
                                {!showCustomer.includes('Shipping Address') && (
                                    <span className='inline-block ml-[auto] text-[24px] leading-[1]'>+</span>
                                )}
                            </h3>
                            <div
                                className={`overflow-hidden ${
                                    showCustomer.includes('Shipping Address') ? 'max-h-none' : 'max-h-[0px]'
                                }`}
                            >
                                <div className='flex gap-[8px] w-[100%]'>
                                    <div className='flex gap-[8px] w-[100%]'>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='first_name'
                                            >
                                                First Name
                                            </label>
                                            <input
                                                type='text'
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                id='first_name'
                                                name='shipping_address.first_name'
                                                value={task.customer.shipping_address.first_name || ''}
                                                onChange={(e: any) => handleChangeCustomer(e)}
                                            />
                                        </div>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='last_name'
                                            >
                                                Last Name
                                            </label>
                                            <input
                                                type='text'
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                id='last_name'
                                                name='shipping_address.last_name'
                                                value={task.customer.shipping_address.last_name || ''}
                                                onChange={(e: any) => handleChangeCustomer(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex gap-[8px] w-[100%]'>
                                    <div className='relative w-[100%]'>
                                        <label
                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                            htmlFor='company'
                                        >
                                            Company
                                        </label>
                                        <input
                                            type='text'
                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                            id='company'
                                            name='shipping_address.company'
                                            value={task.customer.shipping_address.company || ''}
                                            onChange={(e: any) => handleChangeCustomer(e)}
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-[8px] w-[100%]'>
                                    <div className='relative w-[100%]'>
                                        <label
                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                            htmlFor='address1'
                                        >
                                            Address
                                        </label>
                                        <input
                                            type='text'
                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                            id='address1'
                                            name='shipping_address.address1'
                                            value={task.customer.shipping_address.address1 || ''}
                                            onChange={(e: any) => handleChangeCustomer(e)}
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-[8px] w-[100%]'>
                                    <div className='relative w-[100%]'>
                                        <label
                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                            htmlFor='city'
                                        >
                                            City
                                        </label>
                                        <input
                                            type='text'
                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                            id='city'
                                            name='shipping_address.city'
                                            value={task.customer.shipping_address.city || ''}
                                            onChange={(e: any) => handleChangeCustomer(e)}
                                        />
                                    </div>
                                    <div className='relative w-[100%]'>
                                        <label
                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                            htmlFor='province_code'
                                        >
                                            State
                                        </label>
                                        <select
                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                            id='province_code'
                                            name='shipping_address.province_code'
                                            onChange={(e: any) => handleChangeCustomer(e)}
                                            value={task.customer.shipping_address.province_code || ''}
                                        >
                                            {STATE_CODE_LIST.map((stateCode) => (
                                                <option value={stateCode}>{stateCode}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='relative w-[100%]'>
                                        <label
                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                            htmlFor='zip'
                                        >
                                            Zip
                                        </label>
                                        <input
                                            type='text'
                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                            id='zip'
                                            name='shipping_address.zip'
                                            value={task.customer.shipping_address.zip || ''}
                                            onChange={(e: any) => handleChangeCustomer(e)}
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-[8px] w-[100%]'>
                                    <div className='relative w-[100%]'>
                                        <label
                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                            htmlFor='phone'
                                        >
                                            Phone
                                        </label>
                                        <input
                                            type='text'
                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                            id='phone'
                                            name='shipping_address.phone'
                                            value={task.customer.shipping_address.phone || ''}
                                            onChange={(e: any) => handleChangeCustomer(e)}
                                        />
                                    </div>
                                </div>
                                <div className='flex justify-end gap-[16px] items-center mt-[8px]'>
                                    <div
                                        className='flex items-center text-center text-white bg-blue cursor-pointer p-[8px] rounded gap-[8px]'
                                        onClick={() => {
                                            if (customerInfoAndBillingSame && !shippingAndBillingSame) {
                                                setShippingAndBillingSame(true)
                                                setCustomerInfoAndBillingSame(false)
                                            } else {
                                                setShippingAndBillingSame(!shippingAndBillingSame)
                                            }
                                            setShippingAndBillingSame(!shippingAndBillingSame)
                                        }}
                                    >
                                        <span className='text-[14px] font-semibold'>Copy to Billing</span>
                                        <input type='checkbox' checked={shippingAndBillingSame} onChange={() => {}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {task.task_type === 'Sales' &&
                        task?.customer?.billing_address &&
                        !shippingAndBillingSame &&
                        !customerInfoAndBillingSame &&
                        JSON.stringify(customer.billing_address) !== JSON.stringify(customer.shipping_address) && (
                            <div className='relative mb-[16px] p-[8px] pt-[18px] bg-white dark:bg-darkaccent rounded shadow-small'>
                                <h3
                                    className='absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-[rgb(74,74,74)] dark:text-offwhite py-[2px] px-[4px] mb-[8px] w-[fit-content] items-center justify-between'
                                    onClick={() => {
                                        showCustomer.includes('Billing Address')
                                            ? setShowCustomer(showCustomer.filter((s) => s !== 'Billing Address'))
                                            : setShowCustomer([...showCustomer, 'Billing Address'])
                                    }}
                                    style={
                                        showCustomer.includes('Billing Address')
                                            ? { color: 'white' }
                                            : {
                                                  width: '100%',
                                                  background: 'none',
                                                  color: '',
                                                  marginBottom: '12px',
                                                  fontSize: '16px',
                                                  position: 'static',
                                              }
                                    }
                                >
                                    Billing Information
                                    {!showCustomer.includes('Billing Address') && (
                                        <span className='inline-block ml-[auto] text-[24px] leading-[1]'>+</span>
                                    )}
                                </h3>
                                <div
                                    className={`overflow-hidden ${
                                        showCustomer.includes('Billing Address') ? 'max-h-none' : 'max-h-[0px]'
                                    }`}
                                >
                                    <div className='flex gap-[8px] w-[100%]'>
                                        <div className='flex gap-[8px] w-[100%]'>
                                            <div className='relative w-[100%]'>
                                                <label
                                                    className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                    htmlFor='first_name'
                                                >
                                                    First Name
                                                </label>
                                                <input
                                                    type='text'
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    id='first_name'
                                                    name='billing_address.first_name'
                                                    value={task?.customer?.billing_address?.first_name || ''}
                                                    onChange={(e: any) => handleChangeCustomer(e)}
                                                />
                                            </div>
                                            <div className='relative w-[100%]'>
                                                <label
                                                    className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                    htmlFor='last_name'
                                                >
                                                    Last Name
                                                </label>
                                                <input
                                                    type='text'
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    id='last_name'
                                                    name='billing_address.last_name'
                                                    value={task.customer.billing_address.last_name || ''}
                                                    onChange={(e: any) => handleChangeCustomer(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex gap-[8px] w-[100%]'>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='company'
                                            >
                                                Company
                                            </label>
                                            <input
                                                type='text'
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                id='company'
                                                name='billing_address.company'
                                                value={task.customer.billing_address.company || ''}
                                                onChange={(e: any) => handleChangeCustomer(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex gap-[8px] w-[100%]'>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='address1'
                                            >
                                                Address
                                            </label>
                                            <input
                                                type='text'
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                id='address1'
                                                name='billing_address.address1'
                                                value={task.customer.billing_address.address1 || ''}
                                                onChange={(e: any) => handleChangeCustomer(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex gap-[8px] w-[100%]'>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='city'
                                            >
                                                City
                                            </label>
                                            <input
                                                type='text'
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                id='city'
                                                name='billing_address.city'
                                                value={task.customer.billing_address.city || ''}
                                                onChange={(e: any) => handleChangeCustomer(e)}
                                            />
                                        </div>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='province_code'
                                            >
                                                State
                                            </label>
                                            <select
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                id='province_code'
                                                name='billing_address.province_code'
                                                onChange={(e: any) => handleChangeCustomer(e)}
                                                value={task?.customer?.billing_address?.province_code || ''}
                                            >
                                                {STATE_CODE_LIST.map((stateCode) => (
                                                    <option value={stateCode}>{stateCode}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='zip'
                                            >
                                                Zip
                                            </label>
                                            <input
                                                type='text'
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                id='zip'
                                                name='billing_address.zip'
                                                value={task.customer.billing_address.zip || ''}
                                                onChange={(e: any) => handleChangeCustomer(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex gap-[8px] w-[100%]'>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='phone'
                                            >
                                                Phone
                                            </label>
                                            <input
                                                type='text'
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                id='phone'
                                                name='billing_address.phone'
                                                value={task.customer.billing_address.phone || ''}
                                                onChange={(e: any) => handleChangeCustomer(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    <div className='relative mb-[16px] p-[8px] pt-[18px] bg-white dark:bg-darkaccent rounded shadow-small'>
                        <h3
                            className='absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-[rgb(74,74,74)] dark:text-offwhite py-[2px] px-[4px] mb-[8px] w-[fit-content] items-center justify-between'
                            onClick={() => {
                                showCustomer.includes('Customer Information')
                                    ? setShowCustomer(showCustomer.filter((s) => s !== 'Customer Information'))
                                    : setShowCustomer([...showCustomer, 'Customer Information'])
                            }}
                            style={
                                showCustomer.includes('Customer Information')
                                    ? { color: 'white' }
                                    : {
                                          width: '100%',
                                          background: 'none',
                                          color: '',
                                          marginBottom: '12px',
                                          fontSize: '16px',
                                          position: 'static',
                                      }
                            }
                        >
                            Customer
                            {customerInfoAndShippingSame
                                ? customerInfoAndBillingSame || shippingAndBillingSame
                                    ? ', Shipping, and Billing'
                                    : ' & Shipping'
                                : customerInfoAndBillingSame
                                  ? ' & Billing'
                                  : ''}{' '}
                            Information
                            {!showCustomer.includes('Customer Information') && (
                                <span className='inline-block ml-[auto] text-[24px] leading-[1]'>+</span>
                            )}
                        </h3>
                        <div
                            className={`overflow-hidden ${
                                showCustomer.includes('Customer Information') ? 'max-h-none' : 'max-h-[0px]'
                            }`}
                        >
                            <div className='flex gap-[8px] w-[100%]'>
                                <div className='flex gap-[8px] w-[100%]'>
                                    <div className='relative w-[100%]'>
                                        <label
                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                            htmlFor='first_name'
                                        >
                                            First Name
                                        </label>
                                        <input
                                            type='text'
                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                            id='first_name'
                                            name='first_name'
                                            value={task?.customer?.first_name || ''}
                                            onChange={(e: any) => handleChangeCustomer(e)}
                                        />
                                    </div>
                                    <div className='relative w-[100%]'>
                                        <label
                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                            htmlFor='last_name'
                                        >
                                            Last Name
                                        </label>
                                        <input
                                            type='text'
                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                            id='last_name'
                                            name='last_name'
                                            value={task?.customer?.last_name || ''}
                                            onChange={(e: any) => handleChangeCustomer(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-[8px] w-[100%]'>
                                <div className='relative w-[100%]'>
                                    <label
                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                        htmlFor='email'
                                    >
                                        Email
                                    </label>
                                    <input
                                        type='email'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        id='email'
                                        name='email'
                                        value={task?.customer?.email || ''}
                                        onChange={(e: any) => handleChangeCustomer(e)}
                                    />
                                </div>
                            </div>
                            <div className='flex gap-[8px] w-[100%]'>
                                <div className='relative w-[100%]'>
                                    <label
                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                        htmlFor='company'
                                    >
                                        Company
                                    </label>
                                    <input
                                        type='text'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        id='company'
                                        name='default_address.company'
                                        value={task?.customer?.default_address?.company || ''}
                                        onChange={(e: any) => handleChangeCustomer(e)}
                                    />
                                </div>
                            </div>
                            <div className='flex gap-[8px] w-[100%]'>
                                <div className='relative w-[100%]'>
                                    <label
                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                        htmlFor='address1'
                                    >
                                        Address
                                    </label>
                                    <input
                                        type='text'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        id='address1'
                                        name='default_address.address1'
                                        value={task?.customer?.default_address?.address1 || ''}
                                        onChange={(e: any) => handleChangeCustomer(e)}
                                    />
                                </div>
                            </div>
                            <div className='flex gap-[8px] w-[100%]'>
                                <div className='relative w-[100%]'>
                                    <label
                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                        htmlFor='city'
                                    >
                                        City
                                    </label>
                                    <input
                                        type='text'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        id='city'
                                        name='default_address.city'
                                        value={task?.customer?.default_address?.city || ''}
                                        onChange={(e: any) => handleChangeCustomer(e)}
                                    />
                                </div>
                                <div className='relative w-[100%]'>
                                    <label
                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                        htmlFor='province_code'
                                    >
                                        State
                                    </label>
                                    <select
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        id='province_code'
                                        name='default_address.province_code'
                                        onChange={(e: any) => handleChangeCustomer(e)}
                                        value={task?.customer?.default_address?.province_code || ''}
                                    >
                                        {STATE_CODE_LIST.map((stateCode) => (
                                            <option value={stateCode}>{stateCode}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='relative w-[100%]'>
                                    <label
                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                        htmlFor='zip'
                                    >
                                        Zip
                                    </label>
                                    <input
                                        type='text'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        id='zip'
                                        name='default_address.zip'
                                        value={task?.customer?.default_address?.zip || ''}
                                        onChange={(e: any) => handleChangeCustomer(e)}
                                    />
                                </div>
                            </div>
                            <div className='flex gap-[8px] w-[100%]'>
                                <div className='relative w-[100%]'>
                                    <label
                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                        htmlFor='phone'
                                    >
                                        Phone
                                    </label>
                                    <input
                                        type='text'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        id='phone'
                                        name='default_address.phone'
                                        value={task?.customer?.default_address?.phone || ''}
                                        onChange={(e: any) => handleChangeCustomer(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        {showCustomer.includes('Customer Information') && (
                            <div className='flex justify-end gap-[16px] items-center mt-[8px]'>
                                <div
                                    className='flex items-center text-center text-white bg-blue cursor-pointer p-[8px] rounded gap-[8px]'
                                    onClick={() => {
                                        setCustomerInfoAndShippingSame(!customerInfoAndShippingSame)
                                    }}
                                >
                                    <span className='text-[14px] font-semibold'>Copy to Shipping</span>
                                    <input type='checkbox' checked={customerInfoAndShippingSame} onChange={() => {}} />
                                </div>
                                <div
                                    className='flex items-center text-center text-white bg-blue cursor-pointer p-[8px] rounded gap-[8px]'
                                    onClick={() => {
                                        if (shippingAndBillingSame && !customerInfoAndBillingSame) {
                                            setShippingAndBillingSame(false)
                                            setCustomerInfoAndBillingSame(true)
                                        } else {
                                            setCustomerInfoAndBillingSame(!customerInfoAndBillingSame)
                                        }
                                    }}
                                >
                                    <span className='text-[14px] font-semibold'>Copy to Billing</span>
                                    <input type='checkbox' checked={customerInfoAndBillingSame} onChange={() => {}} />
                                </div>
                            </div>
                        )}
                    </div>

                    {task.task_type === 'Sales' && (
                        <div className='grid gap-[8px]'>
                            <div
                                className='relative'
                                onClick={() => {
                                    setShowSearch(true)
                                }}
                            >
                                <CustomerSearchbar
                                    search={search}
                                    handleSearch={handleSearch}
                                    filters={filters}
                                    setSelectedCustomerId={setSelectedCustomerId}
                                />
                                {showSearch && (
                                    <div className='js-create-task-screen-search absolute flex flex-col max-h-[300px] min-w-[195px] overflow-auto gap-[8px] left-[8px] top-[100%] bg-white dark:bg-darkaccent dark:text-offwhite shadow-small p-[8px]'>
                                        {customers.map((c: any) => (
                                            <div
                                                className='p-[8px] hover:bg-offwhite dark:hover:bg-darkgrey cursor-pointer rounded'
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        setShowSearch(false)
                                                    }, 0)
                                                    setCustomer({
                                                        ...c,
                                                        updateCustomer: true,
                                                        tags:
                                                            c.tags && c.tags.includes('lead')
                                                                ? c.tags
                                                                : c.tags && c.tags.length > 0
                                                                  ? c.tags + ',lead'
                                                                  : 'lead',
                                                        address1: c.default_address.address1,
                                                        company: c.default_address.company,
                                                        city: c.default_address.city,
                                                        province_code: c.default_address.province_code,
                                                        zip: c.default_address.zip,
                                                    })
                                                }}
                                            >
                                                <span className=''>
                                                    {c.first_name || c.last_name
                                                        ? `${c.first_name ? c.first_name : ''} ${
                                                              c.last_name ? c.last_name : ''
                                                          }`
                                                        : `${c.email}`}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {selectedCustomerId && (
                                <CustomerModal
                                    setSelectedCustomerId={setSelectedCustomerId}
                                    id={selectedCustomerId}
                                    returnCustomer={setCustomer}
                                    defaultTags={[task.task_type === 'Sales' ? task.topic : '']}
                                />
                            )}
                        </div>
                    )}
                </div>
                <div className='w-[65%]'>
                    <div className='w-[100%]'>
                        <div className='w-[100%] relative mb-[16px] p-[8px] pt-[18px] bg-white rounded shadow-small dark:bg-darkaccent'>
                            <h3 className='absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-white py-[2px] px-[4px] mb-[8px] w-[fit-content]  items-center justify-between'>
                                Order Notes
                            </h3>
                            <textarea
                                className='block w-[100%] min-h-[175px] mt-[8px] max-w-none border-[1px] border-[#cdcdcd] focus:outline focus:outline-[1px] dark:focus:outline-accent dark:bg-darkness dark:border-blue dark:text-offwhite rounded-br-none'
                                name='new_order_note'
                                onChange={(e: any) => handleChangeTask(e)}
                            />
                            {
                                <button
                                    onClick={handleSubmit}
                                    className='block relative mr-0 ml-[auto] top-0 py-[8px] px-[16px] bg-blue text-white font-bold dark:bg-accent dark:text-darkness border-none text-center uppercase rounded-b cursor-pointer'
                                >
                                    Create Task
                                </button>
                            }
                            <div className='grid gap-[8px] mt-[8px]'>
                                {createOrderNotesArray(order.note, userList).map((n, i) => (
                                    <OrderNote
                                        userList={userList}
                                        state={order}
                                        setState={setOrder}
                                        note={n}
                                        index={i}
                                    />
                                ))}
                            </div>
                        </div>
                        {orderMode === 'draftOrders' && (
                            <div className='w-[100%] relative mb-[16px] p-[8px] pt-[18px] bg-white rounded shadow-small dark:bg-darkaccent'>
                                <h3 className='absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-white py-[2px] px-[4px] mb-[8px] w-[fit-content]  items-center justify-between'>
                                    Task Notes
                                </h3>
                                <textarea
                                    className='block w-[100%] min-h-[175px] mt-[8px] max-w-none border-[1px] border-[#cdcdcd] focus: outline-none dark:bg-darkness dark:border-blue dark:text-offwhite'
                                    name='task_notes'
                                    onChange={(e: any) => handleChangeTask(e)}
                                ></textarea>
                            </div>
                        )}
                    </div>
                    <div
                        style={orderMode === 'draftOrders' ? {} : { display: 'none' }}
                        className='w-[100%] relative mb-[16px] p-[8px] pt-[18px] bg-white rounded shadow-small dark:bg-darkaccent'
                    >
                        <h3
                            className='absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-white py-[2px] px-[4px] mb-[8px] w-[fit-content] items-center justify-between'
                            style={{ width: 'fit-content', margin: '0 0 16px' }}
                        >
                            Products
                        </h3>
                        {/* <div className="new-task__product__search__container p-[8px]">
                            <ProductAdder selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} />
                        </div> */}
                        <div className='new-task__product__selected dark:text-offwhite'>
                            {selectedProducts.map((p) => (
                                <SelectedProduct
                                    product={p}
                                    selectedType={task.task_type}
                                    selectedTopic={task.topic}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                />
                            ))}
                        </div>
                    </div>
                    {!loading && (
                        <button
                            onClick={handleSubmit}
                            className='block my-[8px] mx-[auto] py-[8px] px-[16px] bg-blue text-[24px] text-white text-center border-none cursor-pointer font-bold uppercase shadow-small dark:shadow-cool'
                        >
                            Create Task
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
