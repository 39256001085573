import { Cross2Icon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../custom_components/component_Basics/Button'
import { cn } from '../../helpers'

export default function FilterToolBar({ search, setSearch, filterBarObject, children, className }: any) {
    const navigate = useNavigate()
    let isFiltered
    if (filterBarObject) {
        filterBarObject.params.map((param: any) => {
            if (param.values?.length) {
                isFiltered = true
            }
        })
    }
    return (
        <div
            className={cn(
                'w-full grid grid-cols-[2fr_3fr] gap-4 border-b border-lightgrey dark:border-darkgrey items-center pr-[8px] rounded-t-[4px] rounded-tl-[0px] dark:bg-darkaccent',
                className
            )}
        >
            <div className='w-full flex relative '>
                <input
                    type='text'
                    className='w-full bg-transparent dark:text-white focus:outline-none p-2'
                    placeholder='Search'
                    value={search}
                    onChange={({ target }) => {
                        setSearch(target.value)
                    }}
                />
                <div className='self-center'>
                    <Button
                        disabled={search ? false : true}
                        variant='destructive'
                        onClick={() => {
                            setSearch('')
                            // navigate('/procurement')
                        }}
                        className={`${cn(
                            !search && 'text-darkgrey dark:text-darkgrey opacity-50',
                            search && 'border border-red'
                        )} h-8 px-1 lg:px-2`}
                    >
                        Clear Search
                        <Cross2Icon className='ml-2 h-4 w-4' />
                    </Button>
                </div>
            </div>
            <div className='relative shrink-0 flex gap-[8px] justify-end items-center'>
                {children}
                <Button
                    disabled={isFiltered ? false : true}
                    variant='destructive'
                    onClick={() => {
                        filterBarObject.resetFunction()
                    }}
                    className={`${cn(
                        !isFiltered && 'text-darkgrey dark:text-darkgrey opacity-50',
                        isFiltered && 'border border-red'
                    )} h-8 px-1 lg:px-2`}
                >
                    Reset Filters
                    <Cross2Icon className='ml-2 h-4 w-4' />
                </Button>
            </div>
        </div>
    )
}
