import { useEffect, useState } from 'react'
import { vFetch } from '../../helpers'
import Question from '../components/Question'
import { PiChatCircleTextFill } from 'react-icons/pi'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import UsersName from '../components/UsersName'
import { CiSearch } from 'react-icons/ci'
import KnowledgeSearch from '../components/KnowledgeSearch'
import { useQuery } from '@tanstack/react-query'

// main QNA View
export default function QuestionsAndAnswersView({ setView, setSelectedQuestion }: any) {
    const [showAskQuestion, setShowAskQuestion] = useState(false)
    const [question, setQuestion] = useState('')
    const [questionList, setQuestionList] = useState([]) //
    const [isLoading, setIsLoading] = useState(true) //
    const [isError, setIsError] = useState(false)

    // const questionsQuery = useQuery({
    //     queryKey: ['knowledge', 'questions'],
    //     queryFn: async () => {
    //         return await vFetch('/knowledge/questions')
    //     },
    // })
    // const { questions: questionList }: { questions: any[] } = questionsQuery?.data || {}

    const getQuestions = async () => {
        //
        vFetch('/knowledge/questions', {
            cb: (res: any) => {
                setIsLoading(false)
                if (!res.success) {
                    setIsError(true)
                }
                if (res.success) {
                    setQuestionList(res.questions)
                }
            },
        })
    }

    useEffect(() => {
        getQuestions() //
    }, [])

    const handleSubmit = () => {
        vFetch('/knowledge/questions', {
            method: 'POST',
            body: JSON.stringify({ question }),
            cb: () => {
                setShowAskQuestion(false)
                setQuestion('')
                getQuestions() //
            },
        })
    }

    return (
        <div className='flex flex-col h-full'>
            <KnowledgeSearch setView={setView} setSelectedQuestion={setSelectedQuestion} />
            {!showAskQuestion && ( // Recent questions and Ask A Question button
                <div className='flex items-center justify-between gap-2'>
                    <h2 className='py-6 shrink-0 whitespace-nowrap bg-transparent dark:text-offwhite text-2xl font-bold hover:cursor-default'>
                        Recent Questions
                    </h2>
                    <button
                        onClick={() => setShowAskQuestion(true)} // 1
                        className='py-2 px-2 shrink-0 whitespace-nowrap bg-blue text-offwhite dark:bg-accent dark:text-darkaccent font-bold rounded'
                    >
                        Ask A Question
                    </button>
                </div>
            )}
            {showAskQuestion && ( // Ask A Question button functionality (Submit Question and Cancel)
                <div className='flex items-center gap-2 py-6'>
                    <input
                        placeholder='Type your question here'
                        value={question}
                        onChange={(event) => setQuestion(event.target.value)} // 2
                        className='border-b dark:border-none w-full overflow-hidden bg-transparent p-[8px] focus:outline-none dark:rounded-t  dark:text-offwhite dark:bg-darkness'
                    ></input>
                    <button
                        onClick={handleSubmit} // 3
                        className='py-2 px-2 shrink-0 whitespace-nowrap text-offwhite bg-blue dark:bg-accent dark:text-darkaccent font-bold rounded'
                    >
                        Submit Question
                    </button>
                    <button
                        onClick={() => setShowAskQuestion(false)} // 4
                        className='py-2 px-2 shrink-0 whitespace-nowrap bg-darkgrey text-offwhite font-bold rounded'
                    >
                        Cancel
                    </button>
                </div>
            )}

            {isLoading && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Loading...</p>
                </div>
            )}
            {isError && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Unable to load. Please refresh and try again</p>
                </div>
            )}

            {!isError && !isLoading && (
                <div className='h-full overflow-auto pr-2'>
                    {questionList.map((questionRow: any) => (
                        <Question
                            questionRow={questionRow}
                            getQuestions={getQuestions} //
                            setView={setView}
                            setSelectedQuestion={setSelectedQuestion}
                            setQuestionList={setQuestionList}
                        />
                    ))}
                </div>
            )}

            <div></div>
        </div>
    )
}
