import { useEffect, useRef, useState } from 'react'
import { BsFillGearFill } from 'react-icons/bs'
import { vFetch } from '../../helpers'

export default function Settings({ search, period, sortBy, selectedPreset, setNeedsRefresh }) {
    const [show, setShow] = useState(false)
    const [name, setName] = useState('')
    const settingsRef = useRef()

    useEffect(() => {
        const listenToWindow = ({ target }) => {
            if (!settingsRef.current.contains(target)) {
                setShow(false)
            }
        }
        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    const handleCreate = () => {
        vFetch(`/presets`, {
            method: 'POST',
            body: JSON.stringify({
                name,
                belongs_to: 'top-movers',
                value: {
                    search,
                    period,
                    sortBy,
                },
            }),
            cb: (res) => {
                if (res.success) setNeedsRefresh(true)
            },
        })
    }
    const handleUpdate = () => {
        vFetch(`/presets`, {
            method: 'PUT',
            body: JSON.stringify({
                id: selectedPreset.id,
                name,
                belongs_to: 'top-movers',
                value: {
                    search,
                    period,
                    sortBy,
                },
            }),
            cb: (res) => {
                if (res.success) setNeedsRefresh(true)
            },
        })
    }
    const handleDelete = () => {
        vFetch(`/presets`, {
            method: 'DELETE',
            body: JSON.stringify({
                id: selectedPreset.id,
            }),
            cb: (res) => {
                if (res.success) setNeedsRefresh(true)
            },
        })
    }
    useEffect(() => {
        if (selectedPreset && selectedPreset.name) {
            setName(selectedPreset.name)
        } else {
            setName('')
        }
    }, [selectedPreset])
    return (
        <div ref={settingsRef} className='relative'>
            <BsFillGearFill
                onClick={() => setShow((prev) => !prev)}
                className='cursor-pointer w-[24px] h-[24px] p-[1px] text-blue dark:text-accent'
            />
            {show && (
                <div className='w-fit p-[8px] absolute top-[100%] right-0 bg-white dark:bg-darkaccent z-[500] shadow-small rounded-[4px]'>
                    <div className='flex gap-[8px]'>
                        <input
                            type='text'
                            className='p-[4px] border border-lightgrey dark:border-none dark:bg-faintplus dark:text-white rounded-[4px]'
                            placeholder='Preset Name'
                            value={name}
                            onChange={({ target }) => setName(target.value)}
                        />
                        {selectedPreset && selectedPreset.name === name ? (
                            <button
                                className='whitespace-nowrap bg-red dark:bg-lightred font-bold text-white py-[2px] px-[8px] rounded-[4px]'
                                onClick={handleDelete}
                            >
                                Delete Preset
                            </button>
                        ) : (
                            <button
                                className='whitespace-nowrap bg-blue dark:bg-accent font-bold text-white dark:text-darkness py-[2px] px-[8px] rounded-[4px]'
                                onClick={handleCreate}
                            >
                                Create Preset
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
