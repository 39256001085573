import { Dispatch, SetStateAction } from 'react'
import { ManualType, ShopifyProduct } from '../ProductSetup.types'
import { FaTrash } from 'react-icons/fa'

export default function ManualList({
    manuals,
    setProduct,
}: {
    manuals: ManualType[]
    setProduct: Dispatch<SetStateAction<ShopifyProduct>>
}) {
    const handleChange = ({ target }: { target: HTMLInputElement }, correspondingValue: string, index: number) => {
        setProduct((previousState) => {
            const newState = structuredClone(previousState)
            const name = target.name === 'name' ? target.value : correspondingValue
            const href = target.name === 'href' ? target.value : correspondingValue
            if (newState.manuals) {
                newState.manuals.splice(index, 1, `${name}:${href}`)
            }
            return newState
        })
    }
    return (
        <>
            {manuals.map((manual, index) => (
                <div className='flex items-center gap-[8px] p-[8px] border border-grey dark:border-darkgrey rounded-[4px] relative mb-[16px]'>
                    <input
                        className='w-1/4 p-[8px] rounded-[4px] bg-lightgrey dark:text-white dark:bg-faintplus focus:outline-none'
                        value={manual.name}
                        name='name'
                        type='text'
                        placeholder={'Name'}
                        onChange={(e) => handleChange(e, manual.href, index)}
                    />
                    <input
                        className='w-3/4 p-[8px] rounded-[4px] bg-lightgrey dark:text-white dark:bg-faintplus focus:outline-none'
                        value={manual.href}
                        name='href'
                        type='text'
                        placeholder={'Link'}
                        onChange={(e) => handleChange(e, manual.name, index)}
                    />
                    <FaTrash
                        size={'24px'}
                        className='fill-red dark:fill-[rgb(255,0,0)]'
                        onClick={() =>
                            setProduct((previousState) => {
                                const newState = structuredClone(previousState)
                                if (newState.manuals) {
                                    newState.manuals.splice(index, 1)
                                }
                                return newState
                            })
                        }
                    />
                </div>
            ))}
        </>
    )
}
