import { useEffect, useState } from 'react'
import { vFetch } from '../../helpers'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import { FaCircle, FaHeart, FaRegHeart } from 'react-icons/fa'
import { PiChatCircleTextFill } from 'react-icons/pi'
import UsersName from './UsersName'
import { IoChatbubble } from 'react-icons/io5'
import { MdChat } from 'react-icons/md'

// handles each question layout, like/unlike functions
export default function Question({
    questionRow,
    getQuestions,
    setView,
    setSelectedQuestion,
    getAnswers,
    setQuestionList,
}: any) {
    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || {}
    const user = users?.find((user) => {
        return user.id === questionRow.user_id
    })

    const handleLike = (question_id: number) => {
        setQuestionList((previousState: any) => {
            const newState = structuredClone(previousState)
            const index = previousState.findIndex((que: any) => que.id === question_id)
            if (index !== -1) {
                newState[index].is_liked = 1
                newState[index].total_question_likes++
            }
            return newState
        })
        vFetch('/knowledge/questions/likes', {
            method: 'POST',
            body: JSON.stringify({ question_id }),
            cb: () => {},
            skipToast: true,
        })
    }

    const handleUnlike = (question_id: number) => {
        setQuestionList((previousState: any) => {
            const newState = structuredClone(previousState)
            const index = previousState.findIndex((que: any) => que.id === question_id)
            if (index !== -1) {
                newState[index].is_liked = 0
                newState[index].total_question_likes--
            }
            return newState
        })

        vFetch('/knowledge/questions/likes', {
            method: 'DELETE',
            body: JSON.stringify({ question_id }),
            cb: () => {},
            skipToast: true,
        })
    }

    const handleSetQuestion = () => {
        setView('question view')
        setSelectedQuestion(questionRow)
    }
    return (
        <div className='shadow-md border rounded border-lightgrey dark:border-darkgrey  mb-4'>
            {/* // <div className='border rounded border-[rgb(74,74,74)] mb-4'> */}
            <div className='gap-3 pt-2 pl-2'>
                <div className='flex justify-between'>
                    <div className='flex'>
                        <div className='shrink-0 rounded-full flex items-center justify-center w-[50px] h-[50px] text-2xl font-bold text-white dark:text-offwhite bg-orange-500'>
                            {user?.first_name[0]}
                            {user?.last_name[0]}
                        </div>
                        <div className='ml-2'>
                            <div>
                                <button onClick={handleSetQuestion} className='font-bold text-2xl text-left'>
                                    {questionRow.question}
                                </button>
                                <div className='mb-2'>
                                    <UsersName questionRow={questionRow} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <div className='flex items-center gap-2 mt-2 pr-2 font-bold mr-2'>
                            <button onClick={handleSetQuestion} className='flex flex-col gap-1 items-center'>
                                <MdChat className='h-[24px] w-[24px] text-darkgrey dark:text-white' />
                                <span className='block leading-none text-xs text-darkgrey dark:text-offwhite'>
                                    {questionRow.total_answers_per_question}
                                </span>
                            </button>
                            {questionRow.is_liked ? (
                                <button
                                    onClick={() => handleUnlike(questionRow.id)}
                                    className='flex flex-col gap-1 items-center'
                                >
                                    <FaHeart className='h-[24px] w-[24px] text-red/75 dark:text-white' />
                                    <span className='block leading-none text-xs text-darkgrey dark:text-offwhite'>
                                        {questionRow.total_question_likes}
                                    </span>
                                </button>
                            ) : (
                                <button
                                    onClick={() => handleLike(questionRow.id)}
                                    className='flex flex-col gap-1 items-center'
                                >
                                    <FaRegHeart className='h-[24px] w-[24px] text-red/75 dark:text-white' />
                                    <span className='block leading-none text-xs text-darkgrey dark:text-offwhite'>
                                        {questionRow.total_question_likes}
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <p className='border-t border-darkgrey px-6 text-sm font-bold'>Top Answer</p>
            <p className='px-6 mb-2 text-sm '>
                Show answer that has the most amount of likes. If all answers have no likes, do not show anything.
            </p> */}
        </div>
    )
}
