import { useEffect, useState } from 'react'
import { vFetch } from '../../helpers'

export default function FilterValuesScreen() {
    const [filterValues, setFilterValues] = useState([])
    useEffect(() => {
        vFetch('/descriptions/filters', {
            cb: (res) => {
                setFilterValues(res.filterValues)
            },
        })
    }, [])
    return (
        <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col items-center shadow-small'>
            <p className='dark:text-offwhite font-bold mb-[16px] dark:font-normal text-center font-bai'>
                Add filterable values and compare similar scores to products.
            </p>
            {filterValues.map((fv) => (
                <div key={fv.id} className='flex gap-[16px] items-center'>
                    <p>{fv.group_name}</p>
                </div>
            ))}
        </div>
    )
}
