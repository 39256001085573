export const defaultCompany = {
    logo: '',
    name: '',
    product_type: '',
    phone: '',
    email: '',
    domain: '',
    lead_type: 'New',
    facebook: '',
    instagram: '',
    industry: '',
    description: '',
    city: '',
    state: '',
    zip: '',
    timezone: 'America/Chicago',
    number_of_employees: '',
    lifecycle_status: 'Information',
    linkedin: '',
    notes: '',
    competition_score: '',
    reviews_score: '',
    map_enforcement_score: '',
    price_point_score: '',
    refurbs_score: '',
    accept_logo: '',
    has_edi: '',
    has_api: '',
    store_id: '',
    parent_company: '',
}

export type TaskFilterValues = {
    statuses: string[]
    users: string[]
    due_at_options: string[]
}
export type UserSelectedCompanyFilterValues = {
    lifecycle_statuses: string[]
    lead_types: string[]
    users: string[]
}

export type CompanyFilters = {
    id?: number | undefined
    name?: string | undefined
    domain?: any
    lead_type: string[]
    lifecycle_status: string[]
    score?: number
    search?: string
    assigned_to: number[]
    lastCursor: string[] | undefined
    pageParam: number
    sortOption?: any
}
export type CompanyTaskFilters = {
    search: string | undefined
    fields?: string
    id?: number | undefined
    due_at?: any
    due_at_options: string[]
    company_id: number | undefined
    user_ids: number[]
    statuses: string[]
    lastCursor: string[] | undefined
    selectedTaskDueDate?: string | undefined
    pageParam: number
    limit?: number | undefined
}
export type SortOptionsType = {
    score: {
        options: {
            label: string
            value: string
            icon?: React.ComponentType<{ className?: string }>
        }[]
    }
}
