export const defaultCustomer: CustomerInit = {
    accepts_marketing: undefined,
    accepts_marketing_updated_at: new Date(),
    addresses: [],
    admin_graphql_api_id: '',
    created_at: new Date(),
    created_at_date: new Date(),
    currency: '',
    default_address: {
        address1: '',
        address2: null,
        city: '',
        company: null,
        country: '',
        country_code: '',
        country_name: '',
        customer_id: undefined,
        default: false,
        first_name: '',
        id: undefined,
        last_name: '',
        name: '',
        phone: '',
        province: '',
        province_code: '',
        provinceCode: '',
        zip: '',
    },
    email: '',
    email_marketing_consent: {
        consent_updated_at: new Date(),
        opt_in_level: '',
        state: '',
    },
    first_name: '',
    full_name: '',
    id: undefined,
    last_name: '',
    last_order_id: '',
    last_order_name: '',
    marketing_opt_in_level: '',
    multipass_identifier: null,
    note: null,
    orders_count: undefined,
    phone: null,
    sms_marketing_consent: null,
    state: '',
    tags: '',
    tax_exempt: undefined,
    tax_exemptions: [],
    total_spent: undefined,
    updated_at: new Date(),
    verified_email: undefined,
}
export type CustomerInit = {
    accepts_marketing: number | undefined
    accepts_marketing_updated_at: Date
    addresses: {
        address1: string
        address2: null
        city: string
        company: null
        country: string
        country_code: string
        country_name: string
        customer_id: number | undefined
        default: boolean
        first_name: string
        id: number | undefined
        last_name: string
        name: string
        phone: string
        province: string
        province_code: string
        provinceCode?: string | null
        zip: string
    }[]
    admin_graphql_api_id: string
    created_at: Date
    created_at_date: Date
    currency: string
    default_address: {
        address1: string
        address2: null
        city: string
        company: null
        country: string
        country_code: string
        country_name: string
        customer_id: number | undefined
        default: boolean
        first_name: string
        id: number | undefined
        last_name: string
        name: string
        phone: string
        province: string
        province_code: string
        provinceCode?: string | null
        zip: string
    }
    email: string
    email_marketing_consent: {
        consent_updated_at: Date
        opt_in_level: string
        state: string
    }
    first_name: string
    full_name: string
    id: number | undefined
    last_name: string
    last_order_id: string
    last_order_name: string
    marketing_opt_in_level: string
    multipass_identifier: null
    note: null
    orders_count: number | undefined
    phone: null
    sms_marketing_consent: null
    state: string
    tags: string
    tax_exempt: number | undefined
    tax_exemptions: any[]
    total_spent: number | undefined
    updated_at: Date
    verified_email: number | undefined
}
