import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSections } from '../../redux/sections'

export default function SpecificationsScreen() {
    const dispatch = useDispatch()
    const [specText, setSpecText] = useState('')
    const [packageText, setPackageText] = useState('')
    const { specifications, packageContents } = useSelector((state) => state).sections
    const midpoint = (specifications.length + packageContents.length) / 2 + 2

    const handleChange = ({ target }) => {
        if (target.name === 'specs-left') {
            const formattedValue = target.value.replaceAll('\t', '\n')
            setSpecText(formattedValue)
            const result = []
            const cells = formattedValue.split('\n')
            for (let i = 0; i < cells.length - 1; i += 2) {
                const cell1 = cells[i].replaceAll('\t', ' ').trim()
                const cell2 = cells[i + 1].replaceAll('\t', ' ').trim()
                const currentRow = [cell1, cell2].filter((c) => c.length)
                result.push(currentRow.join('::'))
            }
            dispatch(setSections({ specifications: result }))
        } else {
            setPackageText(target.value)
            const values = target.value.split('\n')
            const currentPackageContents = values.map((v) => v.trim()).filter((v) => v.length)
            dispatch(setSections({ packageContents: currentPackageContents }))
        }
    }

    useEffect(() => {
        if (specifications) {
            setSpecText(
                specifications
                    .map((spec) => {
                        const cells = spec.includes('::') ? spec.split('::') : spec.split(':')
                        if (cells.length == 1) {
                            return cells[0] + '\n'
                        }
                        return cells.join('\n')
                    })
                    .join('\n')
            )
        }
        if (packageContents) {
            setPackageText(packageContents.join('\n'))
        }
    }, [])
    return (
        <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col items-center shadow-small'>
            <p className='dark:text-offwhite font-bold mb-[16px] dark:font-normal text-center font-bai'>
                Each cell in the specifications table must be separated by a newline (enter key).
                <br />
                To make a green cell heading, add an additional newline under the heading.
                <br />
                Please only add package contents in the Package Contents section.
            </p>
            <div className='flex items-center gap-[16px] w-full px-[32px]'>
                <div className='spec'>
                    <h2 className='text-darkgrey font-bold dark:text-fire'>Specifications</h2>
                    <textarea
                        className='border border-grey rounded-[4px] dark:text-offwhite dark:bg-darkness dark:border-blue focus:outline-none'
                        spellCheck='true'
                        value={specText}
                        name='specs-left'
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className='spec'>
                    <h2 className='text-darkgrey font-bold dark:text-fire'>Package Contents</h2>
                    <textarea
                        className='border border-grey rounded-[4px] dark:text-offwhite dark:bg-darkness dark:border-blue focus:outline-none'
                        spellCheck='true'
                        value={packageText}
                        name='package-contents'
                        onChange={handleChange}
                    ></textarea>
                </div>
            </div>
            {((specifications && specifications.length > 0) || (packageContents && packageContents.length > 0)) && (
                <div className='bg-white description-preview description-preview--full'>
                    <div className='pdp__specs__row'>
                        {specifications.length > 0 && (
                            <div className='pdp__specs__row__left'>
                                <table className='pdp__specs__row__left__table'>
                                    <tbody className='pdp__specs__row__left__table__tbody'>
                                        {specifications.map((s, index) => {
                                            const cells = s.includes('::') ? s.split('::') : s.split(':')
                                            let heading = false
                                            if (cells.length === 1) {
                                                cells.push('')
                                                heading = true
                                            }
                                            if (index < midpoint) {
                                                return (
                                                    <tr>
                                                        <td
                                                            className={
                                                                heading
                                                                    ? 'pdp__specs__row--bold pdp__specs__row--green'
                                                                    : 'pdp__specs__row--bold'
                                                            }
                                                        >
                                                            {cells[0]}
                                                        </td>
                                                        <td>{cells[1]}</td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <div className='pdp__specs__row__right'>
                            <table className='pdp__specs__row__right__table'>
                                <tbody className='pdp__specs__row__right__table__tbody'>
                                    {specifications.map((s, index) => {
                                        const cells = s.includes('::') ? s.split('::') : s.split(':')
                                        let heading = false
                                        if (cells.length === 1) {
                                            cells.push('')
                                            heading = true
                                        }
                                        if (index >= midpoint) {
                                            return (
                                                <tr>
                                                    <td
                                                        className={
                                                            heading
                                                                ? 'pdp__specs__row--bold pdp__specs__row--green'
                                                                : 'pdp__specs__row--bold'
                                                        }
                                                    >
                                                        {cells[0]}
                                                    </td>
                                                    <td>{cells[1]}</td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                            {packageContents && packageContents.length > 0 && (
                                <div className='pdp__specs__row__right__package'>
                                    <h3
                                        className='pdp__specs__row__right__package__heading'
                                        style={{ marginTop: '16px' }}
                                    >
                                        Package Contents
                                    </h3>
                                    <div className='pdp__specs__row__right__package__container'>
                                        {packageContents.map((p) => (
                                            <p className='pdp__specs__row__right__package__text'>{p}</p>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
