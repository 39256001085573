import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setSections } from '../../redux/sections'
import { LoadingGear, useDebounce, vFetch } from '../../helpers'
import { MetafieldProps, ShopifyProduct } from '../ProductSetup.types'
import CrossSellList from '../components/CrossSellList'

export default function CrossSells({ product, setProduct, handleUpdateMetafield, edited }: MetafieldProps) {
    const [products, setProducts] = useState<ShopifyProduct[]>([])
    const [search, setSearch] = useState('')
    const [filtered, setFiltered] = useState<ShopifyProduct[]>([])
    const [selectedLoading, setSelectedLoading] = useState(true)
    const [productsLoading, setProductsLoading] = useState(true)
    const [selectedCrossSells, setSelectedCrossSells] = useState<ShopifyProduct[]>([])
    const [oldCrossSells, setOldCrossSells] = useState<ShopifyProduct[]>([])
    const debouncedSearch = useDebounce(search, 200)

    const addCrossSell = (selectedProduct: ShopifyProduct) => {
        setProduct((previousState) => ({
            ...previousState,
            cross_sells: [...(previousState.cross_sells || []), `gid://shopify/Product/${selectedProduct.id}`],
        }))
        setSelectedCrossSells((previousState) => [...previousState, selectedProduct])
        setFiltered(
            products.filter((cs) => !selectedCrossSells.find((p) => p.id === cs.id) && cs.id !== selectedProduct.id)
        )
    }
    const removeCrossSell = (selectedProduct: ShopifyProduct) => {
        setProduct((previousState) => ({
            ...previousState,
            cross_sells: (previousState.cross_sells || []).filter(
                (cs) => cs !== `gid://shopify/Product/${selectedProduct.id}`
            ),
        }))
        setSelectedCrossSells((previousState) => [...previousState.filter((cs) => cs.id !== selectedProduct.id)])
        setFiltered(
            products.filter((cs) => !selectedCrossSells.find((p) => p.id === cs.id) && cs.id !== selectedProduct.id)
        )
    }

    const fetchSelectedCrossSells = () => {
        if (product.cross_sells && product.cross_sells.length > 0) {
            setSelectedLoading(true)
            vFetch(`/v2/products?id=${product.cross_sells.map((cs) => cs.split('/')[4]).join(',')}`, {
                cb: (res: any) => {
                    setSelectedLoading(false)
                    setOldCrossSells(res.products)
                    return setSelectedCrossSells(res.products)
                },
            })
        } else {
            setSelectedLoading(false)
            setSelectedCrossSells([])
        }
    }
    const fetchProductList = () => {
        setProductsLoading(true)
        vFetch(`/v2/products?search=${encodeURIComponent(debouncedSearch)}`, {
            cb: (res: any) => {
                setProductsLoading(false)
                if (!res.success) {
                    return
                }
                setProducts(res.products)
                setFiltered(
                    res.products.filter(
                        (p: ShopifyProduct) =>
                            !(product.cross_sells || []).find((cs) => cs.includes((p.id || -1).toString()))
                    )
                )
            },
        })
    }

    useEffect(() => {
        fetchProductList()
    }, [debouncedSearch])

    useEffect(() => {
        fetchSelectedCrossSells()
    }, [])

    return (
        <>
            <div className='flex gap-[16px]'>
                <div className='h-fit sticky top-[66px] w-full dark:bg-darkaccent p-[8px] rounded-[4px] flex flex-col shadow-small overflow-hidden'>
                    <p className='mt-[-8px] ml-[-8px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                        Cross Sells - Customers Also Purchased
                    </p>
                    {selectedLoading ? (
                        <div className='bg-lightgrey dark:bg-darkness max-h-[300px] grid place-items-center p-[32px] overflow-auto'>
                            <LoadingGear width={'200px'} height={'200px'} />
                        </div>
                    ) : (
                        <div className='bg-lightgrey dark:bg-darkness max-h-[300px] overflow-auto'>
                            {selectedCrossSells.length > 0 ? (
                                <CrossSellList
                                    crossSells={selectedCrossSells}
                                    type={'selected'}
                                    handleAdd={addCrossSell}
                                    handleRemove={removeCrossSell}
                                />
                            ) : (
                                <p className='my-[16px] px-[16px] dark:text-white'>No cross sells selected</p>
                            )}
                        </div>
                    )}
                    {edited && (
                        <button
                            className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                            onClick={() => {
                                let crossSellTitles: string[] = []
                                selectedCrossSells.forEach((product) => {
                                    if (product.title) {
                                        crossSellTitles.push(product.title)
                                    }
                                })
                                let oldCrossSellTitles: string[] = []
                                oldCrossSells.forEach((product) => {
                                    if (product.title) {
                                        oldCrossSellTitles.push(product.title)
                                    }
                                })
                                handleUpdateMetafield([
                                    {
                                        ownerId: `gid://shopify/Product/${product.id}`,
                                        namespace: 'custom',
                                        key: 'cross_sells',
                                        type: 'list.product_reference',
                                        value: JSON.stringify(product.cross_sells),
                                        crossSellTitles: crossSellTitles,
                                        oldCrossSellTitles: oldCrossSellTitles,
                                    },
                                ])
                            }}
                        >
                            Save Changes
                        </button>
                    )}
                </div>
                <div className='h-fit sticky top-[66px] w-full dark:bg-darkaccent p-[8px] rounded-[4px] flex flex-col shadow-small overflow-hidden'>
                    <p className='mt-[-8px] ml-[-8px] mb-[8px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                        All Products
                    </p>
                    <input
                        className='py-[2px] px-[8px] rounded-[4px] bg-lightgrey dark:bg-faintplus focus:outline-none dark:text-white mb-[8px]'
                        placeholder='Search'
                        type='text'
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                    />
                    {productsLoading ? (
                        <div className='bg-lightgrey dark:bg-darkness max-h-[300px] grid place-items-center p-[32px] overflow-auto'>
                            <LoadingGear width={'200px'} height={'200px'} />
                        </div>
                    ) : (
                        <div className='bg-lightgrey dark:bg-darkness max-h-[272px] overflow-auto'>
                            <CrossSellList
                                crossSells={filtered}
                                type={'add'}
                                handleAdd={addCrossSell}
                                handleRemove={removeCrossSell}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
