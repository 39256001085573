const SUBMENUS = {
    shipping: ['messages', 'recent', 'ending', 'products', 'brands', 'variants'],
    scrape: ['historical-data'],
    orders: ['purchase-orders', 'daily-freight', 'suppliers'],
    tasks: ['new', 'manager'],
    sales: ['goals'],
    knowledge: [
        'faq',
        'aws',
        'fpsite',
        'fpapps',
        'fpdash',
        'mightycall',
        'sendgrid',
        'breadpayments',
        'ip-api',
        'keys',
    ],
    admin: [
        'refresh',
        'redirects',
        'email-templates',
        'tag-manager',
        'migrations',
        'table-sync',
        'control-tower',
        'componentsList',
        // 'helper-functions',
    ],
    edi: [
        // 'stations',
        // 'messages',
        'orders',
        // "partners",
        // "carriers",
        // "send-message"
    ],
    scrum: [
        'overview',
        // "cycles",
        'projects',
        'issues',
    ],
    procurement: ['email-templates', 'score-settings'],
    analytics: ['user-reports?to=-1&from=-7&option=2&interval=1&compare=1'],
}

export default SUBMENUS

export const SUBMENUS_V2 = {
    shipping: [
        { label: 'messages', address: 'messages' },
        { label: 'recent', address: 'recent' },
        { label: 'ending', address: 'ending' },
        { label: 'products', address: 'products' },
        { label: 'brands', address: 'brands' },
        { label: 'variants', address: 'variants' },
    ],
    scrape: [{ label: 'historical-data', address: 'historical-data' }],
    orders: [
        { label: 'purchase-orders', address: 'purchase-orders' },
        { label: 'daily-freight', address: 'daily-freight' },
        { label: 'suppliers', address: 'suppliers' },
    ],
    tasks: [
        { label: 'new', address: 'new' },
        { label: 'manager', address: 'manager' },
    ],
    sales: [{ label: 'goals', address: 'goals' }],
    knowledge: [
        { label: 'faq', address: 'faq' },
        { label: 'aws', address: 'aws' },
        { label: 'fpsite', address: 'fpsite' },
        { label: 'fpapps', address: 'fpapps' },
        { label: 'fpdash', address: 'fpdash' },
        { label: 'mightycall', address: 'mightycall' },
        { label: 'sendgrid', address: 'sendgrid' },
        { label: 'breadpayments', address: 'breadpayments' },
        { label: 'ip-api', address: ',ip-api' },
        { label: 'keys', address: 'keys' },
    ],
    admin: [
        { label: 'refresh', address: 'refresh' },
        { label: 'redirects', address: 'redirects' },
        { label: 'email-templates', address: 'email-templates' },
        { label: 'tag-manager', address: 'tag-manager' },
        { label: 'migrations', address: 'migrations' },
        { label: 'table-sync', address: 'table-sync' },
        { label: 'control-tower', address: 'control-tower' },
        { label: 'component list', address: 'componentsList' },
    ],
    EDI: [{ label: 'orders', address: 'orders' }],
    scrum: [
        { label: 'overview', address: 'overview' },
        { label: 'projects', address: 'projects' },
        { label: 'issues', address: 'issues' },
    ],
    procurement: [
        { label: 'email-templates', address: 'email-templates' },
        { label: 'score-settings', address: 'score-settings' },
    ],
    analytics: [{ label: 'user-report', address: 'user-reports?to=-1&from=-7&option=2&interval=1&compare=1' }],
    development: [
        { label: 'tasks', address: 'tasks' },
        { label: 'projects', address: 'projects' },
        { label: 'roadmaps', address: 'roadmaps' },
    ],
}
