import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ManualView from '../components/ManualView'
import { setSections } from '../../redux/sections'

export default function ManualsScreen() {
    const dispatch = useDispatch()
    const { manuals } = useSelector((state) => state).sections
    const [currentManuals, setCurrentManuals] = useState(manuals)
    const handleChange = ({ target }) => {
        dispatch(setSections({ manuals: target.value }))
    }
    const handleSave = (manual, index) => {
        const newManuals = [...currentManuals]
        newManuals.splice(index, 1, manual)
        setCurrentManuals(newManuals)
    }
    const handleRemove = (index) => {
        const newManuals = [...currentManuals]
        newManuals.splice(index, 1)
        setCurrentManuals(newManuals)
    }
    const addManual = () => {
        setCurrentManuals([...currentManuals, { name: '', href: '' }])
    }
    useEffect(() => {
        dispatch(setSections({ manuals: currentManuals }))
    }, [currentManuals])
    return (
        <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col items-center shadow-small'>
            <p className='dark:text-offwhite font-bold mb-[16px] dark:font-normal text-center font-bai'>
                Add links to any product manuals or documentation
            </p>
            {manuals &&
                manuals.map((m, index) => (
                    <ManualView
                        name={m.name}
                        href={m.href}
                        index={index}
                        handleSave={handleSave}
                        handleRemove={handleRemove}
                    />
                ))}
            <button
                className='mx-auto text-darkgrey font-bold dark:text-fire border-2 dark:border-fire text-[18px] py-[2px] px-[16px] rounded-[4px]'
                onClick={addManual}
            >
                Add Manual
            </button>
            {manuals && manuals.length > 0 && (
                <div className='bg-white description-preview description-preview--full'>
                    <div className='pdp__manuals__grid'>
                        {manuals.map((m) => (
                            <a className='pdp__manuals__grid__manual' href={m.href} target='_blank' rel='noreferrer'>
                                <div className='pdp__manuals__grid__manual__image-container'>
                                    <img
                                        className='pdp__manuals__grid__manual__image-container__img'
                                        src='https://cdn.shopify.com/s/files/1/1163/1976/files/pdf-icon.png?v=1611684687'
                                        alt='manual icon'
                                        width='auto'
                                        height='auto'
                                        loading='lazy'
                                    />
                                </div>
                                <p className='pdp__manuals__grid__manual__text'>{m.name}</p>
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
