import { useState } from 'react'
import { vFetch } from '../../helpers'
import { CiEdit } from 'react-icons/ci'
import { FaTimes } from 'react-icons/fa'
import { FaTrashCan } from 'react-icons/fa6'

export default function EditOptions({
    setShowEditButton,
    setView,
    setEditQuestion,
    selectedQuestion,
}: {
    setShowEditButton: any
    setView: any
    setEditQuestion: any
    selectedQuestion: any
}) {
    const handleDeleteQuestion = (id: any) => {
        setView('qna')
        return vFetch('/knowledge/questions', {
            method: 'DELETE',
            body: JSON.stringify({ id }),
            cb: (res: any) => {},
        })
    }

    return (
        <div
            className='absolute top-[100%] right-0 flex flex-col shrink-0 border shadow-md text-sm
         dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '
        >
            <button
                onClick={() => setEditQuestion(true)}
                value='Edit'
                className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'
            >
                <CiEdit className='w-[20px] h-[20px]' />
                Edit Question
            </button>
            <button
                value='Delete'
                onClick={() => handleDeleteQuestion(selectedQuestion.id)}
                className='flex gap-2 items-center mb-2 mx-2'
            >
                <FaTrashCan className='w-[20px] text-red text-sm' />
                Delete Question
            </button>
        </div>
    )
}
