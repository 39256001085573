import { ChangeEvent, useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { useDeleteContact, useUpdateContact } from '../../api/useQueries'
import { cn } from '../../../helpers'

export default function CompanyContactV2({ contact }: { contact: any }) {
    const [editableFields, setEditableFields] = useState<any>({})

    const isEdited = Boolean(Object.keys(editableFields).length)

    const updateContact = useUpdateContact()
    const deleteContact = useDeleteContact()

    const { isPending: deletePending } = deleteContact
    const { isPending: updatePending } = updateContact
    const isPending = deletePending || updatePending

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditableFields({
            ...editableFields,
            [target.name]: target.value,
        })
    }

    const handleDelete = async (id: number | string) => {
        deleteContact.mutate({ contact })
    }

    const handleUpdate = async () => {
        await updateContact.mutateAsync(
            { contact: { ...contact, ...editableFields } },
            {
                onSuccess: () => {
                    setEditableFields({})
                },
            }
        )
    }

    return (
        <div
            className={cn(
                isPending && 'opacity-50',
                'text-[14px] grid grid-cols-[1fr_1fr_1fr_1fr_50px] border-b border-lightgrey cursor-pointer dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness'
            )}
        >
            <input
                className='w-full h-full p-[8px] font-bold bg-transparent'
                value={editableFields.name ?? contact.name}
                name='name'
                onChange={handleChange}
                disabled={isPending}
            />
            <input
                className='w-full h-full p-[8px] font-bold bg-transparent'
                value={editableFields.email ?? contact.email}
                name='email'
                onChange={handleChange}
                disabled={isPending}
            />
            <input
                className='w-full h-full p-[8px] font-bold bg-transparent'
                value={editableFields.phone ?? contact.phone}
                name='phone'
                onChange={handleChange}
                disabled={isPending}
            />
            <textarea
                className='w-full h-full max-h-[40px] rounded-none border-0 p-[8px] font-bold bg-transparent'
                value={editableFields.alternate ?? contact.alternate}
                name='alternate'
                onChange={handleChange}
                disabled={isPending}
            ></textarea>
            {isEdited ? (
                <button
                    disabled={deletePending || updatePending}
                    onClick={handleUpdate}
                    className='w-full flex items-center justify-center font-bold bg-blue dark:bg-accent text-white dark:text-darkaccent py-[2px] px-[4px] rounded-[4px] self-center uppercase'
                >
                    Save
                </button>
            ) : (
                <button
                    disabled={deletePending || updatePending}
                    onClick={() => handleDelete(contact.id)}
                    className='w-full flex items-center justify-center'
                >
                    <FaTrash className='text-red dark:text-lightred' />
                </button>
            )}
        </div>
    )
}
