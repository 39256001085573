import { ChangeEvent, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from '../../../../custom_components/component_Basics/Button'
import { cn } from '../../../../helpers'
import { useCompanyProductQuery, useCompanyProductSkusQuery } from '../../../api/useQueries'
import CompanyProductView from '../../CompanyProductView'
import { FetchingSpinner } from '../../../../custom_components/FetchingSpinner'

export default function ProductPopout({ showQuickModal }: { showQuickModal: any }) {
    const productTypesRef = useRef<HTMLDivElement>(null)
    const searchRef = useRef<HTMLInputElement>(null)

    const [selectedSku, setSelectedSku] = useState<{ id: any; sku: any } | undefined>(undefined)
    const [search, setSearch] = useState('')
    const companyProductSkuQuery = useCompanyProductSkusQuery(showQuickModal)
    const { shopifySkus } = companyProductSkuQuery?.data || {}
    const filteredShopifySkus = shopifySkus?.filter((sku: any) => sku.sku.toLowerCase().includes(search.toLowerCase()))
    const companyProductQuery = useCompanyProductQuery(selectedSku?.id)
    const { product } = companyProductQuery?.data || {}

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setSearch(target.value)
    }

    return (
        <div className={cn('flex max-h-[385px] h-[295px] w-[1200px] text-text1 dark:text-darktext1 ')}>
            <div className='flex flex-col w-full'>
                <div className='p-2 flex gap-4 items-center w-full bg-lightgrey dark:bg-darkness'>
                    <h3 className='leading-[1] font-bold'>Product View - {selectedSku ? selectedSku.sku : ''}</h3>
                    <Link
                        draggable={false}
                        to={`/procurement?company_id=${product?.company_id}&view=products`}
                        className={cn(!product && 'pointer-events-none opacity-70')}
                    >
                        <Button className='' size={'sm'} variant={'outline'}>
                            View on procurement dash
                        </Button>
                    </Link>
                </div>
                <div ref={productTypesRef} className='relative flex w-full'>
                    <input
                        ref={searchRef}
                        className='bg-transparent p-[8px] w-full focus:outline-none border-b border-lightgrey dark:border-darkgrey rounded-[4px]'
                        type='text'
                        placeholder='Search SKU'
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                    />
                    {selectedSku && !companyProductQuery.isFetching && (
                        <Button
                            onClick={() => setSelectedSku(undefined)}
                            className='mr-3 self-center'
                            variant={'destructive'}
                            size={'default'}
                        >
                            clear
                        </Button>
                    )}
                    {selectedSku && !product && (
                        <FetchingSpinner
                            className='absolute top-3 right-3'
                            isFetching={companyProductQuery.isPending}
                        />
                    )}

                    {search && (
                        <div className='absolute z-[5] flex flex-col top-[100%] left-0 bg-white dark:bg-darkaccent w-full shadow-small border  border-darkgrey rounded-[4px] max-h-[250px] overflow-auto'>
                            {filteredShopifySkus
                                ?.filter((v: any) => v)
                                .map((pt: any) => (
                                    <button
                                        onClick={() => {
                                            setSelectedSku(pt)
                                            setSearch('')
                                        }}
                                        className='text-left p-[8px] border-b border-lightgrey dark:border-darkgrey'
                                    >
                                        {pt.sku}
                                    </button>
                                ))}
                        </div>
                    )}
                </div>

                {selectedSku && product && (
                    <CompanyProductView product={product} setSearch={setSearch} key={product.id} />
                )}

                <div className='p-[16px] mt-auto w-full bg-lightgrey dark:bg-darkness flex justify-center'></div>
            </div>
        </div>
    )
}

export type CompanyTaskType = {
    id?: string | number
    title: string
    status: 'OPEN' | 'COMPLETED'
    due_at: string
    alert_at?: string
    send_slack_alert?: 0 | 1
    send_email_alert?: 0 | 1
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    updated_at?: string
    name?: string
    type?: string
}
