import { CiSearch } from 'react-icons/ci'
import { vFetch } from '../../helpers'
import { useEffect } from 'react'

export default function MainArticleView({}: any) {
    return (
        <div className='h-full'>
            <div className='flex border-b dark:border-none  dark:bg-darkness'>
                <CiSearch className='ml-1 h-[24px] w-[24px] mt-2 text-grey' />
                <input // top search bar
                    placeholder='Search'
                    className='w-full overflow-hidden bg-transparent p-[8px] focus:outline-none dark:rounded-t dark:text-offwhite'
                ></input>
            </div>
            {/* top section div with pinned articles */}
            <div className='overflow-auto'>
                <div className='pt-8'>
                    <div className='font-bold text-2xl mb-8'>Pinned Articles</div>
                    <div>top section div with pinned articles</div>
                </div>
                {/* bottom section div with all * articles */}
                <div className='pt-8'>
                    <div className='font-bold text-2xl mb-8'>All Development Articles</div>
                    <div>bottom section div with all * articles</div>
                </div>
            </div>
        </div>
    )
}
