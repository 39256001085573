import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import {
    formatDateToLocale,
    formatISOString,
    isISOString,
    numBytesToString,
    parseResObject,
    sortByAlphanumeric,
    typify,
    vFetch,
} from '../../helpers'
import TailSpin from 'react-loading-icons/dist/esm/components/tail-spin'

type Migration = {
    created_at: string
    file_name: string
    id?: number
    raw_data: string
    status: string
}
type TableData = {
    infoType: string
    results: any[]
    uniqueKey: string
}

export default function MigrationsScreen() {
    const modalRef = useRef(null)
    const navigate = useNavigate()
    const user = useSelector<any, any>((state) => state.user)
    const [loading, setLoading] = useState<boolean>(true)
    const [migrations, setMigrations] = useState([])
    const [filteredMigrations, setFilteredMigrations] = useState([])
    const [search, setSearch] = useState<string>('')
    const [currentRaw, setCurrentRaw] = useState('')

    const [querySpeed, setQuerySpeed] = useState<string>('fast')
    const infoTypeOptions = ['database info', 'table info', 'table indexes', 'table constraints', 'views']
    const [infoType, setInfoType] = useState<string>(infoTypeOptions[0])
    const [selectedTable, setSelectedTable] = useState<string>('')

    const [tableData, setTableData] = useState<TableData>({
        infoType: infoTypeOptions[0],
        results: [],
        uniqueKey: 'table',
    })
    const [tableNames, setTableNames] = useState<string[]>([])
    const [showDatabaseResults, setShowDatabaseResults] = useState<boolean>(false)
    const [databaseSortMode, setDatabaseSortMode] = useState<string>(tableData.uniqueKey)

    const databaseColumnNameMap: any = {
        AUTO_INCREMENT: 'increment',
        AVG_ROW_LENGTH: 'avg data/row_byte_size',
        CHARACTER_MAXIMUM_LENGTH: 'max length',
        COLUMN_NAME: 'column',
        Column_name: 'column',
        CREATE_TIME: 'created',
        DATA_LENGTH: 'data_byte_size',
        DATA_TYPE: 'type',
        Expression: 'exp',
        INDEX_LENGTH: 'index_byte_size',
        Index_type: 'type',
        IS_NULLABLE: 'nullable',
        Key_name: 'key',
        Non_unique: 'non-unique',
        NUMERIC_PRECISION: 'precision',
        ORDINAL_POSITION: 'pos',
        Seq_in_index: 'seq',
        Sub_part: 'sub',
        TABLE_NAME: 'table',
        TABLE_ROWS: 'rows',
        UPDATE_TIME: 'updated',
    }

    const removeColumns = ['Index_comment', 'Comment']
    const trueFalseColumns = ['IS_NULLABLE', 'Non_unique', 'null', 'Null', 'NULL']
    function formatRes(resArray: any) {
        return resArray.map((r: any) => {
            const resR: any = {}
            Object.keys(r).forEach((key) => {
                const mapKey = databaseColumnNameMap[key] || key
                if (removeColumns.includes(key)) return
                else if (isISOString(r[key])) resR[mapKey] = new Date(r[key])
                else if (trueFalseColumns.includes(key)) resR[mapKey] = r[key]
                else if (r[key] === null) resR[mapKey] = ''
                else resR[mapKey] = r[key]
            })
            if (resR.data_byte_size !== undefined && resR.index_byte_size !== undefined)
                resR['total data_byte_size'] = resR.data_byte_size + resR.index_byte_size
            return resR
        })
    }

    function loadDatabaseResults(fn: Function) {
        fn()
        setLoading(false)
        setShowDatabaseResults(true)
    }
    async function handleDatabaseSearch() {
        setLoading(true)
        if (infoType === 'database info')
            await vFetch(`/tableData?speed=${querySpeed}`, {
                cb: (res: any) =>
                    loadDatabaseResults(() =>
                        setTableData({ infoType, results: formatRes(res.tables), uniqueKey: 'table' })
                    ),
            })
        if (infoType === 'table info')
            await vFetch(`/tableData/${selectedTable}`, {
                cb: (res: any) =>
                    loadDatabaseResults(() =>
                        setTableData({ infoType, results: formatRes(res.info), uniqueKey: 'pos' })
                    ),
            })
        if (infoType === 'table indexes')
            await vFetch(`/tableData/${selectedTable}/indexes`, {
                cb: (res: any) =>
                    loadDatabaseResults(() =>
                        setTableData({ infoType, results: formatRes(res.indexes), uniqueKey: 'table' })
                    ),
            })
        if (infoType === 'table constraints')
            await vFetch(`/tableData/${selectedTable}/constraints`, {
                cb: (res: any) =>
                    loadDatabaseResults(() =>
                        setTableData({ infoType, results: formatRes(res.constraints), uniqueKey: 'table' })
                    ),
            })
        if (infoType === 'views')
            await vFetch(`/tableData/${selectedTable}/views`, {
                cb: (res: any) =>
                    loadDatabaseResults(() =>
                        setTableData({ infoType, results: formatRes(res.views), uniqueKey: 'table' })
                    ),
            })
    }

    const migrate = (migrationName: string, direction: string) => {
        vFetch(`/migrations`, {
            method: 'POST',
            body: JSON.stringify({
                migrationName,
                direction,
            }),
            cb: (res: any) => refresh(),
        })
    }

    function refresh() {
        Promise.all([
            vFetch(`/migrations`, {
                cb: (res: any) => {
                    if (res.success) {
                        const resMigrations = res.migrations
                            .map((m: any) => parseResObject(m))
                            .sort((a: any, b: any) =>
                                sortByAlphanumeric(a.created_at.getTime(), b.created_at.getTime())
                            )
                            .reverse()
                        setMigrations(resMigrations)
                        setFilteredMigrations(resMigrations)
                        setSearch('')
                    }
                },
            }),
            vFetch('/tableData?speed=fast', {
                cb: (res: any) => {
                    setTableNames(res.tables.map((t: any) => t.TABLE_NAME))
                    setSelectedTable(res.tables[0].TABLE_NAME)
                },
            }),
        ]).then((res) => setLoading(false))
    }
    useEffect(() => {
        refresh()
        if (!user.roles.includes('developer')) navigate('/')

        const listenToWindow = (e: any) => {
            if (e.target === modalRef.current) {
                setCurrentRaw('')
            }
        }
        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    useEffect(() => {
        setFilteredMigrations(
            migrations.filter((m: Migration) => {
                if (!isNaN(Number(search)) && search.length) {
                    if (m.id === parseInt(search)) return true
                    return false
                }
                if (m.file_name.toLowerCase().includes(search.toLowerCase())) return true
                if (m.status.toLowerCase().includes(search.toLowerCase())) return true
                return false
            })
        )
    }, [search])
    function sortDatabaseResults() {
        if (tableData.results.length > 0) {
            const tableDataCopy = { ...tableData }
            const resultsCopy = tableDataCopy.results.slice()
            resultsCopy.sort((a, b) =>
                sortByAlphanumeric(a, b, databaseSortMode.replace('_reverse', ''), tableDataCopy.uniqueKey)
            )
            return databaseSortMode.includes('reverse') ? resultsCopy.reverse() : resultsCopy
        } else return []
    }

    function renderTableData(result: any, key: string) {
        if (result[key] instanceof Date) return formatDateToLocale(result[key])
        if (key.includes('_byte_size')) return numBytesToString(result[key])
        return result[key]
    }

    const [hovered, setHovered] = useState<string>('')
    const labelStyle = 'block dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
    const buttonStyle =
        'px-[8px] h-[30px] bg-blue hover:bg-blue/90 text-white font-semibold dark:bg-accent dark:hover:bg-accent/90 dark:text-black rounded shadow-small'
    const gridColsStyles: any = {
        'database info': 'grid-cols-[repeat(8,auto),1fr]',
        'table info': `grid-cols-[repeat(6,auto),1fr]`,
        'table indexes': `grid-cols-[repeat(12,auto),1fr]`,
        'table constraints': `grid-cols-[repeat(4,auto),1fr]`,
        'views': `grid-cols-[repeat(1,auto),1fr]`,

    }

    return (
        <>
            {loading && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            <div className='flex flex-col gap-[32px] dark:text-offwhite'>
                <div className='flex flex-col gap-[16px]'>
                    <h2 className='text-[20px] tracking-[2px] font-[300] uppercase'>Database Info</h2>
                    <div className='flex gap-[16px]'>
                        <div className='flex flex-col gap-[4px]'>
                            <label className={labelStyle}>Info Type</label>
                            <select
                                value={infoType}
                                className='bg-lightgrey rounded-[4px] py-[4px] px-[8px] dark:bg-faintplus focus:outline dark:focus:outline-accent capitalize'
                                onChange={({ target }) => setInfoType(target.value)}
                            >
                                {infoTypeOptions.map((o) => (
                                    <option value={o} className='dark:bg-darkness/90'>
                                        {o}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {infoType === 'database info' && (
                            <div className='flex flex-col gap-[4px]'>
                                <label className={labelStyle}>Query Speed</label>
                                <select
                                    value={querySpeed}
                                    className='bg-lightgrey rounded-[4px] py-[4px] px-[8px] dark:bg-faintplus focus:outline dark:focus:outline-accent'
                                    onChange={({ target }) => setQuerySpeed(target.value)}
                                >
                                    {['fast', 'accurate'].map((o) => (
                                        <option value={o} className='dark:bg-darkness/90'>
                                            {o}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {infoType.includes('table') && (
                            <div className='flex flex-col gap-[4px]'>
                                <label className={labelStyle}>Table Name</label>
                                <select
                                    value={selectedTable}
                                    className='bg-lightgrey rounded-[4px] py-[4px] px-[8px] dark:bg-faintplus focus:outline dark:focus:outline-accent'
                                    onChange={({ target }) => setSelectedTable(target.value)}
                                >
                                    {tableNames.map((t) => (
                                        <option value={t} className='dark:bg-darkness/90'>
                                            {t}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className='flex flex-col justify-end'>
                            <button className={buttonStyle} onClick={handleDatabaseSearch}>
                                Search
                            </button>
                        </div>
                        {tableData.results.length > 0 && (
                            <div className='flex flex-col justify-end'>
                                <button
                                    className={buttonStyle}
                                    onClick={() => setShowDatabaseResults(!showDatabaseResults)}
                                >
                                    {showDatabaseResults ? 'Hide' : 'Show'} Results
                                </button>
                            </div>
                        )}
                    </div>
                    {showDatabaseResults && (
                        <div
                            className={`relative grid justify-start ${
                                gridColsStyles[tableData.infoType]
                            } border-[1px] border-darkgrey`}
                            onMouseOut={() => setHovered('')}
                        >
                            {tableData.results[0] &&
                                Object.keys(tableData.results[0])?.map((c: any) => (
                                    <div
                                        className='flex items-center gap-[8px] px-[8px] py-[4px] font-bold bg-blue dark:bg-darkaccent border-b-[1px] border-darkgrey capitalize text-white dark:text-offwhite cursor-pointer'
                                        onClick={() => setDatabaseSortMode(databaseSortMode === c ? `${c}_reverse` : c)}
                                    >
                                        {c.replaceAll('_byte_size', '')}
                                        {databaseSortMode === c || databaseSortMode === `${c}_reverse` ? (
                                            <BsArrowDownCircleFill
                                                className={`duration-300 ${
                                                    databaseSortMode.includes('reverse') ? 'rotate-180' : ''
                                                }`}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ))}
                            {sortDatabaseResults().map((result, i) =>
                                Object.keys(result).map((k: any, ki: number) => (
                                    <div
                                        className={`px-[8px] ${
                                            hovered === result[tableData.uniqueKey]
                                                ? '!bg-[rgb(199,202,209)] dark:!bg-blue'
                                                : ''
                                        } ${i % 2 === 0 ? '' : 'bg-[rgb(224,228,235)] dark:bg-darkaccent'} ${
                                            ki + 1 === Object.values(result).length ? 'grow w-full' : ''
                                        }`}
                                        onMouseOver={() => setHovered(result[tableData.uniqueKey])}
                                    >
                                        {renderTableData(result, k)}
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>

                <div className='flex flex-col gap-[16px]'>
                    <h1 className='text-[24px] tracking-[2px] font-[300]  uppercase'>Migrations</h1>
                    <div className='rounded-[4px] shadow-small dark:bg-darkaccent'>
                        <div className='flex border-b border-lightgrey dark:border-darkgrey py-[8px] px-[16px]'>
                            <input
                                className='focus:outline-none w-full bg-transparent dark:text-offwhite'
                                type='text'
                                placeholder='Search'
                                value={search}
                                onChange={({ target }) => setSearch(target.value)}
                            />
                        </div>
                        <div className='grid grid-cols-6 items-center bg-blue dark:bg-darkness border-b border-lightgrey dark:border-darkgrey sticky top-[50px]'>
                            <p className='p-[8px] text-white dark:text-offwhite pl-[16px] font-bold uppercase text-[12px]'>
                                ID
                            </p>
                            <p className='p-[8px] text-white dark:text-offwhite font-bold uppercase text-[12px]'>
                                Name
                            </p>
                            <p className='p-[8px] text-white dark:text-offwhite font-bold uppercase text-[12px]'>
                                Created At
                            </p>
                            <p className='p-[8px] text-white dark:text-offwhite font-bold uppercase text-[12px]'>
                                Status
                            </p>
                            <p className='p-[8px] text-white dark:text-offwhite font-bold uppercase text-[12px]'>
                                Migrate
                            </p>
                            <p className='p-[8px] text-white dark:text-offwhite font-bold uppercase text-[12px]'>Raw</p>
                        </div>
                        {filteredMigrations.map((m: Migration, i) => (
                            <div
                                key={m.file_name}
                                className={`grid grid-cols-6 items-center ${
                                    i !== migrations.length - 1 ? `border-b border-lightgrey dark:border-darkgrey` : ''
                                }`}
                            >
                                <p className='p-[8px] pl-[16px] dark:text-fire font-bold'>{m?.id}</p>
                                <p className='p-[8px] dark:text-white font-bold'>
                                    {m.file_name.split('_')[1].replace('.js', '')}
                                </p>
                                <p className='p-[8px] dark:text-white'>{formatDateToLocale(m.created_at)}</p>
                                <div className='p-[8px] flex gap-[8px] items-center'>
                                    <p
                                        className={`font-bold leading-[1] uppercase m-0 ${
                                            m.status === 'down'
                                                ? 'text-red dark:text-lightred'
                                                : 'text-blue dark:text-accent'
                                        }`}
                                    >
                                        {m.status}
                                    </p>
                                    {m.status === 'down' ? (
                                        <BsArrowDownCircleFill className='text-red dark:text-lightred' />
                                    ) : (
                                        <BsArrowUpCircleFill className='text-blue dark:text-accent' />
                                    )}
                                </div>
                                <div className='flex gap-[8px] p-[8px] pr-[16px]'>
                                    <button
                                        onClick={() => migrate(m.file_name.replace('.js', ''), 'down')}
                                        className='py-[4px] px-[16px] rounded-[4px] bg-red text-white dark:text-offwhite font-bold uppercase'
                                    >
                                        DOWN
                                    </button>
                                    <button
                                        onClick={() => migrate(m.file_name.replace('.js', ''), 'up')}
                                        className='py-[4px] px-[16px] rounded-[4px] bg-blue text-white dark:text-offwhite font-bold uppercase'
                                    >
                                        UP
                                    </button>
                                </div>
                                <button
                                    onClick={() => setCurrentRaw(m.raw_data)}
                                    className='py-[4px] px-[16px] rounded-[4px] bg-darkgrey dark:bg-fire text-white dark:text-darkness font-bold uppercase w-fit'
                                >
                                    VIEW
                                </button>
                            </div>
                        ))}
                    </div>
                    {currentRaw.length > 0 && (
                        <div
                            ref={modalRef}
                            className='fixed w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center z-[500] top-0 left-0'
                        >
                            <div className='p-[32px] rounded-[4px] bg-white dark:bg-darkaccent relative max-w-[80%] max-h-[80%] overflow-auto'>
                                <button
                                    onClick={() => setCurrentRaw('')}
                                    className='absolute top-[0px] right-[8px] text-red dark:text-lightred font-bold text-[32px] leading-[1]'
                                >
                                    &times;
                                </button>
                                <pre className='dark:text-offwhite'>{currentRaw}</pre>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
