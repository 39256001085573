import { useState } from 'react'
import { FaTrash } from 'react-icons/fa'

export default function PlugSelectView({ name, amount, index, handleSave, handleRemove }) {
    const [edited, setEdited] = useState(false)
    const plugMap = {
        '5-20R': {
            image_url: 'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_20A_GFCI_Outlet.png?v=1612382967',
            name: '120v 20 Amp Standard Receptacle (5-20R)',
        },
        'L5-30R': {
            image_url: 'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_30A_Twist_Lock.png?v=1612382967',
            name: '120v 30 Amp Twist Lock (L5-30R)',
        },
        'L14-30R': {
            image_url: 'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_240V_30A_Twist_Lock.png?v=1612382967',
            name: '120/240v 30 Amp Twist Lock (L14-30R)',
        },
        'L14-50R': {
            image_url:
                'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_240V_50A_Heavy_Duty_Outlet.png?v=1612382967',
            name: '120v/240v 50 Amp Heavy Duty Outlet (L14-50R)',
        },
        '14-50R': {
            image_url:
                'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_240V_50A_Heavy_Duty_Outlet.png?v=1612382967',
            name: '120v/240v 50 Amp Heavy Duty Outlet (14-50R)',
        },
        'TT-30R': {
            image_url: 'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_TT-30R.png?v=1612813988',
            name: '120v 30 Amp RV Ready (TT-30R)',
        },
        'L14-20R': {
            image_url: 'https://cdn.shopify.com/s/files/1/1163/1976/files/125V_250V_20_Amp_Twist_Lock.png?v=1613276582',
            name: '125v/250v 20 Amp Twist Lock (L14-20R)',
        },
    }
    const [selectedPlug, setSelectedPlug] = useState({ name, amount })
    const handleChange = ({ target }) => {
        setEdited(true)
        setSelectedPlug({
            ...selectedPlug,
            [target.name]: target.value,
        })
    }
    const saveAndQuit = ({ target }) => {
        setEdited(false)
        handleSave(selectedPlug, index)
    }
    return (
        <div className='flex items-center gap-[8px] p-[8px] border border-grey rounded-[4px] relative mb-[16px]'>
            <select
                onBlur={saveAndQuit}
                value={selectedPlug.name}
                name='name'
                className='p-[8px] border border-grey rounded-[4px] focus:outline-none dark:text-offwhite dark:bg-darkness dark:border-blue focus:outline-none'
                onChange={handleChange}
            >
                {Object.keys(plugMap).map((val) => (
                    <option value={val}>{plugMap[val].name}</option>
                ))}
            </select>
            <p className='text-darkgrey dark:text-offwhite font-bold'>Quantity:</p>
            <input
                value={selectedPlug.amount}
                name='amount'
                type='number'
                className='w-[42px] p-[8px] text-center rounded-[4px] focus:outline-none border border-grey dark:text-offwhite dark:bg-darkness dark:border-blue focus:outline-none'
                onBlur={saveAndQuit}
                onChange={handleChange}
            />
            <FaTrash size={'24px'} className='fill-red dark:fill-[rgb(255,0,0)]' onClick={handleRemove} />
            {edited && (
                <button
                    className='py-[2px] px-[16px] font-bold text-blue border-2 border-blue dark:border-accent dark:text-accent rounded-[4px]'
                    onClick={saveAndQuit}
                >
                    SAVE
                </button>
            )}
        </div>
    )
}
