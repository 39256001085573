import { CustomerInit } from '../customers/customers.types'
import { LineItemInit, OrderCustomer } from '../orders/orders.types'

export type ExistingTaskTypes = {
    Accounting?: string
    'Customer Service'?: string
    'Order Fulfillment'?: string
    Sales?: string
    'Select Type'?: string
}

export type TaskEventType = {
    event_type: string
    created_at: string
    user_id: string
    task_id: number
}
export type PrivateEventType = {
    event_type: string
    created_at: string
    user_id: string
    task_id?: number
    order_id?: number
    draft_order_id?: number
    product_id?: number
}
export type TaskTimelineEventV2 = {
    id: number
    created_at: Date
    type: string
    message: string
    previous_state:
        | any
        | {
              assigned_to: string[]
              created_at: string
              customer: CustomerInit
              draft_order_id: number
              draft_order_name: string
              due_at: Date
              due_at_revisions: number
              is_escalated: number
              line_item_information: string | LineItemInit[]
              order_id: null | number
              order_name: null | string
              order_notes: string
              priority: string
              project: null | string
              status: string
              task_notes: string
              task_type: string
              title: string
              topic: string
              updated_at: string
          }
    task_id: number
    user_id: number
}

export type TaskTimelineEvent = {
    created_at: string
    event_type: string
    message: string
    previous_state?: null | {
        assigned_to: string[]
        created_at: string
        customer: CustomerInit
        draft_order_id: number
        draft_order_name: string
        due_at: Date
        due_at_revisions: number
        is_escalated: number
        line_item_information: string | LineItemInit[]
        order_id: null | number
        order_name: null | string
        order_notes: string
        priority: string
        project: null | string
        status: string
        task_notes: string
        task_type: string
        title: string
        topic: string
        updated_at: string
    }
    user?: {
        email: string
        first_name: string
        last_name: string
        type: string
        user_id: string
    }
}

export type TaskCreateInit = {
    assigned_to: string[]
    customer: OrderCustomer
    draft_order_id: number | undefined | null
    draft_order_name: string | null
    due_at: Date
    is_escalated: boolean
    line_item_information: any[]
    new_order_note: string
    order_id: number | undefined | null
    order_name: string | null
    order_notes: string
    priority: string
    project: string
    status: string
    task_notes: string
    task_type: string
    title: string
    topic: string
}
export const defaultTaskCreate: TaskCreateInit = {
    assigned_to: [],
    customer: {
        accepts_marketing: false,
        accepts_marketing_updated_at: '',
        admin_graphql_api_id: '',
        billing_address: {
            address1: '',
            address2: '',
            city: '',
            company: '',
            country: '',
            country_code: '',
            first_name: '',
            last_name: '',
            latitude: undefined,
            longitude: undefined,
            name: '',
            phone: '',
            province: '',
            province_code: '',
            zip: '',
        },
        created_at: '',
        currency: '',
        default_address: {
            address1: '',
            address2: '',
            city: '',
            company: '',
            country: '',
            country_code: '',
            country_name: '',
            customer_id: undefined,
            default: false,
            first_name: '',
            id: undefined,
            last_name: '',
            name: '',
            phone: '',
            province: '',
            province_code: '',
            zip: '',
        },
        email: '',
        email_marketing_consent: {
            consent_updated_at: null,
            opt_in_level: '',
            state: '',
        },
        first_name: '',
        id: undefined,
        last_name: '',
        marketing_opt_in_level: null,
        multipass_identifier: null,
        note: null,
        phone: '',
        shipping_address: {
            address1: '',
            address2: '',
            city: '',
            company: '',
            country: '',
            country_code: '',
            first_name: '',
            last_name: '',
            latitude: undefined,
            longitude: undefined,
            name: '',
            phone: '',
            province: '',
            province_code: '',
            zip: '',
        },
        sms_marketing_consent: {
            consent_collected_from: '',
            consent_updated_at: null,
            opt_in_level: '',
            state: '',
        },
        state: '',
        tags: '',
        tax_exempt: false,
        tax_exemptions: [],
        updated_at: '',
        verified_email: false,
    },
    draft_order_id: null,
    draft_order_name: null,
    due_at: new Date(),
    is_escalated: false,
    line_item_information: [],
    new_order_note: '',
    order_id: undefined,
    order_name: '',
    order_notes: '',
    priority: 'Low',
    project: '',
    status: '',
    task_notes: '',
    task_type: '',
    title: '',
    topic: '',
}

export interface TaskEditInit extends TaskCreateInit {
    created_at: Date
    due_at_revisions: number
    status: string
    timeline_events: TaskTimelineEvent[]
    updated_at: string
    id: number
}
export type OrderButtonPropsInit = {
    currentOrder: {
        name: string
        admin_graphql_api_id: string
        id: number
    }
}

export const defaultTaskCustomer: OrderCustomer = {
    accepts_marketing: false,
    accepts_marketing_updated_at: '',
    admin_graphql_api_id: '',
    billing_address: {
        address1: '',
        address2: '',
        city: '',
        company: '',
        country: '',
        country_code: '',
        first_name: '',
        last_name: '',
        latitude: undefined,
        longitude: undefined,
        name: '',
        phone: '',
        province: '',
        province_code: '',
        zip: '',
    },
    created_at: '',
    currency: '',
    default_address: {
        address1: '',
        address2: '',
        city: '',
        company: '',
        country: '',
        country_code: '',
        country_name: '',
        customer_id: undefined,
        default: false,
        first_name: '',
        id: undefined,
        last_name: '',
        name: '',
        phone: '',
        province: '',
        province_code: '',
        zip: '',
    },
    email: '',
    email_marketing_consent: {
        consent_updated_at: null,
        opt_in_level: '',
        state: '',
    },
    first_name: '',
    id: undefined,
    last_name: '',
    marketing_opt_in_level: null,
    multipass_identifier: null,
    note: null,
    phone: '',
    shipping_address: {
        address1: '',
        address2: '',
        city: '',
        company: '',
        country: '',
        country_code: '',
        first_name: '',
        last_name: '',
        latitude: undefined,
        longitude: undefined,
        name: '',
        phone: '',
        province: '',
        province_code: '',
        zip: '',
    },
    sms_marketing_consent: {
        consent_collected_from: '',
        consent_updated_at: null,
        opt_in_level: '',
        state: '',
    },
    state: '',
    tags: '',
    tax_exempt: false,
    tax_exemptions: [],
    updated_at: '',
    verified_email: false,
}

export const topicMap: any = {
    'Select Type': ['Type Not Selected'],
    'Customer Service': ['Return', 'Damage', 'Shipping', 'Question', 'Tracking', 'Cancellation', 'Service', 'Warranty'],
    Sales: ['Lead', 'Follow-up'],
    Accounting: ['Accounting', 'Chargeback', 'Claims', 'Credits', 'Inventory', 'Payroll', 'Sales Tax'],
    'Order Fulfillment': [
        'Daily Freight',
        'UPS',
        'Shipping Follow-up',
        'Chargebacks',
        'Fraud Follow-up',
        'Warehouse Work',
        'Out of Stock',
    ],
}
