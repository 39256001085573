import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSections } from '../../redux/sections'
import { sendToast, vFetch } from '../../helpers'

export default function ProductScreen() {
    const [currentProduct, setCurrentProduct] = useState('')
    const [loading, setLoading] = useState(false)
    const [settingImage, setSettingImage] = useState(false)
    const dispatch = useDispatch()
    const {
        sections: { product },
    } = useSelector((state) => state)
    const handleGet = () => {
        setLoading(true)
        dispatch(
            setSections({
                product: '',
                description: '',
                checkmarks: [],
                plugs: [],
                features: [],
                specifications: [],
                packageContents: [],
                warranty: '',
                manuals: [],
            })
        )
        vFetch(`/descriptions/product/${currentProduct}`, {
            cb: (res) => {
                if (res.success) {
                    dispatch(
                        setSections({
                            product: {
                                gid: res.gid,
                                title: res.title,
                                img_src: res.img_src,
                            },
                            description: res.description,
                            checkmarks: res.checkmarks,
                            plugs: res.plugs,
                            features: res.features,
                            specifications: res.specifications,
                            packageContents: res.package_contents,
                            warranty: res.warranty,
                            manuals: res.manuals,
                            crossSells: res.cross_sells,
                        })
                    )
                }
                setLoading(false)
            },
            catchCb: () => setLoading(false),
        })
    }
    const handleChange = ({ target }) => {
        setCurrentProduct(target.value)
    }
    const handleChangeProduct = () => {
        dispatch(
            setSections({
                product: '',
                description: '',
                checkmarks: [],
                plugs: [],
                features: [],
                specifications: [],
                packageContents: [],
                warranty: '',
                manuals: [],
            })
        )
    }
    const LoadingGear = () => {
        return (
            <svg
                style={{ width: '300px', height: '300px' }}
                version='1.1'
                id='L2'
                xmlns='http://www.w3.org/2000/svg'
                x='0px'
                y='0px'
                viewBox='0 0 100 100'
                enable-background='new 0 0 100 100'
            >
                <circle fill='none' stroke='orange' stroke-width='4' stroke-miterlimit='10' cx='50' cy='50' r='48' />
                <line
                    fill='none'
                    stroke-linecap='round'
                    stroke='orange'
                    stroke-width='4'
                    stroke-miterlimit='10'
                    x1='50'
                    y1='50'
                    x2='85'
                    y2='50.5'
                >
                    <animateTransform
                        attributeName='transform'
                        dur='2s'
                        type='rotate'
                        from='0 50 50'
                        to='360 50 50'
                        repeatCount='indefinite'
                    />
                </line>
                <line
                    fill='none'
                    stroke-linecap='round'
                    stroke='orange'
                    stroke-width='4'
                    stroke-miterlimit='10'
                    x1='50'
                    y1='50'
                    x2='49.5'
                    y2='74'
                >
                    <animateTransform
                        attributeName='transform'
                        dur='15s'
                        type='rotate'
                        from='0 50 50'
                        to='360 50 50'
                        repeatCount='indefinite'
                    />
                </line>
            </svg>
        )
    }
    const handleUpload = (e) => {
        setSettingImage(true)
        const data = new FormData()
        data.append('image', e.target.files[0], e.target.files[0].name)

        vFetch(`/removebg/${product.gid.split('/')[4]}`, {
            method: 'POST',
            body: data,
            contentType: 'skip',
            cb: (res) => {
                setSettingImage(false)
                if (res.success) {
                    dispatch(
                        setSections({
                            product: {
                                ...product,
                                img_src: res.s3_img_url,
                            },
                        })
                    )
                } else {
                    sendToast({ message: 'Upload failed! Pleas make sure that your image is smaller than 10MB.' })
                    setSettingImage(false)
                }
            },
            catchCb: () => setSettingImage(false),
        })
    }
    useEffect(() => {
        if (product && product.gid) {
            setCurrentProduct(product.gid.split('/')[4])
        }
    }, [])
    return (
        <div className='p-[16px] w-full rounded-4px shadow-small rounded-[4px] dark:bg-darkaccent flex flex-col items-center '>
            <h2 className='text-darkgrey dark:text-offwhite font-bold text-[24px] max-w-[600px] mx-auto text-center font-bai'>
                {product && product.title}
            </h2>
            {settingImage ? (
                <LoadingGear />
            ) : (
                product && product.img_src && <img src={product.img_src} width='400' height='400' className='contain' />
            )}
            {loading && <LoadingGear />}
            {product && [
                <label className='text-darkgrey dark:text-offwhite font-medium mb-[8px]'>
                    {settingImage ? 'Removing background...' : 'Upload new featured image'}
                </label>,
                <input
                    className='border border-grey bg-white dark:text-offwhite dark:bg-darkness dark:border-blue p-[8px] rounded-[4px] mb-[32px]'
                    type='file'
                    name='image_upload'
                    onChange={handleUpload}
                />,
            ]}
            {!product && !loading && (
                <h2 className='dark:text-offwhite font-bold dark:font-normal mb-[16px] leading-[1] font-bai'>
                    Find a product
                </h2>
            )}
            {!product && (
                <input
                    className='border border-grey rounded-[4px] p-[8px] mb-[16px] dark:bg-darkness dark:border-blue dark:text-white focus:outline-none text-center'
                    type='text'
                    onChange={handleChange}
                    value={currentProduct}
                    placeholder='Product ID Number'
                />
            )}
            {!product ? (
                <button
                    className='border-2 border-blue dark:border-fire px-[16px] py-[4px] text-blue dark:text-fire font-bold text-[18px] rounded-[4px]'
                    onClick={handleGet}
                >
                    GET PRODUCT
                </button>
            ) : (
                <button
                    className='border-2 border-blue dark:border-fire px-[16px] py-[4px] text-blue dark:text-fire font-bold text-[18px]'
                    onClick={handleChangeProduct}
                >
                    CHANGE PRODUCT
                </button>
            )}
        </div>
    )
}
