export interface VendorContactsMap {
    [key: string]: VendorInit
}

export type VendorInit = {
    additional_info: string
    contacts: VendorContactEditInit[]
    name: string
    order_submission_info: string
    order_submission_url: string
    payment_info: string
    return_instructions: string
    return_policy_buyers_remorse: string
    return_policy_doa: string
    return_policy_warranty_repair: string
    store_id: string
}
export const defaultVendor: VendorInit = {
    additional_info: '',
    contacts: [],
    name: '',
    order_submission_info: '',
    order_submission_url: '',
    payment_info: '',
    return_instructions: '',
    return_policy_buyers_remorse: '',
    return_policy_doa: '',
    return_policy_warranty_repair: '',
    store_id: '0',
}

export type VendorContactCreateInit = {
    alternate: string
    cell_phone: string
    contact_type: string
    email: string
    name: string
    phone: string
    phone_extension: string
    phone_prefix: string
    sort_id: number | null
    store_id: string | null
    vendor: string
}
export interface VendorContactEditInit extends VendorContactCreateInit {
    id: number
}
export const vendorContactTypes = [
    'Accounting',
    'Account Management',
    'Order Submission',
    'Troubleshooting And Support',
]
export const defaultVendorContact: VendorContactCreateInit = {
    name: '',
    email: '',
    phone: '',
    phone_prefix: '',
    phone_extension: '',
    vendor: '',
    contact_type: vendorContactTypes[0],
    cell_phone: '',
    alternate: '',
    sort_id: null,
    store_id: null,
}
