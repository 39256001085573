import { ChangeEvent, useEffect, useRef } from 'react'

export default function Textarea({
    id,
    label,
    name,
    value,
    required,
    onChange,
    className,
    outerClassName,
    disabled = false,
    autoFocus,
    placeholder,
    readOnly = false,
}: {
    id: string
    label?: string
    name: string
    value: string
    required?: boolean
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
    className?: string
    outerClassName?: string
    disabled?: boolean
    autoFocus?: boolean
    placeholder?: string
    readOnly?: boolean
}) {
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        const resize = () => {
            textareaRef.current!.style.height = ''
            const computed = window.getComputedStyle(textareaRef.current!)
            const height =
                parseInt(computed.getPropertyValue('border-top-width'), 10) +
                parseInt(computed.getPropertyValue('padding-top'), 10) +
                textareaRef.current!.scrollHeight +
                parseInt(computed.getPropertyValue('padding-bottom'), 10) +
                parseInt(computed.getPropertyValue('border-bottom-width'), 10)

            if (textareaRef.current && textareaRef.current.scrollHeight !== textareaRef.current.clientHeight) {
                textareaRef.current.style.height = `${height}px`
            }
        }

        resize()

        const observer = new ResizeObserver(() => {
            resize()
        })
        observer.observe(textareaRef.current as Element)

        return () => {
            observer.disconnect()
        }
    }, [textareaRef.current?.value])
    return (
        <>
            {label ? (
                <div className={outerClassName || 'flex flex-col gap-[4px] w-full h-full'}>
                    <label
                        htmlFor={id}
                        className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'
                    >
                        {label}
                        {required && (
                            <span className='absolute top-0 right-[-10px] text-[14px] text-red dark:text-lightred'>
                                *
                            </span>
                        )}
                    </label>
                    <textarea
                        rows={1}
                        ref={textareaRef}
                        className={
                            className ||
                            'w-full max-h-[100px] text-[16px] leading-1 bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                        }
                        required={required}
                        value={value}
                        name={name}
                        onChange={onChange}
                        disabled={disabled}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        readOnly={readOnly}
                    ></textarea>
                </div>
            ) : (
                <textarea
                    rows={1}
                    ref={textareaRef}
                    id={id}
                    name={name}
                    required={required}
                    className={
                        className ||
                        'w-full h-full !max-w-none p-[8px] bg-transparent border-lightgrey dark:border-darkgrey rounded-[4px] focus:outline-none h-fit'
                    }
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    readOnly={readOnly}
                ></textarea>
            )}
        </>
    )
}
