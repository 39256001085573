import { useEffect, useState } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { vFetch } from '../helpers'
import { useSelector } from 'react-redux'
const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

export default function OrdersModule() {
    const user = useSelector((state) => state.user)
    const [orders, setOrders] = useState([])

    useEffect(() => {
        setOrders([])
        vFetch(`/orders/latest`, {
            cb: (res) => {
                if (res.success) {
                    setOrders(res.orders)
                }
            },
        })
    }, [user])
    return (
        <div className='p-[16px] rounded-[4px] dark:bg-darkaccent shadow-small'>
            <Link to={'/orders'} className='text-fire font-bold mb-[8px] flex gap-[4px] items-center'>
                Last 50 Orders <BiLinkExternal />
            </Link>
            <div className='max-h-[280px] overflow-auto'>
                {orders.map((o) => (
                    <Link
                        key={o.id}
                        to={`/orders/${o.id}`}
                        className='p-[8px] grid grid-cols-[1fr_2fr_1fr_1fr] gap-[8px] border-b border-lightgrey dark:border-darkgrey'
                    >
                        <p className='text-blue dark:text-accent font-bold'>{o.name}</p>
                        <p className='text-darkgrey dark:text-offwhite font-bold'>
                            {JSON.parse(o.customer).first_name} {JSON.parse(o.customer).last_name}
                        </p>
                        <p className='text-darkgrey dark:text-offwhite font-bold'>
                            {JSON.parse(o.line_items).length} item{JSON.parse(o.line_items).length > 1 ? 's' : ''}
                        </p>
                        <p className='text-lime font-bold'>{moneyFormatter.format(o.total_price)}</p>
                    </Link>
                ))}
            </div>
        </div>
    )
}
