import { useState } from 'react'
import { MetafieldProps } from '../ProductSetup.types'

export default function Specifications({ product, setProduct, handleUpdateMetafield, edited }: MetafieldProps) {
    const [specText, setSpecText] = useState(
        (product.specifications || [])
            .map((spec) => {
                const cells = spec.includes('::') ? spec.split('::') : spec.split(':')
                if (cells.length == 1) {
                    return cells[0] + '\n'
                }
                return cells.join('\n')
            })
            .join('\n')
    )
    const [packageText, setPackageText] = useState((product.package_contents || []).join('\n'))
    const midpoint =
        product.specifications && product.package_contents
            ? (product.specifications.length + product.package_contents.length) / 2 + 2
            : 0

    const handleChange = ({ target }: { target: HTMLTextAreaElement }) => {
        if (target.name === 'specs-left') {
            const formattedValue = target.value.replaceAll('\t', '\n')
            setSpecText(formattedValue)
            const result: string[] = []
            const cells = formattedValue.split('\n')
            for (let i = 0; i < cells.length - 1; i += 2) {
                const cell1 = cells[i].replaceAll('\t', ' ').trim()
                const cell2 = cells[i + 1].replaceAll('\t', ' ').trim()
                const currentRow = [cell1, cell2].filter((c) => c.length)
                result.push(currentRow.join('::'))
            }
            setProduct((previousState) => ({
                ...previousState,
                specifications: result,
            }))
        } else {
            setPackageText(target.value)
            const values = target.value.split('\n')
            const currentPackageContents = values.map((v) => v.trim()).filter((v) => v.length)
            setProduct((previousState) => ({
                ...previousState,
                package_contents: currentPackageContents,
            }))
        }
    }

    return (
        <>
            <div className='flex gap-[16px]'>
                <div className='h-fit sticky top-[66px] w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col shadow-small overflow-hidden'>
                    <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                        Specifications
                    </p>
                    <textarea
                        className='w-full block max-w-none border-none p-[8px] rounded-[4px] dark:text-white bg-lightgrey dark:bg-faintplus focus:outline-none'
                        spellCheck='true'
                        name='specs-left'
                        onChange={handleChange}
                        value={specText}
                    ></textarea>
                    <p className='dark:text-offwhite dark:font-normal font-bold text-[14px] mt-[4px]'>
                        * Each cell in the specifications table must be separated by a newline
                    </p>
                    <p className='dark:text-offwhite dark:font-normal font-bold text-[14px] mt-[4px]'>
                        * To make a green cell heading, add an additional newline under the heading
                    </p>
                    <p className='dark:text-offwhite dark:font-normal font-bold text-[14px] mt-[4px]'>
                        * Please only add package contents in the Package Contents section
                    </p>
                    {edited && (
                        <button
                            className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                            onClick={() =>
                                handleUpdateMetafield([
                                    {
                                        ownerId: `gid://shopify/Product/${product.id}`,
                                        namespace: 'custom',
                                        key: 'specifications',
                                        type: 'list.single_line_text_field',
                                        value: JSON.stringify(product.specifications),
                                    },
                                    {
                                        ownerId: `gid://shopify/Product/${product.id}`,
                                        namespace: 'custom',
                                        key: 'package_contents',
                                        type: 'list.single_line_text_field',
                                        value: JSON.stringify(product.package_contents),
                                    },
                                ])
                            }
                        >
                            Save Changes
                        </button>
                    )}
                </div>
                <div className='w-full h-fit sticky top-[66px] dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col shadow-small overflow-hidden'>
                    <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                        Package Contents
                    </p>
                    <textarea
                        className='w-full block max-w-none border-none p-[8px] rounded-[4px] dark:text-white bg-lightgrey dark:bg-faintplus focus:outline-none'
                        spellCheck='true'
                        name='package-contents'
                        onChange={handleChange}
                        value={packageText}
                    ></textarea>
                </div>
            </div>
            {((product.specifications && product.specifications.length > 0) ||
                (product.package_contents && product.package_contents.length > 0)) && (
                <>
                    <h2 className='dark:text-offwhite font-light uppercase text-[24px] my-[16px]'>
                        Specifications Preview
                    </h2>
                    <div className='bg-white description-preview description-preview--full w-fit !m-0 dark:opacity-[0.9] dark:text-black'>
                        <div className='pdp__specs__row'>
                            {product.specifications && product.specifications.length > 0 && (
                                <div className='pdp__specs__row__left'>
                                    <table className='pdp__specs__row__left__table'>
                                        <tbody className='pdp__specs__row__left__table__tbody'>
                                            {product.specifications.map((s, index) => {
                                                const cells = s.includes('::') ? s.split('::') : s.split(':')
                                                let heading = false
                                                if (cells.length === 1) {
                                                    cells.push('')
                                                    heading = true
                                                }
                                                if (index < midpoint) {
                                                    return (
                                                        <tr>
                                                            <td
                                                                className={
                                                                    heading
                                                                        ? 'pdp__specs__row--bold pdp__specs__row--green'
                                                                        : 'pdp__specs__row--bold'
                                                                }
                                                            >
                                                                {cells[0]}
                                                            </td>
                                                            <td>{cells[1]}</td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <div className='pdp__specs__row__right'>
                                {product.specifications && product.specifications.length >= midpoint && (
                                    <table className='pdp__specs__row__right__table'>
                                        <tbody className='pdp__specs__row__right__table__tbody'>
                                            {product.specifications.map((s, index) => {
                                                const cells = s.includes('::') ? s.split('::') : s.split(':')
                                                let heading = false
                                                if (cells.length === 1) {
                                                    cells.push('')
                                                    heading = true
                                                }
                                                if (index >= midpoint) {
                                                    return (
                                                        <tr>
                                                            <td
                                                                className={
                                                                    heading
                                                                        ? 'pdp__specs__row--bold pdp__specs__row--green'
                                                                        : 'pdp__specs__row--bold'
                                                                }
                                                            >
                                                                {cells[0]}
                                                            </td>
                                                            <td>{cells[1]}</td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                )}
                                {product.package_contents && product.package_contents.length > 0 && (
                                    <div className='pdp__specs__row__right__package'>
                                        <h3
                                            className='pdp__specs__row__right__package__heading'
                                            style={{ marginTop: '16px' }}
                                        >
                                            Package Contents
                                        </h3>
                                        <div className='pdp__specs__row__right__package__container'>
                                            {product.package_contents.map((p) => (
                                                <p className='pdp__specs__row__right__package__text'>{p}</p>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
