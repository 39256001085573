import { useState } from 'react'
import { FaTrash } from 'react-icons/fa'

export default function ManualView({ name, href, index, handleSave, handleRemove }) {
    const [edited, setEdited] = useState(false)
    const [selectedManual, setSelectedManual] = useState({ name, href })
    const handleChange = ({ target }) => {
        setEdited(true)
        setSelectedManual({
            ...selectedManual,
            [target.name]: target.value,
        })
    }
    const saveAndQuit = () => {
        setEdited(false)
        handleSave(selectedManual, index)
    }
    return (
        <div className='flex items-center gap-[8px] p-[8px] border border-grey rounded-[4px] relative mb-[16px]'>
            <input
                className='p-[8px] border border-grey rounded-[4px] dark:text-offwhite dark:bg-darkness dark:border-blue focus:outline-none'
                value={selectedManual.name}
                name='name'
                type='text'
                placeholder={'Name'}
                onChange={handleChange}
            />
            <input
                className='p-[8px] border border-grey rounded-[4px] dark:text-offwhite dark:bg-darkness dark:border-blue focus:outline-none'
                value={selectedManual.href}
                name='href'
                type='text'
                placeholder={'Link'}
                onChange={handleChange}
            />
            <FaTrash size={'24px'} className='fill-red dark:fill-[rgb(255,0,0)]' onClick={() => handleRemove(index)} />
            {edited && (
                <button
                    className='py-[2px] px-[16px] font-bold text-blue border-2 border-blue dark:border-accent dark:text-accent rounded-[4px]'
                    onClick={saveAndQuit}
                >
                    SAVE
                </button>
            )}
        </div>
    )
}
