import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user'
import dataReducer from './data'
import selectedReducer from './selected'
import sectionsReducer from './sections'
import appFunctionsReducer from './appFunctions'
import settingsReducer from './settings'
import appReducer from './appVersion'
import procurementTaskFilterParamsReducer from './procurementTasks'

export default configureStore({
    reducer: {
        app: appReducer,
        data: dataReducer,
        user: userReducer,
        selected: selectedReducer,
        sections: sectionsReducer,
        appFunctions: appFunctionsReducer,
        settings: settingsReducer,
        procurementTaskFilterParams: procurementTaskFilterParamsReducer,
    },
})
