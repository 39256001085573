import { useEffect, useState } from 'react'
import CustomerModal from './CustomerModal'
import Searchbar from './Searchbar'
import { TailSpin } from 'react-loading-icons'
import { parseResObject, vFetch } from '../helpers'

export default function CustomersScreen() {
    const [loading, setLoading] = useState(false)
    const [customers, setCustomers] = useState([])
    const [firstCustomerId, setFirstCustomerId] = useState(null)
    const [newSearch, setNewSearch] = useState(true)
    const [selectedCustomerId, setSelectedCustomerId] = useState(null)
    const [filters, setFilters] = useState([])
    const [filtersReadyToSearch, setFiltersReadyToSearch] = useState(0)
    const [search, setSearch] = useState('')
    const mFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format
    const useDebounce = (value, delay) => {
        const [debouncedValue, setDebouncedValue] = useState(value)
        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value.toLowerCase())
            }, delay)
            return () => {
                clearTimeout(handler)
            }
        }, [value, delay])
        return debouncedValue
    }
    const debouncedSearch = useDebounce(search, 300)
    const abortController = new AbortController()
    const [pageInfo, setPageInfo] = useState({
        before: null,
        after: null,
        page: 1,
    })

    useEffect(() => {
        if (!selectedCustomerId && newSearch) {
            setLoading(true)
            const tags = `&tags=${filters.filter((f) => f.name === 'Tagged with' && f.value).map((f) => f.value)}`
            const notTags = `&notTags=${filters
                .filter((f) => f.name === 'Not tagged with' && f.value)
                .map((f) => f.value)}`
            let cursor = ''
            if (pageInfo.before) {
                cursor = `&before=${pageInfo.before}`
            } else if (pageInfo.after) {
                cursor = `&after=${pageInfo.after}`
            }
            const searchParam = debouncedSearch.length > 0 ? `&search=${search.toLowerCase()}` : ''
            vFetch(`/customers/newBaseRoute?limit=50${cursor}${searchParam}${tags}${notTags}`, {
                abortController,
                cb: (res) => {
                    if (res.success) {
                        const resCustomers = res.customers.map((c) => parseResObject(c))
                        setCustomers(resCustomers)
                        if (newSearch) {
                            setFirstCustomerId(res.customers.length ? res.customers[0].id : null)
                            setNewSearch(false)
                        }
                        setLoading(false)
                    }
                },
            })

            return () => abortController.abort()
        }
    }, [pageInfo, newSearch, selectedCustomerId])
    useEffect(() => {
        if (!selectedCustomerId) {
            setNewSearch(true)
        }
    }, [debouncedSearch, selectedCustomerId, filtersReadyToSearch])
    useEffect(() => {
        if (filtersReadyToSearch !== filters.reduce((acc, cur) => acc + (cur.edit === false && cur.value ? 1 : 0), 0)) {
            setFiltersReadyToSearch(filters.reduce((acc, cur) => acc + (cur.edit === false && cur.value ? 1 : 0), 0))
            setPageInfo({
                before: null,
                after: null,
                page: 1,
            })
        }
    }, [filters])

    const handleNext = () => {
        setNewSearch(true)
        setPageInfo({
            before: null,
            after: customers[customers.length - 1].id,
            page: (() => {
                return pageInfo.page + 1
            })(),
        })
    }
    const handlePrev = () => {
        setNewSearch(true)
        setPageInfo({
            before: customers[0].id,
            after: null,
            page:
                pageInfo.page > 1
                    ? (() => {
                          return pageInfo.page - 1
                      })()
                    : 1,
        })
    }
    const handleSearch = ({ target }) => {
        setPageInfo({
            before: null,
            after: null,
            page: 1,
        })
        setSearch(target.value)
    }

    return (
        <div className=''>
            {loading && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            <div className='flex justify-between items-center mb-[8px]'>
                <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Customers</h1>
                <div>
                    <button
                        className='px-[8px] py-[4px] font-semibold text-white dark:text-black bg-blue dark:bg-accent rounded'
                        onClick={() => {
                            setSelectedCustomerId('New Customer')
                        }}
                    >
                        + New Customer
                    </button>
                </div>
            </div>
            <Searchbar
                search={search}
                handleSearch={handleSearch}
                filters={filters}
                setFilters={setFilters}
                setSelectedCustomerId={setSelectedCustomerId}
            />
            <div className='border-darkgrey border-[1px] border-t-0 rounded-t mt-[8px]'>
                <div className='rounded-t p-[8px] grid grid-cols-[200px_110px_minmax(100px,200px)_40px_100px_200px] justify-between gap-[16px] items-center font-bold text-[12px] text-white bg-blue dark:bg-darkness border-darkgrey border-b-[1px] border-t-[1px]'>
                    <span>Name</span>
                    <span>Marketing</span>
                    <span>Address</span>
                    <span>Orders</span>
                    <span>Total</span>
                    <span>Tags</span>
                </div>
                {customers.map((c) => (
                    <div
                        onClick={() => setSelectedCustomerId(c.id)}
                        className='p-[8px] grid grid-cols-[200px_110px_minmax(100px,200px)_40px_100px_200px] justify-between gap-[16px] items-center text-[14px] dark:text-offwhite hover:bg-[rgb(199,202,209)] [&:nth-child(even)]:bg-[rgb(224,228,235)] [&:nth-child(even)]:hover:bg-[rgb(199,202,209)] dark:[&:nth-child(even)]:bg-darkaccent dark:[&:nth-child(even)]:hover:bg-blue dark:hover:bg-blue cursor-pointer'
                    >
                        <span className=''>
                            {c.first_name || c.last_name
                                ? `${c.first_name ? c.first_name : ''} ${c.last_name ? c.last_name : ''}`
                                : `${c.email}`}
                        </span>
                        <span
                            className={`px-[8px] py-[2px] leading-[1] rounded-[4px] w-fit text-center text-[13px] text-black ${
                                c.accepts_marketing ? 'bg-[#a1edd0]' : 'bg-lightgrey'
                            }`}
                        >
                            {c.accepts_marketing ? 'Subscribed' : 'Not Subscribed'}
                        </span>
                        <span className=''>
                            {c.default_address &&
                                `${c.default_address.city !== null ? c.default_address.city : ''}${
                                    c.default_address.province_code
                                        ? (c.default_address.city ? ', ' : '') + c.default_address.province_code
                                        : ''
                                }`}
                        </span>
                        <span className=''>{c.orders_count}</span>
                        <span className=''>{mFormat(c.total_spent)}</span>
                        <span className=''>{c.tags && c.tags.split(',').join(', ')}</span>
                        {/* <div className="p-[8px] relative">
                        <span>{c.tags !== null && c.tags.split(",").join(", ").slice(0, 30)}{c.tags.split(",").join(", ").length > 30 && "..."}</span>
                        <div className={`${c.tags.split(",").join(", ").length > 3 ? "flex" : "hidden"} absolute justify-end items-center top-0 left-0 w-[100%] h-[100%] p-[8px]`}>
                            <AiFillCaretDown />
                        </div>
                    </div> */}
                    </div>
                ))}
            </div>
            <div className='flex justify-center gap-[16px]'>
                {pageInfo.page > 1 ? (
                    <button
                        className='font-semibold p-[8px] dark:text-white bg-white hover:bg-offwhite dark:bg-darkaccent dark:hover:bg-darkaccent/80 shadow-small rounded'
                        onClick={handlePrev}
                    >
                        PREV
                    </button>
                ) : (
                    <div className='w-[57px]' />
                )}
                <div className='flex items-center dark:text-white'>
                    <p>Page {pageInfo.page}</p>
                </div>
                {customers.length >= 50 && (
                    <button
                        className='font-semibold p-[8px] dark:text-white bg-white hover:bg-offwhite dark:bg-darkaccent dark:hover:bg-darkaccent/80 shadow-small rounded'
                        onClick={handleNext}
                    >
                        NEXT
                    </button>
                )}
                {selectedCustomerId && (
                    <CustomerModal setSelectedCustomerId={setSelectedCustomerId} id={selectedCustomerId} />
                )}
            </div>
        </div>
    )
}
