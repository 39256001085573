import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons'
import { SortingState, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { cn } from '../../helpers'

export type StatusDataByUser = {
    user_id: number | string
    active: number
    high_priority: number
    low_medium_priority: number
    new: number
    staging: number
    unqualified: number
    unqualified_follow_up: number
}
export default function Table({ tableData, columns, columnCount }: any) {
    const [sorting, setSorting] = React.useState<SortingState>([])
    const table = useReactTable({
        columns,
        data: tableData,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: { sorting },
    })

    const columnInsert: string[] = []
    for (let index = 0; index < columnCount; index++) {
        columnInsert.push('1fr')
    }

    return (
        <table className='w-[85vw] bg-blue shadow-md p-2 '>
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                        key={headerGroup.id}
                        className={cn(
                            columnInsert ? `grid-cols-[${columnInsert.join('_')}]` : 'grid-cols-10',
                            ' bg-blue px-2 '
                        )}
                    >
                        {headerGroup.headers.map((header) => (
                            <th key={header.id} colSpan={header.colSpan} className='text-start text-sm px-2'>
                                {header.isPlaceholder ? null : (
                                    <div
                                        className={
                                            header.column.getCanSort()
                                                ? 'cursor-pointer select-none flex gap-1'
                                                : 'flex gap-1'
                                        }
                                        onClick={header.column.getToggleSortingHandler()}
                                        title={
                                            header.column.getCanSort()
                                                ? header.column.getNextSortingOrder() === 'asc'
                                                    ? 'Sort ascending'
                                                    : header.column.getNextSortingOrder() === 'desc'
                                                      ? 'Sort descending'
                                                      : 'Clear sort'
                                                : undefined
                                        }
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                            asc: (
                                                <div className='self-center min-h-[18px]'>
                                                    <CaretUpIcon />
                                                </div>
                                            ),
                                            desc: (
                                                <div className='self-center min-h-[18px]'>
                                                    <CaretDownIcon />
                                                </div>
                                            ),
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className='w-full'>
                {table.getRowModel().rows.map((row) => (
                    <tr
                        key={row.id}
                        className={cn(
                            `w-full bg-bg1 dark:bg-darkbg1 text-black dark:text-offwhite border-b-[3px] px-2 border-lightgrey`
                        )}
                    >
                        {row.getVisibleCells().map((cell) => {
                            return (
                                <td key={cell.id} className='px-2'>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            )
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
