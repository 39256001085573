import Topbar from './Topbar'
import Sidebar from './Sidebar'
import ModalDiv from './ModalDiv.js'
import './Layout.css'
import Popout from './Popout'
import KnowledgeScreen from '../knowledge/KnowledgeScreen'
import { useSelector } from 'react-redux'

export default function Layout({ children, access, submenu, currentStoreId, setCurrentStoreId }) {
    const { showKnowledgeScreen } = useSelector((state) => state.settings)
    return (
        <>
            <ModalDiv />
            <Topbar currentStoreId={currentStoreId} setCurrentStoreId={setCurrentStoreId} />
            <Sidebar access={access} submenu={submenu} />
            <Popout />
            <main className='w-full h-full p-[32px] relative text-black dark:text-offwhite'>{children}</main>
            {showKnowledgeScreen && <KnowledgeScreen />}
        </>
    )
}
