import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '../../helpers'

const buttonVariants = cva(
    'inline-flex items-center rounded-md justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default:
                    'bg-bg1 dark:bg-darkbg1 text-text1-foreground dark:text-darktext1-foreground shadow hover:bg-bg1/90 dark:hover:bg-darkbg1/90',
                destructive:
                    'bg-bg1 dark:bg-darkbg1 text-danger dark:text-darkdanger shadow-sm hover:bg-danger/90 hover:dark:bg-darkbg2',
                outline:
                    'border border-input bg-bg1 dark:bg-darkbg1 shadow-sm hover:bg-lightgrey hover:dark:bg-darkbg2 hover:text-accent2',
                secondary:
                    'bg-darkbg1 dark:bg-offwhite text-text2 dark:text-darktext2 shadow-sm hover:bg-button2 dark:hover:bg-darkbutton2',
                ghost: 'hover:bg-lightgrey hover:dark:bg-darkbg1 hover:text-accent2',
                link: 'text-primary underline-offset-4 hover:underline',
            },
            size: {
                default: 'h-9 px-4 py-2',
                sm: 'h-8 rounded-md px-3 text-xs',
                lg: 'h-10 rounded-md px-8',
                icon: 'h-9 w-9',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
)

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : 'button'
        return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
