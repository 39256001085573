import { useEffect, useRef, useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { vFetch } from '../helpers'

export default function TagModal({
    selectedTag = {},
    setSelectedTag,
    groupList = [],
    productTypes = [],
    setNeedsRefresh,
}) {
    const limitedToRef = useRef()
    const excludedFromRef = useRef()
    const groupsRef = useRef()
    const modalRef = useRef()
    const [tempTag, setTempTag] = useState({ ...selectedTag })
    const [showModal, setShowModal] = useState(false)
    const [limitedTo, setLimitedTo] = useState([])
    const [limitedToSearch, setLimitedToSearch] = useState('')
    const [excludedFrom, setExcludedFrom] = useState([])
    const [excludedFromSearch, setExcludedFromSearch] = useState('')
    const [filteredProductTypes, setFilteredProductTypes] = useState(productTypes)
    const [filteredGroupList, setFilteredGroupList] = useState([])
    const [selectedGroups, setSelectedGroups] = useState([])
    const [groupSearch, setGroupSearch] = useState('')
    const [showLimitedTo, setShowLimitedTo] = useState(false)
    const [showExcludedFrom, setShowExcludedFrom] = useState(false)
    const [showGroups, setShowGroups] = useState(false)
    const [saving, setSaving] = useState(false)
    const [deleting, setDeleting] = useState(false)

    useEffect(() => {
        setFilteredProductTypes([...productTypes])
    }, [productTypes])
    useEffect(() => {
        setFilteredGroupList([...groupList])
    }, [groupList])
    useEffect(() => {
        if (selectedTag.tag_name || selectedTag.is_new) {
            setShowModal(true)
            setLimitedTo(selectedTag.limited_to ? JSON.parse(selectedTag.limited_to) : [])
            setExcludedFrom(selectedTag.excluded_from ? JSON.parse(selectedTag.excluded_from) : [])
            setSelectedGroups(selectedTag.group_name ? selectedTag.group_name.split(',') : [])
            setSaving(false)
            setDeleting(false)
            const compareValues = selectedTag.compare_values ? JSON.parse(selectedTag.compare_values) : {}
            setTempTag({
                ...selectedTag,
                is_feature: selectedTag.is_feature == 1 ? true : false,
                compare_group: compareValues.group,
                compare_priority: compareValues.priority || 0,
                compare_match_score: compareValues.matchScore || 0,
                compare_exclusive: compareValues.exclusive == 1 ? true : false,
                compare_close_matches: compareValues.closeMatches,
                compare_close_match_score: compareValues.closeMatchScore || 0,
            })
        } else {
            setShowModal(false)
            setLimitedTo([])
            setExcludedFrom([])
            setSelectedGroups([])
            setTempTag({})
            setSaving(false)
            setDeleting(false)
        }
    }, [selectedTag])
    useEffect(() => {
        setFilteredProductTypes(
            productTypes.filter((type) => type.toLowerCase().includes(limitedToSearch.toLowerCase()))
        )
        if (limitedToSearch.length === 0) {
            setShowLimitedTo(false)
        } else {
            setShowLimitedTo(true)
        }
    }, [limitedToSearch])
    useEffect(() => {
        setFilteredProductTypes(
            productTypes.filter((type) => type.toLowerCase().includes(excludedFromSearch.toLowerCase()))
        )
        if (excludedFromSearch.length === 0) {
            setShowExcludedFrom(false)
        } else {
            setShowExcludedFrom(true)
        }
    }, [excludedFromSearch])
    useEffect(() => {
        setFilteredGroupList(groupList.filter((group) => group.toLowerCase().includes(groupSearch.toLowerCase())))
        if (groupSearch.length === 0) {
            setShowGroups(false)
        } else {
            setShowGroups(true)
        }
    }, [groupSearch])
    const handleChange = ({ target }) => {
        if (target.type === 'checkbox') {
            setTempTag((prev) => ({
                ...prev,
                [target.name]: target.checked,
            }))
        } else {
            setTempTag((prev) => ({
                ...prev,
                [target.name]: target.value,
            }))
        }
    }
    const handleAddLimitedTo = (type) => {
        setLimitedTo((prev) => [...prev, type])
        setLimitedToSearch('')
    }
    const handleAddExcludedFrom = (type) => {
        setExcludedFrom((prev) => [...prev, type])
        setExcludedFromSearch('')
    }
    const handleAddGroup = (type) => {
        setSelectedGroups((prev) => [...prev, type])
        setGroupSearch('')
    }
    const handleRemoveLimitedTo = (index) => {
        setLimitedTo((prev) => {
            const newArr = [...prev]
            newArr.splice(index, 1)
            return newArr
        })
    }
    const handleRemoveExcludedFrom = (index) => {
        setExcludedFrom((prev) => {
            const newArr = [...prev]
            newArr.splice(index, 1)
            return newArr
        })
    }
    const handleRemoveGroup = (index) => {
        setSelectedGroups((prev) => {
            const newArr = [...prev]
            newArr.splice(index, 1)
            return newArr
        })
    }
    const handleSubmit = () => {
        setSaving(true)
        vFetch(`/tagManager/tags`, {
            method: selectedTag.is_new ? 'POST' : 'PUT',
            body: JSON.stringify({
                tag_name: tempTag.tag_name,
                display_name: tempTag.display_name,
                group_name: selectedGroups.join(','),
                is_feature: tempTag.is_feature ? true : false,
                limited_to: limitedTo || [],
                excluded_from: excludedFrom || [],
                compare_values: {
                    group: tempTag.compare_group,
                    priority: tempTag.compare_priority || 1,
                    matchScore: tempTag.compare_match_score || 0,
                    exclusive: tempTag.compare_exclusive ? true : false,
                    closeMatches: tempTag.compare_close_matches || [],
                    closeMatchScore: tempTag.closeMatchScore || 0,
                },
                previous_tag_name: selectedTag.tag_name,
            }),
            cb: (res) => {
                if (res.success) {
                    setNeedsRefresh(true)
                    setShowModal(false)
                    setLimitedTo([])
                    setExcludedFrom([])
                    setSelectedGroups([])
                    setTempTag({})
                }
                setSaving(false)
            },
            catchCb: () => setSaving(false),
        })
    }
    const handleDelete = () => {
        setDeleting(true)
        vFetch(`/tagManager/tags`, {
            method: 'DELETE',
            body: JSON.stringify({
                tag_name: selectedTag.tag_name,
            }),
            cb: (res) => {
                if (res.success) {
                    setNeedsRefresh(true)
                    setShowModal(false)
                    setLimitedTo([])
                    setExcludedFrom([])
                    setSelectedGroups([])
                    setTempTag({})
                }
                setDeleting(false)
            },
            catchCb: () => setDeleting(false),
        })
    }

    useEffect(() => {
        const listenToWindow = (e) => {
            if (!limitedToRef.current.contains(e.target)) {
                setShowLimitedTo(false)
            }
            if (!excludedFromRef.current.contains(e.target)) {
                setShowExcludedFrom(false)
            }
            if (!groupsRef.current.contains(e.target)) {
                setShowGroups(false)
            }
            if (modalRef.current === e.target) {
                setSelectedTag({})
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => {
            window.removeEventListener('click', listenToWindow)
            setSaving(false)
            setDeleting(false)
        }
    }, [])
    return (
        <>
            {showModal && (
                <div
                    ref={modalRef}
                    class='fixed top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] z-[50] grid place-items-center'
                >
                    <div className='p-[16px] bg-white dark:bg-darkaccent rounded-[4px] relative'>
                        <div class='flex gap-[16px]'>
                            <button
                                onClick={() => setSelectedTag(false)}
                                className='absolute text-[32px] leading-[1] font-bold text-red dark:text-lightred top-0 right-[8px]'
                            >
                                &times;
                            </button>
                            <div>
                                <h2 className='font-bold dark:text-offwhite uppercase w-full pb-[2px] border-b border-grey mb-[8px]'>
                                    Tag Properties
                                </h2>
                                <div className='flex gap-[8px] mb-[4px]'>
                                    <div className='flex flex-col gap-[4px] w-[300px]'>
                                        <div className='flex items-center gap-[4px] relative'>
                                            <label
                                                htmlFor='tag_name'
                                                className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                                            >
                                                Tag Name
                                            </label>
                                            <BsInfoCircle className='dark:text-offwhite w-[12px] h-[12px] peer' />
                                            <div className='hidden absolute top-[20px] z-[5] peer-hover:block p-[8px] bg-white dark:text-offwhite dark:bg-darkaccent rounded-[4px] shadow-small'>
                                                The shopify tag you want to map filters to.
                                            </div>
                                        </div>
                                        <input
                                            className='py-[2px] px-[4px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white'
                                            id='tag_name'
                                            name='tag_name'
                                            value={tempTag.tag_name}
                                            onChange={handleChange}
                                            type='text'
                                        />
                                    </div>
                                    <div className='flex flex-col gap-[4px] w-[300px]'>
                                        <div className='flex items-center gap-[4px] relative'>
                                            <label
                                                htmlFor='display_name'
                                                className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                                            >
                                                Display Name
                                            </label>
                                            <BsInfoCircle className='dark:text-offwhite w-[12px] h-[12px] peer' />
                                            <div className='hidden absolute top-[20px] z-[5] peer-hover:block p-[8px] bg-white dark:text-offwhite dark:bg-darkaccent rounded-[4px] shadow-small'>
                                                The filter name to be displayed on collections pages.
                                            </div>
                                        </div>
                                        <input
                                            className='py-[2px] px-[4px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white'
                                            id='display_name'
                                            name='display_name'
                                            value={tempTag.display_name}
                                            onChange={handleChange}
                                            type='text'
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-[8px] mb-[4px]'>
                                    <div className='flex flex-col gap-[4px] w-[300px]'>
                                        <div className='flex items-center gap-[4px] relative'>
                                            <label
                                                htmlFor=''
                                                className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                                            >
                                                Groups
                                            </label>
                                            <BsInfoCircle className='dark:text-offwhite w-[12px] h-[12px] peer' />
                                            <div className='hidden absolute top-[20px] z-[5] peer-hover:block p-[8px] bg-white dark:text-offwhite dark:bg-darkaccent rounded-[4px] shadow-small'>
                                                The group / dropdown button that the tag should belong to on the
                                                collection page.
                                            </div>
                                        </div>
                                        <div
                                            ref={groupsRef}
                                            className='bg-lightgrey dark:bg-darkness rounded-[4px] relative'
                                        >
                                            <input
                                                className='w-full bg-transparent dark:text-offwhite py-[2px] px-[8px] focus:outline-none'
                                                placeholder='Search'
                                                type='text'
                                                value={groupSearch}
                                                onChange={({ target }) => setGroupSearch(target.value)}
                                            />
                                            {showGroups && (
                                                <div className='max-h-[100px] w-full absolute top-[100%] left-0 overflow-y-auto bg-lightgrey dark:bg-darkness z-[3]'>
                                                    {filteredGroupList.filter(
                                                        (group) =>
                                                            !selectedGroups.includes(group) && group.trim().length
                                                    ).length > 0 ? (
                                                        <>
                                                            {filteredGroupList
                                                                .filter(
                                                                    (group) =>
                                                                        !selectedGroups.includes(group) &&
                                                                        group.trim().length
                                                                )
                                                                .slice(0, 10)
                                                                .map((group) => (
                                                                    <div
                                                                        onClick={() => handleAddGroup(group)}
                                                                        className='cursor-pointer p-[8px] w-full dark:text-offwhite border-b border-lightgrey dark:border-darkgrey'
                                                                    >
                                                                        {group}
                                                                    </div>
                                                                ))}
                                                        </>
                                                    ) : (
                                                        <div className='cursor-pointer p-[8px] w-full dark:text-offwhite border-b border-lightgrey dark:border-darkgrey'>
                                                            No results for this search
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className='flex gap-[8px]'>
                                            {selectedGroups.map((type, index) => (
                                                <div className='relative font-bold px-[6px] pr-[18px] text-[14px] bg-blue dark:bg-fire rounded-[4px] text-white dark:text-darkness'>
                                                    <span>{type}</span>
                                                    <button
                                                        onClick={() => handleRemoveGroup(index)}
                                                        className='text-[20px] text-red dark:text-darkness leading-[1] absolute top-0 right-[2px]'
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='flex items-end justify-between border border-grey dark:border-darkgrey h-fit mt-[20px] rounded-[4px] p-[4px] px-[8px] gap-[16px] w-[300px]'>
                                        <label
                                            htmlFor='is_feature'
                                            className='mb-[2px] w-full cursor-pointer font-bold text-darkgrey dark:text-offwhite uppercase leading-[1] text-[14px]'
                                        >
                                            Is Feature
                                        </label>
                                        <input
                                            className='w-[20px] h-[20px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white'
                                            id='is_feature'
                                            name='is_feature'
                                            checked={tempTag.is_feature == 1}
                                            onChange={handleChange}
                                            type='checkbox'
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-[8px] mb-[4px]'>
                                    <div className='flex flex-col gap-[4px] w-[300px]'>
                                        <label
                                            htmlFor=''
                                            className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                                        >
                                            Product Type Must Be
                                        </label>
                                        <div
                                            ref={limitedToRef}
                                            className='bg-lightgrey dark:bg-darkness rounded-[4px] relative'
                                        >
                                            <input
                                                className='w-full bg-transparent dark:text-offwhite py-[2px] px-[8px] focus:outline-none'
                                                placeholder='Search'
                                                type='text'
                                                value={limitedToSearch}
                                                onChange={({ target }) => setLimitedToSearch(target.value)}
                                            />
                                            {showLimitedTo && (
                                                <div className='max-h-[100px] w-full absolute top-[100%] left-0 overflow-y-auto bg-lightgrey dark:bg-darkness z-[3]'>
                                                    {filteredProductTypes.filter(
                                                        (type) => !limitedTo.includes(type) && type.trim().length
                                                    ).length > 0 ? (
                                                        <>
                                                            {filteredProductTypes
                                                                .filter(
                                                                    (type) =>
                                                                        !limitedTo.includes(type) && type.trim().length
                                                                )
                                                                .slice(0, 10)
                                                                .map((type) => (
                                                                    <div
                                                                        onClick={() => handleAddLimitedTo(type)}
                                                                        className='cursor-pointer p-[8px] w-full dark:text-offwhite border-b border-lightgrey dark:border-darkgrey'
                                                                    >
                                                                        {type}
                                                                    </div>
                                                                ))}
                                                        </>
                                                    ) : (
                                                        <div className='cursor-pointer p-[8px] w-full dark:text-offwhite border-b border-lightgrey dark:border-darkgrey'>
                                                            No results for this search
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className='flex gap-[8px]'>
                                            {limitedTo.map((type, index) => (
                                                <div className='relative font-bold px-[6px] pr-[18px] text-[14px] bg-blue dark:bg-fire rounded-[4px] text-white dark:text-darkness'>
                                                    <span>{type}</span>
                                                    <button
                                                        onClick={() => handleRemoveLimitedTo(index)}
                                                        className='text-[20px] text-red dark:text-darkness leading-[1] absolute top-0 right-[2px]'
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-[4px] w-[300px]'>
                                        <label
                                            htmlFor=''
                                            className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                                        >
                                            Product Type Cannot Be
                                        </label>
                                        <div
                                            ref={excludedFromRef}
                                            className='bg-lightgrey dark:bg-darkness rounded-[4px] relative'
                                        >
                                            <input
                                                className='w-full bg-transparent dark:text-offwhite py-[2px] px-[8px] focus:outline-none'
                                                placeholder='Search'
                                                type='text'
                                                value={excludedFromSearch}
                                                onChange={({ target }) => setExcludedFromSearch(target.value)}
                                            />
                                            {showExcludedFrom && (
                                                <div className='max-h-[100px] w-full absolute top-[100%] left-0 overflow-y-auto bg-lightgrey dark:bg-darkness z-[3]'>
                                                    {filteredProductTypes.filter(
                                                        (type) => !excludedFrom.includes(type) && type.trim().length
                                                    ).length > 0 ? (
                                                        <>
                                                            {filteredProductTypes
                                                                .filter(
                                                                    (type) =>
                                                                        !excludedFrom.includes(type) &&
                                                                        type.trim().length
                                                                )
                                                                .slice(0, 10)
                                                                .map((type) => (
                                                                    <div
                                                                        onClick={() => handleAddExcludedFrom(type)}
                                                                        className='cursor-pointer p-[8px] w-full dark:text-offwhite border-b border-lightgrey dark:border-darkgrey'
                                                                    >
                                                                        {type}
                                                                    </div>
                                                                ))}
                                                        </>
                                                    ) : (
                                                        <div className='cursor-pointer p-[8px] w-full dark:text-offwhite border-b border-lightgrey dark:border-darkgrey'>
                                                            No results for this search
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className='flex gap-[8px]'>
                                            {excludedFrom.map((type, index) => (
                                                <div className='relative font-bold px-[6px] pr-[18px] text-[14px] bg-blue dark:bg-fire rounded-[4px] text-white dark:text-darkness'>
                                                    <span>{type}</span>
                                                    <button
                                                        onClick={() => handleRemoveExcludedFrom(index)}
                                                        className='text-[20px] text-red dark:text-darkness leading-[1] absolute top-0 right-[2px]'
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 className='font-bold dark:text-offwhite uppercase w-full pb-[2px] border-b border-grey mb-[8px]'>
                                    Compare Values
                                </h2>
                                <div className='flex gap-[8px] mb-[4px]'>
                                    <div className='flex flex-col gap-[4px] w-[300px]'>
                                        <div className='flex items-center gap-[4px] relative'>
                                            <label
                                                htmlFor='compare_group'
                                                className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                                            >
                                                Group
                                            </label>
                                            <BsInfoCircle className='dark:text-offwhite w-[12px] h-[12px] peer' />
                                            <div className='hidden absolute top-[20px] z-[5] peer-hover:block p-[8px] bg-white dark:text-offwhite dark:bg-darkaccent rounded-[4px] shadow-small'>
                                                Which filter group should be used to generate compare similar score.
                                            </div>
                                        </div>
                                        <select
                                            className='py-[3px] px-[4px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white focus:outline-none'
                                            id='compare_group'
                                            name='compare_group'
                                            value={tempTag.compare_group}
                                            onChange={handleChange}
                                        >
                                            <option value=''>Select a group</option>
                                            {groupList.map((group) => (
                                                <option value={group}>{group}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='flex flex-col gap-[4px] w-[300px]'>
                                        <div className='flex items-center gap-[4px] relative'>
                                            <label
                                                htmlFor='compare_priority'
                                                className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                                            >
                                                Priority
                                            </label>
                                            <BsInfoCircle className='dark:text-offwhite w-[12px] h-[12px] peer' />
                                            <div className='hidden absolute top-[20px] z-[5] peer-hover:block p-[8px] bg-white dark:text-offwhite dark:bg-darkaccent rounded-[4px] shadow-small'>
                                                For exclusive comparisons, how to prioritize which value to take (1 is
                                                highest priority). For example, if a product has two Wattage group tags
                                                then the one with the higher priority will be used.
                                            </div>
                                        </div>
                                        <input
                                            className='py-[2px] px-[4px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white focus:outline-none'
                                            id='compare_priority'
                                            name='compare_priority'
                                            value={tempTag.compare_priority}
                                            onChange={handleChange}
                                            type='number'
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-[8px] mb-[6px]'>
                                    <div className='flex flex-col gap-[4px] w-[300px]'>
                                        <div className='flex items-center gap-[4px] relative'>
                                            <label
                                                htmlFor='compare_match_score'
                                                className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                                            >
                                                Match Score
                                            </label>
                                            <BsInfoCircle className='dark:text-offwhite w-[12px] h-[12px] peer' />
                                            <div className='hidden absolute top-[20px] z-[5] peer-hover:block p-[8px] bg-white dark:text-offwhite dark:bg-darkaccent rounded-[4px] shadow-small'>
                                                Number of points to add to similarity score when products share the tag.
                                            </div>
                                        </div>
                                        <input
                                            className='py-[2px] px-[4px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white focus:outline-none'
                                            id='compare_match_score'
                                            name='compare_match_score'
                                            value={tempTag.compare_match_score}
                                            onChange={handleChange}
                                            type='number'
                                        />
                                    </div>
                                    <div className='flex items-end justify-between border border-grey dark:border-darkgrey h-fit mt-[20px] rounded-[4px] p-[4px] px-[8px] gap-[16px] w-[300px]'>
                                        <label
                                            htmlFor='compare_exclusive'
                                            className='mb-[2px] w-full cursor-pointer font-bold text-darkgrey dark:text-offwhite uppercase leading-[1] text-[14px]'
                                        >
                                            Is Exclusive
                                        </label>
                                        <input
                                            className='w-[20px] h-[20px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white'
                                            id='compare_exclusive'
                                            name='compare_exclusive'
                                            checked={tempTag.compare_exclusive == 1}
                                            onChange={handleChange}
                                            type='checkbox'
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-[8px] mb-[4px]'>
                                    <div className='flex flex-col gap-[4px] w-[300px]'>
                                        <div className='flex items-center gap-[4px] relative'>
                                            <label
                                                htmlFor='compare_close_matches'
                                                className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                                            >
                                                Close Matches
                                            </label>
                                            <BsInfoCircle className='dark:text-offwhite w-[12px] h-[12px] peer' />
                                            <div className='hidden absolute top-[20px] z-[5] peer-hover:block p-[8px] bg-white dark:text-offwhite dark:bg-darkaccent rounded-[4px] shadow-small'>
                                                Other tag names that should contribute to the total match score.
                                            </div>
                                        </div>
                                        <input
                                            className='py-[2px] px-[4px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white'
                                            id='compare_close_matches'
                                            name='compare_close_matches'
                                            value={tempTag.compare_close_matches}
                                            onChange={handleChange}
                                            type='text'
                                        />
                                    </div>
                                    <div className='flex flex-col gap-[4px] w-[300px]'>
                                        <label
                                            htmlFor='compare_close_match_score'
                                            className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                                        >
                                            Close Match Score
                                        </label>
                                        <input
                                            className='py-[2px] px-[4px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white'
                                            id='compare_close_match_score'
                                            name='compare_close_match_score'
                                            value={tempTag.compare_close_match_score}
                                            onChange={handleChange}
                                            type='text'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center gap-[8px] min-h-[32px] mt-[16px] relative'>
                            <button
                                onClick={handleSubmit}
                                className='bg-blue text-white dark:bg-accent dark:text-darkness rounded-[4px] py-[4px] px-[16px] font-bold uppercase'
                            >
                                {saving ? 'Saving...' : 'Save Changes'}
                            </button>
                            {!selectedTag.is_new && (
                                <button
                                    onClick={handleDelete}
                                    className='bg-red absolute text-[12px] bottom-0 right-0 text-white dark:bg-lightred rounded-[4px] py-[4px] px-[16px] font-bold uppercase'
                                >
                                    {deleting ? 'Deleting...' : 'Delete Tag'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
