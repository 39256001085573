import { DoubleArrowDownIcon, DoubleArrowUpIcon, ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { useContext, useEffect, useRef, useState } from 'react'
import { FaCaretDown, FaCaretUp, FaCheck, FaTrash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../custom_components/component_Basics/Button'
import { cn, parseResObject } from '../../../helpers'
import { UserInit } from '../../../users/users.types'
import { useDeleteTask, useUpdateTask } from '../../api/useQueries'
import { DATE_TODAY } from '../../constants'
import { ProcurementContext, ProcurementContextType, formatDateWithYear } from '../../helpers'
import DueDateChanger from '../DueDateChanger'
import Textarea from '../Textarea'
import { CompanyTaskType } from '../modals/quickActions/CreateTask'

export default function TaskListItem({
    task,
    urlParamTaskId,
}: {
    task: CompanyTaskType
    urlParamTaskId: string | undefined
}) {
    const navigate = useNavigate()
    const [editNote, setEditNote] = useState(false)
    const noteRef = useRef<HTMLDivElement>(null)
    const [editableFields, setEditableFields] = useState<any>({})
    const [showDueAtEditor, setShowDueAtEditor] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [displayedDate, setDisplayedDate] = useState(task.due_at)
    const [expand, setExpand] = useState(false)

    const isEdited = Boolean(Object.values(editableFields).length)

    const { users, activeModal } = useContext<ProcurementContextType>(ProcurementContext)

    const assignedUser: UserInit = users.filter((user: UserInit) => user.id === task.user_id)[0]
    if (assignedUser) {
        parseResObject(assignedUser)
    }

    const updateTask = useUpdateTask()
    const deleteTask = useDeleteTask()

    const isPending = deleteTask.isPending || updateTask.isPending

    const handleCancelDateChange = () => {
        let editedFields = editableFields
        delete editedFields.due_at
        delete editedFields.alert_at
        delete editedFields.send_email_alert
        delete editedFields.send_slack_alert
        setEditableFields(editedFields)
        setDisplayedDate(task.due_at)
        setShowDueAtEditor(false)
    }

    if (updateTask.isPending) {
        task.status = updateTask?.variables?.editableFields?.status
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!noteRef.current?.contains(e.target as Element)) {
                setTimeout(() => {
                    setEditNote(false)
                    setExpand(false)
                }, 100)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    const handleUpdate = (status: 'OPEN' | 'COMPLETED') => {
        updateTask.mutate(
            {
                id: task.id,
                editableFields: { ...editableFields, status: status },
                company_id: task.company_id,
            },
            {
                onSuccess: () => {
                    setEditableFields({})
                },
            }
        )
    }

    return (
        <div
            className={cn(
                'relative w-full p-2 pl-2 rounded-[4px] border border-blue/25 dark:border-darkgrey bg-bg1 dark:bg-darkness shadow-md',
                isPending && 'opacity-50',
                deleteTask.isPending && 'opacity-50',
                urlParamTaskId == task.id && 'border-accent2 dark:border-darkaccent2'
            )}
        >
            <div
                className={`${cn(
                    expand && 'grid grid-rows-[30px_1fr]',
                    !expand && 'grid grid-rows-[30px_1fr]',
                    'transition-all duration-200  top-[50px] grid-cols-[36px_12px_1.5fr_12px_1fr_.5fr_28px_1fr_1fr] col-span-full  dark:text-offwhite dark:border-darkgrey dark:bg-darkness'
                )} `}
            >
                <div className='flex items-center justify-center gap-[8px] font-bold text-[14px] row-start-1 row-end-3'>
                    <FaCheck
                        onClick={() => handleUpdate(task.status === 'OPEN' ? 'COMPLETED' : 'OPEN')}
                        className={cn(
                            isPending && 'pointer-events-none',
                            `${
                                task.status === 'OPEN'
                                    ? 'opacity-[0.25] text-black border-black dark:text-offwhite dark:border-offwhite'
                                    : 'text-lime border-lime'
                            } cursor-pointer rounded-full p-[4px] border-2  w-[24px] h-[24px]`
                        )}
                    />
                </div>
                <div></div>
                <Button
                    variant={'link'}
                    onClick={() => {
                        if (task?.type === 'products') {
                            navigate(`/procurement?company_id=${task.company_id}&view=products`)
                        } else {
                            navigate(`/procurement?company_id=${task.company_id}`)
                        }
                    }}
                    className={cn(
                        activeModal === 'company' && 'pointer-events-none',
                        'font-bold items-start justify-start p-0 text-accent2 dark:text-darkaccent2  overflow-hidden'
                    )}
                >
                    {task.name}
                </Button>
                <div></div>
                <p className='font-bold text-[14px]'>
                    {assignedUser?.first_name} {assignedUser?.last_name}
                </p>
                <p className='font-bold text-[14px] capitalize'>{task?.type}</p>
                {task.status === 'OPEN' && task.due_at < DATE_TODAY.toISOString() ? (
                    <ExclamationTriangleIcon className='text-danger dark:text-darkdanger mt-1 ' />
                ) : (
                    <div></div>
                )}
                <div className='col-span-2'>
                    <div className='flex justify-between mb-[8px]'>
                        <div className='flex gap-[8px] items-center relative'>
                            {task.status === 'OPEN' || isEdited ? (
                                <>
                                    <button
                                        onClick={() => setShowDueAtEditor((previousState) => !previousState)}
                                        className='font-bold text-[12px] flex gap-[8px] items-center'
                                        disabled={isPending}
                                    >
                                        <p>Due {formatDateWithYear(new Date(displayedDate ?? task.due_at))}</p>
                                        {showDueAtEditor ? <FaCaretUp /> : <FaCaretDown />}
                                    </button>
                                    {showDueAtEditor && (
                                        <div className=' absolute shadow-small border-[1px] dark:border-darkgrey top-[100%] right-0 w-[400px] z-[50] bg-white dark:bg-darkaccent p-[8px]'>
                                            <DueDateChanger
                                                inNote={false}
                                                setDisplayedDate={setDisplayedDate}
                                                handleCancelDateChange={handleCancelDateChange}
                                                setTask={setEditableFields}
                                                setShowDueAtEditor={setShowDueAtEditor}
                                                disabled={isPending}
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <p className='font-bold text-[12px]'>
                                    Completed {formatDateWithYear(new Date(task.updated_at!))}
                                </p>
                            )}
                            <>
                                {task.status === 'OPEN' ? (
                                    <button
                                        onClick={() => handleUpdate(task.status === 'OPEN' ? 'COMPLETED' : 'OPEN')}
                                        className='text-[12px] bg-lime dark:text-darkness font-bold px-[4px] rounded-[4px]'
                                        disabled={isPending}
                                    >
                                        Mark As Complete
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => handleUpdate(task.status === 'OPEN' ? 'COMPLETED' : 'OPEN')}
                                        className='text-[14px] text-white dark:text-offwhite bg-darkgrey font-bold px-[4px] rounded-[4px]'
                                        disabled={isPending}
                                    >
                                        Reopen task
                                    </button>
                                )}
                            </>
                        </div>
                        <button onClick={() => setShowDelete(true)}>
                            <FaTrash className='text-red dark:text-lightred' />
                        </button>
                    </div>
                </div>

                <div className='relative flex gap-[8px] w-full max-w-[100%]  col-start-3 col-end-10'>
                    <div
                        ref={noteRef}
                        onClick={() => {
                            setExpand(true)
                            setEditNote(true)
                        }}
                        className={`w-full h-fit relative
                            ${
                                editNote
                                    ? ''
                                    : 'overflow-hidden max-h-[70px] after:w-full after:h-[32px] after:bg-[linear-gradient(to_top,white,transparent)] dark:after:bg-[linear-gradient(to_top,#05111C,transparent)] after:absolute after:bottom-0 after:left-0'
                            }`}
                    >
                        <Textarea
                            className='dark:bg-darkbg1 w-full h-full !max-w-none p-[8px] bg-transparent border-lightgrey dark:border-darkgrey rounded-[4px] focus:outline-none !max-h-none h-fit'
                            id={`note-${task.id}`}
                            name={`note-${task.id}`}
                            value={editableFields.title ?? task.title}
                            disabled={isPending}
                            onChange={({ target }) =>
                                setEditableFields((prev: any) => {
                                    return {
                                        ...prev,
                                        title: target.value,
                                    }
                                })
                            }
                        />
                        <div className='absolute bottom-2 right-2'>
                            <button
                                disabled={true}
                                onClick={() => {
                                    expand ? setExpand(false) : setExpand(true)
                                }}
                                className='min-h-[20px] min-w-[20px] text-accent2'
                            >
                                {expand ? <DoubleArrowUpIcon /> : <DoubleArrowDownIcon />}
                            </button>
                        </div>
                    </div>

                    {isEdited ? (
                        <button
                            onClick={() => handleUpdate('OPEN')}
                            className='shrink-0 py-[2px] px-[8px] text-[14px] font-bold bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] shadow-small max-h-[50px]'
                        >
                            SAVE CHANGES
                        </button>
                    ) : (
                        <>
                            {showDelete && (
                                <div className='flex gap-[8px] w-fit shrink-0'>
                                    <button
                                        onClick={() => setShowDelete(false)}
                                        className='shrink-0 py-[2px] px-[8px] text-[14px] font-bold bg-lightgrey dark:bg-darkgrey text-white dark:text-offwhite rounded-[4px] shadow-small max-h-[50px]'
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => deleteTask.mutate({ ...task })}
                                        className='shrink-0 py-[2px] px-[8px] text-[14px] font-bold bg-red dark:bg-lightred text-white dark:text-offwhite rounded-[4px] shadow-small max-h-[50px]'
                                    >
                                        Delete Task?
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
