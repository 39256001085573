import { useEffect, useRef, useState } from 'react'
import { IoTimeSharp } from 'react-icons/io5'

export default function Period({ period, setPeriod }) {
    const [show, setShow] = useState(false)
    const periodRef = useRef()

    useEffect(() => {
        const listenToWindow = ({ target }) => {
            if (!periodRef.current.contains(target)) {
                setShow(false)
            }
        }
        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div ref={periodRef} className='relative'>
            <IoTimeSharp
                onClick={() => setShow((prev) => !prev)}
                className='w-[24px] h-[24px] cursor-pointer text-blue dark:text-accent'
            />
            {show && (
                <div className='w-fit p-[8px] absolute top-[100%] right-0 bg-white dark:bg-darkaccent z-[500] shadow-small rounded-[4px]'>
                    <button
                        className={`text-center w-full my-[2px] py-[2px] rounded-[4px] border border-lightgrey dark:border-none font-bold ${
                            period === 7
                                ? 'bg-blue dark:bg-accent text-white dark:text-darkness font-bold'
                                : 'bg-lightgrey dark:bg-darkness'
                        }`}
                        onClick={() => setPeriod(7)}
                    >
                        Last 7 Days
                    </button>
                    <button
                        className={`text-center w-full my-[2px] py-[2px] rounded-[4px] border border-lightgrey dark:border-none font-bold ${
                            period === 14
                                ? 'bg-blue dark:bg-accent text-white dark:text-darkness font-bold'
                                : 'bg-lightgrey dark:bg-darkness'
                        }`}
                        onClick={() => setPeriod(14)}
                    >
                        Last 14 Days
                    </button>
                    <button
                        className={`text-center w-full my-[2px] py-[2px] rounded-[4px] border border-lightgrey dark:border-none font-bold ${
                            period === 30
                                ? 'bg-blue dark:bg-accent text-white dark:text-darkness font-bold'
                                : 'bg-lightgrey dark:bg-darkness'
                        }`}
                        onClick={() => setPeriod(30)}
                    >
                        Last 30 Days
                    </button>
                    <button
                        className={`text-center w-full my-[2px] py-[2px] rounded-[4px] border border-lightgrey dark:border-none font-bold ${
                            period === 90
                                ? 'bg-blue dark:bg-accent text-white dark:text-darkness font-bold'
                                : 'bg-lightgrey dark:bg-darkness'
                        }`}
                        onClick={() => setPeriod(90)}
                    >
                        Last 90 Days
                    </button>
                    <input
                        className=' w-[120px] px-[4px] border border-lightgrey dark:border-none dark:bg-faintplus dark:text-white rounded-[4px]'
                        type='number'
                        placeholder='Custom (days)'
                        value={period}
                        onChange={({ target }) => setPeriod(target.value)}
                    />
                </div>
            )}
        </div>
    )
}
