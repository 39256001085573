import { FulfillmentOrderInit, OrderTimelineEvent } from './orders.types'
import { formatMoney, parseResObject, sendToast, sortByAlphanumeric, vFetch } from '../helpers'
import { LineItemInit, OrderInit } from './orders.types'
import { TaskEditInit } from '../tasks/tasks.types'
import { UserInit } from '../users/users.types'

export function renderDiscount(order: OrderInit, sku: string, item: LineItemInit, showDiscount: string) {
    if (sku) {
        if (showDiscount === sku)
            return (
                <div className='js-order__discount absolute grid gap-[16px] top-[24px] shadow-small p-[16px] left-[-20px] rounded bg-white dark:bg-darkness z-index-4'>
                    <h3 className='js-order__discount font-semibold text-[16px]'>
                        {item.discount_allocations.length > 1 ? 'Discounts' : 'Discount'}
                    </h3>
                    {item.discount_allocations.map((discount: any) => (
                        <div className='js-order__discount'>
                            <h4 className='js-order__discount font-semibold'>
                                {order.discount_applications[discount.discount_application_index].allocation_method ===
                                    'across' ||
                                order.discount_applications[discount.discount_application_index].allocation_method ===
                                    'each'
                                    ? 'Per Item'
                                    : 'Please tell the developers if you see this message.'}
                            </h4>
                            <p className='js-order__discount text-[16px]'>
                                {formatMoney(discount.amount / Number(item.quantity))}
                            </p>
                            <h4 className='js-order__discount font-semibold mt-[16px]'>
                                {order.discount_applications[discount.discount_application_index].allocation_method ===
                                    'across' ||
                                order.discount_applications[discount.discount_application_index].allocation_method ===
                                    'each'
                                    ? 'Total'
                                    : 'Please tell the developers if you see this message.'}
                            </h4>
                            <p className='js-order__discount text-[16px]'>{formatMoney(discount.amount)}</p>
                            <h4 className='js-order__discount font-semibold mt-[16px]'>Reason</h4>
                            <p className='js-order__discount text-[16px]'>
                                {order.discount_applications[discount.discount_application_index].title}
                            </p>
                        </div>
                    ))}
                </div>
            )
    }
}
export function itemPriceAfterDiscounts(item: LineItemInit) {
    return (
        Number(item.price) -
        item.discount_allocations.reduce((acc, cur) => Number(acc) + Number(cur.amount), 0) / item.quantity
    )
}
export function findDiscountApplications(order: OrderInit, thisItemOnly?: LineItemInit) {
    const discounts: any[] = []
    const existingItems = order.line_items.filter(
        (item) =>
            !order.refunds.some((refund) =>
                refund.refund_line_items.some(
                    (refundedItem) => refundedItem.quantity === item.quantity && refundedItem.line_item.sku === item.sku
                )
            )
    )

    // line_item level discounts
    order.discount_applications.forEach((discount: any, discountIndex: number) => {
        existingItems.forEach((item: LineItemInit) =>
            item.discount_allocations.forEach((itemDiscount: any) => {
                if (thisItemOnly && item.sku !== thisItemOnly.sku) return
                const foundDiscount: any = { discount, item }
                if (
                    itemDiscount.discount_application_index === discountIndex &&
                    (discount.target_selection === 'explicit' || discount.target_selection === 'entitled')
                ) {
                    if (discount.value_type === 'percentage') {
                        if (
                            formatMoney(Number(item.price) * (discount.value / 100) * item.quantity) ===
                            formatMoney(Number(itemDiscount.amount))
                        ) {
                            return discounts.push({
                                ...foundDiscount,
                                per_item_discount: formatMoney(Number(item.price) * (discount.value / 100)),
                                total_discount_value: formatMoney(
                                    Number(item.price) * (discount.value / 100) * item.quantity
                                ),
                            })
                        }
                    } else if (
                        discount.value_type === 'fixed_amount' &&
                        formatMoney(itemDiscount.amount) === formatMoney(discount.value * item.quantity)
                    ) {
                        return discounts.push({
                            ...foundDiscount,
                            per_item_discount: formatMoney(discount.value),
                            total_discount_value: formatMoney(itemDiscount.amount),
                        })
                    }
                }
            })
        )
    })

    // order level discounts
    order.discount_applications.forEach((discount: any, discountIndex: number) => {
        existingItems.forEach((item: LineItemInit) =>
            item.discount_allocations.forEach((itemDiscount: any) => {
                if (thisItemOnly && item.sku !== thisItemOnly.sku) return
                const foundDiscount: any = { discount, item }
                const existingItemDiscounts = discounts.filter(
                    (existingDiscount) => existingDiscount.item.sku === item.sku
                )
                if (itemDiscount.discount_application_index === discountIndex && discount.target_selection === 'all') {
                    if (discount.value_type === 'percentage') {
                        if (
                            formatMoney(
                                (Number(item.price) -
                                    existingItemDiscounts.reduce(
                                        (acc, cur) => acc + Number(cur.per_item_discount.replace('$', '')),
                                        0
                                    )) *
                                    (discount.value / 100) *
                                    item.quantity
                            ) === formatMoney(Number(itemDiscount.amount))
                        ) {
                            return discounts.push({
                                ...foundDiscount,
                                per_item_discount: formatMoney(
                                    (Number(item.price) -
                                        existingItemDiscounts.reduce(
                                            (acc, cur) => acc + Number(cur.per_item_discount.replace('$', '')),
                                            0
                                        )) *
                                        (discount.value / 100) *
                                        item.quantity
                                ),
                                total_discount_value: formatMoney(
                                    (Number(item.price) -
                                        existingItemDiscounts.reduce(
                                            (acc, cur) => acc + Number(cur.per_item_discount.replace('$', '')),
                                            0
                                        )) *
                                        (discount.value / 100) *
                                        item.quantity
                                ),
                            })
                        }
                    } else if (
                        discount.value_type === 'fixed_amount' &&
                        formatMoney(itemDiscount.amount) === formatMoney(discount.value * item.quantity)
                    ) {
                        return discounts.push({
                            ...foundDiscount,
                            per_item_discount: formatMoney(discount.value),
                            total_discount_value: formatMoney(itemDiscount.amount),
                        })
                    }
                }
            })
        )
    })
    return discounts
}

export function totalDiscountsAndAdjustments(order: OrderInit) {
    return order.refunds.reduce(
        (refundAcc, refundCur) => refundAcc + refundCur.transactions.reduce((acc, cur) => acc + Number(cur.amount), 0),
        0
    )
    //return order.refunds.reduce((refundAcc, refundCur) => refundAcc + refundCur.refund_line_items.reduce((acc, cur) => acc - cur.subtotal! - cur.total_tax!, 0) + refundCur.order_adjustments.reduce((acc, cur) => acc + Number(cur.amount), 0), 0);
}
export function filterOutRefundedItems(order: OrderInit, fulfillmentOrders?: FulfillmentOrderInit[]) {
    if (fulfillmentOrders) {
        const lineItems = fulfillmentOrders
            .filter((f) => f.status !== 'cancelled')
            .map((f) =>
                f.line_items.filter(
                    (item) =>
                        (item.quantity > 0 && f.status === 'closed' && item.quantity !== item.fulfillable_quantity) ||
                        (item.quantity > 0 && f.status !== 'closed')
                )
            )
            .flat()
        if (!order.line_items.length) return []

        return lineItems
            .filter(
                (item) =>
                    !order.refunds.some((refund) =>
                        refund.refund_line_items.some(
                            (refundedItem) =>
                                refundedItem.quantity === item.quantity &&
                                refundedItem.line_item.id === item.line_item_id
                        )
                    )
            )
            .map((item) => order.line_items.find((maybeItem) => maybeItem.id === item.line_item_id))
    }
    console.error('fulfillmentOrders is undefined')
    sendToast({ message: 'Missing Fulfillment orders...' })
    return order.line_items
}

export const paidByCustomer = (order: OrderInit) => {
    // ternary operator, because when order.financial_status === "authorized" paid by customer ALWAYS = 0
    // (order.current_total_price || order.total_price) because sometimes order.current_total_price === 0
    // Like when order.financial_status === "partially refunded"
    // order.total_price is the backup price because sometimes order.total_price doesn't match what the customer has paid
    // like when order.financial_status === "partially_paid"
    return order.financial_status === 'authorized'
        ? formatMoney(0)
        : formatMoney((order.current_total_price || order.total_price) - order.total_outstanding)
}
export const totalOrderPrice = (order: OrderInit) => {
    return (
        Number(order.financial_status.includes('paid') ? order.current_total_price : order.total_price) -
        totalDiscountsAndAdjustments(order)
    )
}

export function refreshTasks(
    order: OrderInit,
    setTasks: Function,
    setTaskEvents: Function,
    user: UserInit,
    setAdminEventLogged: Function,
    adminEventLogged: boolean
) {
    const statusValues: any = {
        OPEN: 0,
        COMPLETED: 10,
    }

    vFetch(`/tasks/search?order_name=${order.order_number}`, {
        cb: (res: any) => {
            const tasks: TaskEditInit[] = res.tasks.map((t: any) => {
                parseResObject(t)
                t.due_at = new Date(t.due_at.toISOString().slice(0, -1))
                if (adminEventLogged === false) {
                    vFetch(`/taskEvents`, {
                        skipToast: true,
                        method: 'POST',
                        body: JSON.stringify({
                            task_id: t.id,
                            user_id: user.id,
                            event_type: 'viewed',
                        }),
                    })
                }
                return t
            })
            setAdminEventLogged(true)
            setTasks(
                tasks.sort((a: TaskEditInit, b: TaskEditInit) =>
                    sortByAlphanumeric(statusValues[a.status], statusValues[b.status])
                )
            )
            vFetch(
                `/events/tasks?id=${tasks.filter((t) => (t as TaskEditInit).id).map((t) => (t as TaskEditInit).id)}`,
                {
                    cb: (res: any) => {
                        if (res.success)
                            setTaskEvents(
                                res.events.map((e: any) => {
                                    return { ...parseResObject(e), normalized_event_type: 'Task Events Public' }
                                })
                            )
                    },
                }
            )
        },
    })
}
export function refreshPurchaseOrders(order: OrderInit, setPurchaseOrders: Function) {
    vFetch(`/orders/purchase-orders/search?order_name=${order.name.replace('#', '')}`, {
        cb: (res: any) => setPurchaseOrders(res.purchase_orders.map((po: any) => parseResObject(po))),
    })
}
export function refreshReturns(order: OrderInit, setReturns: Function) {
    vFetch(`/returns?orderNumbers=${order.order_number}`, {
        cb: (res: any) => setReturns(res.returns.map((r: any) => parseResObject(r))),
    })
}

export function LEGACY_CODE_renderTimelineEvent(event: OrderTimelineEvent) {
    const date = new Date(Date.parse(event.createdAt))
    const time = [
        [date.toLocaleTimeString().split(':')[0], date.toLocaleTimeString().split(':')[1]].join(':'),
        date.toLocaleTimeString().split(':')[2].split(' ')[1],
    ].join(' ')
    const linkRegex = /\<a href="(.+)">(.+)<\/a>/

    let { message } = event
    let strings = []

    message = message.replaceAll('&lt;', '<').replaceAll('&gt;', '>')
    if (message.match(linkRegex)) {
        while (message.match(linkRegex)) {
            const link: string[] = message.match(linkRegex) || []
            const linkIndex = message.indexOf(link[0])
            const string = message.slice(0, linkIndex)
            strings.push(<span className='inline'>{string}</span>)
            message = message.replace(string + link[0], '')
            strings.push(
                <a className='inline hover:underline text-blue dark:text-accent' href={link[1]}>
                    {link[2]}
                </a>
            )
        }
        strings.push(message.slice(0))
    } else {
        strings = [<p>{message}</p>]
    }

    return event.__typename === 'BasicEvent' ? (
        <li key={event.id} className='flex mb-[16px] gap-[8px] basis-[100%] items-center'>
            <div className='relative top-0 bg-[#809ead] dark:bg-accent border-[5px] border-white dark:border-darkaccent rounded-full shrink-0 h-[16px] ml-[calc(var(--p-space-025)+0.59375rem+var(--p-space-4))] mr-[var(--p-space-4)] w-[16px] z-index-2 self-center'></div>

            <div className='w-[100%] max-w-[100%] p-[16px] gap-[8px] rounded dark:text-offwhite'>
                {strings.map((string) => string)}
            </div>
            <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'>
                <p className='dark:text-offwhite'>{date.toLocaleDateString()}</p>
                <p className='text-darkgrey dark:text-grey'>{time}</p>
            </div>
        </li>
    ) : (
        event.__typename === 'CommentEvent' && (
            <li
                key={event.id}
                className='flex mb-[16px] gap-[8px] bg-white dark:bg-darkaccent basis-[100%] items-center'
            >
                <div className='relative top-0 bg-[#809ead] dark:bg-accent border-[5px] border-white dark:border-darkaccent rounded-full shrink-0 h-[16px] ml-[calc(var(--p-space-025)+0.59375rem+var(--p-space-4))] mr-[var(--p-space-4)] w-[16px] z-index-2 self-center'></div>

                <div className='w-[100%] max-w-[100%] p-[16px] gap-[8px] dark:bg-darkness shadow-small dark:shadow-cool rounded dark:text-offwhite'>
                    {strings.map((string) => string)}
                </div>
                <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'>
                    <p className='dark:text-offwhite'>{date.toLocaleDateString()}</p>
                    <p className='text-darkgrey dark:text-grey'>{time}</p>
                </div>
            </li>
        )
    )
}
