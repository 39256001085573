import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setSections } from '../../redux/sections'
import { vFetch } from '../../helpers'

export default function CrossSellsScreen() {
    const [allProducts, setAllProducts] = useState([])
    const [search, setSearch] = useState('')
    const [filtered, setFiltered] = useState([])
    const debouncedSearch = useDebounce(search, 200)
    function useDebounce(value, delay) {
        const [debouncedValue, setDebouncedValue] = useState(value)
        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)
            return () => {
                clearTimeout(handler)
            }
        }, [value, delay])
        return debouncedValue
    }
    const LoadingGear = () => {
        return (
            <svg
                style={{ width: '300px', height: '300px' }}
                version='1.1'
                id='L2'
                xmlns='http://www.w3.org/2000/svg'
                x='0px'
                y='0px'
                viewBox='0 0 100 100'
                enable-background='new 0 0 100 100'
            >
                <circle fill='none' stroke='orange' stroke-width='4' stroke-miterlimit='10' cx='50' cy='50' r='48' />
                <line
                    fill='none'
                    stroke-linecap='round'
                    stroke='orange'
                    stroke-width='4'
                    stroke-miterlimit='10'
                    x1='50'
                    y1='50'
                    x2='85'
                    y2='50.5'
                >
                    <animateTransform
                        attributeName='transform'
                        dur='2s'
                        type='rotate'
                        from='0 50 50'
                        to='360 50 50'
                        repeatCount='indefinite'
                    />
                </line>
                <line
                    fill='none'
                    stroke-linecap='round'
                    stroke='orange'
                    stroke-width='4'
                    stroke-miterlimit='10'
                    x1='50'
                    y1='50'
                    x2='49.5'
                    y2='74'
                >
                    <animateTransform
                        attributeName='transform'
                        dur='15s'
                        type='rotate'
                        from='0 50 50'
                        to='360 50 50'
                        repeatCount='indefinite'
                    />
                </line>
            </svg>
        )
    }
    const { crossSells } = useSelector((state) => state).sections
    const dispatch = useDispatch()
    const addCrossSell = (gid) => {
        dispatch(
            setSections({
                crossSells: [...crossSells, gid],
            })
        )
    }
    const removeCrossSell = (gid) => {
        dispatch(
            setSections({
                crossSells: [...crossSells.filter((cs) => cs !== gid)],
            })
        )
    }
    useEffect(() => {
        if (allProducts.length > 0) {
            setFiltered(allProducts.filter((p) => p.title.toLowerCase().includes(debouncedSearch.toLowerCase())))
        }
    }, [debouncedSearch])
    useEffect(() => {
        vFetch(`/products?fields=title,gid,img_src`, {
            cb: (res) => {
                if (res.success) {
                    setAllProducts(res.products)
                    setFiltered(res.products)
                }
            },
        })
    }, [])
    return (
        <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col items-center shadow-small'>
            <p className='dark:text-offwhite font-bold mb-[16px] dark:font-normal text-center font-bai'>
                Add products to show in the 'Customers Also Purchased' section
            </p>
            {allProducts.length > 0 ? (
                <div className='flex w-full items-center gap-[32px]'>
                    <div className='w-full'>
                        <h3 className='dark:text-fire font-bold text-[18px] mb-[8px]'>Added</h3>
                        <div className='w-full p-[16px] pt-[8px] border border-grey rounded-[4px] h-[400px] dark:text-offwhite dark:bg-darkness dark:border-blue'>
                            {crossSells.map((p) => {
                                const match = allProducts.find((product) => product.gid === p)
                                if (match) {
                                    return (
                                        <div
                                            key={match.gid + '-added'}
                                            className='flex gap-[8px] items-center py-[4px] border-b border-grey dark:text-offwhite dark:font-[600]'
                                            data-gid={match.gid}
                                            onClick={() => removeCrossSell(match.gid)}
                                        >
                                            <img
                                                className='w-[50px] h-[50px] block contain shrink-0 p-[4px] rounded-[4px] bg-offwhite'
                                                height='50'
                                                width='50'
                                                src={match.img_src}
                                                loading='lazy'
                                            />
                                            {match.title}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <div className='cross-sells__half cross-sells__add'>
                        <div className='flex items-center gap-[8px] mb-[8px]'>
                            <h3 className='dark:text-fire font-bold text-[18px]'>All Products</h3>
                            <input
                                className='border border-grey rounded-[4px] px-[8px] dark:text-offwhite dark:bg-darkness dark:border-blue focus:outline-none'
                                type='text'
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder='search'
                            />
                        </div>
                        <div className='w-full p-[16px] pt-[8px] border border-grey rounded-[4px] h-[400px] overflow-auto dark:text-offwhite dark:bg-darkness dark:border-blue'>
                            {filtered
                                .filter((p) => !crossSells.includes(p.gid))
                                .map((p) => (
                                    <div
                                        key={p.gid + '-all'}
                                        className='flex gap-[8px] items-center py-[4px] border-b border-grey dark:text-offwhite dark:font-[600]'
                                        data-gid={p.gid}
                                        onClick={() => addCrossSell(p.gid)}
                                    >
                                        <img
                                            className='w-[50px] h-[50px] block contain shrink-0 p-[4px] rounded-[4px] bg-offwhite'
                                            height='50'
                                            width='50'
                                            src={p.img_src}
                                            loading='lazy'
                                        />
                                        {p.title}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            ) : (
                <LoadingGear />
            )}
        </div>
    )
}
