import { ChangeEvent } from 'react'
import { cn } from '../../helpers'
import { useSelector } from 'react-redux'

export default function Input({
    id,
    label,
    name,
    type,
    value,
    onChange,
    required,
    disabled,
    placeholder,
    readOnly,
    className,
    onclick,
    autoFocus,
}: {
    id: string
    label?: string
    name: string
    type: string
    value: string | number
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
    required?: boolean
    disabled?: boolean
    placeholder?: string
    readOnly?: boolean
    className?: string
    onclick?: any
    autoFocus?: boolean
}) {
    const settings = useSelector((state: any) => state.settings)
    return (
        <div className='flex flex-col gap-[4px] w-full'>
            {label && (
                <label
                    htmlFor={id}
                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'
                >
                    {label}
                    {required && (
                        <span className='absolute top-0 right-[-10px] text-[14px] text-red dark:text-lightred'>*</span>
                    )}
                </label>
            )}
            <input
                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                className={cn(
                    `w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]`,
                    className
                )}
                autoFocus={autoFocus}
                id={id}
                autoComplete='off'
                name={name}
                type={type}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                required={required ? true : false}
                disabled={disabled ? true : false}
                readOnly={readOnly}
                onClick={onclick}
            />
        </div>
    )
}
