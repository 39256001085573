import { useQuery } from '@tanstack/react-query'
import { Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react'
import { LoadingGear, vFetch } from '../../../helpers'
import { ProcurementContext, ProcurementContextType } from '../../helpers'
import { FetchingSpinner } from '../../../custom_components/FetchingSpinner'

export default function ProductCommentsModal({
    product_id,
    setShowModal,
}: {
    product_id: string | number
    setShowModal: Dispatch<SetStateAction<boolean>>
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const { users } = useContext<ProcurementContextType>(ProcurementContext)
    const { data, isLoading, isFetching } = useQuery({
        queryKey: [product_id, 'comments'],
        queryFn: () => vFetch(`/procurement/products/comments?product_id=${product_id}`),
    })

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                setShowModal(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    })

    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] flex items-center justify-center z-[50]'
        >
            <div className='p-[16px] rounded-[4px] shadow-small bg-white dark:bg-darkaccent max-h-[90vh] max-w-[90vw] overflow-auto relative'>
                <button
                    onClick={() => setShowModal(false)}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>
                <div className='flex items-center gap-[8px] mb-[8px]'>
                    <h2 className='font-bold text-fire text-[18px] uppercase leading-[1]'>Edits</h2>
                    {!isLoading && <FetchingSpinner isFetching={isFetching} />}
                </div>
                {isLoading ? (
                    <LoadingGear width='100px' height='100px' />
                ) : (
                    <ul className='list-disc pl-[8px] ml-[8px]'>
                        {data.comments.map((comment: any) => (
                            <li className='text-[16px]'>
                                {comment.value} - {new Date(comment.created_at).toDateString()} (
                                {
                                    (users.find((user) => user.id === comment.user_id) || { user_id: 'Unknown User' })
                                        .user_id
                                }
                                )
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}
