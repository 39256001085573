import { useContext, useEffect, useState } from 'react'
import { CompanyContext, ProcurementContext, ProcurementContextType } from '../../helpers'
import CompanyContact from '../listItems/CompanyContact'
import { useCompanyContactsQuery } from '../../api/useQueries'
import CompanyContactV2 from '../listItems/CompanyContactV2'
import FilterToolBar from '../FilterToolBar'

export default function ContactListV2() {
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const { setShowQuickModal } = useContext<any>(CompanyContext)
    const [search, setSearch] = useState('')

    const companyContactsQuery = useCompanyContactsQuery({ companyId: selectedCompany.id })

    const { isLoading, isFetching } = companyContactsQuery
    const { contacts } = companyContactsQuery.data || {}

    const filteredContacts = contacts?.filter((contact: any) => {
        return (
            contact.name?.toLowerCase().includes(search.toLowerCase()) ||
            contact.email?.toLowerCase().includes(search.toLowerCase())
        )
    })

    return (
        <div className='px-[16px] pl-0'>
            <div className='mb-[8px] w-full flex gap-[16px]'>
                <FilterToolBar search={search} setSearch={setSearch} filterBarObject={undefined} />
                <button
                    onClick={() => setShowQuickModal('contact')}
                    className='min-h-[32px] self-center shrink-0 text-[14px] leading-[1] py-[2px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase'
                >
                    + New Contact
                </button>
            </div>
            <div className='flex flex-col overflow-auto'>
                <div className='sticky top-0 grid grid-cols-[1fr_1fr_1fr_1fr_50px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Name</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Email</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Phone</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Alternate</div>
                </div>
                {filteredContacts?.map((contact: any) => {
                    return <CompanyContactV2 key={contact.id} contact={contact} />
                })}
            </div>
        </div>
    )
}
