import { useEffect, useState } from 'react'
import { vFetch } from '../../helpers'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import {
    getDate,
    differenceInHours,
    differenceInDays,
    differenceInWeeks,
    differenceInYears,
    differenceInMonths,
    startOfDay,
    differenceInMinutes,
    differenceInSeconds,
} from 'date-fns'

export default function UsersName({ questionRow }: { questionRow: any }) {
    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || { users: [] }
    const user = users?.find((user) => {
        return user.id === questionRow.user_id
    })
    const seconds = differenceInSeconds(new Date(), questionRow.created_at)
    const minutes = differenceInMinutes(new Date(), questionRow.created_at)
    const hours = differenceInHours(new Date(), questionRow.created_at)
    const days = differenceInDays(new Date(), questionRow.created_at)
    const weeks = differenceInWeeks(new Date(), questionRow.created_at)
    const months = differenceInMonths(new Date(), questionRow.created_at)
    const years = differenceInYears(new Date(), questionRow.created_at)

    function getTimeDifference(createdAt: Date) {
        const seconds = differenceInSeconds(new Date(), createdAt)
        if (seconds < 60) {
            return `just now`
        }

        const minutes = differenceInMinutes(new Date(), createdAt)
        if (minutes > 1 && minutes < 60) {
            return `${minutes} minutes ago`
        }
        if (minutes < 2) {
            return `just now`
        }

        const hours = differenceInHours(new Date(), createdAt)
        if (hours < 24) {
            return `${hours} hours ago`
        }
        if (hours < 2) {
            return `${hours} hour ago`
        }

        const days = differenceInDays(startOfDay(new Date()), startOfDay(createdAt))
        if (days < 7) {
            return `${days} days ago`
        }

        const weeks = differenceInWeeks(startOfDay(new Date()), startOfDay(createdAt))
        if (weeks < 4) {
            return `${weeks} weeks ago`
        }

        const months = differenceInMonths(startOfDay(new Date()), startOfDay(createdAt))
        if (months < 12) {
            return `${months} months ago`
        }

        const years = differenceInYears(startOfDay(new Date()), startOfDay(createdAt))
        if (years) {
            return `${years} years ago`
        }
    }
    const timeDifference = getTimeDifference(questionRow.created_at)

    // 2024-05-10T21:58:31.000Z

    return (
        <div className='text-sm flex gap-3 text-darkgrey dark:text-offwhite'>
            <p>
                {user?.first_name} {user?.last_name} asked {getTimeDifference(questionRow.created_at)}
            </p>
        </div>
    )
}
