import { useRef } from 'react'
import { useEffect, useState, memo } from 'react'
import { useDebounce, vFetch } from '../../../helpers'

const ProductSearch = ({ productSearchRef, setProductSearch, productType, showDropdown, setShowDropdown }) => {
    const [search, setSearch] = useState('')
    const debounceSearch = useDebounce(search, 300)
    const handleClearAndClose = () => {
        setShowDropdown(false)
        setSearch('')
    }
    useEffect(() => {
        setProductSearch(debounceSearch)
    }, [debounceSearch])
    return (
        <div className='new-task__product__search__row'>
            {showDropdown && (
                <div onClick={handleClearAndClose} className='new-task__product__search__close'>
                    &times;
                </div>
            )}
            <select className='new-task__product__select' value={productType}>
                <option value=''>Type</option>
            </select>
            <label style={{ width: 0, height: 0, overflow: 'hidden', zIndex: -10 }} htmlFor='product_search'>
                Search Products
            </label>
            <input
                type='text'
                id='product_search'
                name='product_search'
                className='new-task__product__search'
                placeholder='Search Products'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                autoComplete='off'
                ref={productSearchRef}
                onFocus={() => setShowDropdown(true)}
            />
        </div>
    )
}

const ProductDropdown = memo(function ProductDropdown({
    productDropdownRef,
    productSearch,
    showDropdown,
    selectedProducts,
    setSelectedProducts,
}) {
    const [products, setProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const ProductButton = ({ currentProduct }) => {
        const handleAddProduct = () => {
            setSelectedProducts([...selectedProducts.filter((p) => p.title !== currentProduct.title), currentProduct])
        }
        return (
            <div onClick={handleAddProduct} className='new-task__products__dropdown__product'>
                <div
                    className={
                        selectedProducts.find((p) => p.title === currentProduct.title)
                            ? 'new-task__products__dropdown__checkbox new-task__products__dropdown__checkbox--checked'
                            : 'new-task__products__dropdown__checkbox'
                    }
                >
                    &nbsp;
                </div>
                <div className='new-task__products__image-container'>
                    <img
                        className='new-task__products__img'
                        src={currentProduct.img_src}
                        width='50'
                        hight='50'
                        loading='lazy'
                        alt='Product image'
                    />
                </div>
                <p>{currentProduct.title}</p>
            </div>
        )
    }
    useEffect(() => {
        vFetch(`/products?fields=title,gid,img_src`, {
            cb: (res) => {
                if (res.success) {
                    setProducts(res.products)
                    setFilteredProducts(res.products)
                }
            },
        })
    }, [])
    useEffect(() => {
        const prefilter = products.filter((p) => p.title.toLowerCase().includes(productSearch.toLowerCase()))
        setFilteredProducts(prefilter)
    }, [productSearch])
    return (
        <>
            {showDropdown && (
                <div ref={productDropdownRef} className='new-task__products__dropdown'>
                    {filteredProducts.slice(0, 200).map((p) => (
                        <ProductButton currentProduct={p} />
                    ))}
                </div>
            )}
        </>
    )
})

export default function ProductCombo({ selectedProducts, setSelectedProducts }) {
    const [productSearch, setProductSearch] = useState('')
    const [productType, setProductType] = useState('all')
    const [showDropdown, setShowDropdown] = useState(false)
    const productSearchRef = useRef()
    const productDropdownRef = useRef()

    return (
        <>
            <ProductSearch
                productSearchRef={productSearchRef}
                setProductSearch={setProductSearch}
                productType={productType}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
            />
            <ProductDropdown
                productDropdownRef={productDropdownRef}
                productSearch={productSearch}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                showDropdown={showDropdown}
            />
        </>
    )
}
