import { Dispatch, SetStateAction } from 'react'
import { AdjustmentRow } from '../CostAndPriceScreen.types'
import { vFetch } from '../../helpers'

export default function AdjustmentModal({
    adjustments = [],
    setShowAdjustmentModal,
    setAdjustments,
    setNeedsRefresh,
}: {
    adjustments: AdjustmentRow[]
    setShowAdjustmentModal: Dispatch<SetStateAction<boolean>>
    setAdjustments: Dispatch<SetStateAction<AdjustmentRow[]>>
    setNeedsRefresh: Dispatch<SetStateAction<boolean>>
}) {
    const handleSubmitAdjustments = () => {
        vFetch(`/costAndPriceAdjustments`, {
            method: 'POST',
            body: JSON.stringify({ adjustments }),
            cb: (res: any) => {
                if (!res.success) {
                    return
                }
                setAdjustments([])
                setNeedsRefresh(true)
                setShowAdjustmentModal(false)
            },
        })
    }
    return (
        <div className='fixed top-0 left-0 w-full h-full grid place-items-center bg-[rgb(0,0,0,0.5)] z-[500]'>
            <div className='relative w-[90vw] h-[90vh] bg-white dark:bg-darkaccent rounded-[4px] shadow-small p-[32px] flex flex-col'>
                <button
                    onClick={() => setShowAdjustmentModal(false)}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>
                <h2 className='font-bold dark:text-offwhite text-[32px] leading-[1] mb-[16px]'>Adjustment Summary</h2>
                <div className='h-full overflow-auto mb-[16px]'>
                    <div className='sticky top-0 grid grid-cols-4 col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                        <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>SKU</div>
                        <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Cost</div>
                        <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Price</div>
                        <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>
                            Compare At Price
                        </div>
                    </div>
                    {adjustments.map((adjustment) => (
                        <div className='grid grid-cols-4 dark:text-offwhite border-b border-lightgrey dark:border-darkgrey'>
                            <p className='p-[8px] font-bold dark:text-offwhite'>{adjustment.sku}</p>
                            <p className='p-[8px] font-bold dark:text-offwhite'>{adjustment.unit_cost}</p>
                            <p className='p-[8px] font-bold dark:text-offwhite'>{adjustment.price}</p>
                            <p className='p-[8px] font-bold dark:text-offwhite'>{adjustment.compare_at_price}</p>
                        </div>
                    ))}
                </div>
                <div className='w-full flex gap-[16px] justify-center items-center'>
                    <button
                        onClick={handleSubmitAdjustments}
                        className='py-[4px] px-[16px] bg-blue dark:bg-accent font-bold uppercase text-white dark:text-darkness'
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}
