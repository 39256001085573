import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { sortByAlphanumeric, sortRelevantSearch, vFetch } from '../helpers'
import TailSpin from 'react-loading-icons/dist/esm/components/tail-spin'
import EditVendorContact from './EditVendorContact'
import EditVendor from './EditVendor'
import {
    VendorContactCreateInit,
    VendorContactEditInit,
    VendorContactsMap,
    VendorInit,
    defaultVendor,
    vendorContactTypes,
} from './vendors.types'
import VendorsList from './components/VendorsList'

export default function VendorsListScreen({}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const googleCode = searchParams.get('code')
    const scope = searchParams.get('scope')
    const [tokens, setTokens] = useState(null)
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')
    const [expanded, setExpanded] = useState<string[]>([])
    const [allVendors, setAllVendors] = useState<string[]>([])
    const [vendorsInfo, setVendorsInfo] = useState<VendorContactsMap>({})
    const [filteredVendorsInfo, setFilteredVendorsInfo] = useState<VendorContactsMap>({})
    const [showMode, setShowMode] = useState('all')
    const [vendorToEdit, setVendorToEdit] = useState<VendorInit | null>(null)
    const [vendorContactToEdit, setVendorContactToEdit] = useState<
        VendorContactEditInit | VendorContactCreateInit | null
    >(null)
    const [contactTypes, setContactTypes] = useState<string[]>([...vendorContactTypes])

    function refreshVendorsList() {
        if (!googleCode && !scope) {
            vFetch(`/google/auth`, {
                cb: (res: any) => {
                    if (res.success) setTokens(res.tokens)
                    else if (res.authorizationURL) window.location.replace(res.authorizationURL)
                },
            })
        }
        if (googleCode && scope && !tokens) {
            const {
                REACT_APP_MODE,
                REACT_APP_PRODUCTION_API_URL,
                REACT_APP_DEVELOPMENT_API_URL,
                REACT_APP_LOCAL_API_URL,
            } = process.env
            const API_URL =
                REACT_APP_MODE === 'production'
                    ? REACT_APP_PRODUCTION_API_URL
                    : REACT_APP_MODE === 'development'
                      ? REACT_APP_DEVELOPMENT_API_URL
                      : REACT_APP_LOCAL_API_URL
            vFetch(`/google/auth`, {
                method: 'POST',
                body: JSON.stringify({ name: 'Google', code: googleCode }),
                cb: (res: any) => {
                    if (res.success) {
                        setTokens(res.tokens)
                        window.history.pushState({ data: 'Token success' }, 'Token sucess', `${API_URL}/vendors`)
                    } else if (res.authorizationURL) {
                        window.location.replace(res.authorizationURL)
                    }
                },
            })
        }
        const resVendorContactMap: VendorContactsMap = {}
        vFetch('/vendors/contacts/types', {
            cb: (res: any) =>
                setContactTypes(
                    [...contactTypes.filter((v) => !res.types.includes(v)), ...res.types].sort((a: string, b: string) =>
                        sortByAlphanumeric(a, b)
                    )
                ),
        })
        vFetch(`/vendors/contacts`, {
            cb: (res: any) => {
                const vendorNames = Object.keys(res.vendorContactMap).sort((a, b) => sortByAlphanumeric(a, b))
                vendorNames.forEach(
                    (v) =>
                        (resVendorContactMap[v] = {
                            ...resVendorContactMap[v],
                            contacts: res.vendorContactMap[v]
                                .map((c: any) => {
                                    return { ...c, name: c.name || '' }
                                })
                                .sort((a: any, b: any) => sortByAlphanumeric(a, b, 'sort_id', 'contact_type')),
                        })
                )
                vFetch(`/vendors`, {
                    cb: (res: any) => {
                        const resVendorsInfo: VendorContactsMap = {}
                        res.vendors.forEach((v: any) => {
                            if (resVendorContactMap[v.name] && resVendorContactMap[v.name].contacts)
                                resVendorContactMap[v.name].contacts.sort((a, b) =>
                                    sortByAlphanumeric(a, b, 'sort_id', 'contact_type')
                                )
                            resVendorsInfo[v.name] = {
                                ...v,
                                contacts: resVendorContactMap[v.name] ? resVendorContactMap[v.name].contacts : [],
                            }
                            if (Object.keys(resVendorContactMap).includes(v.name))
                                resVendorContactMap[v.name] = { ...resVendorContactMap[v.name], ...v }
                        })
                        setVendorsInfo(resVendorsInfo)
                        setAllVendors(
                            Object.keys(resVendorsInfo).sort((a: string, b: string) => sortByAlphanumeric(a, b))
                        )
                        const deletedVendors = Object.keys(filteredVendorsInfo).filter((v) => !resVendorsInfo[v])
                        deletedVendors.forEach((v) => delete filteredVendorsInfo[v])
                    },
                })
                setLoading(false)
            },
        })
    }
    useEffect(() => {
        refreshVendorsList()
    }, [])
    useEffect(() => {
        setSearch('')
    }, [showMode])
    useEffect(() => {
        const lSearch = search.toLowerCase()
        let tempVendors: VendorContactsMap = {}
        let vendorNames = allVendors
        if (lSearch) {
            vendorNames = allVendors.filter((v) => v.toLowerCase().includes(lSearch))
            vendorNames.sort((a, b) => sortRelevantSearch(lSearch, a, b))
        }
        if (showMode === 'all') vendorNames.forEach((v) => (tempVendors[v] = vendorsInfo[v]))
        if (showMode === 'incomplete')
            vendorNames.forEach((v) =>
                vendorsInfo[v].contacts.length === 0 ? (tempVendors[v] = vendorsInfo[v]) : null
            )
        setExpanded(expanded.filter((v) => allVendors.includes(v)))
        setFilteredVendorsInfo(tempVendors)
    }, [allVendors, vendorsInfo, showMode, search])
    return (
        <div className='dark:text-offwhite'>
            {(vendorToEdit || vendorToEdit !== null) && (
                <EditVendor
                    contactTypes={contactTypes}
                    vendorsInfo={vendorsInfo}
                    vendor={vendorToEdit}
                    setVendorToEdit={setVendorToEdit}
                    refreshVendorsList={refreshVendorsList}
                />
            )}
            {vendorContactToEdit && (
                <EditVendorContact
                    contactTypes={contactTypes}
                    contact={vendorContactToEdit}
                    setVendorContactToEdit={setVendorContactToEdit}
                    refreshVendorsList={refreshVendorsList}
                />
            )}
            {loading && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Vendor Contacts List</h1>
            <div className='sticky top-[51px] flex justify-center px-[16px] py-[8px] bg-white dark:bg-darkaccent rounded-t border-[1px] border-darkgrey z-40'>
                <div className='grow'>
                    <button
                        className={`px-[8px] py-[4px] uppercase font-bold border-[1px] border-darkgrey rounded-l ${
                            showMode === 'all' && 'text-white bg-blue dark:bg-accent dark:text-black'
                        }`}
                        onClick={() => {
                            setShowMode('all')
                        }}
                    >
                        All ({allVendors.length})
                    </button>
                    <button
                        className={`px-[8px] py-[4px] uppercase font-bold border-[1px] border-darkgrey rounded-r  ${
                            showMode === 'incomplete' && 'text-white bg-blue dark:bg-accent dark:text-black'
                        }`}
                        onClick={() => {
                            setShowMode('incomplete')
                        }}
                    >
                        Incomplete ({Object.values(vendorsInfo).filter((v) => v.contacts.length === 0).length})
                    </button>
                </div>
                <input
                    className='grow px-[4px] self-center dark:text-offwhite rounded bg-[whitesmoke] dark:bg-darkness w-[300px]'
                    placeholder='Vendor Search'
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                />
                <div className='grow flex gap-[8px] justify-end items-center'>
                    <button
                        className='px-[8px] py-[4px] uppercase font-bold rounded text-white dark:text-black bg-blue hover:bg-blue/80 dark:bg-accent dark:hover:bg-accent/80'
                        onClick={() => setVendorToEdit(defaultVendor)}
                    >
                        + Vendor
                    </button>
                </div>
            </div>
            <VendorsList
                vendorsInfo={filteredVendorsInfo}
                expanded={expanded}
                setVendorsInfo={setVendorsInfo}
                setExpanded={setExpanded}
                setVendorToEdit={setVendorToEdit}
                setVendorContactToEdit={setVendorContactToEdit}
                refreshVendorsList={refreshVendorsList}
            />
        </div>
    )
}
