import { FaHeart, FaRegHeart, FaTimes } from 'react-icons/fa'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import { vFetch } from '../../helpers'
import { FaTrashCan } from 'react-icons/fa6'
import { CiEdit } from 'react-icons/ci'
import { useEffect, useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import Textarea from '../../procurement/components/Textarea'
import { useSelector } from 'react-redux'

export default function Answers({ answersRow, getAnswers, setAnswerList }: any) {
    const [ShowUpdateAndDeleteButtons, setShowUpdateAndDeleteButtons] = useState(false)
    const [showEditButton, setShowEditButton] = useState(true)
    const [showCancelEditButton, setShowCancelEditButton] = useState(false)
    const [editAnswer, setEditAnswer] = useState(false)
    const [editableAnswer, setEditableAnswer] = useState<undefined | string>()
    const [answer, setAnswer] = useState<any>(answersRow.answer)

    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || {}
    const user = users?.find((user) => {
        return user.id === answersRow.user_id
    })

    // user.id === questionRow.user_id
    // const {users} = useSelector((state)) => ({
    // })

    //     get user using useSelector((state: any) => state.user)
    // check user.id === questionRow.user_id

    const currentUser = useSelector((state: any) => state.user)
    const canEdit = currentUser.id === answersRow.user_id

    const handleDeleteAnswer = (id: any) => {
        return vFetch('/knowledge/answers', {
            method: 'DELETE',
            body: JSON.stringify({ id }),
            cb: (res: any) => {
                if (res.success) {
                    getAnswers()
                    setShowEditButton(true)
                    setEditableAnswer(undefined)
                    setEditAnswer(false)
                    setShowUpdateAndDeleteButtons(false)
                }
            },
        })
    }

    // HANDLES WITH FETCHES
    const handleAnswerLike = (answer_id: number) => {
        setAnswerList((previousState: any) => {
            const newState = structuredClone(previousState)
            const index = previousState.findIndex((ans: any) => ans.id === answer_id)
            if (index !== -1) {
                newState[index].is_liked = 1
                newState[index].total_likes++
            }
            return newState
        })

        vFetch('/knowledge/answers/likes', {
            method: 'POST',
            body: JSON.stringify({ answer_id }),
            cb: () => {},
            skipToast: true,
        })
    }

    const handleAnswerUnlike = (answer_id: number) => {
        setAnswerList((previousState: any) => {
            const newState = structuredClone(previousState)
            const index = previousState.findIndex((ans: any) => ans.id === answer_id)
            if (index !== -1) {
                newState[index].is_liked = 0
                newState[index].total_likes--
            }
            return newState
        })

        vFetch('/knowledge/answers/likes', {
            method: 'DELETE',
            body: JSON.stringify({ answer_id }),
            cb: () => {},
            skipToast: true,
        })
    }

    const handleUpdateAnswer = () =>
        vFetch('/knowledge/answers', {
            method: 'PUT',
            body: JSON.stringify({ id: answersRow.id, answer: editableAnswer }), //
            cb: (res: any) => {
                getAnswers()
                setShowEditButton(true)
                setEditableAnswer(undefined)
                setEditAnswer(false)
            },
        })

    // HANDLES
    const handleEditClick = () => {
        setShowUpdateAndDeleteButtons(true)
        setShowEditButton(false)
        setShowCancelEditButton(true)
    }

    const handleCancel = () => {
        setShowUpdateAndDeleteButtons(false)
        setShowEditButton(true)
        setShowCancelEditButton(false)
    }

    const handleCancelEditAnswer = () => {
        setEditAnswer(false)
        setShowEditButton(true)
        setEditableAnswer(undefined)
    }

    const handleClickEditAnswerButton = () => {
        setEditAnswer(true)
        handleCancel()
    }

    useEffect(() => {
        setAnswer(answersRow)
    }, [answersRow])

    return (
        <div>
            <div className='relative p-2 items-center border rounded border-lightgrey dark:border-darkgrey flex justify-between mb-2'>
                <div className='w-full flex gap-2 items-center'>
                    <div className='w-full flex gap-2 items-center'>
                        <div className='shrink-0 rounded-full flex items-center justify-center w-[44px] h-[44px] text-2xl font-bold text-white dark:text-offwhite bg-orange-500'>
                            {user?.first_name[0]}
                            {user?.last_name[0]}
                        </div>
                        <Textarea
                            id={answersRow.answer}
                            name='answer'
                            value={editableAnswer ?? answersRow.answer}
                            onChange={({ target }) => {
                                setEditableAnswer(target.value)
                            }}
                            readOnly={!editAnswer}
                            className={
                                !editAnswer
                                    ? 'dark:bg-darkbg1 text-sm p-0 border-0 h-fit focus:outline-none w-full'
                                    : 'w-full bg-transparent h-fit p-2'
                            }
                        />
                    </div>

                    {editAnswer && (
                        <div className='flex items-center gap-2'>
                            {editableAnswer && editableAnswer !== answersRow.answer && (
                                <button
                                    className='leading-none text-sm p-2 shrink-0 whitespace-nowrap bg-blue text-offwhite dark:bg-accent dark:text-darkaccent font-bold rounded'
                                    onClick={handleUpdateAnswer}
                                >
                                    Save
                                </button>
                            )}
                            <button
                                value='Cancel'
                                onClick={handleCancelEditAnswer}
                                className='leading-none text-sm p-2 shrink-0 whitespace-nowrap bg-darkgrey text-offwhite font-bold rounded'
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </div>

                <div className='flex gap-6 pr-6'>
                    {!editAnswer && (
                        <div>
                            {answersRow.is_liked ? (
                                <button
                                    onClick={() => handleAnswerUnlike(answersRow.id)}
                                    className='flex flex-col gap-1 items-center'
                                >
                                    <FaHeart className='h-[24px] w-[24px]  text-red/75 dark:text-white' />
                                    <span className='block leading-none text-xs text-darkgrey dark:text-offwhite'>
                                        {answersRow.total_likes}
                                    </span>
                                </button>
                            ) : (
                                <button
                                    onClick={() => handleAnswerLike(answersRow.id)}
                                    className='flex flex-col gap-1 items-center'
                                >
                                    <FaRegHeart className='h-[24px] w-[24px]  text-red/75 dark:text-white' />
                                    <span className='block leading-none text-xs text-darkgrey dark:text-offwhite'>
                                        {answersRow.total_likes}
                                    </span>
                                </button>
                            )}
                        </div>
                    )}
                    {canEdit && (
                        <div className='absolute top-2 right-0 items-center font-bold gap-4'>
                            {showEditButton ? (
                                <button
                                    onClick={handleEditClick}
                                    className=' text-black dark:text-offwhite text-xl font-bold'
                                >
                                    <BsThreeDotsVertical />
                                </button>
                            ) : (
                                <div>
                                    <button
                                        onClick={handleCancel}
                                        className='shrink-0 whitespace-nowrap dark:text-offwhite text-xl font-bold'
                                    >
                                        <FaTimes className='text-red w-[22px] h-[22px]' />
                                    </button>
                                </div>
                            )}

                            {ShowUpdateAndDeleteButtons && (
                                <div
                                    className='font-normal z-50 absolute right-0 flex flex-col shrink-0 border shadow-md text-sm
                                dark:text-white dark:bg-darkbg1 dark:border-darkgrey whitespace-nowrap text-darkgrey bg-white rounded'
                                >
                                    <button
                                        onClick={handleClickEditAnswerButton}
                                        value='Edit'
                                        className='flex gap-2 items-center dark:text-offwhite m-2'
                                    >
                                        <CiEdit className='w-[20px] h-[20px]' />
                                        Edit Answer
                                    </button>
                                    <button
                                        value='Delete'
                                        onClick={() => handleDeleteAnswer(answersRow.id)}
                                        className='flex gap-2 items-center mb-2 mx-2'
                                    >
                                        <FaTrashCan className='w-[20px] text-red text-sm' />
                                        Delete Answer
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
