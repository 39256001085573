import { useEffect, useState } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { sendToast, sortByAlphanumeric, vFetch } from '../../helpers'
const NO_DELETE_TEMPLATES = ['Purchase Order']
export default function EmailTemplateScreen() {
    const orderProps = {
        'Order Name': '{{ order.name }}',
        Subtotal: '{{ order.current_subtotal_price }}',
        Total: '{{ order.current_total_price }}',
        Balance: '{{ order.total_outstanding }}',
        Company: '{{ order.shipping_address.company }}',
        'Street Address': '{{ order.shipping_address.address1 }}',
        'Address Line 2': '{{ order.shipping_address.address2 }}',
        City: '{{ order.shipping_address.city }}',
        'State Code': '{{ order.shipping_address.province_code }}',
        Zip: '{{ order.shipping_address.zip }}',
    }
    const customerProps = {
        'First Name': '{{ customer.first_name }}',
        'Last Name': '{{ customer.last_name }}',
        'Email Address': '{{ customer.email }}',
    }
    const supplierProps = {
        Name: '{{ supplier.name }}',
        'Contact Name': '{{ supplier.contact_name }}',
        'Unfulfilled Orders': '{{ supplier.unfulfilled_orders }}',
    }
    const blankTemplate = {
        name: '',
        greeting: 'Hi {{ customer.first_name }},',
        subject: '',
        html: '',
        respond_to: 'sales@factorypure.com',
        include_signature: true,
        cc: '',
        belongs_to: 'Any',
    }
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0)
    const [emailTemplates, setEmailTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(blankTemplate)
    const [edited, setEdited] = useState(false)
    const [newMode, setNewMode] = useState(false)
    const [needsRefresh, setNeedsRefresh] = useState('refresh')
    const templateTypeOptions = ['Any', 'Customer', 'Manufacturer']
    useEffect(() => {
        if (emailTemplates.length > 0 && !newMode) {
            setSelectedTemplate(emailTemplates[selectedTemplateIndex])
        }
    }, [selectedTemplateIndex, newMode])
    useEffect(() => {
        if (JSON.stringify(emailTemplates[selectedTemplateIndex]) !== JSON.stringify(selectedTemplate)) {
            setEdited(true)
        } else {
            setEdited(false)
        }
    }, [selectedTemplate])
    useEffect(() => {
        if (emailTemplates.length === 0) {
            setNewMode(true)
        } else {
            setNewMode(false)
        }
    }, [emailTemplates])
    useEffect(() => {
        if (needsRefresh.length > 0) {
            vFetch(`/emailTemplates/all${needsRefresh === 'liveResults' ? '?liveResults=true' : ''}`, {
                cb: (res) => {
                    if (res.success) {
                        setEmailTemplates(res.emailTemplates.sort((a, b) => sortByAlphanumeric(a, b, 'name')))
                    }
                },
            })
            setNeedsRefresh('')
        }
    }, [needsRefresh])
    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value).then(() => sendToast({ message: 'Copied!' }))
    }
    const handleSubmit = () => {
        if (selectedTemplate.id) {
            vFetch(`/emailTemplates`, {
                method: 'PUT',
                body: JSON.stringify(selectedTemplate),
                cb: (res) => {
                    if (res.success) {
                        setNewMode(false)
                        setNeedsRefresh('liveResults')
                        setSelectedTemplateIndex(0)
                        setEdited(false)
                    }
                },
            })
        } else {
            vFetch(`/emailTemplates`, {
                method: 'POST',
                body: JSON.stringify(selectedTemplate),
                cb: (res) => {
                    if (res.success) {
                        setNewMode(false)
                        setNeedsRefresh('liveResults')
                        setSelectedTemplateIndex(0)
                        setEdited(false)
                    }
                },
            })
        }
    }
    const handleDelete = () => {
        const confirmation = window.confirm('Are you sure?')
        if (confirmation) {
            vFetch(`/emailTemplates`, {
                method: 'DELETE',
                body: JSON.stringify({ id: selectedTemplate.id }),
                cb: (res) => {
                    if (res.success) {
                        setNeedsRefresh('liveResults')
                        setSelectedTemplateIndex(0)
                    }
                },
            })
        }
    }
    const handleChange = ({ target }) => {
        setSelectedTemplate({
            ...selectedTemplate,
            [target.name]: target.type === 'checkbox' ? target.checked : target.value,
        })
    }
    const createNewTemplate = () => {
        setSelectedTemplate(blankTemplate)
        setNewMode(true)
    }
    const handleCancel = () => {
        setSelectedTemplateIndex(0)
        setNewMode(false)
    }
    return (
        <div className='flex flex-col'>
            <h1 className='uppercase text-[24px] mb-[16px] tracking-[2px] font-[300] dark:text-offwhite'>
                {newMode ? 'Create Email Template' : 'Edit Email Templates'}
            </h1>
            <div className='flex gap-[8px] items-center mb-[8px]'>
                {!newMode && (
                    <select
                        className='bg-lightgrey focus:outline-none dark:text-white dark:bg-faintplus p-[4px] rounded-[4px]'
                        value={selectedTemplateIndex}
                        onChange={({ target }) => setSelectedTemplateIndex(target.value)}
                    >
                        {emailTemplates.map((e, index) => (
                            <option
                                className='bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                key={e.name}
                                value={index}
                            >
                                {e.name}
                            </option>
                        ))}
                    </select>
                )}
                {newMode ? (
                    <>
                        {emailTemplates.length > 0 && (
                            <button
                                onClick={handleCancel}
                                className='py-[4px] px-[16px] rounded-[4px] bg-red text-white font-bold ml-auto'
                            >
                                CANCEL
                            </button>
                        )}
                    </>
                ) : (
                    <button onClick={createNewTemplate} className='dark:text-offwhite font-[600] ml-auto'>
                        + Create New Template
                    </button>
                )}
            </div>
            <div className='flex gap-[16px]'>
                <div className='p-[16px] w-[70%] shadow-small rounded-[4px] flex flex-col gap-[8px] dark:bg-darkaccent relative'>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label
                            htmlForm='name'
                            className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                        >
                            template Type
                        </label>
                        <select
                            className='bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px] max-w-[300px]'
                            value={selectedTemplate.belongs_to || templateTypeOptions[0]}
                            onChange={({ target }) => {
                                setSelectedTemplate({
                                    ...selectedTemplate,
                                    belongs_to: target.value === 'All' ? null : target.value,
                                })
                            }}
                        >
                            {templateTypeOptions.map((o) => (
                                <option
                                    className='bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    value={o.toLowerCase()}
                                >
                                    {o}
                                </option>
                            ))}
                        </select>
                    </div>
                    {!newMode && !NO_DELETE_TEMPLATES.includes(selectedTemplate.name) && (
                        <button
                            onClick={handleDelete}
                            className='absolute top-0 right-0 py-[2px] px-[8px] font-bold text-[12px] bg-red text-white rounded-[4px]'
                        >
                            DELETE
                        </button>
                    )}
                    <div className='flex gap-[8px]'>
                        <div className='flex flex-col gap-[4px] w-full'>
                            <label
                                htmlForm='name'
                                className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                            >
                                Name
                            </label>
                            <input
                                name='name'
                                onChange={handleChange}
                                id='name'
                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                value={selectedTemplate.name}
                            />
                        </div>
                        <div className='flex flex-col gap-[4px] w-full'>
                            <label
                                htmlFor='respond_to'
                                className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                            >
                                Respond To
                            </label>
                            <select
                                className='w-full h-[32px] text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                value={selectedTemplate.respond_to}
                                name='respond_to'
                                onChange={handleChange}
                                id='respond_to'
                            >
                                <option value='sales@factorypure.com'>sales@factorypure.com</option>
                                <option value='orders@factorypure.com'>orders@factorypure.com</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[4px]'>
                        <label htmlFor='cc' className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            CC (comma separated)
                        </label>
                        <input
                            name='cc'
                            onChange={handleChange}
                            id='cc'
                            className='bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            value={selectedTemplate.cc}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px]'>
                        <label
                            htmlFor='subject'
                            className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                        >
                            Subject
                        </label>
                        <input
                            name='subject'
                            onChange={handleChange}
                            id='subject'
                            className='bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            value={selectedTemplate.subject}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px]'>
                        <label
                            htmlFor='html'
                            className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                        >
                            Body
                        </label>
                        <div className='border-grey dark:border-darkgrey border rounded-[4px] p-[8px]'>
                            <div className='flex flex-col gap-[4px] mb-[8px]'>
                                <label
                                    htmlFor='greeting'
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                >
                                    Greeting
                                </label>
                                <input
                                    name='greeting'
                                    id='greeting'
                                    value={selectedTemplate.greeting}
                                    className='bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px] w-full'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex flex-col gap-[4px] mb-[8px]'>
                                <label
                                    htmlFor='html'
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                >
                                    Main Content
                                </label>
                                <textarea
                                    name='html'
                                    onChange={handleChange}
                                    id='html'
                                    className='w-full max-w-none bg-lightgrey border-none focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px] !mb-0'
                                    value={selectedTemplate.html}
                                ></textarea>
                            </div>
                            <div className='flex gap-[4px] items-center'>
                                <input
                                    name='include_signature'
                                    onChange={handleChange}
                                    id='include_signature'
                                    type='checkbox'
                                    checked={selectedTemplate.include_signature}
                                />
                                <label htmlFor='include_signature' className='dark:text-offwhite'>
                                    Show Email Signature?
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-[30%] p-[16px] rounded-[4px] shadow-small dark:bg-darkaccent'>
                    <div className='relative'>
                        <h2 className='font-bold uppercase text-[14px] dark:text-offwhite leading-[1] mb-[8px] text-darkgrey flex gap-[8px] items-center peer'>
                            Templating Helper
                            <AiFillInfoCircle className='peer cursor-help' />
                            <div className='dark:bg-darkaccent opacity-0 peer-hover:opacity-100 absolute pointer-events-none top-[24px] z-[5] bg-white shadow-small p-[8px] rounded-[4px] font-normal normal-case'>
                                <p className='mb-[8px]'>
                                    Email templates have access to three resources, the order, the customer, and the
                                    supplier.
                                </p>
                                <p>
                                    Click these properties to copy them to your clipboard, then paste the shortcode into
                                    the editor to insert dynamic content in your email.
                                </p>
                            </div>
                        </h2>
                        <h3 className='font-bold text-darkgrey text-[12px] dark:text-offwhite uppercase border-b border-darkgrey mb-[8px]'>
                            Order Properties
                        </h3>
                        <div className='flex flex-wrap gap-[8px] mb-[8px]'>
                            {Object.entries(orderProps).map(([key, val]) => (
                                <button
                                    key={val}
                                    className='py-[2px] px-[8px] rounded-[4px] border border-grey dark:border-fire dark:text-fire font-[600] block cursor-pointer'
                                    onClick={() => copyToClipboard(val)}
                                >
                                    {key}
                                </button>
                            ))}
                        </div>
                        <h3 className='font-bold text-darkgrey text-[12px] dark:text-offwhite uppercase border-b border-darkgrey mb-[8px]'>
                            Customer Properties
                        </h3>
                        <div className='flex flex-wrap gap-[8px] mb-[8px]'>
                            {Object.entries(customerProps).map(([key, val]) => (
                                <button
                                    key={val}
                                    className='py-[2px] px-[8px] rounded-[4px] border border-grey dark:border-fire dark:text-fire font-[600] block cursor-pointer'
                                    onClick={() => copyToClipboard(val)}
                                >
                                    {key}
                                </button>
                            ))}
                        </div>
                        <h3 className='font-bold text-darkgrey text-[12px] dark:text-offwhite uppercase border-b border-darkgrey mb-[8px]'>
                            Supplier Properties
                        </h3>
                        <div className='flex flex-wrap gap-[8px]'>
                            {Object.entries(supplierProps).map(([key, val]) => (
                                <button
                                    key={val}
                                    className='py-[2px] px-[8px] rounded-[4px] border border-grey dark:border-fire dark:text-fire font-[600] block cursor-pointer'
                                    onClick={() => copyToClipboard(val)}
                                >
                                    {key}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {edited && (
                <div className='mx-auto mt-[16px] flex gap-[16px]'>
                    <button
                        className='py-[4px] px-[16px] rounded-[4px] bg-blue text-white font-bold text-[24px]'
                        onClick={handleSubmit}
                    >
                        {!newMode ? 'UPDATE' : 'CREATE'}
                    </button>
                </div>
            )}
        </div>
    )
}
