import { ChangeEvent, ReactElement, ReactNode } from 'react'

export default function Select({
    className,
    id,
    name,
    onChange,
    value,
    children,
    label,
    disabled = false,
}: {
    className?: string
    id: string
    name: string
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void
    value: any
    children?: ReactNode
    label?: string
    disabled?: boolean
}) {
    return (
        <div className='flex flex-col gap-[4px] w-full'>
            {label && (
                <label
                    htmlFor={id}
                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'
                >
                    {label}
                </label>
            )}
            <select
                disabled={disabled}
                className={
                    className ||
                    `w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]`
                }
                id={id}
                name={name}
                onChange={onChange}
                value={value}
            >
                {children}
            </select>
        </div>
    )
}
