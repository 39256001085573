import { useEffect, useState } from 'react'
import MoreActions from '../processingTimes/components/MoreActions'
import Refresh from '../processingTimes/components/Refresh'
import { LoadingGear, useDebounce, vFetch } from '../helpers'
import ProductList from './components/ProductList'
import { AdjustmentRow, VariantCostRow } from './CostAndPriceScreen.types'
import AdjustmentModal from './components/AdjustmentModal'

export default function CostAndPriceScreen() {
    const [needsRefresh, setNeedsRefresh] = useState(true)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('')
    const [loading, setLoading] = useState(false)
    const [variants, setVariants] = useState([])
    const [adjustments, setAdjustments] = useState([] as AdjustmentRow[])
    const [showAdjustmentModal, setShowAdjustmentModal] = useState(false)
    const debouncedSearch = useDebounce(search, 300)

    useEffect(() => {
        if (needsRefresh) {
            setLoading(true)
            setVariants([])
            vFetch(
                `/costAndPriceAdjustments/variants?search=${debouncedSearch}${
                    filter.length ? `&${filter.toLowerCase()}=true` : ''
                }`,
                {
                    cb: (res: any) => {
                        setNeedsRefresh(false)
                        setLoading(false)
                        if (!res.success) return
                        setVariants(res.variants || [])
                    },
                    catchCb: (err: any) => {
                        setNeedsRefresh(false)
                        setLoading(false)
                    },
                }
            )
        }
    }, [needsRefresh])

    useEffect(() => {
        setNeedsRefresh(true)
    }, [debouncedSearch, filter])

    const handleAdjustment = (target: HTMLInputElement, variant: VariantCostRow) => {
        setAdjustments((previousState) => {
            const newState = structuredClone(previousState)
            const foundIndex = newState.findIndex((adj) => adj.gid === variant.gid)
            if (foundIndex !== -1) {
                newState[foundIndex][target.name as 'unit_cost' | 'price' | 'compare_at_price'] = parseFloat(
                    target.value
                )
            } else {
                newState.push({
                    sku: variant.sku,
                    gid: variant.gid,
                    parent_gid: variant.parent_gid,
                    price: variant.price,
                    unit_cost: variant.unit_cost,
                    compare_at_price: variant.compare_at_price,
                    [target.name]: target.value,
                } as AdjustmentRow)
            }
            return newState
        })
    }
    return (
        <>
            {adjustments.length > 0 && (
                <div className='fixed top-[50%] right-0 translate-y-[-50%] flex flex-col gap-[16px]'>
                    <button
                        onClick={() => setShowAdjustmentModal(true)}
                        className='py-[4px] px-[16px] bg-blue dark:bg-accent font-bold uppercase text-white dark:text-darkness'
                    >
                        Save
                    </button>
                    <button
                        onClick={() => {
                            setAdjustments([])
                            setNeedsRefresh(true)
                            setFilter('')
                            setSearch('')
                        }}
                        className='py-[4px] px-[16px] bg-red dark:bg-lightred font-bold uppercase text-white'
                    >
                        Reset
                    </button>
                </div>
            )}
            <div className='flex justify-between mb-[8px]'>
                <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Cost &amp; Price Adjuster</h1>
                <div className='flex gap-[8px]'>
                    <MoreActions options={[]} />
                    <Refresh setNeedsRefresh={setNeedsRefresh} />
                </div>
            </div>
            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh]'>
                <div className='flex justify-between border-b border-lightgrey dark:border-darkgrey items-center pr-[8px] rounded-t-[4px] rounded-tl-[0px] dark:bg-darkaccent'>
                    <div className='w-full p-[8px] flex'>
                        <input
                            type='text'
                            className='w-full bg-transparent dark:text-white focus:outline-none'
                            placeholder='Search'
                            value={search}
                            onChange={({ target }) => {
                                setSearch(target.value)
                            }}
                        />
                    </div>
                    <div className='flex gap-[8px] items-center text-[14px] text-darkgrey'>
                        {(filter !== '' || search.length > 0) && (
                            <button
                                onClick={() => {
                                    setFilter('')
                                    setSearch('')
                                }}
                                className={`rounded-[4px] font-bold py-[4px] px-[8px] bg-red dark:bg-lightred text-white`}
                            >
                                CLEAR
                            </button>
                        )}
                        <button
                            onClick={() => setFilter('DUE')}
                            className={`rounded-[99vw] font-bold py-[4px] px-[8px] whitespace-nowrap ${
                                filter === 'DUE'
                                    ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                                    : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                            }`}
                        >
                            DUE FOR UPDATE
                        </button>
                        <button
                            onClick={() => setFilter('RECENT')}
                            className={`rounded-[99vw] font-bold py-[4px] px-[8px] whitespace-nowrap ${
                                filter === 'RECENT'
                                    ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                                    : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                            }`}
                        >
                            RECENTLY EDITED
                        </button>
                    </div>
                </div>
                {loading ? (
                    <div className='w-full h-[40vw] grid place-items-center shadow-small bg-white dark:bg-darkaccent'>
                        <LoadingGear />
                    </div>
                ) : (
                    <>
                        <ProductList
                            variants={variants}
                            handleAdjustment={handleAdjustment}
                            adjustments={adjustments}
                        />
                    </>
                )}
            </div>
            {showAdjustmentModal && (
                <AdjustmentModal
                    adjustments={adjustments}
                    setShowAdjustmentModal={setShowAdjustmentModal}
                    setAdjustments={setAdjustments}
                    setNeedsRefresh={setNeedsRefresh}
                />
            )}
        </>
    )
}
