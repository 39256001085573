import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import store from './redux/store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AnalyticsProvider } from 'use-analytics'
import analytics from './analytics/analytics'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 30000,
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <QueryClientProvider client={queryClient}>
        <AnalyticsProvider instance={analytics}>
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </AnalyticsProvider>
    </QueryClientProvider>
)
