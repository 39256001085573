import { useEffect, useState } from 'react'
import { AiFillCaretDown, AiOutlineConsoleSql } from 'react-icons/ai'
import { MdOutlineFilterList } from 'react-icons/md'
import { closePopout, sendToast, vFetch } from '../helpers'

function isInParentButNotInnerContainer(e, className, otherClassName) {
    let element = e.target
    let inParent = false
    let inInner = false
    while (element.parentNode) {
        if (typeof className === 'string') {
            if (element.classList.contains(className)) inParent = true
            if (element.classList.contains(otherClassName)) inInner = true
        } else {
            for (let name of className) {
                if (element.classList.contains(name)) inParent = true
                if (element.classList.contains(otherClassName)) inInner = true
            }
        }
        element = element.parentNode
    }
    return inParent && !inInner
}

export default function Searchbar({ search, handleSearch, filters, setFilters }) {
    const [showPresetOptions, setShowPresetOptions] = useState(false)
    const [presetOptions, setPresetOptions] = useState([])
    const [showFilters, setShowFilters] = useState(false)
    const [showFilterOptions, setShowFilterOptions] = useState(null)
    const [filterOptions, setFilterOptions] = useState(['Tagged with', 'Not tagged with'])
    const [tags, setTags] = useState()
    const [preset, setPreset] = useState(null)
    const [newPreset, setNewPreset] = useState(null)
    const [presetEdited, setPresetEdited] = useState(false)
    const [presetName, setPresetName] = useState('')

    function newFilter(f) {
        return { name: f, value: null, edit: true, id: Math.floor(Math.random() * 1000000) }
    }

    const handleSavePreset = () => {
        if (!presetName.trim()) {
            return sendToast({ message: 'Please add a name to this preset before saving.' })
        }

        if (!presetOptions.some((p) => p.name.trim().toLowerCase() === presetName.trim().toLowerCase())) {
            vFetch(`/presets`, {
                method: 'POST',
                body: JSON.stringify({
                    name: presetName.trim(),
                    belongs_to: 'customers',
                    value: filters,
                }),
                cb: (res) => {
                    if (res.success) {
                        setPreset({ name: presetName, belongs_to: 'customers', value: filters })
                        setNewPreset({ name: presetName, belongs_to: 'customers', value: filters })
                        setPresetName('')
                        setPresetOptions([
                            ...presetOptions,
                            {
                                name: presetName.trim(),
                                belongs_to: 'customers',
                                value: filters,
                            },
                        ])
                    }
                },
            })
        } else {
            sendToast({ message: 'That preset name is already in use.' })
        }
    }

    const handleDeletePreset = () => {
        vFetch(`/presets`, {
            method: 'DELETE',
            body: JSON.stringify({ id: preset.id }),
            cb: (res) => {
                if (res.success) {
                    setPresetOptions(presetOptions.filter((p) => JSON.stringify(p) !== JSON.stringify(preset)))
                    setPreset(null)
                    setFilters([])
                }
            },
        })
    }

    useEffect(() => {
        function listenToFilterOptionsList(e) {
            closePopout(e, ['js-presets-filter-options-list'], setShowFilterOptions)
        }
        window.addEventListener('click', listenToFilterOptionsList)

        vFetch(`/presets?belongs_to=customers`, {
            cb: (res) => {
                if (res.success)
                    setPresetOptions(
                        res.presets
                            .map((p) => {
                                return { ...p, value: JSON.parse(p.value) }
                            })
                            .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
                    )
            },
        })

        return () => {
            window.removeEventListener('click', listenToFilterOptionsList)
        }
    }, [])

    useEffect(() => {
        setNewPreset(preset)
        setPresetEdited(false)
    }, [preset])

    useEffect(() => {
        if (JSON.stringify(preset) !== JSON.stringify(newPreset)) {
            setPresetEdited(true)
            setPreset(null)
        }
    }, [newPreset])

    useEffect(() => {
        if (showFilters) {
            vFetch(`/customers/tags`, {
                cb: (res) => {
                    if (res.success)
                        setTags(res.tags.map((t) => t.tag).sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1)))
                },
            })
        }
    }, [showFilters])

    useEffect(() => {
        if (filters.length && !showFilters) {
            setShowFilters(true)
        }

        if (newPreset) {
            setNewPreset({ ...newPreset, value: filters })
        }
    }, [filters, showFilters])

    useEffect(() => {
        function listenToFilterOption(e) {
            closePopout(
                e,
                [`js-presets-filter-option`],
                setFilters,
                filters.map((f) => {
                    return { ...f, edit: false }
                })
            )
        }

        window.addEventListener('click', listenToFilterOption)
        return () => {
            window.removeEventListener('click', listenToFilterOption)
        }
    }, [filters])

    return (
        <div
            className={`bg-white dark:bg-darkaccent shadow-small duration-300 rounded ${
                showFilters ? `max-h-full` : 'max-h-[41px] overflow-hidden'
            } `}
        >
            <div
                className={`relative flex justify-between gap-[8px] p-[8px] ${
                    showFilters && 'border-b-[1px] border-offwhite dark:border-darkgrey'
                } `}
            >
                <input
                    className='js-searchbar-input px-[4px] dark:text-offwhite rounded bg-[whitesmoke] dark:bg-darkness'
                    type='text'
                    onChange={handleSearch}
                    value={search}
                    placeholder='Customer Search'
                />
                {!showFilters ? (
                    <div
                        className='relative w-[31px] h-[25px] rounded hover:bg-[whitesmoke] dark:hover:bg-darkness/50 cursor-pointer border-[2px] border-offwhite dark:border-darkgrey'
                        onClick={() => {
                            setShowFilters(!showFilters)
                        }}
                    >
                        <MdOutlineFilterList
                            size={'30px'}
                            className={`absolute top-[-1px] right-[2px] w-[23px] h-[23px] fill-darkgrey dark:fill-grey hover:fill-black dark:hover:fill-offwhite transition-all`}
                        />
                    </div>
                ) : (
                    <div className='flex gap-[16px]'>
                        <span
                            className='dark:text-offwhite hover:underline cursor-pointer'
                            onClick={() => {
                                setFilters([])
                                setPreset(null)
                                setShowFilters(false)
                            }}
                        >
                            Cancel
                        </span>
                        {filters.length > 0 && filters.some((f) => f.value) && (
                            <div className='flex gap-[16px]'>
                                <input
                                    className='dark:text-offwhite dark:bg-darkness rounded px-[4px] border-[1px] border-darkgrey dark:border-none'
                                    placeholder='Filter Preset Name'
                                    value={presetName}
                                    onChange={({ target }) => {
                                        setPresetName(target.value)
                                    }}
                                />
                                <button
                                    className='p-[8px] py-[0px] font-semibold text-white dark:text-black bg-blue dark:bg-accent rounded'
                                    onClick={handleSavePreset}
                                >
                                    Save Preset
                                </button>
                                {preset && !presetEdited && (
                                    <button
                                        className='p-[8px] py-[0px] font-semibold text-white bg-red rounded'
                                        onClick={handleDeletePreset}
                                    >
                                        Delete Preset
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className='flex flex-wrap p-[8px] gap-[8px]'>
                <div className='relative flex items-center gap-[8px] dark:text-white'>
                    {presetOptions.length > 0 && (
                        <div
                            className='flex items-center gap-[8px] border-[2px] border-darkgrey rounded p-[4px] hover:bg-[whitesmoke] dark:hover:bg-darkness/20 cursor-pointer'
                            onClick={() => {
                                setShowPresetOptions(!showPresetOptions)
                            }}
                        >
                            <span className='font-semibold text-[14px]'>{preset ? preset.name : 'Preset'}</span>
                            <AiFillCaretDown />
                            {showPresetOptions && (
                                <div className='absolute left-[0px] top-[calc(100%)] flex flex-col min-w-[97px] bg-white dark:bg-darkaccent border-[1px] border-darkgrey shadow-small'>
                                    {presetOptions.map((p) => (
                                        <span
                                            className='p-[8px] py-[4px] hover:bg-[whitesmoke] dark:hover:bg-darkgrey cursor-pointer whitespace-nowrap'
                                            onClick={() => {
                                                setPreset(p)
                                                setNewPreset(p)
                                                setFilters(p.value)
                                            }}
                                        >
                                            {p.name}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    <div
                        className='js-presets-filter-options-list relative flex items-center gap-[8px] border-[2px] border-darkgrey rounded p-[4px] hover:bg-[whitesmoke] dark:hover:bg-darkness/20 cursor-pointer'
                        onClick={() => {
                            setShowFilterOptions(showFilterOptions !== 'Filter List' ? 'Filter List' : null)
                        }}
                    >
                        <span className='font-semibold text-[14px]'>Add filter</span>
                        <AiFillCaretDown />
                        {showFilterOptions === 'Filter List' && (
                            <div className='absolute left-[0px] top-[calc(100%)] flex flex-col min-w-[97px] bg-white dark:bg-darkaccent border-[1px] border-darkgrey shadow-small'>
                                {filterOptions.map((f) => (
                                    <span
                                        className='p-[8px] py-[4px] hover:bg-[whitesmoke] dark:hover:bg-darkgrey cursor-pointer whitespace-nowrap'
                                        onClick={() => {
                                            setTimeout(() => {
                                                setFilters([...filters, newFilter(f)])
                                            }, 0)
                                        }}
                                    >
                                        {f}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {filters.map((f) => (
                    <div className='js-presets-filter-option flex items-center dark:text-offwhite'>
                        <div
                            className='js-filter-option-input relative flex gap-[4px] items-center rounded-full border-[1px] border-darkgrey hover:dark:border-offwhite px-[8px] pr-[24px] py-[2px] cursor-pointer'
                            onClick={(e) => {
                                if (
                                    isInParentButNotInnerContainer(
                                        e,
                                        'js-filter-option-input',
                                        'js-filter-option-tagged-option'
                                    )
                                )
                                    e.ctrlKey
                                        ? setFilters([...filters, newFilter(f.name)])
                                        : setFilters(
                                              filters.map((filter) =>
                                                  filter.id !== f.id ? filter : { ...f, edit: true }
                                              )
                                          )
                            }}
                        >
                            <h2 className='text-[12px] font-semibold'>{f.name}:</h2>
                            <span className='text-[12px]'>{f.value}</span>
                            <span
                                className='absolute text-center font-bold right-[2px] top-[2px] text-[12px] hover:text-[18px] hover:top-[-4px] h-[20px] w-[15px] cursor-pointer'
                                onClick={() => {
                                    setTimeout(() => {
                                        setFilters(filters.filter((of) => f.id !== of.id))
                                    }, 0)
                                }}
                            >
                                x
                            </span>
                            {f.edit && (
                                <form
                                    className='absolute top-[calc(100%+8px)] left-[0] bg-white dark:bg-darkaccent border-[1px] border-grey rounded p-[8px] cursor-auto'
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        setFilters(
                                            filters.map((filter) =>
                                                filter.id !== f.id ? filter : { ...f, edit: false }
                                            )
                                        )
                                    }}
                                >
                                    <label>{f.name}</label>
                                    <input
                                        className='dark:bg-darkness rounded px-[2px] border-[1px] border-darkgrey dark:border-none'
                                        value={filters.find((filter) => filter.id === f.id).value}
                                        onChange={(e) => {
                                            setFilters(
                                                filters.map((filter) =>
                                                    filter.id !== f.id ? filter : { ...f, value: e.target.value }
                                                )
                                            )
                                        }}
                                    />

                                    {f && f.name.toLowerCase().includes('tagged with') && (
                                        <div className='relative'>
                                            <div className='js-filter-option-tagged-option absolute top-[calc(100%+4px)] left-[0] bg-white dark:bg-darkaccent p-[8px] max-h-[200px] overflow-auto shadow-small w-[100%] rounded-b'>
                                                {tags
                                                    .filter((t) =>
                                                        f.value ? t.toLowerCase().includes(f.value.toLowerCase()) : true
                                                    )
                                                    .map((t) => (
                                                        <p
                                                            className='hover:bg-gray hover:bg-offwhite dark:hover:bg-darkgrey cursor-pointer p-[8px] py-[4px] rounded'
                                                            onClick={(e) => {
                                                                setFilters(
                                                                    filters.map((filter) =>
                                                                        filter.id !== f.id
                                                                            ? filter
                                                                            : { ...f, edit: false, value: t }
                                                                    )
                                                                )
                                                            }}
                                                        >
                                                            {t}
                                                        </p>
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                </form>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
