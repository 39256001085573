import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'
import * as auditService from '../../audit/api/service'
import { CompanyFilters, CompanyTaskFilters } from '../Procurements.types'
import { UserInit } from '../../users/users.types'

// queries/companies.ts

export const companies = createQueryKeys('companies', {
    productTypes: () => ({
        queryKey: ['types'],
        queryFn: (context) => service.getProductTypes(),
    }),
    shopifyVendors: () => ({
        queryKey: ['vendors'],
        queryFn: (context) => service.getShopifyVendors(),
    }),
    productAuditHistory: (table, record_id, filters) => ({
        queryKey: [table, record_id, filters],
        queryFn: (context) => {
            return auditService.getAuditHistory(table, record_id, filters)
        },
    }),
    shopifySkus: () => ({
        queryKey: ['shopify_skus'],
        queryFn: (context) => service.getShopifySkus(),
    }),
    product: (id) => ({
        queryKey: [id],
        queryFn: (context) => service.getProduct(id),
    }),
    detail: (companyId: number | undefined) => ({
        queryKey: [{ companyId }],
        queryFn: (context) => service.getCompany({ companyId }),
        contextQueries: {
            events: (users: UserInit[]) => ({
                queryKey: ['events'],
                queryFn: (context) => service.getEvents({ companyId, users }),
            }),
            incomingEmails: ({ companyEmail, contactList }: { companyEmail: string; contactList: any[] }) => ({
                queryKey: ['incomingEmails'],
                queryFn: (context) => service.getIncomingEmails({ companyEmail, contactList }),
            }),
            tasks: (filters: CompanyTaskFilters) => ({
                queryKey: [{ filters }],
                queryFn: (context) => service.getTasks({ filters }),
            }),
            products: (filters?: any) => ({
                queryKey: [{ filters }],
                queryFn: (context) => service.getProducts(filters),
                contextQueries: {
                    alerts: () => ({
                        queryKey: ['alerts'],
                        queryFn: (context) => service.getAlerts({ companyId }),
                    }),
                },
            }),

            contacts: () => ({
                queryKey: ['contacts'],
                queryFn: (context) => service.getContacts({ companyId }),
            }),
            files: () => ({
                queryKey: ['files'],
                queryFn: (context) => service.getFiles({ companyId }),
            }),
            emails: (search: string) => ({
                queryKey: ['emails'],
                queryFn: (context) => service.getEmails({ companyId, search }),
            }),
            scores: () => ({
                queryKey: ['scores'],
                queryFn: (context) => service.getScores({ companyId }),
            }),
        },
    }),
    scoreSetup: () => ({
        queryKey: ['groups'],
        queryFn: () => service.getScoreSetupGroups(),
        contextQueries: {
            options: () => ({
                queryKey: ['options'],
                queryFn: (context) => service.getScoreSetupOptions(),
            }),
        },
    }),
    list: (filters: CompanyFilters) => ({
        queryKey: [{ filters }],
        queryFn: (context) => service.getCompanies({ filters }),
        contextQueries: {
            search: (search: string) => ({
                queryKey: [search],
                queryFn: (context) => service.getSearchCompanies({ filters, search }),
            }),
        },
    }),
    parent: (parentCompany: string) => ({
        queryKey: [{ parentCompany }],
        queryFn: (context) => service.getParentCompany(parentCompany),
        contextQueries: {
            events: (parentCompany, companyNameMap: any) => ({
                queryKey: ['events'],
                queryFn: (context) => service.getParentCompanyEvents(parentCompany, companyNameMap),
            }),
            incomingEmails: ({ contactList, companyNameMap }: { contactList: any[]; companyNameMap: any }) => ({
                queryKey: [contactList],
                queryFn: (context) => service.getIncomingParentCompanyEmails(contactList, companyNameMap),
            }),
            tasks: (parentCompany: string, companyNameMap: any) => ({
                queryKey: ['tasks'],
                queryFn: (context) => service.getParentCompanyTasks(parentCompany, companyNameMap),
            }),
            emails: (search: string, companyNameMap: any) => ({
                queryKey: ['emails'],
                queryFn: (context) => service.getParentCompanyEmails(parentCompany, companyNameMap, search),
            }),
            contacts: (companyNameMap: any) => ({
                queryKey: ['contacts'],
                queryFn: (context) => service.getParentCompanyContacts(parentCompany, companyNameMap),
            }),
            files: (companyNameMap: any) => ({
                queryKey: ['files'],
                queryFn: (context) => service.getParentCompanyFiles(parentCompany, companyNameMap),
            }),
        },
    }),
})
export const tasks = createQueryKeys('tasks', {
    detail: (taskId: string) => [taskId],
    list: (filters: CompanyTaskFilters) => ({
        queryKey: [{ filters }],
        queryFn: (context) => service.getTasks({ filters }),
        contextQueries: {
            search: (search: string | undefined) => ({
                queryKey: [search],
                queryFn: (context) =>
                    service.getSearchTasks({
                        filters,
                        search,
                    }),
            }),
        },
    }),
    calendar: (filters: CompanyTaskFilters) => ({
        queryKey: [{ filters }],
        queryFn: (context) => service.getTasksCalendar({ filters }),
    }),
})

export const procurementQueries = mergeQueryKeys(companies, tasks)
