import { useEffect, useRef, useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { vFetch } from '../helpers'

export default function GroupModal({ selectedGroup = {}, setSelectedGroup, setNeedsRefresh }) {
    const modalRef = useRef()
    const [tempGroup, setTempGroup] = useState({ ...selectedGroup })
    const [showModal, setShowModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [deleting, setDeleting] = useState(false)

    useEffect(() => {
        if (selectedGroup.name || selectedGroup.is_new) {
            setShowModal(true)
            setTempGroup({ ...selectedGroup })
            setSaving(false)
            setDeleting(false)
        } else {
            setShowModal(false)
            setTempGroup({})
            setSaving(false)
            setDeleting(false)
        }
    }, [selectedGroup])
    const handleChange = ({ target }) => {
        if (target.type === 'checkbox') {
            setTempGroup((prev) => ({
                ...prev,
                [target.name]: target.checked,
            }))
        } else {
            setTempGroup((prev) => ({
                ...prev,
                [target.name]: target.value,
            }))
        }
    }
    const handleSubmit = () => {
        setSaving(true)
        vFetch(`/tagManager/groups`, {
            method: selectedGroup.is_new ? 'POST' : 'PUT',
            body: JSON.stringify({
                name: tempGroup.name,
                type: tempGroup.type,
                disjunctive: tempGroup.disjunctive ? true : false,
                previous_name: selectedGroup.name,
            }),
            cb: (res) => {
                if (res.success) {
                    setNeedsRefresh(true)
                    setShowModal(false)
                    setTempGroup({})
                }
                setSaving(false)
            },
            catchCb: (err) => setSaving(false),
        })
    }
    const handleDelete = () => {
        setDeleting(true)
        vFetch(`/tagManager/groups`, {
            method: 'DELETE',
            body: JSON.stringify({
                name: selectedGroup.name,
            }),
            cb: (res) => {
                if (res.success) {
                    setNeedsRefresh(true)
                    setShowModal(false)
                    setTempGroup({})
                }
                setDeleting(false)
            },
            catchCb: (err) => setDeleting(false),
        })
    }
    useEffect(() => {
        const listenToWindow = (e) => {
            if (modalRef.current === e.target) {
                setSelectedGroup({})
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => {
            window.removeEventListener('click', listenToWindow)
            setSaving(false)
            setDeleting(false)
        }
    }, [])
    return (
        <>
            {showModal && (
                <div
                    ref={modalRef}
                    class='fixed top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] z-[50] grid place-items-center'
                >
                    <div className='p-[16px] bg-white dark:bg-darkaccent rounded-[4px] relative'>
                        <button
                            onClick={() => setSelectedGroup(false)}
                            className='absolute text-[32px] leading-[1] font-bold text-red dark:text-lightred top-0 right-[8px]'
                        >
                            &times;
                        </button>
                        <div className='flex flex-col gap-[4px] w-[600px] mb-[8px]'>
                            <div className='flex items-center gap-[4px] relative'>
                                <label
                                    htmlFor='name'
                                    className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                                >
                                    Name
                                </label>
                                <BsInfoCircle className='dark:text-offwhite w-[12px] h-[12px] peer' />
                                <div className='hidden absolute top-[20px] z-[5] peer-hover:block p-[8px] bg-white dark:text-offwhite dark:bg-darkaccent rounded-[4px] shadow-small'>
                                    The name that will be displayed on collection pages, and also used for adding tags
                                    to the grouping.
                                </div>
                            </div>
                            <input
                                className='py-[2px] px-[4px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white focus:outline-none'
                                id='name'
                                name='name'
                                value={tempGroup.name}
                                onChange={handleChange}
                                type='text'
                            />
                        </div>
                        <div className='flex flex-col gap-[4px] w-[600px]'>
                            <label
                                htmlFor='type'
                                className='font-bold text-darkgrey dark:text-offwhite uppercase text-[12px]'
                            >
                                Type
                            </label>
                            <select
                                className='py-[2px] px-[4px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white focus:outline-none'
                                id='type'
                                name='type'
                                value={tempGroup.type}
                                onChange={handleChange}
                            >
                                <option value=''>Select A Type</option>
                                <option value='checkbox'>Checkbox</option>
                                <option value='radio'>Radio</option>
                            </select>
                        </div>
                        <div className='flex items-end justify-between border border-grey dark:border-darkgrey h-fit mt-[20px] rounded-[4px] p-[4px] px-[8px] gap-[16px] w-[600px]'>
                            <label
                                htmlFor='disjunctive'
                                className='mb-[2px] w-full cursor-pointer font-bold text-darkgrey dark:text-offwhite uppercase leading-[1] text-[14px]'
                            >
                                Disjunctive
                            </label>
                            <input
                                className='w-[20px] h-[20px] bg-lightgrey dark:bg-faintplus rounded-[4px] dark:text-white'
                                id='disjunctive'
                                name='disjunctive'
                                checked={tempGroup.disjunctive == 1}
                                onChange={handleChange}
                                type='checkbox'
                            />
                        </div>
                        <div className='flex justify-center gap-[8px] min-h-[32px] mt-[16px] relative'>
                            <button
                                onClick={handleSubmit}
                                className='mx-auto block bg-blue text-white dark:bg-accent dark:text-darkness rounded-[4px] py-[4px] px-[16px] font-bold uppercase'
                            >
                                {saving ? 'Saving...' : 'Save Changes'}
                            </button>
                            {!selectedGroup.is_new && (
                                <button
                                    onClick={handleDelete}
                                    className='bg-red absolute text-[12px] bottom-0 right-0 text-white dark:bg-lightred rounded-[4px] py-[4px] px-[16px] font-bold uppercase'
                                >
                                    {deleting ? 'Deleting...' : 'Delete Group'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
