import { useState } from 'react'
import { MetafieldProps } from '../ProductSetup.types'

export default function Features({ product, setProduct, handleUpdateMetafield, edited }: MetafieldProps) {
    const [featureText, setFeatureText] = useState((product.features || []).join('\n'))
    const handleChange = ({ target }: { target: HTMLTextAreaElement }) => {
        setFeatureText(target.value)
        const values = target.value.split('\n')
        const currentFeatures = values.map((v) => v.trim()).filter((v) => v.length)
        setProduct((previousState) => ({
            ...previousState,
            features: currentFeatures,
        }))
    }

    return (
        <>
            <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] shadow-small overflow-hidden'>
                <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                    Features
                </p>
                <textarea
                    className='w-full block max-w-none border-none p-[8px] rounded-[4px] dark:text-white bg-lightgrey dark:bg-faintplus focus:outline-none'
                    spellCheck='true'
                    onChange={handleChange}
                    value={featureText}
                ></textarea>
                <p className='dark:text-offwhite dark:font-normal font-bold text-[14px] mt-[4px]'>
                    * Each feature must be separated by a newline
                </p>
                {edited && (
                    <button
                        className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                        onClick={() =>
                            handleUpdateMetafield([
                                {
                                    ownerId: `gid://shopify/Product/${product.id}`,
                                    namespace: 'custom',
                                    key: 'features',
                                    type: 'list.single_line_text_field',
                                    value: JSON.stringify(product.features),
                                },
                            ])
                        }
                    >
                        Save Changes
                    </button>
                )}
            </div>
            {product.features && product.features.length > 0 && (
                <>
                    <h2 className='dark:text-offwhite font-light uppercase text-[24px] my-[16px]'>Features Preview</h2>
                    <div className='bg-white description-preview description-preview--full w-fit !m-0 dark:opacity-[0.9] dark:text-black'>
                        <div className='pdp__features__row'>
                            <div className='pdp__features__left'>
                                <ul className='pdp__features__left__list'>
                                    {product.features.slice(0, Math.ceil(product.features.length / 2)).map((f) => (
                                        <li dangerouslySetInnerHTML={{ __html: f }}></li>
                                    ))}
                                </ul>
                            </div>
                            <div className='pdp__features__right'>
                                <ul className='pdp__features__right__list'>
                                    {product.features.slice(Math.ceil(product.features.length / 2)).map((f) => (
                                        <li dangerouslySetInnerHTML={{ __html: f }}></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
