import { BiSolidBook } from 'react-icons/bi'
import { FaBookOpen, FaCircle, FaCode, FaGraduationCap, FaTimes } from 'react-icons/fa'
import { HiMiniQuestionMarkCircle, HiQuestionMarkCircle } from 'react-icons/hi2'
import { IoDocumentTextSharp, IoPerson, IoRemoveOutline } from 'react-icons/io5'
import { FiPackage } from 'react-icons/fi'
import { useEffect, useRef, useState } from 'react'
import QuestionsAndAnswersView from './views/QuestionsAndAnswersView'
import { MdAdminPanelSettings } from 'react-icons/md'
import QuestionsView from './views/QuestionWithAnswersView'
import { useDispatch, useSelector } from 'react-redux'
import { setSettings } from '../redux/settings'
import { FaGear } from 'react-icons/fa6'
import CreateSubmitAQuestionModal from './components/SubmitAQuestionModal'
import MainArticleView from './views/MainArticleView'

export default function KnowledgeScreen() {
    const modalRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const [view, setView] = useState('qna')
    const { showKnowledgeScreen } = useSelector((state: any) => state.settings)
    const [openSubmitQuestionModal, setOpenSubmitQuestionModal] = useState(false)

    const [selectedQuestion, setSelectedQuestion] = useState<undefined | any>()

    useEffect(() => {
        if (showKnowledgeScreen) {
            document.body.classList.add('overflow-y-hidden')
        }
        return () => document.body.classList.remove('overflow-y-hidden')
    }, [showKnowledgeScreen])

    // if user clicks on margin around page, it will close
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                dispatch(setSettings({ showKnowledgeScreen: false }))
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='dark:text-offwhite fixed top-0 left-0 w-full h-[100vh] bg-[rgb(0,0,0,0.5)] flex items-center justify-center z-[50]'
        >
            <div className='flex relative rounded bg-white dark:bg-darkaccent w-[95%] h-[95%]'>
                <button
                    className='absolute top-0 right-0'
                    onClick={() => dispatch(setSettings({ showKnowledgeScreen: false }))}
                >
                    <FaTimes className='text-red w-7 h-7' />
                </button>
                <div className='flex flex-col w-[300px] shrink-0 h-full'>
                    <div className='text-2xl border-b  border-lightgrey dark:border-darkgrey p rounded-tl p-8 shrink-0'>
                        <div className='flex items-center gap-2'>
                            <FaGraduationCap className='w-8 text-blue dark:text-accent' />{' '}
                            <div className='font-bold hover:cursor-default'>FPKnowledge</div>
                        </div>
                        <div className='w-[70px] h-[2px] bg-white'></div>
                        <div className='text-sm font-semibold'>
                            <div className='flex items-center gap-1 pt-2 mb-1' onClick={() => setView('qna')}>
                                <HiMiniQuestionMarkCircle className='w-6 text-blue dark:text-accent' />{' '}
                                <button>Questions & Answers</button>
                            </div>
                            {/* <div className='flex items-center gap-1 mb-1'>
                                <IoDocumentTextSharp className='w-6  text-accent' /> <button>Resources</button>
                            </div>
                            <div className='flex items-center gap-1'>
                                <BiSolidBook className='w-6  text-accent' /> <button>Team Directory</button>
                            </div> */}
                        </div>
                    </div>
                    <div className='border-b  border-lightgrey dark:border-darkgrey p-8 h-full'>
                        <h2 className='font-bold mb-3 hover:cursor-default'>Departments (0)</h2>
                        {/* <div className='text-sm font-semibold'>
                            <div className='flex items-center gap-1 mb-1'>
                                <IoPerson className='w-6  text-accent' />{' '}
                                <p className='cursor-default'>Customer Service</p>
                            </div>
                            <div className='flex items-center gap-1 mb-1'>
                                <FiPackage className='w-6  text-accent' />{' '}
                                <p className='cursor-default'>Order Fulfillment</p>
                            </div>
                            <div className='flex items-center gap-1 mb-1'>
                                <FaBookOpen className='w-6  text-accent' /> <p className='cursor-default'>Accounting</p>
                            </div>
                            <div className='flex items-center gap-1'>
                                <FaCode className='w-6  text-accent' /> <p className='cursor-default'>Development</p>
                            </div>
                        </div> */}
                    </div>
                    <div className='text-sm font-semibold rounded-bl p-8 shrink-0'>
                        <div className='flex items-center gap-1 mb-1'>
                            <IoPerson className='w-6 text-blue  dark:text-accent' />{' '}
                            <button className='py-2'>My Contributions TEST</button>
                        </div>
                        <div className='flex items-center gap-1 mb-1'>
                            <FaGear className='w-6 text-blue dark:text-accent' /> <button>Admin Panel</button>
                        </div>
                    </div>
                </div>
                <div className='h-full w-full border-l border-r  border-lightgrey dark:border-darkgrey pw-full p-8'>
                    {/* <div className='fixed z-50 min-w-[400px] bottom-[3.5%] left-1/2 translate-x-[-50%] w-[62%]'> */}
                    <div className='fixed z-50 min-w-[400px] bottom-[2.5%] left-1/2 translate-x-[-50%] w-[600px]'>
                        {openSubmitQuestionModal && (
                            <CreateSubmitAQuestionModal openModal={setOpenSubmitQuestionModal} />
                        )}
                    </div>
                    {view === 'qna' && (
                        <QuestionsAndAnswersView setView={setView} setSelectedQuestion={setSelectedQuestion} />
                    )}
                    {view === 'question view' && (
                        <QuestionsView
                            setView={setView}
                            setSelectedQuestion={setSelectedQuestion}
                            selectedQuestion={selectedQuestion}
                        />
                    )}
                    {view === 'article view' && <MainArticleView setView={setView} />}
                </div>
                <div className='text-sm font-semibold flex flex-col w-[300px] shrink-0 h-full'>
                    <div className='border-b  border-lightgrey dark:border-darkgrey p rounded-tr p-8 shrink-0'>
                        <button
                            onClick={() => {
                                setOpenSubmitQuestionModal(true)
                            }}
                            className=' mb-1'
                        >
                            Submit A New Question
                        </button>
                        <button className=' mb-1'>Create A New Article</button>
                        {/* <button className=' mb-1'>Open A Ticket</button> */}
                    </div>
                    <div className='rounded-br p-8 h-full'>
                        <button className=' mb-1'>Using the knowledge module</button>
                        {/* <button className=' mb-1'>Knowledge approval process</button>
                        <button className=' mb-1'>Time off requests</button>
                        <br></br>
                        <button>Gusto</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
