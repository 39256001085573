import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useDeleteAlert } from '../../api/useQueries'
import { useContext, useState } from 'react'
import { ProcurementContext, ProcurementContextType } from '../../helpers'

export function ProductAlert({ alert }: { alert: any }) {
    const user = useSelector((state: any) => state.user)
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const [showDelete, setShowDelete] = useState(false)

    const deleteAlert = useDeleteAlert(selectedCompany.id)

    const handleDeleteAlert = () => {
        deleteAlert.mutate(alert, {
            onSuccess: () => {
                setShowDelete(false)
            },
        })
    }

    return (
        <div
            className={`p-[4px] px-[8px] flex justify-between rounded-[4px] border border-fire bg-fire/10 font-bold text-[14px] ${
                deleteAlert.isPending ? 'opacity-[0.5] pointer-events-none' : ''
            }`}
        >
            <p>{alert.message}</p>
            {user.id === alert.user_id && !showDelete && (
                <button onClick={() => setShowDelete(true)}>
                    <FaTimes />
                </button>
            )}
            {showDelete && (
                <div className='flex gap-[8px] self-center justify-self-end'>
                    <button
                        onClick={handleDeleteAlert}
                        className={`${
                            deleteAlert.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase`}
                    >
                        Delete
                    </button>
                    <button
                        onClick={() => setShowDelete(false)}
                        className={`${
                            deleteAlert.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase`}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    )
}
