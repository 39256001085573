import { useEffect, useState } from 'react'
import { LoadingGear, vFetch } from '../helpers'
import Period from './components/Period'
import Settings from './components/Settings'
const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

export default function MoversScreen() {
    const [savedSettings, setSavedSettings] = useState(
        localStorage.getItem('top_movers_settings')
            ? JSON.parse(localStorage.getItem('top_movers_settings'))
            : {
                  period: 7,
                  search: '',
                  sortBy: 'sales-percent-desc',
              }
    )
    const [diffData, setDiffData] = useState([])
    const [page, setPage] = useState(0)
    const [currentSlice, setCurrentSlice] = useState([])
    const LIMIT = 50
    const [period, setPeriod] = useState(savedSettings.period)
    const [search, setSearch] = useState(savedSettings.search)
    const [sortBy, setSortBy] = useState(savedSettings.sortBy)
    const [loading, setLoading] = useState(true)
    const [presets, setPresets] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedPreset, setSelectedPreset] = useState(-1)
    const [needsRefresh, setNeedsRefresh] = useState(true)
    const sortFunc = (a, b, type, direction) => {
        const diffName = type === 'sales' ? 'diff' : 'trafficDiff'
        const percentName = type === 'sales' ? 'percentChange' : 'trafficPercentChange'
        const da = a[diffName]
        const db = b[diffName]
        const pda = a[percentName]
        const pdb = b[percentName]
        if (direction === 'desc') {
            if (pda == -100 && pdb == -100) {
                return da - db
            }
            return pda - pdb
        } else {
            if (pda == Infinity && pdb == Infinity) {
                return db - da
            }
            return pdb - pda
        }
    }
    const diffSort = (a, b, type, direction) => {
        const diffName = type === 'sales' ? 'diff' : 'trafficDiff'
        return direction === 'desc' ? a[diffName] - b[diffName] : b[diffName] - a[diffName]
    }
    useEffect(() => {
        if (needsRefresh) {
            setNeedsRefresh(false)
            vFetch(`/presets?belongs_to=top-movers`, {
                cb: (res) => {
                    if (res.success) {
                        if (savedSettings) {
                            const foundPresetIndex = res.presets.findIndex((p) => {
                                const presetPeriod = JSON.parse(p.value).period
                                const presetSearch = JSON.parse(p.value).search
                                const presetSortBy = JSON.parse(p.value).sortBy
                                return (
                                    JSON.stringify({
                                        period: presetPeriod,
                                        search: presetSearch,
                                        sortBy: presetSortBy,
                                    }) ==
                                    JSON.stringify({
                                        period,
                                        search,
                                        sortBy,
                                    })
                                )
                            })
                            setSelectedPreset(foundPresetIndex)
                        }
                        setPresets(res.presets)
                    }
                },
            })
        }
    }, [needsRefresh])
    const abortController = new AbortController()
    useEffect(() => {
        if (period) {
            setLoading(true)
            setPage(0)
            vFetch(`/orders/movers?period=${period}`, {
                abortController,
                cb: (res) => {
                    if (res.success) {
                        setDiffData(res.diff)
                        setLoading(false)
                    }
                },
            })
        }
        return () => abortController.abort()
    }, [period])
    useEffect(() => {
        const filteredDiffData = [...diffData].filter(
            (item) =>
                item.product.toLowerCase().includes(search.toLowerCase()) ||
                item.product.toLowerCase().includes(search.toLowerCase())
        )
        if (sortBy.includes('product')) {
            if (sortBy.includes('desc')) {
                setCurrentSlice(
                    [...filteredDiffData]
                        .sort((a, b) => (a.product.split(' :: ')[1] < b.product.split(' :: ')[1] ? -1 : 1))
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            } else {
                setCurrentSlice(
                    [...filteredDiffData]
                        .sort((a, b) => (a.product.split(' :: ')[1] > b.product.split(' :: ')[1] ? -1 : 1))
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            }
        }
        if (sortBy.includes('sku')) {
            if (sortBy.includes('desc')) {
                setCurrentSlice(
                    [...filteredDiffData]
                        .sort((a, b) => (a.product.split(' :: ')[0] < b.product.split(' :: ')[0] ? -1 : 1))
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            } else {
                setCurrentSlice(
                    [...filteredDiffData]
                        .sort((a, b) => (a.product.split(' :: ')[0] > b.product.split(' :: ')[0] ? -1 : 1))
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            }
        }
        if (sortBy.includes('results')) {
            if (sortBy.includes('desc')) {
                setCurrentSlice(
                    [...filteredDiffData]
                        .sort(
                            (a, b) =>
                                (b.scrape_result ? b.scrape_result.length : 0) -
                                (a.scrape_result ? a.scrape_result.length : 0)
                        )
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            } else {
                setCurrentSlice(
                    [...filteredDiffData]
                        .sort(
                            (a, b) =>
                                (a.scrape_result ? a.scrape_result.length : 0) -
                                (b.scrape_result ? b.scrape_result.length : 0)
                        )
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            }
        }
        if (sortBy.includes('sales')) {
            if (sortBy.includes('percent')) {
                if (sortBy.includes('desc')) {
                    setCurrentSlice(
                        [...filteredDiffData]
                            .sort((a, b) => sortFunc(a, b, 'sales', 'asc'))
                            .slice(LIMIT * page, LIMIT * (page + 1))
                    )
                } else {
                    setCurrentSlice(
                        [...filteredDiffData]
                            .sort((a, b) => sortFunc(a, b, 'sales', 'desc'))
                            .slice(LIMIT * page, LIMIT * (page + 1))
                    )
                }
            } else {
                if (sortBy.includes('desc')) {
                    setCurrentSlice(
                        [...filteredDiffData]
                            .sort((a, b) => diffSort(a, b, 'sales', 'asc'))
                            .slice(LIMIT * page, LIMIT * (page + 1))
                    )
                } else {
                    setCurrentSlice(
                        [...filteredDiffData]
                            .sort((a, b) => diffSort(a, b, 'sales', 'desc'))
                            .slice(LIMIT * page, LIMIT * (page + 1))
                    )
                }
            }
        }
        if (sortBy.includes('traffic')) {
            if (sortBy.includes('percent')) {
                if (sortBy.includes('desc')) {
                    setCurrentSlice(
                        [...filteredDiffData]
                            .sort((a, b) => sortFunc(a, b, 'traffic', 'asc'))
                            .slice(LIMIT * page, LIMIT * (page + 1))
                    )
                } else {
                    setCurrentSlice(
                        [...filteredDiffData]
                            .sort((a, b) => sortFunc(a, b, 'traffic', 'desc'))
                            .slice(LIMIT * page, LIMIT * (page + 1))
                    )
                }
            } else {
                if (sortBy.includes('desc')) {
                    setCurrentSlice(
                        [...filteredDiffData]
                            .sort((a, b) => diffSort(a, b, 'traffic', 'asc'))
                            .slice(LIMIT * page, LIMIT * (page + 1))
                    )
                } else {
                    setCurrentSlice(
                        [...filteredDiffData]
                            .sort((a, b) => diffSort(a, b, 'traffic', 'desc'))
                            .slice(LIMIT * page, LIMIT * (page + 1))
                    )
                }
            }
        }
    }, [diffData, sortBy, page, search])
    useEffect(() => {
        localStorage.setItem('top_movers_settings', JSON.stringify({ search, sortBy, period }))
        if (presets && presets.length > 0) {
            const foundPresetIndex = presets.findIndex((p) => {
                const presetPeriod = JSON.parse(p.value).period
                const presetSearch = JSON.parse(p.value).search
                const presetSortBy = JSON.parse(p.value).sortBy
                return (
                    JSON.stringify({
                        period: presetPeriod,
                        search: presetSearch,
                        sortBy: presetSortBy,
                    }) ==
                    JSON.stringify({
                        period,
                        search,
                        sortBy,
                    })
                )
            })
            setSelectedPreset(foundPresetIndex)
        }
    }, [sortBy, search, period])
    const handleSetSortBy = (type) => {
        setPage(0)
        if (type === 'product') {
            setSortBy((prev) => (prev === 'product-desc' ? 'product-asc' : 'product-desc'))
        }
        if (type === 'sku') {
            setSortBy((prev) => (prev === 'sku-desc' ? 'sku-asc' : 'sku-desc'))
        }
        if (type === 'results') {
            setSortBy((prev) => (prev === 'results-desc' ? 'results-asc' : 'results-desc'))
        }
        if (type === 'sales-diff') {
            setSortBy((prev) => {
                if (prev === 'sales-diff-asc') {
                    return 'sales-diff-desc'
                } else {
                    return 'sales-diff-asc'
                }
            })
        }
        if (type === 'sales-percent') {
            setSortBy((prev) => {
                if (prev === 'sales-percent-asc') {
                    return 'sales-percent-desc'
                } else {
                    return 'sales-percent-asc'
                }
            })
        }
        if (type === 'traffic-diff') {
            setSortBy((prev) => {
                if (prev === 'traffic-diff-asc') {
                    return 'traffic-diff-desc'
                } else {
                    return 'traffic-diff-asc'
                }
            })
        }
        if (type === 'traffic-percent') {
            setSortBy((prev) => {
                if (prev === 'traffic-percent-asc') {
                    return 'traffic-percent-desc'
                } else {
                    return 'traffic-percent-asc'
                }
            })
        }
    }
    const handleSetPreset = (preset, index) => {
        setSelectedPreset(index)
        const parsedValue = typeof preset.value !== 'object' ? JSON.parse(preset.value) : preset.value
        setSearch(parsedValue.search)
        setPeriod(parsedValue.period)
        setSortBy(parsedValue.sortBy)
    }
    const handleAddRemoveRow = ({ target }, row) => {
        if (selectedRows.find((item) => item.gid === row.gid)) {
            setSelectedRows((prev) => [...prev].filter((item) => item.gid !== row.gid))
        } else {
            setSelectedRows((prev) => [
                ...prev,
                {
                    gid: row.gid,
                    price: row.price,
                    search_term: row.search_term,
                    match_values: row.match_values,
                },
            ])
        }
    }
    const handleSetScrape = ({ target }, index) => {
        if (target.name === 'match') {
            setSelectedRows((rows) => {
                const newRows = [...rows]
                if (newRows[index].match_values.join(', ').length > target.value.length) {
                    newRows[index].match_values = target.value
                        .split(/[ ,]+/)
                        .map((v) => v.trim())
                        .filter((v) => v.length)
                } else {
                    newRows[index].match_values = target.value.split(/[ ,]+/).map((v) => v.trim())
                }
                return newRows
            })
        } else {
            setSelectedRows((rows) => {
                const newRows = [...rows]
                newRows[index].search_term = target.value

                return newRows
            })
        }
    }
    const scrapeSelected = () => {
        vFetch(`/scrape`, {
            method: 'POST',
            body: JSON.stringify({
                scrape_items: selectedRows,
            }),
            cb: (res) => setSelectedRows([]),
        })
    }
    return (
        <div>
            <>
                <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Top Movers</h1>
                {selectedRows.length > 0 && (
                    <button
                        className='fixed z-[500] top-[50%] right-0 bg-blue dark:bg-accent dark:text-darkness dark:shadow-cool font-bold text-white py-[4px] px-[16px]'
                        onClick={scrapeSelected}
                    >
                        SCRAPE
                    </button>
                )}
                <div className='grid grid-cols-[32px_0.5fr_1fr_120px_120px_120px_120px] dark:bg-darkaccent shadow-small rounded-[4px] my-[8px]'>
                    <div className='flex col-span-full p-[8px] border-b border-grey dark:border-darkgrey dark:text-offwhite'>
                        <input
                            className='focus:outline-none w-full bg-transparent'
                            type='text'
                            placeholder='Search'
                            value={search}
                            onChange={({ target }) => setSearch(target.value)}
                        />
                        <Period period={period} setPeriod={setPeriod} />
                        <Settings
                            search={search}
                            period={period}
                            sortBy={sortBy}
                            selectedPreset={presets[selectedPreset]}
                            setNeedsRefresh={setNeedsRefresh}
                        />
                    </div>
                    {presets && presets.length > 0 && (
                        <div className='flex col-span-full p-[8px] border-b border-grey dark:border-darkgrey gap-[8px]'>
                            {presets.map((p, index) => (
                                <button
                                    className={`py-[2px] px-[8px] rounded-[99vh] text-[13px] font-bold ${
                                        selectedPreset === index
                                            ? 'bg-blue dark:bg-accent dark:text-darkness text-white font-bold'
                                            : 'bg-lightgrey dark:text-offwhite dark:bg-darkness'
                                    }`}
                                    onClick={() => handleSetPreset(p, index)}
                                >
                                    {p.name}
                                </button>
                            ))}
                        </div>
                    )}
                    {loading ? (
                        <div className='w-full h-[80vh] grid place-items-center col-span-full'>
                            <LoadingGear />
                        </div>
                    ) : (
                        <>
                            <div className='sticky top-[52px] z-[5] items-end col-span-full grid grid-cols-[32px_1fr_1fr_100px_120px_100px_120px_100px_120px_100px] border-b border-lightgrey dark:border-darkgrey dark:bg-darkness bg-blue'>
                                <p className='font-[100]'></p>
                                <div className='p-[8px] grid grid-cols-2 col-span-2'>
                                    <p className='font-bold text-[13px] text-accent uppercase col-span-2 border-b border-lightgrey dark:border-grey'>
                                        Product Details
                                    </p>
                                    <p
                                        className={`text-[13px] uppercase select-none cursor-pointer ${
                                            sortBy.includes('product') ? 'text-fire font-black' : 'text-white font-bold'
                                        }`}
                                        onClick={() => handleSetSortBy('product')}
                                    >
                                        Product
                                        {sortBy.includes('product') && (
                                            <span className='text-[16px] leading-[1px] ml-[4px] inline-block'>
                                                ({sortBy.includes('asc') ? '↑' : '↓'})
                                            </span>
                                        )}
                                    </p>
                                    <p
                                        className={`text-[13px] uppercase select-none cursor-pointer ${
                                            sortBy.includes('sku') ? 'text-fire font-black' : 'text-white font-bold'
                                        }`}
                                        onClick={() => handleSetSortBy('sku')}
                                    >
                                        SKU
                                        {sortBy.includes('sku') && (
                                            <span className='text-[16px] leading-[1px] ml-[4px] inline-block'>
                                                ({sortBy.includes('asc') ? '↑' : '↓'})
                                            </span>
                                        )}
                                    </p>
                                </div>
                                <div className='p-[8px] grid grid-cols-2 col-span-2'>
                                    <p className='font-bold text-[13px] text-accent uppercase col-span-2 border-b border-lightgrey dark:border-grey'>
                                        Sales vs. Prev Period
                                    </p>
                                    <p
                                        className={`text-[13px] uppercase select-none cursor-pointer ${
                                            sortBy.includes('sales-diff')
                                                ? 'text-fire font-black'
                                                : 'text-white font-bold'
                                        }`}
                                        onClick={() => handleSetSortBy('sales-diff')}
                                    >
                                        Order #
                                        {sortBy.includes('sales-diff') && (
                                            <span className='text-[16px] leading-[1px] ml-[4px] inline-block'>
                                                ({sortBy.includes('asc') ? '↑' : '↓'})
                                            </span>
                                        )}
                                    </p>
                                    <p
                                        className={`text-[13px] uppercase select-none cursor-pointer ${
                                            sortBy.includes('sales-percent')
                                                ? 'text-fire font-black'
                                                : 'text-white font-bold'
                                        }`}
                                        onClick={() => handleSetSortBy('sales-percent')}
                                    >
                                        Order %
                                        {sortBy.includes('sales-percent') && (
                                            <span className='text-[16px] leading-[1px] ml-[4px] inline-block'>
                                                ({sortBy.includes('asc') ? '↑' : '↓'})
                                            </span>
                                        )}
                                    </p>
                                </div>
                                <div className='p-[8px] grid grid-cols-2 col-span-2'>
                                    <p className='font-bold text-[13px] text-accent uppercase col-span-2 border-b border-lightgrey dark:border-grey'>
                                        Traffic vs. Prev Period
                                    </p>
                                    <p
                                        className={`text-[13px] uppercase select-none cursor-pointer ${
                                            sortBy.includes('traffic-diff')
                                                ? 'text-fire font-black'
                                                : 'text-white font-bold'
                                        }`}
                                        onClick={() => handleSetSortBy('traffic-diff')}
                                    >
                                        Session #
                                        {sortBy.includes('traffic-diff') && (
                                            <span className='font-medium text-[16px] leading-[1px] ml-[4px] inline-block'>
                                                ({sortBy.includes('asc') ? '↑' : '↓'})
                                            </span>
                                        )}
                                    </p>
                                    <p
                                        className={`text-[13px] uppercase select-none cursor-pointer ${
                                            sortBy.includes('traffic-percent')
                                                ? 'text-fire font-black'
                                                : 'text-white font-bold'
                                        }`}
                                        onClick={() => handleSetSortBy('traffic-percent')}
                                    >
                                        Session %
                                        {sortBy.includes('traffic-percent') && (
                                            <span className='font-medium text-[16px] leading-[1px] ml-[4px] inline-block'>
                                                ({sortBy.includes('asc') ? '↑' : '↓'})
                                            </span>
                                        )}
                                    </p>
                                </div>
                                <div className='p-[8px] col-span-3 grid grid-cols-[100px_120px_84px]'>
                                    <p className='font-bold text-[13px] text-accent uppercase col-span-3 border-b border-lightgrey dark:border-grey'>
                                        Scrape Data
                                    </p>
                                    <p className={`text-[13px] uppercase select-none text-white font-bold`}>FP PRICE</p>
                                    <p className={`text-[13px] uppercase select-none text-white font-bold`}>
                                        LOWEST PRICE
                                    </p>
                                    <p
                                        className={`text-[13px] uppercase select-none cursor-pointer ${
                                            sortBy.includes('results') ? 'text-fire font-black' : 'text-white font-bold'
                                        }`}
                                        onClick={() => handleSetSortBy('results')}
                                    >
                                        RESULTS
                                        {sortBy.includes('results') && (
                                            <span className='text-[16px] leading-[1px] ml-[4px] inline-block'>
                                                ({sortBy.includes('asc') ? '↑' : '↓'})
                                            </span>
                                        )}
                                    </p>
                                </div>
                            </div>
                            {currentSlice.map((d, index) => (
                                <div className='col-span-full grid grid-cols-[32px_1fr_1fr_100px_120px_100px_120px_100px_120px_100px] border-b border-lightgrey hover:bg-lightgrey dark:hover:bg-faintplus dark:border-darkgrey'>
                                    <input
                                        className='w-[16px] h-[16px] p-[8px] m-auto block cursor-pointer'
                                        type='checkbox'
                                        checked={selectedRows.find((item) => item.gid === d.gid)}
                                        onChange={(e) => handleAddRemoveRow(e, d)}
                                    />
                                    {!selectedRows.find((item) => item.gid === d.gid) ? (
                                        <>
                                            <p
                                                className={`p-[8px] dark:text-offwhite ${
                                                    sortBy.includes('product') ? `bg-lightgrey dark:bg-faintplus` : ''
                                                }`}
                                            >
                                                {d.product.split(' :: ')[1]}
                                            </p>
                                            <p
                                                className={`p-[8px] dark:text-offwhite ${
                                                    sortBy.includes('sku') ? `bg-lightgrey dark:bg-faintplus` : ''
                                                }`}
                                            >
                                                {d.product.split(' :: ')[0]}
                                            </p>
                                            <div className={`h-full col-span-2 relative grid grid-cols-[100px_120px]`}>
                                                <p
                                                    className={`${
                                                        d.diff >= 0
                                                            ? 'text-[green] dark:text-lime'
                                                            : 'text-red dark:text-lightred'
                                                    } p-[8px] font-bold border-none peer ${
                                                        sortBy.includes('sales-diff')
                                                            ? 'bg-lightgrey dark:bg-faintplus'
                                                            : ''
                                                    }`}
                                                >
                                                    {d.diff > 0 && '+'}
                                                    {d.diff}
                                                </p>
                                                <p
                                                    className={`${
                                                        d.percentChange >= 0
                                                            ? 'text-[green] dark:text-lime'
                                                            : 'text-red dark:text-lightred'
                                                    } p-[8px] font-bold peer ${
                                                        sortBy.includes('sales-percent')
                                                            ? 'bg-lightgrey dark:bg-faintplus'
                                                            : ''
                                                    }`}
                                                >
                                                    {d.percentChange != Infinity
                                                        ? `${d.percentChange > 0 ? '+' : ''}${d.percentChange}%`
                                                        : '-'}
                                                </p>
                                                <div className='absolute p-[8px] top-[32px] transition-all delay-150 w-fit left-0 invisible overflow-hidden hover:visible peer-hover:visible z-[5] bg-white dark:bg-darkaccent dark:text-offwhite shadow-small rounded-[4px]'>
                                                    <p className='font-medium text-[14px]'>
                                                        Current sales: {d.periodOneSales}
                                                    </p>
                                                    <p className='font-medium text-[14px]'>
                                                        Previous sales: {d.periodTwoSales}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={`h-full col-span-2 relative grid grid-cols-[100px_120px]`}>
                                                <p
                                                    className={`${
                                                        d.trafficDiff >= 0
                                                            ? 'text-[green] dark:text-lime'
                                                            : 'text-red dark:text-lightred'
                                                    } p-[8px] font-bold border-none peer ${
                                                        sortBy.includes('traffic-diff')
                                                            ? 'bg-lightgrey dark:bg-faintplus'
                                                            : ''
                                                    }`}
                                                >
                                                    {d.trafficDiff > 0 && '+'}
                                                    {d.trafficDiff}
                                                </p>
                                                <p
                                                    className={`${
                                                        d.trafficPercentChange >= 0
                                                            ? 'text-[green] dark:text-lime'
                                                            : 'text-red dark:text-lightred'
                                                    } p-[8px] font-bold peer ${
                                                        sortBy.includes('traffic-percent')
                                                            ? 'bg-lightgrey dark:bg-faintplus'
                                                            : ''
                                                    }`}
                                                >
                                                    {d.trafficPercentChange != Infinity
                                                        ? `${d.trafficPercentChange > 0 ? '+' : ''}${
                                                              d.trafficPercentChange
                                                          }%`
                                                        : '-'}
                                                </p>
                                                <div className='absolute p-[8px] top-[32px] transition-all delay-150 w-fit left-0 invisible overflow-hidden hover:visible peer-hover:visible z-[5] bg-white dark:bg-darkaccent dark:text-offwhite shadow-small rounded-[4px]'>
                                                    <p className='font-medium text-[14px]'>
                                                        Current pageviews: {d.periodOneTraffic}
                                                    </p>
                                                    <p className='font-medium text-[14px]'>
                                                        Previous pageviews: {d.periodTwoTraffic}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={`h-full col-span-2 relative grid grid-cols-[100px_120px]`}>
                                                <p
                                                    className={`${
                                                        d.fp_scrape_price.price &&
                                                        d.lowest_price.price &&
                                                        Number(
                                                            d.fp_scrape_price?.price
                                                                ?.toString()
                                                                .replace(/[^0-9.-]+/g, '')
                                                        ) <=
                                                            Number(
                                                                d.lowest_price?.price
                                                                    ?.toString()
                                                                    .replace(/[^0-9.-]+/g, '')
                                                            )
                                                            ? 'text-[green] dark:text-lime'
                                                            : 'text-red dark:text-lightred'
                                                    } p-[8px] font-bold border-none peer`}
                                                >
                                                    {moneyFormatter.format(d.price || 0)}
                                                </p>
                                                <p
                                                    className={`${
                                                        d.fp_scrape_price.price &&
                                                        d.lowest_price.price &&
                                                        Number(
                                                            d.fp_scrape_price?.price
                                                                ?.toString()
                                                                .replace(/[^0-9.-]+/g, '')
                                                        ) <=
                                                            Number(
                                                                d.lowest_price?.price
                                                                    ?.toString()
                                                                    .replace(/[^0-9.-]+/g, '')
                                                            )
                                                            ? 'text-[green] dark:text-lime'
                                                            : 'text-red dark:text-lightred'
                                                    } p-[8px] font-bold border-none peer`}
                                                >
                                                    {moneyFormatter.format(d.lowest_price.price || 0)}
                                                </p>
                                                <div className='max-h-[400px] overflow-y-auto absolute p-[8px] top-[32px] transition-all delay-150 w-fit left-0 invisible hover:visible peer-hover:visible z-[5] bg-white dark:bg-darkaccent dark:text-offwhite shadow-small rounded-[4px]'>
                                                    {d.scrape_result.map((row) => (
                                                        <p className='text-[13px] mb-[8px]'>
                                                            <span className='font-bold'>{row.price}</span> -{' '}
                                                            <span className='font-bold'>{row.vendor}</span> -{' '}
                                                            {row.title}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                            <p
                                                className={`p-[8px] font-bold dark:text-offwhite ${
                                                    sortBy.includes('results') ? `bg-lightgrey dark:bg-faintplus` : ''
                                                }`}
                                            >
                                                {d.scrape_result.length}
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <div className='col-span-9 grid grid-cols-[1fr_1fr_100px_120px_100px_120px_100px_120px_100px]'>
                                                <p className={`p-[8px] dark:text-offwhite`}>
                                                    {d.product.split(' :: ')[1]}
                                                </p>
                                                <p className={`p-[8px] dark:text-offwhite`}>
                                                    {d.product.split(' :: ')[0]}
                                                </p>
                                                <div className='flex flex-col p-[8px] col-span-3'>
                                                    <label className='font-bold uppercase text-[12px] dark:text-offwhite'>
                                                        Search Term
                                                    </label>
                                                    <input
                                                        className='py-[2px] px-[4px] border border-lightgrey rounded-[4px] dark:bg-faintplus dark:text-white dark:border-none'
                                                        type='text'
                                                        name='term'
                                                        value={selectedRows.find((r) => r.gid === d.gid).search_term}
                                                        onChange={(e) =>
                                                            handleSetScrape(
                                                                e,
                                                                selectedRows.findIndex((r) => r.gid === d.gid)
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className='flex flex-col p-[8px] col-span-3'>
                                                    <label className='font-bold uppercase text-[12px] dark:text-offwhite'>
                                                        Match Values
                                                    </label>
                                                    <input
                                                        className='py-[2px] px-[4px] border border-lightgrey rounded-[4px] dark:bg-faintplus dark:text-white dark:border-none'
                                                        type='text'
                                                        name='match'
                                                        value={selectedRows
                                                            .find((r) => r.gid === d.gid)
                                                            .match_values.join(', ')}
                                                        onChange={(e) =>
                                                            handleSetScrape(
                                                                e,
                                                                selectedRows.findIndex((r) => r.gid === d.gid)
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                </div>

                <div className='flex gap-[16px] w-fit mx-auto mt-[16px]'>
                    <button
                        className='disabled:opacity-25 py-[2px] px-[8px] rounded-[4px] border border-grey bg-lightgrey dark:bg-darkaccent dark:text-offwhite'
                        disabled={page === 0}
                        onClick={() => setPage((prev) => prev - 1)}
                    >
                        PREV
                    </button>
                    <p className='py-[2px] px-[8px] rounded-[4px] dark:text-offwhite'>{page + 1}</p>
                    <button
                        className='disabled:opacity-25 py-[2px] px-[8px] rounded-[4px] border border-grey bg-lightgrey dark:bg-darkaccent dark:text-offwhite'
                        disabled={page * LIMIT + currentSlice.length >= diffData.length || currentSlice.length < 50}
                        onClick={() => setPage((prev) => prev + 1)}
                    >
                        NEXT
                    </button>
                </div>
            </>
        </div>
    )
}
