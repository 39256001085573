import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Chart from 'chart.js/auto'
import TasksModule from '../tasks/TasksModule'
import OrdersModule from '../orders/OrdersModule'
import ScrapeModule from '../scrape/ScrapeModule'
import { formatMoney, vFetch } from '../helpers'

export default function HomeScreen() {
    const user = useSelector((state) => state.user)
    const settings = useSelector((state) => state.settings)
    const [salesToday, setSalesToday] = useState(0)
    const [totalOrders, setTotalOrders] = useState(0)
    const [salesChart, setSalesChart] = useState(null)
    const chart = useRef(null)
    const lightOptions = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#4a4a4a',
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#4a4a4a',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            line: {
                fill: false,
                borderColor: 'orange',
                tension: 0.5,
                pointStyle: false,
            },
        },
    }
    const darkOptions = {
        scales: {
            x: {
                grid: {
                    display: false,
                    borderColor: 'white',
                },
                ticks: {
                    color: '#eeefea',
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#eeefea',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            line: {
                fill: false,
                borderColor: '#42EFD0',
                tension: 0.5,
                pointStyle: false,
            },
        },
    }
    useEffect(() => {
        vFetch(`/analytics/sales/today`, {
            cb: (res) => {
                if (res.success) {
                    setSalesToday(
                        res.sales.reduce((acc, curr) => {
                            return acc + parseFloat(curr.total)
                        }, 0)
                    )
                    setTotalOrders(res.sales.length)
                    const salesPerHour = {}
                    for (let i = 0; i < 24; i++) salesPerHour[i] = 0
                    for (let item of res.sales) {
                        const hour = new Date(item.created_at).getHours()
                        salesPerHour[hour] += parseFloat(item.total)
                    }
                    const tempSalesChart = new Chart(chart.current, {
                        type: 'line',
                        data: {
                            labels: Object.keys(salesPerHour),
                            datasets: [
                                {
                                    data: Object.values(salesPerHour),
                                },
                            ],
                        },
                        options: settings.theme == 'dark' ? darkOptions : lightOptions,
                    })
                    setSalesChart(tempSalesChart)
                }
            },
        })
    }, [])
    useEffect(() => {
        if (salesChart) {
            salesChart.options = settings.theme === 'dark' ? darkOptions : lightOptions
            salesChart.update()
        }
    }, [settings.theme])
    return (
        <>
            {user && (
                <>
                    <h1 className='font-bold dark:font-normal text-[32px] leading-[1] mb-[16px] dark:text-offwhite font-bai'>
                        Welcome back, {user.firstName}
                    </h1>
                    {/* <div className="grid grid-cols-4 gap-[8px] p-[16px]">
                        <div className="text-white">
                            Tasks
                        </div>
                        <div className="text-white">
                            Sales
                        </div>
                        <div className="text-white">
                            Customers
                        </div>
                        <div className="text-white">
                            Products
                        </div>
                    </div> */}
                    <div className='grid grid-cols-2 gap-[16px]'>
                        <div className='dark:bg-darkaccent shadow-small rounded-[4px] p-[8px] max-h-[400px]'>
                            <p className='text-fire font-bold mb-[8px]'>
                                Today's Total Sales: {formatMoney(salesToday)}
                            </p>
                            <canvas className='max-h-[300px]' ref={chart}></canvas>
                        </div>
                        <TasksModule />
                        <OrdersModule />
                        <ScrapeModule />
                    </div>
                </>
            )}
        </>
    )
}
