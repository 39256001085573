import { useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import FiltersMenu from '../custom_components/FiltersMenu'
import SelectedOrdersActions from './components/SelectedOrderActions'
import { IoMdWarning } from 'react-icons/io'
import { TbCircleFilled, TbCircle } from 'react-icons/tb'
import { AiFillCaretDown } from 'react-icons/ai'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { FaRegCommentDots } from 'react-icons/fa'
import { BiPurchaseTagAlt } from 'react-icons/bi'
import { BsListTask } from 'react-icons/bs'
import { TailSpin } from 'react-loading-icons'
import { dayMap, DayMap, formatDateToLocale, formatMoney, monthMap, MonthMap, parseResObject, vFetch } from '../helpers'
import {
    FinancialStatusMap,
    FulfillmentStatusMap,
    LineItemInit,
    OrderInit,
    financialStatusMap,
    fulfillmentStatusMap,
} from './orders.types'
import { Filter } from '../custom_components/FiltersMenu'
import { TaskEditInit } from '../tasks/tasks.types'
import TaskPreviewNavBlock from '../tasks/components/TaskPreviewNavBlock'
import { ToggleSwitch } from '../custom_components/ToggleSwitch'

function renderUniqueVendors(lineItems: LineItemInit[]) {
    const vendorArray: string[] = []
    lineItems.forEach((item) => !vendorArray.includes(item.vendor) && vendorArray.push(item.vendor))

    return vendorArray.map((vendor) => (
        <p key={vendor} className='w-[100%]'>
            {
                <strong>
                    {vendor && vendor !== 'FactoryPure' && vendor !== 'Shipping'
                        ? `${vendor} - `
                        : vendor === 'FactoryPure'
                          ? 'FP - '
                          : vendor === 'Shipping'
                            ? 'S - '
                            : ''}
                </strong>
            }
            {lineItems
                .map((item) => {
                    if (item.vendor === vendor && vendor !== 'FactoryPure' && vendor !== 'Shipping') {
                        return item.sku
                    } else if (item.vendor === vendor && (vendor === 'FactoryPure' || vendor === 'Shipping')) {
                        return item?.variant_title
                            ?.split(' ')
                            .map((word) => word[0])
                            .join('')
                    }
                })
                .filter((item) => item)
                .join(', ')}
        </p>
    ))
}

function renderIconMessage(iconMessage: string | React.ReactElement) {
    let unparsedMessage = iconMessage
    let parsedMessage = []

    if (typeof unparsedMessage === 'string') {
        while (unparsedMessage.match('\n')) {
            parsedMessage.push(unparsedMessage.slice(0, unparsedMessage.indexOf('\n')))
            parsedMessage.push(<br></br>)
            unparsedMessage = unparsedMessage.replace(unparsedMessage.slice(0, unparsedMessage.indexOf('\n') + 1), '')
        }
    }
    parsedMessage.push(unparsedMessage)
    return parsedMessage
}

type IconMessage =
    | [string, string | Element | HTMLElement | React.ReactElement | Element[] | HTMLElement[] | React.ReactElement[]]
    | false

export default function OrdersListScreen() {
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const [initRender, setInitRender] = useState<boolean>(true)
    const [POMode, setPOMode] = useState<boolean>(
        JSON.parse(localStorage.getItem('purchase_orders_mode') || 'false') || false
    )
    const [startDate, setStartDate] = useState(
        params.get('startDate')
            ? new Date(params.get('startDate') || '')
            : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999)
    )

    const [orders, setOrders] = useState<OrderInit[]>([])
    const [purchaseOrders, setPurchaseOrders] = useState<any[]>([])
    const [tasks, setTasks] = useState<TaskEditInit[]>([])
    const purchaseOrdersForOrder = (order: OrderInit) =>
        purchaseOrders.filter((purchaseOrder) => purchaseOrder.order_name === order.name)
    const tasksForOrder = (order: OrderInit) => tasks.filter((task) => task.order_name === order.name)
    const [firstOrderID, setFirstOrderID] = useState<string>('')
    const [allOrders, setAllOrders] = useState([])
    const [selected, setSelected] = useState<string[]>([])
    const [iconMessage, setIconMessage] = useState<IconMessage>(false)
    const [showLineItems, setShowLineItems] = useState<string>('')
    const [showTags, setShowTags] = useState(false)
    const [startID, setStartID] = useState<string | number | null | undefined>(params.get('startID') || '')
    const [endID, setEndID] = useState<string | number | null | undefined>(params.get('endID') || '')
    const [direction, setDirection] = useState<string>(params.get('direction') || '')
    const [loading, setLoading] = useState<boolean>(true)

    const [filterByPStatus, setFilterByPStatus] = useState(
        params
            .get('pStatus')
            ?.split(',')
            .filter((v) => v) || []
    )
    const [filterByPStatusOptions, setFilterByPStatusOptions] = useState([
        'paid',
        'authorized',
        'partially_paid',
        'partially_refunded',
        'refunded',
        'voided',
    ])
    // overdue, expired, unpaid, pending removed because they do not typically appear in the database
    const [showFilterByPStatusOptions, setShowFilterByPStatusOptions] = useState(false)

    const [filterByFStatus, setFilterByFStatus] = useState(
        params
            .get('fStatus')
            ?.split(',')
            .filter((v) => v) || []
    )
    const [filterByFStatusOptions, setFilterByFStatusOptions] = useState([
        'fulfilled',
        'unfulfilled',
        'partial',
        'restocked',
    ])
    // partially_fulfilled, scheduled, on_hold, request_declined removed because they do not typically appear in the database.
    const [showFilterByFStatusOptions, setShowFilterByFStatusOptions] = useState(false)

    const [filterByVendor, setFilterByVendor] = useState(
        params
            .get('vendor')
            ?.split(',')
            .filter((v) => v) || []
    )
    const [filterByVendorOptions, setFilterByVendorOptions] = useState<string[]>([])
    const [filterByVendorQuantities, setFilterByVendorQuantities] = useState<number[]>([])
    const [showFilterByVendorOptions, setShowFilterByVendorOptions] = useState(false)

    const [filterByTags, setFilterByTags] = useState(
        params
            .get('tags')
            ?.split(',')
            .filter((v) => v) || []
    )
    const [filterByTagsOptions, setFilterByTagsOptions] = useState<string[]>([])
    const [showFilterByTagsOptions, setShowFilterByTagsOptions] = useState<boolean>(false)

    const [filterByHasTimelineComments, setFilterByHasTimelineComments] = useState(
        params
            .get('htc')
            ?.split(',')
            .filter((v) => v) || []
    )
    const [filterByHasTimelineCommentsOptions, setFilterByHasTimelineCommentsOptions] = useState<string[]>([
        'True',
        'False',
    ])
    const [showFilterByHasTimelineCommentsOptions, setShowFilterByHasTimelineCommentsOptions] = useState<boolean>(false)

    const pStatusFilter = new Filter(
        'Filter by Pay Status',
        filterByPStatus,
        setFilterByPStatus,
        filterByPStatusOptions,
        showFilterByPStatusOptions,
        setShowFilterByPStatusOptions,
        useRef(null)
    )
    const fStatusFilter = new Filter(
        'Filter by Fulfillment',
        filterByFStatus,
        setFilterByFStatus,
        filterByFStatusOptions,
        showFilterByFStatusOptions,
        setShowFilterByFStatusOptions,
        useRef(null)
    )
    const vendorFilter = new Filter(
        'Filter By Vendor',
        filterByVendor,
        setFilterByVendor,
        filterByVendorOptions,
        showFilterByVendorOptions,
        setShowFilterByVendorOptions,
        useRef(null),
        undefined,
        filterByVendorQuantities
    )
    const tagsFilter = new Filter(
        'Filter By Tags',
        filterByTags,
        setFilterByTags,
        filterByTagsOptions,
        showFilterByTagsOptions,
        setShowFilterByTagsOptions,
        useRef(null)
    )
    const hasTimelineCommentsFilter = new Filter(
        'Has Timeline Comments',
        filterByHasTimelineComments,
        setFilterByHasTimelineComments,
        filterByHasTimelineCommentsOptions,
        showFilterByHasTimelineCommentsOptions,
        setShowFilterByHasTimelineCommentsOptions,
        useRef(null)
    )

    const filters = [pStatusFilter, fStatusFilter, vendorFilter, tagsFilter, hasTimelineCommentsFilter]
    filters.forEach((filter: Filter, index: number) => (filter.zIndex = filters.length - index))
    const filterProps = {
        startDate: startDate,
        setStartDate: setStartDate,
        endDate: startDate,
        items: allOrders,
        hide: POMode ? ['Filter by Pay Status', 'Filter by Fulfillment', 'Has Timeline Comments', 'Calendar'] : [],
    }

    const orderExpiring = (order: OrderInit) =>
        new Date(order.created_at.getFullYear(), order.created_at.getMonth(), order.created_at.getDate()) <=
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6) &&
        order.financial_status !== 'paid' &&
        order.financial_status !== 'refunded' &&
        order.financial_status !== 'voided'

    function resizeGrid() {
        const calcStyle: any = {}
        for (let i = 0; i <= 12; i++) {
            const contentBox = document.getElementsByClassName(`js-order__grid-content-${i}`)[0]
            if (contentBox) calcStyle[i] = { minWidth: contentBox.getBoundingClientRect().width + 'px' }
            else calcStyle[i] = { minWidth: 'auto' }
        }
        setCalculatedStyle(calcStyle)
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!(e.target as HTMLElement).classList.contains('js-items-dropdown')) {
                setShowLineItems('')
            }
            if (!(e.target as HTMLElement).classList.contains('js-tags-dropdown')) {
                setShowTags(false)
            }
        }
        window.addEventListener('click', listenToWindow)
        window.addEventListener('resize', resizeGrid)

        setInitRender(false)

        return () => {
            window.removeEventListener('click', listenToWindow)
            window.removeEventListener('resize', resizeGrid)
        }
    }, [])

    function refresh() {
        setLoading(true)

        const resPStatusOptions = [...filterByPStatusOptions]
        const resFStatusOptions = [...filterByFStatusOptions]
        const resVendorOptions = [...filterByVendorOptions]
        const resVendorQuantities: number[] = []
        const resTagsOptions: string[] = [...filterByTagsOptions]

        if (POMode) {
            vFetch(
                `/orders/limited?firstOrderID=${firstOrderID}&startID=${startID}&endID=${endID}&direction=${direction}&pStatus=${[
                    'authorized',
                    'paid',
                    'partially_paid',
                    'partially_refunded',
                ]}&fStatus=${['partial', 'unfulfilled']}&vendors=${filterByVendor}&tags=${filterByTags}&htc=${[
                    'False',
                ]}&POMode=${POMode}` +
                    `&startDate=${new Date(
                        new Date().getFullYear() - 1,
                        new Date().getMonth(),
                        new Date().getDate()
                    ).toISOString()}&endDate=${new Date().toISOString()}`,
                {
                    cb: (res: any) => {
                        if (res.success) {
                            setDirection('')
                            const resOrders: OrderInit[] = res.orders.map((order: any) => {
                                parseResObject(order)
                                order.line_items.forEach((item: any) => {
                                    item.vendor &&
                                        !resVendorOptions.includes(item.vendor.trim()) &&
                                        resVendorOptions.push(item.vendor.trim()) &&
                                        resVendorQuantities.push(0)
                                })
                                order.tags
                                    .split(',')
                                    .forEach(
                                        (tag: string) =>
                                            !resTagsOptions.includes(tag.trim()) &&
                                            tag.trim() &&
                                            resTagsOptions.push(tag.trim())
                                    )
                                return order
                            })
                            if (res.first_order_id) setFirstOrderID(res.first_order_id)
                            setOrders(resOrders)
                            setFilterByVendorOptions(
                                resVendorOptions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                            )
                            setFilterByTagsOptions(
                                resTagsOptions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                            )

                            resOrders.forEach((order: OrderInit) => {
                                order.line_items.forEach((item) => {
                                    if (item.vendor) {
                                        if (resVendorQuantities[resVendorOptions.indexOf(item.vendor.trim())]) {
                                            resVendorQuantities[resVendorOptions.indexOf(item.vendor.trim())]++
                                        } else {
                                            resVendorQuantities[resVendorOptions.indexOf(item.vendor.trim())] = 1
                                        }
                                    }
                                })
                            })
                            setFilterByVendorQuantities(resVendorQuantities)
                        }
                        setLoading(false)
                    },
                    catchCb: () => setLoading(false),
                }
            )
        } else {
            vFetch(
                `/orders/limited?firstOrderID=${firstOrderID}&startID=${startID}&endID=${endID}&direction=${direction}&pStatus=${filterByPStatus}&fStatus=${filterByFStatus}&vendors=${filterByVendor}&tags=${filterByTags}&htc=${filterByHasTimelineComments}&POMode=${POMode}` +
                    `&startDate=${startDate.toISOString()}&endDate=${new Date(
                        new Date(startDate).getFullYear(),
                        new Date(startDate).getMonth(),
                        new Date(startDate).getDate(),
                        23,
                        59,
                        59,
                        999
                    ).toISOString()}`,
                {
                    cb: (res: any) => {
                        setDirection('')
                        if (res.success) {
                            const resOrders = res.orders.map((order: any) => {
                                parseResObject(order)
                                if (
                                    order.financial_status &&
                                    !resPStatusOptions.includes(order.financial_status.trim())
                                )
                                    resPStatusOptions.push(order.financial_status.trim())
                                if (
                                    order.fulfillment_status &&
                                    !resFStatusOptions.includes(order.fulfillment_status.trim())
                                )
                                    resFStatusOptions.push(order.fulfillment_status.trim())
                                order.line_items.forEach((item: any) => {
                                    item.vendor &&
                                        !resVendorOptions.includes(item.vendor.trim()) &&
                                        resVendorOptions.push(item.vendor.trim()) &&
                                        resVendorQuantities.push(0)
                                })
                                order.line_items.forEach((item: any) => {
                                    item.vendor &&
                                        !resVendorOptions.includes(item.vendor.trim()) &&
                                        resVendorOptions.push(item.vendor.trim()) &&
                                        resVendorQuantities.push(0)
                                })
                                order.tags
                                    .split(',')
                                    .forEach(
                                        (tag: string) =>
                                            !resTagsOptions.includes(tag.trim()) &&
                                            tag.trim() &&
                                            resTagsOptions.push(tag.trim())
                                    )
                                return order
                            })

                            vFetch(
                                `/orders/purchase-orders/search?order_name=${resOrders.map((o: any) =>
                                    o.name.replace('#', '')
                                )}`,
                                { cb: (res: any) => setPurchaseOrders(res.purchase_orders) }
                            )
                            vFetch(`/tasks/search?order_name=${resOrders.map((o: any) => o.name.replace('#', ''))}`, {
                                cb: (res: any) => setTasks(res.tasks.map((t: any) => parseResObject(t))),
                            })
                            if (res.first_order_id) setFirstOrderID(res.first_order_id)
                            setOrders(resOrders)
                            setFilterByPStatusOptions(
                                resPStatusOptions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                            )
                            setFilterByFStatusOptions(
                                resFStatusOptions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                            )
                            setFilterByVendorOptions(
                                resVendorOptions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                            )
                            setFilterByTagsOptions(
                                resTagsOptions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                            )
                            setFilterByHasTimelineCommentsOptions(['True', 'False'])

                            resOrders.forEach((order: OrderInit) => {
                                order.line_items.forEach((item) => {
                                    if (item.vendor) {
                                        if (resVendorQuantities[resVendorOptions.indexOf(item.vendor.trim())]) {
                                            resVendorQuantities[resVendorOptions.indexOf(item.vendor.trim())]++
                                        } else {
                                            resVendorQuantities[resVendorOptions.indexOf(item.vendor.trim())] = 1
                                        }
                                    }
                                })
                            })
                            setFilterByVendorQuantities(resVendorQuantities)
                        }
                        setLoading(false)
                    },
                    catchCb: () => setLoading(false),
                }
            )
        }
    }

    useEffect(() => {
        if (orders.length) {
            setStartID(orders[0].id)
            setEndID(orders[orders.length - 1]?.id)
            window.history.pushState(
                undefined,
                '',
                `orders?startID=${startID}&endID=${endID}&direction=${direction}&pStatus=${filterByPStatus}&fStatus=${filterByFStatus}&vendor=${filterByVendor}&tags=${filterByTags}&htc=${filterByHasTimelineComments}&startDate=${startDate.toISOString()}&endDate=${startDate.toISOString()}`
            )
        }
    }, [orders])

    useEffect(() => {
        resizeGrid()
    }, [orders, purchaseOrders, tasks])

    useEffect(() => {
        if (initRender === false) {
            window.history.pushState(
                undefined,
                '',
                `orders?startID=${startID}&endID=${endID}&direction=${direction}&pStatus=${filterByPStatus}&fStatus=${filterByFStatus}&vendor=${filterByVendor}&tags=${filterByTags}&htc=${filterByHasTimelineComments}&startDate=${startDate.toISOString()}&endDate=${startDate.toISOString()}`
            )
            setDirection('')
            refresh()
            setStartID(undefined)
            setEndID(undefined)
        }
    }, [startDate])

    useEffect(() => {
        refresh()
        window.history.pushState(
            undefined,
            '',
            `orders?startID=${startID}&endID=${endID}&direction=${direction}&pStatus=${filterByPStatus}&fStatus=${filterByFStatus}&vendor=${filterByVendor}&tags=${filterByTags}&htc=${filterByHasTimelineComments}&startDate=${startDate.toISOString()}&endDate=${startDate.toISOString()}`
        )
    }, [filterByPStatus, filterByFStatus, filterByVendor, filterByTags, filterByHasTimelineComments, POMode])

    useEffect(() => {
        if (direction && !initRender) refresh()
    }, [direction])

    useEffect(() => {
        if (initRender === false) {
            setDirection('')
            setStartID('')
            setEndID('')
        }
    }, [filterByPStatus, filterByFStatus, filterByVendor, filterByTags, filterByHasTimelineComments, POMode])

    useEffect(() => {
        localStorage.setItem('purchase_orders_mode', POMode ? JSON.stringify(POMode) : 'false')
        localStorage.removeItem('mode')
        setFilterByVendorOptions([])
        if (!POMode) {
            vFetch(
                `/orders/all?fields=created_at,created_at_date&filters=(fulfillment_status IS NULL OR fulfillment_status='partial'),(financial_status='authorized' OR financial_status='paid' OR financial_status='partially_paid' OR financial_status='partially_refunded'),(has_timeline_comment=0)`,
                {
                    cb: (res: any) => setAllOrders(res.orders.map((order: any) => parseResObject(order))),
                }
            )
        }
        setSelected([])
    }, [POMode])

    function handleCheck(event: any) {
        const { target } = event
        const orderId = target.name || target.id
        if (orderId === 'all') return setSelected(target.checked ? orders.map((o) => String(o.id)) : [])
        setSelected(selected.includes(orderId) ? selected.filter((id) => id !== orderId) : [...selected, orderId])
    }

    function handleNavigate(e: MouseEvent, orderId: number | undefined) {
        if (e.ctrlKey) return
        e.preventDefault()
        if (
            !(e.target as HTMLElement).classList.contains('js-items-dropdown') &&
            !(e.target as HTMLElement).classList.contains('js-tags-dropdown') &&
            !(e.target as HTMLElement).classList.contains('js-dont-navigate')
        ) {
            navigate(`/orders/${orderId}`)
        }
    }

    const [calculatedStyle, setCalculatedStyle] = useState<any>({})

    return (
        <div className='relative text-black dark:text-offwhite'>
            {loading && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Orders</h1>
            <div className='flex items-center justify-between h-[50px] gap-x-[8px] font-bai dark:text-offwhite'>
                <div className='flex gap-[16px]'>
                    {!POMode && (
                        <>
                            <h2 className='font-bold text-[24px]'>
                                {startDate
                                    .toDateString()
                                    .split(' ')
                                    .map((dateItem) =>
                                        dayMap[dateItem as keyof DayMap]
                                            ? `${dayMap[dateItem as keyof DayMap]}, `
                                            : monthMap[dateItem as keyof MonthMap]
                                              ? `${monthMap[dateItem as keyof MonthMap]}`
                                              : Number(dateItem) < 32
                                                ? `${Number(dateItem)},`
                                                : dateItem
                                    )
                                    .join(' ')}
                            </h2>
                            {startDate.toISOString() !==
                                new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth(),
                                    new Date().getDate(),
                                    23,
                                    59,
                                    59,
                                    999
                                ).toISOString() && (
                                <button
                                    className='dark:text-accent font-semibold py-[8px] px-[16px] rounded hover:bg-offwhite dark:hover:bg-darkaccent'
                                    onClick={() =>
                                        setStartDate(
                                            new Date(
                                                new Date().getFullYear(),
                                                new Date().getMonth(),
                                                new Date().getDate(),
                                                23,
                                                59,
                                                59,
                                                999
                                            )
                                        )
                                    }
                                >
                                    Reset Date
                                </button>
                            )}
                        </>
                    )}
                </div>
                <div className='flex items-center gap-2'>
                    <span>PO Mode </span>
                    <ToggleSwitch
                        defaultChecked={!!POMode}
                        onClick={() => {
                            setPOMode(!POMode)
                        }}
                    />
                    {/* <Switch
                        defaultChecked={!!POMode}
                        onClick={() => {
                            setPOMode(!POMode)
                        }}
                    /> */}
                </div>
            </div>
            <FiltersMenu filters={filters} ex={filterProps} />
            <SelectedOrdersActions selected={selected} setSelectedOrders={setSelected} />
            <div className='pb-[32px]'>
                {/* New style */}
                <div
                    // There are 9 or 7 columns, but the last one is 1fr to take up the remaining space.
                    style={{ gridTemplateColumns: `repeat(11, auto) 1fr` }}
                    className='grid justify-start relative text-[12px] font-bai font-extrabold border-[1px] border-darkgrey rounded-t cursor-pointer'
                >
                    {/* Used for calculating a style. This entire thing is hidden from HIDDEN START */}
                    <div className='contents [&>*]:!py-0 [&>*]:px-[8px] [&>*]:whitespace-nowrap'>
                        <div
                            className='js-order__grid-content-1 opacity-0 h-0 grid items-center justify-start cursor-default !pl-[8px]'
                            id='all'
                        >
                            <input type='checkbox' name='all' />
                        </div>
                        <div className='js-order__grid-content-2 opacity-0 h-0'>Order #</div>
                        <div className='js-order__grid-content-3 opacity-0 h-0'></div>
                        <div className='js-order__grid-content-4 opacity-0 h-0'>Date</div>
                        <div className='js-order__grid-content-5 opacity-0 h-0'>Customer</div>
                        <div className='js-order__grid-content-6 opacity-0 h-0'>Total</div>
                        <div className='js-order__grid-content-7 opacity-0 h-0'>Pay STS</div>
                        <div className='js-order__grid-content-8 opacity-0 h-0 flex justify-center'>TTC</div>
                        <div className='js-order__grid-content-9 opacity-0 h-0'>Ful. STS</div>
                        <div className='js-order__grid-content-10 opacity-0 h-0'>Vendors</div>
                        <div className='js-order__grid-content-11 opacity-0 h-0'>Items</div>
                        <div className='js-order__grid-content-12 opacity-0 h-0'>Tags</div>
                    </div>
                    {/* HIDDEN END */}

                    <div
                        style={{ gridColumn: `1 / span 12` }}
                        className={`flex sticky top-[51px] [&>*]:py-[8px] [&>*]:px-[8px] [&>*]:border-b-[1px] [&>*]:border-b-darkgrey !p-0 bg-blue text-white dark:text-offwhite dark:bg-darkness z-index-1`}
                    >
                        <div
                            style={calculatedStyle[1]}
                            className='grid items-center justify-start !p-0 !pl-[8px] cursor-default js-dont-navigate h-[100%]'
                            onClick={handleCheck}
                            id='all'
                        >
                            <input type='checkbox' name='all' onClick={handleCheck} />
                        </div>
                        <div style={calculatedStyle[2]}>Order #</div>
                        <div style={calculatedStyle[3]}></div>
                        <div style={calculatedStyle[4]}>Date</div>
                        <div style={calculatedStyle[5]}>Customer</div>
                        <div style={calculatedStyle[6]} className=''>
                            Total
                        </div>
                        <div style={calculatedStyle[7]}>Pay STS</div>
                        <div style={calculatedStyle[8]} className='text-center'>
                            TTC
                        </div>
                        <div style={calculatedStyle[9]} className=''>
                            Ful. STS
                        </div>
                        <div style={calculatedStyle[10]} className=''>
                            Vendors
                        </div>
                        <div style={calculatedStyle[11]} className=''>
                            Items
                        </div>
                        <div style={calculatedStyle[12]} className='w-full'>
                            Tags
                        </div>
                    </div>

                    {orders.map((order, i) => (
                        <NavLink
                            key={order.id}
                            className={`contents text-[12px] font-bai cursor-pointer [&>*]:py-[8px] [&>*]:px-[8px] [&>*:first-child]:pl-[8px] [&>*:last-child]:pr-[8px] ${
                                i % 2
                                    ? '[&>*]:bg-[rgb(224,228,235)] [&>*]:dark:bg-darkness'
                                    : '[&>*]:dark:bg-darkaccent'
                            } ${
                                orderExpiring(order)
                                    ? '[&>*]:!bg-[#8a0f0f] [&>*]:!text-[white] [&>*]:hover:!bg-[#CC0000]'
                                    : '[&>*]:hover:!bg-[rgb(199,202,209)] [&>*]:dark:hover:!bg-blue'
                            }`}
                            to={`/orders/${order.id}`}
                            onClick={(e: any) => handleNavigate(e, order.id)}
                        >
                            <div
                                className='js-dont-navigate grid items-start justify-center cursor-default h-[100%]'
                                onClick={(e: any) => handleCheck(e)}
                                id={String(order.id)}
                            >
                                <input
                                    className='js-dont-navigate pointer-events-none'
                                    type='checkbox'
                                    onClick={(e: any) => handleCheck(e)}
                                    checked={selected.includes(String(order.id))}
                                    name={String(order.id)}
                                />
                            </div>
                            <div className=''>{order.name}</div>
                            <div className='js-orders-icon-list relative py-[8px] flex gap-[8px] items-start h-[100%] w-[100%]'>
                                <div className='flex gap-[8px] items-center'>
                                    {order.note && (
                                        <div
                                            className='js-orders-icon'
                                            onMouseOver={() => {
                                                setIconMessage([order.name, `${order.note}`])
                                            }}
                                        >
                                            <svg
                                                className={`stroke-black ${
                                                    orderExpiring(order) && 'stroke-white'
                                                } dark:stroke-accent pointer-events-none`}
                                                stroke='currentColor'
                                                fill='currentColor'
                                                strokeWidth='0'
                                                viewBox='0 0 24 24'
                                                height='1em'
                                                width='1em'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    className='pointer-events-none'
                                                    fill='none'
                                                    strokeWidth='2'
                                                    d='M3,1 L3,23 L16,23 L21,18 L21,1 L3,1 Z M6,17 L11,17 M6,13 L18,13 M6,9 L16,9 M3,5 L21,5 M21,17 L15,17 L15,23'
                                                ></path>
                                            </svg>
                                        </div>
                                    )}
                                    {order.has_timeline_comment ? (
                                        <FaRegCommentDots
                                            onMouseOver={() => {
                                                setIconMessage([order.name, 'This order has timeline comments.'])
                                            }}
                                            className='dark:fill-accent h-[14px] w-[14px]'
                                        />
                                    ) : undefined}
                                    {!!purchaseOrdersForOrder(order).length && (
                                        <BiPurchaseTagAlt
                                            className='h-[16px] w-[16px] dark:fill-accent'
                                            onMouseOver={() => {
                                                setIconMessage([
                                                    order.name,
                                                    <span>
                                                        This order has{' '}
                                                        {purchaseOrdersForOrder(order).length > 1
                                                            ? `${purchaseOrdersForOrder(order).length} purchase orders`
                                                            : 'a purchase order'}
                                                        :{' '}
                                                        {purchaseOrdersForOrder(order).map((PO: any) => (
                                                            <span
                                                                key={`${order.id}-${PO.id}`}
                                                                className='js-dont-navigate text-blue dark:text-accent hover:underline cursor-pointer font-semibold mr-[4px]'
                                                                onContextMenu={() => {
                                                                    window.open(`/orders/purchase-orders/${PO.id}`)
                                                                }}
                                                                onClick={() => {
                                                                    ;(window.event as MouseEvent).ctrlKey
                                                                        ? window.open(
                                                                              `/orders/purchase-orders/${PO.id}`
                                                                          )
                                                                        : navigate(`/orders/purchase-orders/${PO.id}`)
                                                                }}
                                                            >
                                                                {PO.id}
                                                            </span>
                                                        ))}
                                                    </span>,
                                                ])
                                            }}
                                        />
                                    )}
                                    {!!tasksForOrder(order).length && (
                                        <BsListTask
                                            className='h-[16px] w-[16px] dark:fill-accent'
                                            onMouseOver={() =>
                                                setIconMessage([
                                                    order.name,
                                                    <div className='p-[4px]'>
                                                        <h3 className='text-[16px] font-bold'>
                                                            Order {order.name} - Tasks
                                                        </h3>
                                                        {tasksForOrder(order)?.map((t: any) => (
                                                            <TaskPreviewNavBlock task={t} />
                                                        ))}
                                                    </div>,
                                                ])
                                            }
                                        />
                                    )}
                                    {order.risk_level === 'HIGH' ? (
                                        <IoMdWarning
                                            className='h-[16px] w-[16px] fill-[#da0000]'
                                            onMouseOver={() => {
                                                setIconMessage([order.name, 'This order has a high risk of fraud.'])
                                            }}
                                        />
                                    ) : order.risk_level === 'MEDIUM' ? (
                                        <IoMdWarning
                                            className='h-[16px] w-[16px] fill-[#d19e1c]'
                                            onMouseOver={() => {
                                                setIconMessage([order.name, 'This order might be fraudulent.'])
                                            }}
                                        />
                                    ) : undefined}
                                </div>
                                {/* I think this is unnecessary with the new grid rendering method */}
                                {/* <div
                                    className='flex grow h-[100%] shrink-0'
                                    onMouseOver={() => setIconMessage(false)}
                                /> */}
                                {iconMessage[0 as keyof IconMessage] === order.name && (
                                    <div className='hidden js-orders-icon-message absolute hover:flex p-[10px] min-w-[350px] top-[calc(100%-10px)] left-[-36px] cursor-auto js-dont-navigate'>
                                        <div className='left-[0px] top-[0px] js-orders-icon p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small js-dont-navigate'>
                                            {renderIconMessage(iconMessage[1 as keyof IconMessage])}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='flex flex-wrap gap-x-[4px]'>
                                <p>
                                    {[
                                        order.created_at.getMonth() + 1,
                                        order.created_at.getDate(),
                                        String(order.created_at.getFullYear()).slice(-2),
                                    ].join('/')}
                                </p>
                                <p>{formatDateToLocale(order.created_at).split(' ').slice(-2).join(' ')}</p>
                            </div>
                            <div className=''>
                                {order?.customer &&
                                    `${order.customer.first_name ? order.customer.first_name : ''} ${
                                        order.customer.last_name ? order.customer.last_name : ''
                                    }`}
                            </div>
                            <div className=''>{formatMoney(Number(order.current_total_price))}</div>
                            <div className='text-[12px]'>
                                <div
                                    className={`${
                                        (order.financial_status &&
                                            financialStatusMap[order.financial_status as keyof FinancialStatusMap]
                                                .bg) ||
                                        'bg-fire'
                                    } w-[auto] flex gap-[4px] items-center text-darkgrey px-[4px] rounded-full`}
                                >
                                    {(order.financial_status &&
                                        financialStatusMap[order.financial_status as keyof FinancialStatusMap]
                                            .symbol) || <TbCircle />}
                                    <span className='inline-block font-bold text-center'>
                                        {(order.financial_status &&
                                            financialStatusMap[order.financial_status as keyof FinancialStatusMap]
                                                .abbr) ||
                                            'U'}
                                    </span>
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                {Math.floor(
                                    Math.max(
                                        0,
                                        new Date(order.created_at).getTime() -
                                            new Date(
                                                new Date().getFullYear(),
                                                new Date().getMonth(),
                                                new Date().getDate() - 7,
                                                new Date().getHours(),
                                                new Date().getMinutes()
                                            ).getTime()
                                    ) / 86400000
                                )}
                            </div>
                            <div className='flex items-start justify-center text-[12px]'>
                                <div
                                    className={`${
                                        (order.fulfillment_status &&
                                            fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                                                .bg) ||
                                        'bg-fire'
                                    } w-[40px] flex gap-[4px] items-center text-darkgrey px-[4px] rounded-full`}
                                >
                                    {(order.fulfillment_status &&
                                        fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                                            .symbol) || <TbCircle />}
                                    <span className='inline-block w-[15px] font-bold text-center'>
                                        {(order.fulfillment_status &&
                                            fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                                                .abbr) ||
                                            order.fulfillment_status?.slice(0, 1).toUpperCase() ||
                                            'U'}
                                    </span>
                                </div>
                            </div>
                            <div className='flex flex-wrap'>
                                {renderUniqueVendors(order.line_items)}
                                {/* This used to be tracking numbers */}
                                {/* {order.fulfillments.map(f =>
                                <span className="hover:underline" onClick={() => {
                                    if (!f.tracking_url.includes("ups.com")) {
                                        navigator.clipboard.writeText(f.tracking_number);
                                        setTimeout(() => {
                                            alert("Tracking number copied: " + f.tracking_number + "\n\nYou can right-click and paste the tracking number into the tracking website.\n\nClick OK to continue to the tracking website.");
                                            window.open(f.tracking_url, "_blank");
                                        }, 0)
                                    } else {
                                        window.open(f.tracking_url, "_blank");
                                    }
                                }}>
                                    {f.tracking_number}
                                </span>
                            )} */}
                            </div>
                            <div className='flex relative h-[100%] w-[100%] items-start justify-center'>
                                {order.line_items
                                    .map((item) => {
                                        return Number(item.quantity)
                                    })
                                    .reduce((acc: number, cur: number) => acc + cur, 0)}
                                <div
                                    className='js-items-dropdown absolute top-0 flex w-[100%] h-[100%] hover:opacity-[1] opacity-0 bg-[rgba(0,0,0,0.1)] items-start justify-end'
                                    onClick={() => {
                                        showLineItems !== order.name
                                            ? setShowLineItems(order.name)
                                            : setShowLineItems('')
                                    }}
                                >
                                    <div className='pt-[10px] pointer-events-none'>
                                        <AiFillCaretDown
                                            className={`pointer-events-none duration-300 ${
                                                showLineItems === order.name ? '!rotate-0' : 'rotate-180'
                                            }`}
                                        />
                                    </div>
                                </div>
                                {showLineItems === order.name && (
                                    <div className='[&>*]:pointer-events-none js-items-dropdown grid absolute top-[calc(100%-5px)] min-w-[300px] w-[25vw] right-[0] p-[8px] text-black bg-white shadow-small dark:text-offwhite dark:bg-darkaccent dark:border-[1px] dark:border-darkgrey z-index-2 cursor-auto'>
                                        <span className='js-items-dropdown inline font-bold text-[16px] w-[auto] text-end'>
                                            Items
                                        </span>
                                        {order.line_items.filter(
                                            (item) =>
                                                !item.fulfillment_status || item.fulfillment_status === 'unfulfilled'
                                        ).length > 0 && (
                                            <>
                                                <div className='js-items-dropdown w-[40px] flex gap-[4px] items-center bg-fire text-darkgrey px-[4px] rounded-full mb-[16px]'>
                                                    <TbCircle className='js-items-dropdown' />
                                                    <span className='js-items-dropdown inline-block w-[15px] font-bold text-center'>
                                                        U
                                                    </span>
                                                </div>
                                                <div className='js-items-dropdown grid gap-[16px]'>
                                                    {order.line_items.map((item) => {
                                                        if (
                                                            item.fulfillment_status === 'scheduled' ||
                                                            item.fulfillment_status === 'unfulfilled' ||
                                                            (!item.fulfillment_status && item.requires_shipping)
                                                        ) {
                                                            return (
                                                                <div
                                                                    key={`${order.id}-${item.id}`}
                                                                    className='js-items-dropdown flex gap-[16px]'
                                                                >
                                                                    <div className='js-items-dropdown relative flex items-center'>
                                                                        <img
                                                                            className='js-items-dropdown object-contain max-h-[75px] max-w-[75px]'
                                                                            src={
                                                                                window.IMAGE_MAP[
                                                                                    `gid://shopify/Product/${item.product_id}`
                                                                                ]
                                                                            }
                                                                            alt={item.name}
                                                                            height='100px'
                                                                            width='100px'
                                                                        />
                                                                        <span className='js-items-dropdown absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                                            {item.quantity}
                                                                        </span>
                                                                    </div>
                                                                    <div className='js-items-dropdown my-[12px]'>
                                                                        <a
                                                                            href={`https://factorypure.myshopify.com/admin/products/${item.product_id}`}
                                                                            target='_blank'
                                                                            className='js-dont-navigate js-items-dropdown font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                                        >
                                                                            {item.name}
                                                                        </a>
                                                                        <p className='js-items-dropdown pt-[4px]'>
                                                                            <strong className='js-items-dropdown'>
                                                                                SKU:
                                                                            </strong>{' '}
                                                                            {item.sku}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </>
                                        )}
                                        {order.line_items.filter((item) => item.fulfillment_status === 'fulfilled')
                                            .length > 0 && (
                                            <>
                                                <div className='js-items-dropdown w-[40px] flex gap-[4px] items-center bg-[rgb(190,255,200)] text-darkgrey px-[4px] rounded-full mb-[16px]'>
                                                    <TbCircleFilled className='js-items-dropdown' />
                                                    <span className='js-items-dropdown inline-block w-[15px] font-bold text-center'>
                                                        F
                                                    </span>
                                                </div>
                                                <div className='js-items-dropdown grid gap-[16px]'>
                                                    {order.line_items.map((item) => {
                                                        if (
                                                            item.fulfillment_status === 'fulfilled' ||
                                                            (!item.fulfillment_status && item.requires_shipping)
                                                        ) {
                                                            return (
                                                                <div
                                                                    key={`${order.id}-${item.id}`}
                                                                    className='js-items-dropdown flex gap-[16px]'
                                                                >
                                                                    <div className='js-items-dropdown relative flex items-center'>
                                                                        <img
                                                                            className='js-items-dropdown object-contain max-h-[75px] max-w-[75px]'
                                                                            src={
                                                                                window.IMAGE_MAP[
                                                                                    `gid://shopify/Product/${item.product_id}`
                                                                                ]
                                                                            }
                                                                            alt={item.name}
                                                                            height='100px'
                                                                            width='100px'
                                                                        />
                                                                        <span className='js-items-dropdown absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                                            {item.quantity}
                                                                        </span>
                                                                    </div>
                                                                    <div className='js-items-dropdown my-[12px]'>
                                                                        <a
                                                                            href={`https://factorypure.myshopify.com/admin/products/${item.product_id}`}
                                                                            target='_blank'
                                                                            className='js-dont-navigate js-items-dropdown font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                                        >
                                                                            {item.name}
                                                                        </a>
                                                                        <p className='js-items-dropdown pt-[4px]'>
                                                                            <strong className='js-items-dropdown'>
                                                                                SKU:
                                                                            </strong>{' '}
                                                                            {item.sku}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className='flex flex-wrap gap-[4px]'>{order.tags}</div>
                        </NavLink>
                    ))}
                </div>

                {!POMode && (
                    <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[8px] justify-between items-center mt-[16px] rounded'>
                        <div className='min-w-[30px] min-h-[30px]'>
                            {orders[0] && (orders[0].id ? Number(firstOrderID) > orders[0].id : false) && (
                                <div
                                    className='grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                    onClick={() => setDirection('backward')}
                                >
                                    <BiLeftArrow className='fill-darkgrey dark:fill-accent' />
                                </div>
                            )}
                        </div>
                        {orders.length > 0 && (
                            <div className='flex min-w-[160px] justify-center grow font-bold dark:text-offwhite'>
                                {orders[0].name} - {orders[orders.length - 1].name}
                            </div>
                        )}
                        <div className='min-w-[30px] min-h-[30px]'>
                            {(orders.length >= 50 || (orders[0] && orders[0].id == Number(firstOrderID))) && (
                                <div
                                    className='grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                    onClick={() => setDirection('forward')}
                                >
                                    <BiRightArrow className='fill-darkgrey dark:fill-accent' />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
