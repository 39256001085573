import { useContext } from 'react'
import { ProcurementContext, ProcurementContextType, ProductPollingContext } from '../helpers'
import { useSelector } from 'react-redux'

export default function ProductViewers({ product_id }: { product_id: string | number }) {
    const user = useSelector((state: any) => state.user)
    const { users } = useContext<ProcurementContextType>(ProcurementContext)
    const { appState } = useContext<any>(ProductPollingContext)

    const userIds = (appState || {})[product_id] || []

    return (
        <div className='flex gap-[8px] items-center'>
            {userIds
                .filter((userId: string) => userId !== user.id.toString())
                .map((userId: string) => (
                    <div className='rounded-full w-[32px] h-[32px] border-2 border-blue dark:border-accent flex font-bold uppercase items-center justify-center'>
                        {(users.find((u) => u.id.toString() === userId) || { user_id: '??' }).user_id.slice(0, 2)}
                    </div>
                ))}
        </div>
    )
}
