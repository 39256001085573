import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    S3File,
    defaultItemChecklist,
    defaultReturn,
    ReturnCreateInit,
    ReturnEditInit,
    DamageClaimCreateInit,
    defaultDamageChecklist,
    defaultDamageClaim,
} from './../returns.types'
import { getDataSizeMB, closePopout, useDebounce, formatMoney, sendToast, vFetch, parseResObject } from '../../helpers'
import { LineItemInit, OrderInit } from '../../orders/orders.types'
import { TaskEditInit } from '../../tasks/tasks.types'
import AreYouSureYouWantToSubmitReturnModal from './AreYouSureYouWantToSubmitReturnModal'
import TailSpin from 'react-loading-icons/dist/esm/components/tail-spin'
import ReturnInfoSection from './ReturnInfoSection'
import { canSubmitReturn, getTaxTotal } from '../returns.helpers'
import { itemPriceAfterDiscounts, renderDiscount } from '../../orders/orders.helpers'
import SelectReturnItem from './SelectItem'

type CreateReturnModuleProps = {
    returnTask?: TaskEditInit | null
    returnOrder?: OrderInit | null
    setShowReturnsModal?: any
    refreshReturns?: any
    type: 'return' | 'damage'
}

export default function CreateReturnModule({
    returnTask,
    returnOrder,
    setShowReturnsModal,
    refreshReturns,
    type,
}: CreateReturnModuleProps) {
    const user = useSelector<any, any>((state) => state.user)
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [showDiscount, setShowDiscount] = useState('')
    const [showAreYouSureYouWantToSubmitReturnModal, setShowAreYouSureYouWantToSubmitReturnModal] =
        useState<boolean>(false)
    const [order, setOrder] = useState<OrderInit | null>(returnOrder || null)
    const [fulfillmentOrders, setFulfillmentOrders] = useState<any[]>([])
    const [customer, setCustomer] = useState<any>(null)
    const [returnsMap, setReturnsMap] = useState<any>({})
    const [r, setR] = useState<ReturnCreateInit | DamageClaimCreateInit>(
        type === 'return'
            ? ({
                  ...defaultReturn,
                  customer: returnOrder?.customer.id || null,
                  po_number: returnOrder?.order_number || null,
                  rep: user.user_id,
                  task_id: returnTask?.id || null,
              } as ReturnCreateInit)
            : ({
                  ...defaultDamageClaim,
                  customer: returnOrder?.customer.id || null,
                  po_number: returnOrder?.order_number || null,
                  rep: user.user_id,
                  task_id: returnTask?.id || null,
              } as DamageClaimCreateInit)
    )
    const [selectedItem, setSelectedItem] = useState<LineItemInit>()

    // order / task button stuff
    const [orderSearch, setOrderSearch] = useState<string>('')
    const [filteredOrders, setFilteredOrders] = useState<any[]>([])
    const orderSearchInput = useRef(null)
    const [showOrderDropdown, setShowOrderDropdown] = useState<boolean>(false)
    const [taskSearch, setTaskSearch] = useState<string>('')
    const taskSearchInput = useRef(null)
    const [showTaskDropdown, setShowTaskDropdown] = useState<boolean>(false)
    const debouncedSearch = useDebounce(orderSearch, 300)
    const abortController = new AbortController()

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!(e.target as HTMLElement).classList.contains('js-order__discount')) setShowDiscount('')
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    })
    useEffect(() => {
        if (order?.id) {
            vFetch(`/customers/multipleById?ids=${order?.customer?.id}`, {
                cb: (res: any) => {
                    if (res.success && res.customers.length > 0) {
                        setR({ ...r, po_number: order.order_number!, customer: order?.customer?.id! })
                        setCustomer(parseResObject(res.customers[0]))
                    } else sendToast({ message: 'Customer not found.' })
                },
            })
        }
    }, [order])
    useEffect(() => {
        const fulfillmentsWithSelectedItem =
            order?.fulfillments.filter(
                (f) => f.tracking_number && f.line_items.find((item: LineItemInit) => item.sku === selectedItem?.sku)
            ) || []
        const firstTrackingNumber = fulfillmentsWithSelectedItem[0]
            ? fulfillmentsWithSelectedItem[0].tracking_number
            : ''
        const resetReturn = {
            ...r,
            manufacturer: selectedItem ? selectedItem?.vendor : '',
            model: selectedItem,
            original_tracking_number: firstTrackingNumber,
        }
        if (r.type === 'return') setR({ ...resetReturn, item_checklist: defaultItemChecklist })
        if (r.type === 'damage') setR({ ...resetReturn, damage_checklist: defaultDamageChecklist })
    }, [selectedItem])
    useEffect(() => {
        if (debouncedSearch) {
            vFetch(`/orders?fields=name,admin_graphql_api_id,id&search=${debouncedSearch}`, {
                abortController,
                cb: (res: any) => {
                    if (res.success) {
                        if (res.orders) setFilteredOrders(res.orders)
                        else if (res.draft_orders) setFilteredOrders(res.draft_orders)
                    }
                },
            })
            if (debouncedSearch && debouncedSearch.length > 0 && debouncedSearch !== order?.name) {
                setShowOrderDropdown(true)
            } else {
                setShowOrderDropdown(false)
            }
            return () => abortController.abort()
        }
    }, [debouncedSearch])

    function handleNavigate(e: MouseEvent, poNumber: number | undefined, returnId?: number) {
        const target = e.target as HTMLElement
        if (
            closePopout(
                e,
                ['js-returns-checkbox', 'js-items-dropdown', 'js-tags-dropdown', 'js-dont-navigate'],
                () => {}
            )
        ) {
            if (poNumber !== undefined && !returnId) {
                vFetch(`/orders/all?filters=order_number=${poNumber}`, {
                    cb: (res: any) => {
                        if (res.success && res.orders?.length > 0) {
                            if (e.ctrlKey) window.open(`/orders/${res.orders[0].id}`)
                            else if (e.type === 'contextmenu') window.open(`/orders/${res.orders[0].id}`)
                            else navigate(`/orders/${res.orders[0].id}`)
                        } else sendToast({ message: 'Order page cannot be found. Please contact the developers.' })
                    },
                })
            }
            if (returnId !== undefined && closePopout(e, ['js-return-po-number', 'js-return-checkbox'], () => {})) {
                if (e.ctrlKey) window.open(`/returns/edit?returns=${returnId}`)
                else if (e.type === 'contextmenu') window.open(`/returns/edit?returns=${returnId}`)
                else navigate(`/returns/edit?returns=${returnId}`)
            }
        }
    }

    function handleChangeReturn(name: string, value: any) {
        setR({ ...r, [name]: value ? value : null })
    }
    function handleChangeShippingAddress(e: SyntheticEvent) {
        setR({ ...r, [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value })
    }
    // function handleChangeRefundType (target:HTMLInputElement, pricePlusTax:number) {
    //     const newReturn = {...r, type: target.value};
    //     // newReturn.refund = target.value === "full" ? Number(pricePlusTax.toFixed(2)) : 0;
    //     setR(newReturn);
    // }
    // function handleChangeRefundQuantity (target:HTMLInputElement, pricePlusTax:number) {
    //     const newReturn = {...r, quantity: Number(target.value)};
    //     // newReturn.refund = Number((newReturn.quantity * pricePlusTax).toFixed(2));
    //     setR(newReturn);
    // }
    // function handleChangeRefundAmount (value:string) {
    //     const newReturn = {...r, refund: Number(value)};
    //     if (newReturn.refund_type === "full") newReturn.refund_type = "partial";
    //     setR(newReturn);
    // }

    // submit return handlers
    async function batchSubmit(r: ReturnCreateInit | DamageClaimCreateInit) {
        sendToast({ message: 'Files are large. Uploading images in batches.' })
        const allFiles = [...r.files]

        function sizeAndIndexOfSmallestFile(fileArray: S3File[]) {
            let smallest = { size: Infinity, index: -1 }
            fileArray.forEach((file, i) => {
                if ((file.data as File).size < smallest.size) smallest = { size: (file.data as File).size, index: i }
            })
            return smallest
        }
        async function createAndSendBatch(totalFiles: number, filesSent: number, batchNum: number) {
            let fileBatch: S3File[] = []
            const data = new FormData()
            data.append('returns', JSON.stringify([{ ...r, files: [] }]))
            data.append('batchNum', JSON.stringify(batchNum))

            while (
                getDataSizeMB(fileBatch) < 12 &&
                allFiles.length &&
                getDataSizeMB(fileBatch) + getDataSizeMB(allFiles[sizeAndIndexOfSmallestFile(allFiles).index]) < 12
            ) {
                const smallestFile = allFiles.splice(sizeAndIndexOfSmallestFile(allFiles).index, 1)
                fileBatch = fileBatch.concat(smallestFile)
            }
            sendToast({ message: `Uploading ${fileBatch.length} files...` })
            fileBatch.forEach((f) => data.append('0_files', f.data as File, f.name))
            vFetch(`/returns/batch`, {
                method: 'PUT',
                contentType: 'skip',
                body: data,
                cb: (res: any) => {
                    if (res.success) {
                        if (res?.insertIds?.length) (r as ReturnEditInit).id = res.insertIds[0]
                        sendToast({
                            message: `Upload ${batchNum} complete. ${filesSent + fileBatch.length}/${totalFiles}`,
                        })
                        if (allFiles.length)
                            createAndSendBatch(r.files.length, filesSent + fileBatch.length, batchNum + 1)
                        else {
                            setLoading(false)
                            setShowReturnsModal(null)
                            refreshReturns()
                        }
                    } else {
                        setLoading(false)
                    }
                },
            })
        }
        createAndSendBatch(r.files.length, 0, 1)
    }
    function handleSubmit() {
        const returnToSubmit: ReturnCreateInit | DamageClaimCreateInit = {
            ...r,
            // DO NOT REMOVE!! important for updated_at and created_at to be undefined.
            refund_to_customer: r.refund_to_customer || undefined,
            refund_from_manufacturer_actual: r.refund_from_manufacturer_actual || undefined,
            refund_from_manufacturer_expected: r.refund_from_manufacturer_expected || undefined,
        }
        returnToSubmit.files.map((f) => {
            return { base64: undefined, ...f }
        })

        setLoading(true)
        if (getDataSizeMB(returnToSubmit.files) > 12) {
            batchSubmit(returnToSubmit)
        } else {
            const data = new FormData()
            data.append('returns', JSON.stringify([{ ...returnToSubmit, files: [] }]))
            returnToSubmit.files.forEach((f) => data.append('0_files', f.data as File, f.name))
            try {
                if (returnToSubmit.files.length) sendToast({ message: 'Uploading images...' })

                vFetch(`/returns`, {
                    method: 'POST',
                    contentType: 'skip',
                    body: data,
                    cb: (res: any) => {
                        if (res.success) {
                            setLoading(false)
                            sendToast({ message: 'Upload successful' })
                            setShowReturnsModal(null)
                            refreshReturns()
                        } else {
                            setLoading(false)
                        }
                    },
                })
            } catch (err) {
                sendToast({
                    message:
                        'Something went wrong and the server could not be reached. Please try again or contact the developers.',
                })
                setLoading(false)
            }
        }
    }

    // styles
    function itemIsSelected(item: any) {
        if (selectedItem?.id) {
            if (JSON.stringify(item) === JSON.stringify(selectedItem))
                return 'bg-offwhite dark:bg-darkaccent/50 border-[2px] border-blue dark:border-accent'
            else return 'opacity-50'
        }
    }
    const buttonStyle =
        'p-[8px] bg-blue hover:bg-blue/90 text-white font-semibold dark:bg-accent dark:hover:bg-accent/90 dark:text-black rounded shadow-small capitalize'
    const pickItemStyle = 'hover:bg-offwhite dark:hover:bg-darkaccent rounded px-[8px]'
    const labelStyle = 'block dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
    const fieldContainerStyle = 'flex flex-col gap-[4px]'
    const inputStyle =
        'bg-lightgrey dark:bg-darkaccent p-[4px] border-[1px] dark:border-blue focus:dark:border-accent outline-0 rounded'

    // mini components
    const OrderButton = ({ currentOrder }: any) => {
        const handleChangeOrder = () => {
            setOrderSearch(currentOrder.name.replace('#', ''))
            vFetch(`/tasks?order_name=${encodeURIComponent(currentOrder.name)}`, {
                cb: (res: any) => {
                    if (res.success) {
                        vFetch(`/orders/${currentOrder.id}`, {
                            cb: (oRes: any) => {
                                if (oRes.success) {
                                    const resOrder: any = parseResObject(oRes.order)
                                    setOrder(resOrder)
                                    setShowOrderDropdown(false)
                                }
                            },
                        })
                        vFetch(`/orders/${currentOrder.id}/fulfillment-orders`, {
                            cb: (res: any) => setFulfillmentOrders(res.fulfillment_orders),
                        })
                    }
                },
            })
            setShowOrderDropdown(false)
        }
        return (
            <div
                onClick={handleChangeOrder}
                className='js-dropdown__option flex px-[8px] py-[2px] gap-[8px] items-center cursor-pointer hover:bg-[rgb(240,240,240)] dark:hover:bg-darkaccent'
            >
                <div
                    className={`w-[16px] h-[16px] border-[1px] border-[#cdcdcd] dark:border-[blueviolet] rounded js-dropdown__checkbox${
                        order?.name === currentOrder.name ? ' js-dropdown__checkbox--checked' : ''
                    }`}
                >
                    &nbsp;
                </div>
                <p className='pointer-events-none'>{currentOrder.name}</p>
            </div>
        )
    }

    return (
        <div className='text-[16px] font-normal grid gap-[16px] bg-white dark:bg-darkaccent dark:text-offwhite p-[16px] pr-[16px] pt-[32px] rounded shadow-small'>
            {/* {edited && <button className="fixed top-[50px] right-0 py-[4px] px-[16px] font-bold bg-blue text-white dark:text-darkness dark:bg-accent border-0 z-[5] uppercase shadow-small dark:shadow-cool">Save Return{returns.length > 0 ? "s" : ""}</button>} */}
            {loading && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            <div className='text-[16px] font-normal grid gap-[16px] bg-white dark:bg-darkaccent dark:text-offwhite p-[16px] overflow-auto max-h-[calc(100vh-51px-64px-100px)] border-darkness/25 border-[1px] rounded'>
                {!returnTask && (
                    <div className='flex'>
                        {!returnOrder && (
                            <div className='relative'>
                                <label className={labelStyle}>Order #</label>
                                <input
                                    className={inputStyle}
                                    type='text'
                                    id='task_order_name'
                                    name='task_order_name'
                                    onChange={(e) => setOrderSearch(e.target.value)}
                                    onFocus={() => setShowOrderDropdown(true)}
                                    autoComplete='off'
                                    value={orderSearch}
                                    ref={orderSearchInput}
                                />
                                {/* <input className={inputStyle} value={r.po_number ? r.po_number : ""} onChange={({target}) => handleChangeReturn("po_number", !isNaN(Number(target.value)) ?  parseInt(target.value) : null)}/> */}
                                {showOrderDropdown && (
                                    <div className='js-dropdown z-index-5 absolute top-[100%] max-h-[200px] overflow-auto border-[1px] border-[#cdcdcd] border-t-0 dark:border-darkgrey bg-[white] dark:text-offwhite dark:bg-darkness flex flex-col gap-[16px] left-[-1px] min-w-[100%]'>
                                        {filteredOrders.slice(0, 10).map((o) => (
                                            <OrderButton currentOrder={o} />
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* {!returnTask && (
                            <div>
                                <label className={labelStyle}>Task #</label>
                                <input
                                    className={inputStyle}
                                    value={r.task_id ? r.task_id : ''}
                                    onChange={({ target }) => {
                                        handleChangeReturn(
                                            'task_id',
                                            !isNaN(Number(target.value)) ? parseInt(target.value) : null
                                        )
                                    }}
                                />
                            </div>
                        )} */}
                    </div>
                )}
                {order?.id && <SelectReturnItem r={r} setR={setR} order={order} setSelectedItem2={setSelectedItem} />}
                {r.model?.id && (
                    <div className='grid gap-[16px]'>
                        <div className='flex'>
                            <h3 className='font-semibold text-[16px]'>
                                Item to {type} from{' '}
                                <span
                                    className='hover:underline dark:hover:text-accent cursor-pointer'
                                    onClick={(event: any) => handleNavigate(event, r?.po_number || undefined)}
                                >
                                    Order {order?.order_number}
                                </span>
                            </h3>
                        </div>
                        <div className='grow bg-white dark:bg-darkness p-[16px] rounded shadow-small'>
                            <h3 className='font-semibold text-[16px] w-[100%] border-b-[1px] border-black dark:border-grey'>
                                Item
                            </h3>
                            <div className='flex gap-[16px]'>
                                {r.model?.id ? (
                                    <div
                                        key={r.model?.id}
                                        className='flex gap-[16px] py-[24px] w-[100%] justify-between col-span-2 text-[14px]'
                                    >
                                        <div className='flex gap-[16px]'>
                                            <div className='grid w-[100px] items-center relative'>
                                                <img
                                                    className='object-contain min-w-[100px] min-h-[100px] rounded dark:bg-darkness'
                                                    src={
                                                        window.IMAGE_MAP[`gid://shopify/Product/${r.model.product_id}`]
                                                    }
                                                    alt={r.model.name}
                                                />
                                            </div>
                                            <div className=''>
                                                <a
                                                    href={`https://factorypure.com/search?q=${r.model.sku}`}
                                                    target='_blank'
                                                    className='font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                >
                                                    {r.model.title}
                                                </a>
                                                {r.model.variant_title && (
                                                    <p className='pt-[4px]'>
                                                        <strong className='text-darkgrey dark:text-grey'>
                                                            Variant:
                                                        </strong>{' '}
                                                        {r.model.variant_title}
                                                    </p>
                                                )}
                                                <p className='pt-[4px]'>
                                                    <strong className='text-darkgrey dark:text-grey'>SKU:</strong>{' '}
                                                    {r.model.sku}
                                                </p>
                                                <ul className='list-disc ml-[16px] mt-[8px]'>
                                                    {/* {selectedItem.discount_allocations.map((discount:any) => 
                                                    <li>{order.discount_applications[discount.discount_application_index].title ? order.discount_applications[discount.discount_application_index].title : order.discount_applications[discount.discount_application_index].code} discount applied</li>
                                                    )} */}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='flex gap-[32px]'>
                                            <div className='flex flex-col gap-[8px]'>
                                                <div>
                                                    <label className={labelStyle + ' text-right'}>price</label>
                                                    <div className='relative grid text-right'>
                                                        <span>{formatMoney(itemPriceAfterDiscounts(r.model))}</span>
                                                        {r.model.discount_allocations.length > 0 && (
                                                            <s
                                                                onClick={() => setShowDiscount(r.model.sku)}
                                                                className='js-order__discount text-grey hover:underline cursor-pointer'
                                                            >
                                                                {formatMoney(Number(r.model.price))}
                                                            </s>
                                                        )}
                                                        {renderDiscount(order!, r.model.sku, r.model, showDiscount)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <label className={labelStyle + ' text-right'}>Tax</label>
                                                <span className=''>
                                                    {formatMoney(getTaxTotal(r.model.tax_lines) / r.model.quantity)}
                                                </span>
                                            </div>
                                            <div>
                                                <label className={labelStyle + ' text-right'}>QTY</label>
                                                <span className='flex gap-[4px]'>{r.model.quantity}</span>
                                            </div>
                                            <div>
                                                <label className={labelStyle + ' text-right'}>Total</label>
                                                <div className='grid text-right'>
                                                    <span className=''>
                                                        {formatMoney(
                                                            Number(itemPriceAfterDiscounts(r.model)) *
                                                                r.model.quantity +
                                                                getTaxTotal(r.model.tax_lines)
                                                        )}
                                                    </span>
                                                    {r.model.discount_allocations.length > 0 && (
                                                        <s
                                                            onClick={() => setShowDiscount(r.model.sku)}
                                                            className='js-order__discount text-grey hover:underline cursor-pointer'
                                                        >
                                                            {formatMoney(
                                                                getTaxTotal(r.model.tax_lines) +
                                                                    Number(r.model.price) * r.model.quantity
                                                            )}
                                                        </s>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {!returnTask && !returnOrder && (
                                            <div className='flex gap-[16px] my-[16px]'>
                                                <div>
                                                    <label className={labelStyle}>Manufacturer</label>
                                                    <input
                                                        value={r.manufacturer}
                                                        className={inputStyle}
                                                        onChange={({ target }) =>
                                                            handleChangeReturn('manufacturer', target.value)
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <label className={labelStyle}>Model</label>
                                                    <input
                                                        value={r.model.unparsedModel}
                                                        className={inputStyle}
                                                        // onChange={({target}) => {
                                                        //     const newReturns = [...returns];
                                                        //     newReturns.splice(i, 1, {...r, model: {unparsedModel: target.value}})
                                                        //     setReturns(newReturns);
                                                        // }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='flex gap-[16px] bg-white dark:bg-darkness/50 rounded'>
                            {!returnOrder && !returnTask && (
                                <div className='w-[500px] dark:bg-darkness p-[16px] rounded shadow-small'>
                                    <h3 className='font-semibold text-[16px] w-[100%] border-b-[1px] border-black dark:border-grey mb-[16px]'>
                                        Customer
                                    </h3>
                                    <div className='flex flex-col justify-between gap-[8px] dark:bg-darkness rounded-[4px]'>
                                        <div className={fieldContainerStyle}>
                                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Company
                                            </label>
                                            <input
                                                className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                type='text'
                                                name='company'
                                                value={customer?.default_address?.company}
                                                onChange={handleChangeShippingAddress}
                                            />
                                        </div>
                                        <div className='flex gap-[8px] grow'>
                                            <div className='flex flex-col gap-[4px] w-full'>
                                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                    First Name
                                                </label>
                                                <input
                                                    className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                    type='text'
                                                    name='first_name'
                                                    value={customer?.default_address?.first_name}
                                                    onChange={handleChangeShippingAddress}
                                                />
                                            </div>
                                            <div className='flex flex-col gap-[4px] w-full'>
                                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                    Last Name
                                                </label>
                                                <input
                                                    className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                    type='text'
                                                    name='last_name'
                                                    value={customer?.default_address?.last_name}
                                                    onChange={handleChangeShippingAddress}
                                                />
                                            </div>
                                        </div>
                                        <div className={fieldContainerStyle}>
                                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Address Line 1
                                            </label>
                                            <input
                                                className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                type='text'
                                                name='address1'
                                                value={customer?.default_address?.address1}
                                                onChange={handleChangeShippingAddress}
                                            />
                                        </div>
                                        <div className={fieldContainerStyle}>
                                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Address Line 2
                                            </label>
                                            <input
                                                className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                type='text'
                                                name='address2'
                                                value={customer?.default_address?.address2}
                                                onChange={handleChangeShippingAddress}
                                            />
                                        </div>
                                        <div className='flex gap-[8px] w-full'>
                                            <div className='flex flex-col gap-[4px] w-1/3'>
                                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                    City
                                                </label>
                                                <input
                                                    className='w-full bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                    type='text'
                                                    name='city'
                                                    value={customer?.default_address?.city}
                                                    onChange={handleChangeShippingAddress}
                                                />
                                            </div>
                                            <div className='flex flex-col gap-[4px] w-1/3'>
                                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                    State
                                                </label>
                                                <input
                                                    className='w-full bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                    type='text'
                                                    name='province_code'
                                                    value={customer?.default_address?.province_code}
                                                    onChange={handleChangeShippingAddress}
                                                />
                                            </div>
                                            <div className='flex flex-col gap-[4px] w-1/3'>
                                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                    Zip
                                                </label>
                                                <input
                                                    className='w-full bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                    type='text'
                                                    name='zip'
                                                    value={customer?.default_address?.zip}
                                                    onChange={handleChangeShippingAddress}
                                                />
                                            </div>
                                        </div>
                                        <div className={fieldContainerStyle}>
                                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Country
                                            </label>
                                            <input
                                                className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                type='text'
                                                name='country'
                                                value={customer?.default_address?.country}
                                                onChange={handleChangeShippingAddress}
                                            />
                                        </div>
                                        <div className={fieldContainerStyle}>
                                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Phone
                                            </label>
                                            <input
                                                className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                type='text'
                                                name='phone'
                                                value={customer?.default_address?.phone}
                                                onChange={handleChangeShippingAddress}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {r.model && (
                                <ReturnInfoSection
                                    buttonStyle={buttonStyle}
                                    inputStyle={inputStyle}
                                    fieldContainerStyle={fieldContainerStyle}
                                    labelStyle={labelStyle}
                                    order={order}
                                    r={{ ...r }}
                                    setR={setR}
                                    handleChangeReturn={handleChangeReturn}
                                />
                            )}
                        </div>
                    </div>
                )}
                {!order?.id && <span>Enter an order or task ID #</span>}
                {showAreYouSureYouWantToSubmitReturnModal && (
                    <AreYouSureYouWantToSubmitReturnModal
                        r={r}
                        failingValues={canSubmitReturn(r)}
                        handleSubmit={handleSubmit}
                        setShowAreYouSureYouWantToSubmitReturnModal={setShowAreYouSureYouWantToSubmitReturnModal}
                    />
                )}
            </div>
            <div className='flex justify-center w-[100%] bg-white dark:bg-darkaccent min-h-[40px]'>
                {Object.values(canSubmitReturn(r)).every((v) => v === true) && selectedItem && (
                    <button className={buttonStyle} onClick={handleSubmit}>
                        Start {r.type}
                    </button>
                )}
                {!Object.values(canSubmitReturn(r)).every((v) => v === true) && selectedItem && r.model && (
                    <button
                        className={buttonStyle}
                        onClick={() => {
                            setShowAreYouSureYouWantToSubmitReturnModal(true)
                        }}
                    >
                        Submit Incomplete {r.type}
                    </button>
                )}
            </div>
        </div>
    )
}
