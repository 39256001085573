import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PlugSelectView from '../components/PlugSelectView'
import PlugView from '../components/PlugView'
import { setSections } from '../../redux/sections'

export default function PlugScreen() {
    const dispatch = useDispatch()
    const { plugs } = useSelector((state) => state).sections
    const [currentPlugs, setCurrentPlugs] = useState(plugs)
    const handleSave = (plug, index) => {
        const newPlugs = [...currentPlugs]
        newPlugs.splice(index, 1, plug)
        setCurrentPlugs(newPlugs)
    }
    const handleRemove = (index) => {
        const newPlugs = [...currentPlugs]
        newPlugs.splice(index, 1)
        setCurrentPlugs(newPlugs)
    }
    const addPlug = () => {
        setCurrentPlugs([...currentPlugs, { name: '5-20R', amount: 1 }])
    }
    useEffect(() => {
        dispatch(setSections({ plugs: currentPlugs }))
    }, [currentPlugs])
    return (
        <div className='w-full p-[16px] dark:bg-darkaccent shadow-small flex flex-col items-center'>
            <p className='dark:text-offwhite text-center dark:font-normal font-bold mb-[16px] font-bai'>
                Add and select plug types and quantities for generators
            </p>
            <div className='flex flex-col items-center'>
                {currentPlugs.map((plug, index) => (
                    <PlugSelectView
                        name={plug.name}
                        amount={plug.amount}
                        index={index}
                        handleSave={handleSave}
                        handleRemove={handleRemove}
                    />
                ))}
                <button
                    className='mx-auto text-darkgrey font-bold dark:text-fire border-2 dark:border-fire text-[18px] py-[2px] px-[16px] rounded-[4px]'
                    onClick={addPlug}
                >
                    Add Plug
                </button>
            </div>
            {plugs && plugs.length > 0 && (
                <div className='bg-white description-preview'>
                    {plugs.map((p) => (
                        <PlugView name={p.name} amount={p.amount} />
                    ))}
                </div>
            )}
        </div>
    )
}
