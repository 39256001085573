import { FaTrash } from 'react-icons/fa'
import { useDeleteFile } from '../../api/useQueries'
import { formatDate } from '../../helpers'
import { cn } from '../../../helpers'

export default function CompanyFileV2({ file }: { file: any }) {
    const deleteFile = useDeleteFile()

    const handleDeleteFile = () => {
        deleteFile.mutate({ file })
    }

    return (
        <div
            className={cn(
                deleteFile.isPending && 'opacity-50',
                'text-[14px] grid grid-cols-[1fr_1fr_1fr_1fr_50px] border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness'
            )}
        >
            <a
                key={file.name}
                href={file.src}
                style={{ wordBreak: 'break-word' }}
                className='p-[8px] font-bold dark:text-offwhite'
            >
                {file.name}
            </a>
            <div style={{ wordBreak: 'break-word' }} className='p-[8px] font-bold dark:text-offwhite'>
                {file.type}
            </div>
            <div style={{ wordBreak: 'break-word' }} className='p-[8px] font-bold dark:text-offwhite'>
                {file.file_type}
            </div>
            <div style={{ wordBreak: 'break-word' }} className='p-[8px] font-bold dark:text-offwhite'>
                {formatDate(new Date(file.created_at))}
            </div>
            <button disabled={deleteFile.isPending} onClick={handleDeleteFile}>
                <FaTrash className='text-red dark:text-lightred' />
            </button>
        </div>
    )
}
