import { Dispatch, FormEvent, SetStateAction, useState } from 'react'
import Input from '../../procurement/components/Input'
import { FaTimes } from 'react-icons/fa'
import Textarea from '../../procurement/components/Textarea'
import Select from '../../procurement/components/Select'
import { useCreateTicket } from '../api/useQueries'
import { useSelector } from 'react-redux'

export default function CreateTicket({
    setQuickModal,
}: {
    setQuickModal: Dispatch<SetStateAction<undefined | string>>
}) {
    const user = useSelector((state: any) => state.user)
    const defaultTicket = {
        title: '',
        description: '',
        status: 'triage',
        priority: 'low',
        created_by: user.id,
        is_ticket: 1,
    }
    const [ticketType, setTicketType] = useState<'[BUG]' | '[FEATURE]'>('[BUG]')
    const [ticket, setTicket] = useState({ ...defaultTicket })

    const createTicketMutation = useCreateTicket()

    const handleCreateTicket = async (e: FormEvent) => {
        e.preventDefault()
        createTicketMutation.mutate(
            { ...ticket, title: `${ticketType} ${ticket.title.trim()}` },
            {
                onSuccess: () => {
                    setQuickModal(undefined)
                    setTicket({ ...defaultTicket })
                    return
                },
            }
        )
    }

    return (
        <form
            onSubmit={handleCreateTicket}
            className='fixed min-w-[500px] pb-4 bg-white dark:bg-darkaccent z-50 bottom-0 left-[50%] translate-x-[-50%] rounded border border-darkgrey border-b-0 rounded-b-none'
        >
            <div className='p-2 rounded flex justify-between items-center bg-lightgrey dark:bg-darkness'>
                <h3 className='font-bold text-[14px] leading-tight'>New Ticket</h3>
                <button onClick={() => setQuickModal(undefined)} type='button'>
                    <FaTimes className='text-red dark:text-lightred' />
                </button>
            </div>
            <div className='flex flex-col gap-2 p-4'>
                <div className='flex gap-2'>
                    <Select
                        label='Type'
                        id='type'
                        name='type'
                        onChange={({ target }) => setTicketType(target.value as typeof ticketType)}
                        value={ticketType}
                    >
                        <option value='[BUG]'>Bug</option>
                        <option value='[FEATURE]'>Feature</option>
                    </Select>
                    <Select
                        label='Is this urgent?'
                        id='priority'
                        name='priority'
                        onChange={({ target }) => setTicket({ ...ticket, [target.name]: target.value })}
                        value={ticket.priority}
                    >
                        <option value='low'>No</option>
                        <option value='urgent'>Yes</option>
                    </Select>
                </div>
                <Input
                    label='Subject'
                    id='title'
                    name='title'
                    required={true}
                    onChange={({ target }) => setTicket({ ...ticket, [target.name]: target.value })}
                    type='text'
                    autoFocus={true}
                    value={ticket.title}
                />
                <Textarea
                    label='Description'
                    id='description'
                    name='description'
                    required={true}
                    placeholder={
                        ticketType === '[BUG]'
                            ? 'Please describe the bug in detail and, if possible, include the steps taken that led to the issue.'
                            : 'Describe your feature request here'
                    }
                    onChange={({ target }) => setTicket({ ...ticket, [target.name]: target.value })}
                    value={ticket.description}
                />
            </div>
            <button className='block h-fit mx-auto bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold py-1 px-2 leading-tight rounded'>
                Create Ticket
            </button>
        </form>
    )
}
