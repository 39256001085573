import { parseResObject, signatureHTMLString, vFetch } from '../../helpers'
import { UserInit } from '../../users/users.types'
import { CompanyFilters, CompanyTaskFilters } from '../Procurements.types'
import { CompanyProductType } from '../components/CompanyProduct'
import { CompanyTaskType } from '../components/modals/quickActions/CreateTask'
import { createdAtSort } from '../helpers'

// queries/companies.ts
const COMPANIES_BASE_URL = '/procurement/companies'

export const getCompany = async ({ companyId }: { companyId: any }) => {
    const filters = {
        id: companyId,
        lead_type: [],
        lifecycle_status: [],
        assigned_to: [],
        lastCursor: [],
    }
    const data = await vFetch(`${COMPANIES_BASE_URL}/V2?filters=${JSON.stringify(filters)}`, {})
    delete data.companies[0].updated_at
    return {
        ...data,
        company: data.companies[0],
    }
}

export const getCompanies = async ({ filters }: { filters: CompanyFilters }) => {
    const data = await vFetch(`${COMPANIES_BASE_URL}/V2?filters=${JSON.stringify(filters)}`, {})
    return data
}
export const getSearchCompanies = async ({ filters, search }: { filters: CompanyFilters; search: any }) => {
    const newFilters = {
        ...filters,
        search: search,
    }
    const data = await vFetch(`${COMPANIES_BASE_URL}/V2/search?filters=${JSON.stringify(newFilters)}`, {})
    return data
}

//company mutations

export const createCompany = async (company: any, scoreOptionSelections: any) => {
    return await vFetch(`${COMPANIES_BASE_URL}`, {
        method: 'POST',
        body: JSON.stringify({
            ...company,
            scoreOptionSelections,
        }),
    })
}
export const duplicateCompany = async (company: any) => {
    return await vFetch(`${COMPANIES_BASE_URL}/duplicate`, {
        method: 'POST',
        body: JSON.stringify({
            ...company,
        }),
    })
}

export const updateCompany = async (company: any) => {
    return await vFetch(`${COMPANIES_BASE_URL}`, {
        method: 'PUT',
        body: JSON.stringify({
            ...company,
        }),
    })
}
export const deleteCompany = async (company: any) => {
    return await vFetch(`${COMPANIES_BASE_URL}`, {
        method: 'DELETE',
        body: JSON.stringify({
            id: company.id,
        }),
    })
}

//company tasks
const TASKS_BASE_URL = '/procurement/tasks'

export const getTasks = async ({ filters }: { filters: CompanyTaskFilters }) => {
    const data = await vFetch(`${TASKS_BASE_URL}/testing?filters=${JSON.stringify(filters)}`, {})
    return data
}
export const getTasksCalendar = async ({ filters }: { filters: CompanyTaskFilters }) => {
    const data = await vFetch(`${TASKS_BASE_URL}/testing?filters=${JSON.stringify(filters)}`, {})
    return data
}
export const getSearchTasks = async ({ filters, search }: { filters: CompanyTaskFilters; search: any }) => {
    const newFilters = {
        ...filters,
        search: search,
    }
    const data = await vFetch(`${TASKS_BASE_URL}/testing?filters=${JSON.stringify(newFilters)}`, {})
    return data
}

// task mutations

export const updateTask = async (task: CompanyTaskType) => {
    return await vFetch(`${TASKS_BASE_URL}`, {
        method: 'PUT',
        body: JSON.stringify({
            ...task,
        }),
    })
}
export const deleteTask = async (task: CompanyTaskType) => {
    return await vFetch(`${TASKS_BASE_URL}`, {
        method: 'DELETE',
        body: JSON.stringify({
            id: task.id,
        }),
    })
}

//company detail

export const getEvents = async ({ companyId, users }: { companyId: number | undefined; users: UserInit[] }) => {
    // do we need to search here?
    // const data = await vFetch(`/procurement/events?company_id=${companyId}${search ? `&search=${search}` : ''}`, {
    const data = await vFetch(`/procurement/events?company_id=${companyId}`, {
        cb: (res: any) => {
            const sortedEvents = (res.events || [])
                .sort((a: any, b: any) => {
                    if (new Date(a.created_at).getTime() < new Date(b.created_at).getTime()) {
                        return -1
                    }
                    return 1
                })
                .filter((e: any) => e.type !== 'hidden')
            const parsedEvents: any[] = []
            let currentEventTime
            let currentEventGroup = []
            for (const event of sortedEvents) {
                if (event.type !== 'basic') {
                    parsedEvents.push(event)
                    continue
                }
                if (!currentEventTime) {
                    currentEventTime = new Date(event.created_at).getTime()
                    currentEventGroup = [event]
                } else if (Math.abs(new Date(event.created_at).getTime() - currentEventTime) < 5000) {
                    currentEventGroup.push(event)
                } else {
                    if (currentEventGroup.length > 1) {
                        const foundUser = users.find((u) => u.id === event.user_id)
                        parsedEvents.push({
                            type: 'basic-event-group',
                            message: `${foundUser?.first_name} ${foundUser?.last_name} (${foundUser?.user_id}) made multiple updates.`,
                            created_at: event.created_at,
                            events: currentEventGroup,
                        })
                    } else if (currentEventGroup.length === 1) {
                        parsedEvents.push(currentEventGroup[0])
                    }
                    currentEventGroup = [event]
                    currentEventTime = new Date(event.created_at).getTime()
                }
            }
            if (currentEventGroup.length > 1) {
                const firstEvent = currentEventGroup[0]
                const foundUser = users.find((u) => u.id === firstEvent.user_id)
                parsedEvents.push({
                    type: 'basic-event-group',
                    message: `${foundUser?.first_name} ${foundUser?.last_name} (${foundUser?.user_id}) made multiple updates.`,
                    created_at: firstEvent.created_at,
                    events: currentEventGroup,
                })
            } else if (currentEventGroup.length === 1) {
                parsedEvents.push(currentEventGroup[0])
            }
            const parsedData = { success: res.success, events: parsedEvents }
            return parsedData
        },
    })
    return data
}

export const getIncomingEmails = async ({
    companyEmail,
    contactList,
}: {
    companyEmail: string
    contactList: any[]
}) => {
    const emailList = [companyEmail, ...contactList.map((c) => c.email)].filter((v) => v).join(',')
    if (emailList.length) {
        return await vFetch(`/procurement/emails?from=${emailList}&to=${emailList}`, {
            cb: (res: any) => {
                res.emails.forEach((email: any) => {
                    return parseResObject(email)
                })
                const sortedIncomingEmails = res.emails.sort(createdAtSort)
                const threadIds = new Set(res.emails.map((email: any) => email.thread_id))
                let uniqueIncomingEmails: any[] = []
                sortedIncomingEmails.forEach((email: any) => {
                    if (threadIds.has(email.thread_id)) {
                        uniqueIncomingEmails.push(email)
                        threadIds.delete(email.thread_id)
                    }
                })
                res.emails = uniqueIncomingEmails
                return res
            },
        })
    }
    return []
}

// export const getTasks = async ({ companyId }: { companyId: number }) => {
//     setLoading(true)
//     vFetch(`/procurement/tasks?company_id=${companyId}`, {
//         cb: (res: any) => {
//             setTasks(res.tasks)
//         },
//     }).then(() => {
//         setLoading(false)
//         setShowQuickModal(undefined)
//     })
// }

// product
export const getProductTypes = async () => {
    return await vFetch('/v2/products/types', {})
}
export const getShopifyVendors = async () => {
    return await vFetch('/v2/products/vendors', {})
}
export const getShopifySkus = async () => {
    return await vFetch('/procurement/products/skus', {})
}
export const getProducts = async ({
    statuses,
    related_variants_group_id,
    search,
    companyId,
    lastCursor,
    limit,
    sortBy,
    fields,
}: {
    statuses: string[]
    related_variants_group_id: number | string
    search: string
    companyId: number | undefined
    lastCursor: number | undefined
    limit: number
    sortBy: { field: string; direction: string }
    fields: string
}) => {
    const queryParams = [
        statuses ? `status=${statuses.join(',')}` : null,
        related_variants_group_id ? `related_variants_group_id=${related_variants_group_id}` : null,
        search ? `search=${search}` : null,
        lastCursor ? `lastCursor=${lastCursor}` : null,
        limit ? `limit=${limit}` : null,
        fields ? `fields=${fields}` : null,
        sortBy ? `sortBy=${sortBy.field},${sortBy.direction}` : null,
        `company_id=${companyId}`,
    ]
        .filter((v) => v)
        .join('&')
    return await vFetch(`/procurement/products?${queryParams}`).then((res) => ({
        products: (res.products || [])
            .map((p: any) => ({
                ...p,
                cost: p.cost || 0,
                list_price: p.list_price || 0,
                shipping_fee: p.shipping_fee || 0,
            }))
            .map(parseResObject),
        lastCursor: res.lastCursor,
    }))
}
export const getProduct = async (id: string | number) => {
    const queryParams = [id ? `id=${id}` : null].filter((v) => v).join('&')
    return await vFetch(`/procurement/products/detail?${queryParams}`).then((res) => ({
        product: (res.product || [])
            .map((p: any) => ({
                ...p,
                cost: p.cost || 0,
                list_price: p.list_price || 0,
                shipping_fee: p.shipping_fee || 0,
            }))
            .map(parseResObject)[0],
    }))
}

export const createProduct = async ({
    companyId,
    related_variants_group_id,
}: {
    companyId: number | undefined
    related_variants_group_id: string | null
}) => {
    return await vFetch('/procurement/products', {
        method: 'POST',
        body: JSON.stringify({
            sku: 'NEWPRODUCT',
            company_id: companyId,
            status: 'calculating-margins',
            related_variants_group_id,
            weblinks: [{ link: '', title: 'Manufacturer Website' }],
        }),
    })
}
export const duplicateProduct = async (product: any) => {
    return await vFetch(`/procurement/products/duplicate`, {
        method: 'POST',
        body: JSON.stringify({
            ...product,
        }),
    })
}

export const upsertProducts = async ({
    products,
    company_id,
}: {
    products: CompanyProductType[]
    company_id: string | number
}) => {
    return await vFetch('/procurement/products', {
        method: 'PUT',
        body: JSON.stringify({
            products,
            company_id,
        }),
        skipToast: true,
    })
}
export const updateProductPricingValues = async ({
    products,
    company_id,
}: {
    products: { id: number; cost: number; shipping_fee: number; list_price: number }[]
    company_id: string | number
}) => {
    return await vFetch('/procurement/products/pricing', {
        method: 'PUT',
        body: JSON.stringify({
            products,
            company_id,
        }),
        skipToast: true,
    })
}
export const setPrimaryVariant = async ({
    products,
    company_id,
}: {
    products: CompanyProductType[]
    company_id: string | number
}) => {
    return await vFetch('/procurement/products/variantGroupings', {
        method: 'PUT',
        body: JSON.stringify({
            products,
            company_id,
        }),
    })
}

export const deleteProduct = async ({ productId }: { productId: string | number }) => {
    return await vFetch('/procurement/products', {
        method: 'DELETE',
        body: JSON.stringify({ id: productId }),
    })
}

export const getContacts = async ({ companyId }: { companyId: number | undefined }) => {
    return await vFetch(`/procurement/contacts?company_id=${companyId}`, {})
}

export const getAndReturnContacts = async ({ companyId }: { companyId: number | undefined }) => {
    return await vFetch(`/procurement/contacts?company_id=${companyId}`, {})
}

export const getFiles = async ({ companyId }: { companyId: number | undefined }) => {
    return await vFetch(`/procurement/files?company_id=${companyId}`, {})
}
export const getEmails = async ({ companyId, search }: { companyId: number | undefined; search: string }) => {
    return await vFetch(`/email?table_id=companies=${companyId}${search ? `&search=${search}` : ''}`, {
        cb: (res: any) => {
            res.emails.forEach((email: any) => {
                return parseResObject(email)
            })
            return res
        },
    })
}

// COMPANY SCORES

export const updatePositionMap = async (positionMap: any) => {
    return await vFetch('/procurement/scores/position', {
        method: 'PUT',
        body: JSON.stringify(positionMap),
    })
}

// SCORE GROUP SETUP
export const createScoreSetupGroup = async (group: any) => {
    return await vFetch('/procurement/scores/groups', {
        method: 'POST',
        body: JSON.stringify(group),
    })
}
export const getScoreSetupGroups = async () => {
    return await vFetch('/procurement/scores/groups', {
        cb: (res: any) => {
            res.groups.forEach((group: any) => {
                return parseResObject(group)
            })
            return res
        },
    })
}
export const updateScoreSetupGroup = async (group: any) => {
    return await vFetch('/procurement/scores/groups', {
        method: 'PUT',
        body: JSON.stringify({
            group: {
                id: group.id,
                name: group.name,
                is_required: group.is_required,
            },
        }),
    })
}
export const deleteScoreSetupGroup = async ({ group }: any) => {
    return await vFetch('/procurement/scores/groups', {
        method: 'DELETE',
        body: JSON.stringify(group),
    })
}

// SCORE OPTION SETUP
export const createScoreSetupOption = async (option: any) => {
    return await vFetch('/procurement/scores/options', {
        method: 'POST',
        body: JSON.stringify(option),
    })
}
export const getScoreSetupOptions = async () => {
    return await vFetch('/procurement/scores/options')
}
export const updateScoreSetupOption = async (scoreOption: any) => {
    return await vFetch('/procurement/scores/options', {
        method: 'PUT',
        body: JSON.stringify(scoreOption),
    })
}
export const deleteScoreSetupOption = async ({ option }: any) => {
    return await vFetch('/procurement/scores/options', {
        method: 'DELETE',
        body: JSON.stringify({
            id: option.id,
        }),
    })
}

export const getScores = async ({ companyId }: { companyId: number | undefined }) => {
    return await vFetch(`/procurement/scores?company_id=${companyId}`)
}

export const updateScore = async ({ companyId, option }: any) => {
    return await vFetch('/procurement/scores', {
        method: 'POST',
        body: JSON.stringify({
            company_id: companyId,
            procurement_score_group_id: option.group_id,
            procurement_score_option_id: option.option_id,
        }),
    })
}
export const deleteScore = async ({ companyId, option }: any) => {
    return await vFetch('/procurement/scores', {
        method: 'DELETE',
        body: JSON.stringify({
            company_id: companyId,
            procurement_score_group_id: option.group_id,
            procurement_score_option_id: option.option_id,
        }),
    })
}

// company details mutations

export const createTaskModal = async ({ task }: any) => {
    return await vFetch('/procurement/tasks', {
        method: 'POST',
        body: JSON.stringify(task),
    })
}

//notes

export const createNote = async ({
    user,
    note,
    companyId,
    task,
    createTask,
}: {
    user: any
    note: any
    companyId: number
    task?: any
    createTask: boolean
}) => {
    await vFetch('/procurement/events', {
        method: 'POST',
        body: JSON.stringify({
            type: 'note',
            message: `${user.firstName} ${user.lastName} (${user.user_id}) Added a new note.`,
            value: note,
            raw_data: note,
            company_id: companyId,
        }),
    })
    if (createTask) {
        return await vFetch('/procurement/tasks', {
            method: 'POST',
            body: JSON.stringify({
                ...task,
                title: note,
            }),
        })
    }
}

export const deleteNote = async (id: number | string) => {
    await vFetch('/procurement/events', {
        method: 'DELETE',
        body: JSON.stringify({ id }),
    })
}

export const updateNote = async (note: any) => {
    delete note.company_name
    await vFetch('/procurement/events', {
        method: 'PUT',
        body: JSON.stringify({ id: note.id, value: note }),
    })
}

//contacts

export const createContact = async (contact: any) => {
    return await vFetch('/procurement/contacts', {
        method: 'POST',
        body: JSON.stringify(contact),
    })
}

export const deleteContact = async (id: number | string) => {
    await vFetch('/procurement/contacts', {
        method: 'DELETE',
        body: JSON.stringify({ id }),
    })
}

export const updateContact = async (contact: any) => {
    await vFetch('/procurement/contacts', {
        method: 'PUT',
        body: JSON.stringify({ ...contact }),
    })
}

//files

export const createFile = async ({
    files,
    types,
    companyId,
    user,
}: {
    files: FileList | null
    types: string[]
    companyId: number
    user: UserInit
}) => {
    if (!files) {
        return
    }
    for (let i = 0; i < Array.from(files).length; i++) {
        const file = Array.from(files)[i]
        const type = types[i]
        const data = new FormData()
        data.append(type, file, file.name)
        await vFetch(`/procurement/files?company_id=${companyId}&user_id=${user.id}`, {
            method: 'POST',
            body: data,
            contentType: 'skip',
        })
    }
}

export const deleteFile = async (file: any) => {
    await vFetch('/procurement/files', {
        method: 'DELETE',
        body: JSON.stringify({ id: file.id, key: file.src.replace('https://fpdash-bucket.s3.amazonaws.com', '') }),
    })
}

//emails

export const createEmail = async ({ email, companyId, user }: { email: any; companyId: number; user: UserInit }) => {
    await vFetch(`/email`, {
        method: 'POST',
        body: JSON.stringify({
            ...email,
            emailText: email.body.trim(),
            user_id: user.id,
            foreign_id: companyId,
            foreign_table: 'companies',
            body: `
            <style>
                pre {
                    font-family: arial, sans-serif;
                    word-wrap: break-word;
                    white-space: pre-wrap
                }
            </style>
            <pre style="font-family: arial, sans-serif; word-wrap: break-word; white-space: pre-wrap;">${email.greeting.trim()}\n\n${email.body.trim()}</pre>
            ${signatureHTMLString(user)}`,
        }),
    })
}

//assignments

export const addAssignment = async ({
    company_id,
    user_id,
}: {
    company_id: string | number
    user_id: string | number
}) => {
    await vFetch('/procurement/assignments', {
        method: 'POST',
        body: JSON.stringify({ assignments: [{ company_id, user_id }] }),
    })
}

export const removeAssignment = async ({
    company_id,
    user_id,
}: {
    company_id: string | number
    user_id: string | number
}) => {
    await vFetch('/procurement/assignments', {
        method: 'DELETE',
        body: JSON.stringify({ company_id, user_id }),
    })
}

// export const createNoteEvent = async (event: any) => {
//     return vFetch(`${EVENTS_BASE_URL}`, {
//         method: 'POST',
//         body: JSON.stringify({
//             type: 'note',
//             message: `${event.user.firstName} ${event.user.lastName} (${event.user.user_id}) Added a new note.`,
//             value: event.editableNote,
//             raw_data: event.editableNote,
//             company_id: event.id,
//         }),
//     })
// }

export const getAlerts = async ({ companyId }: { companyId?: number | string }) => {
    vFetch(`/procurement/alerts?affects=products&company_id=${companyId}`)
    return await vFetch(`/procurement/alerts?affects=products&company_id=${companyId}`)
}

export const createAlert = async (body: any) => {
    return await vFetch('/procurement/alerts', {
        method: 'POST',
        body: JSON.stringify(body),
    })
}

export const deleteAlert = async (alertId: string | number) => {
    return await vFetch('/procurement/alerts', {
        method: 'DELETE',
        body: JSON.stringify({
            id: alertId,
        }),
    })
}

export const addComment = async ({ value, product_id }: { value: string; product_id: string | number }) => {
    return await vFetch('/procurement/products/comments', {
        method: 'POST',
        body: JSON.stringify({
            value,
            product_id,
        }),
        skipToast: true,
    })
}
export const addVariantGrouping = async ({
    sku,
    product,
    company_id,
}: {
    sku: string | number
    product: any
    company_id: number | string
}) => {
    return await vFetch('/procurement/products/variantGroupings', {
        method: 'POST',
        body: JSON.stringify({
            sku,
            product,
            company_id,
        }),
    })
}
export const deleteVariantGrouping = async ({
    related_variants_group_id,
}: {
    related_variants_group_id: string | number
}) => {
    return await vFetch('/procurement/products/variantGroupings', {
        method: 'DELETE',
        body: JSON.stringify({
            related_variants_group_id,
        }),
    })
}

//PARENT COMPANIES

export const getParentCompany = async (parentCompany: string) => {
    const data = await vFetch(`/procurement/companies/parent?parent_company=${encodeURIComponent(parentCompany)}`)
    return data
}

export const getParentCompanyContacts = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/procurement/contacts/parent?${query}`)
    data.contacts = data.contacts?.map((contact: any) => ({
        ...contact,
        company_name: companyNameMap[contact.company_id as keyof typeof companyNameMap],
    }))
    return data
}

export const getParentCompanyEmails = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/procurement/emails/parent?${query}`)
    data.emails?.map(parseResObject)
    data.emails = data.emails?.map((email: any) => ({
        ...email,
        company_name: companyNameMap[email.company_id as keyof typeof companyNameMap],
    }))
    return data
}

export const getParentCompanyGmails = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/procurement/emails/parent/gmail?${query}`)
    return data
}

export const getParentCompanyEvents = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/procurement/events/parent?${query}`)
    data.events = data.events?.map((event: any) => ({
        ...event,
        company_name: companyNameMap[event.company_id as keyof typeof companyNameMap],
    }))
    return data
}

export const getIncomingParentCompanyEmails = async (contactList: any[], companyNameMap: any) => {
    const emailList = contactList
        .map((c) => c.email)
        .filter((v) => v)
        .join(',')
    if (emailList.length) {
        const data = await vFetch(`/procurement/emails?from=${emailList}&to=${emailList}`)
        data.incomingEmails?.map(parseResObject)

        const sortedIncomingEmails = data.emails.sort(createdAtSort)
        const threadIds = new Set(data.emails.map((email: any) => email.thread_id))
        let uniqueIncomingEmails: any[] = []
        sortedIncomingEmails.forEach((email: any) => {
            if (threadIds.has(email.thread_id)) {
                uniqueIncomingEmails.push(email)
                threadIds.delete(email.thread_id)
            }
        })
        data.emails = uniqueIncomingEmails
        data.emails = data.emails?.map((gmail: any) => ({
            ...gmail,
            company_name: companyNameMap[gmail.company_id],
        }))
        return data
    }
    return []
}

export const getParentCompanyFiles = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/procurement/files/parent?${query}`)
    data.files = data.files?.map((file: any) => ({ ...file, company_name: companyNameMap[file.company_id] }))
    return data
}

export const getParentCompanyTasks = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/procurement/tasks/parent?${query}`)
    data.tasks = data.tasks?.map((task: any) => ({ ...task, name: companyNameMap[task.company_id] }))
    return data
}

//PARENT COMPANIES MUTATIONS

export const createParentCompanyNote = async ({ note, user, company_ids }: any) => {
    await vFetch('/procurement/events/parent', {
        method: 'POST',
        body: JSON.stringify({
            event: {
                type: 'note',
                message: `${user.firstName} ${user.lastName} (${user.user_id}) Added a new note.`,
                value: note,
                raw_data: note,
            },
            company_ids,
        }),
    })
}
export const createParentCompanyContact = async ({ contact, company_ids }: any) => {
    await vFetch('/procurement/contacts/parent', {
        method: 'POST',
        body: JSON.stringify({ contact, company_ids }),
    })
}
