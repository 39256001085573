import { useEffect, useState } from 'react'
import { LoadingGear, vFetch } from '../../helpers'
import { useSelector } from 'react-redux'

export default function Identifiers({
    product_id,
    refreshProductSetupEvents,
}: {
    product_id: number | string
    refreshProductSetupEvents: Function
}) {
    const [variants, setVariants] = useState<any[]>([])
    const [OriginalVariants, setOriginalVariants] = useState<any[]>([])

    const user = useSelector<any, any>((state) => state.user)
    const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>(0)
    const [edited, setEdited] = useState(false)
    const [changedFields, setChangedFields] = useState<string[]>([])
    const selectedVariant = JSON.parse(
        JSON.stringify(variants[selectedVariantIndex] || {}, (key, value) => (value === null ? '' : value), 2)
    )
    const [loading, setLoading] = useState(true)

    const saveIdentifiers = async (variant: VariantType, skipToast?: boolean) => {
        let events: Object[] = []
        changedFields.forEach((field: string) => {
            const value = variant[field as keyof typeof variant]
            const previousValue = OriginalVariants[selectedVariantIndex][field as keyof typeof variant]
            if (!(value === previousValue)) {
                events.push({
                    step: `identifiers`,
                    field,
                    variant_name: variant.title,
                    updated_value: value,
                    previous_value: previousValue,
                    product_id: product_id,
                    user_id: user.id,
                })
            }
        })
        vFetch(`/productSetupEvents`, {
            method: 'POST',
            body: JSON.stringify({
                events: events,
            }),
        })
        await vFetch('/v2/variants/identifiers', {
            method: 'PUT',
            body: JSON.stringify({
                id: `gid://shopify/ProductVariant/${variant.id}`,
                sku: variant.sku,
                barcode: variant.barcode,
                custom_supplier_sku: variant.custom_supplier_sku || null,
                custom_supplier_model_number: variant.custom_supplier_model_number || null,
                custom_upc: variant.custom_upc || null,
                custom_part_number: variant.custom_part_number || null,
            }),
            skipToast,
        })
        setChangedFields([])
        setOriginalVariants(variants)
        refreshProductSetupEvents(product_id)
    }
    const handleChange = async ({ target }: { target: HTMLInputElement }) => {
        setEdited(true)
        if (!changedFields.includes(target.name)) {
            setChangedFields([...changedFields, target.name])
        }

        setVariants((previousState: any) => {
            const newState = structuredClone(previousState)
            newState[selectedVariantIndex] = {
                ...newState[selectedVariantIndex],
                [target.name]: target.value,
            }
            return newState
        })
    }

    const handleChangeVariant = async (index: number) => {
        if (edited) {
            setEdited(false)
            saveIdentifiers(selectedVariant, true)
        }
        setSelectedVariantIndex(index)
    }

    useEffect(() => {
        vFetch(`/v2/variants?product_id=${product_id}`, {
            cb: (res: any) => {
                if (res.success) {
                    setVariants(res.variants)
                    setOriginalVariants(res.variants)
                }
            },
        }).then(() => setLoading(false))
    }, [])

    return (
        <>
            <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col shadow-small overflow-hidden'>
                <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                    Identifiers
                </p>
                {loading ? (
                    <div className='p-[32px] grid place-items-center'>
                        <LoadingGear />
                    </div>
                ) : (
                    <>
                        <div className='flex gap-[16px]'>
                            <div className='w-1/3 flex flex-col gap-[8px]'>
                                <p className='uppercase font-light text-[18px] dark:text-offwhite mb-[8px] border-b '>
                                    Select Variant
                                </p>
                                {variants.map((variant, index: number) => (
                                    <button
                                        onClick={() => handleChangeVariant(index)}
                                        disabled={variant.id === selectedVariant.id}
                                        className={`${
                                            variant.id === selectedVariant.id
                                                ? 'bg-blue dark:bg-accent text-white dark:text-darkaccent'
                                                : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                                        } py-[4px] px-[16px] rounded-[4px] font-bold`}
                                    >
                                        {variant.title}
                                    </button>
                                ))}
                            </div>
                            <div className='w-1/3'>
                                <p className='uppercase font-light text-[18px] dark:text-offwhite mb-[8px] border-b '>
                                    In-House Identifiers
                                </p>
                                <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='sku'
                                    >
                                        SKU
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='sku'
                                            type='text'
                                            value={selectedVariant.sku}
                                            name='sku'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='barcode'
                                    >
                                        Barcode
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='barcode'
                                            type='text'
                                            value={selectedVariant.barcode}
                                            name='barcode'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='w-1/3'>
                                <p className='uppercase font-light text-[18px] dark:text-offwhite mb-[8px] border-b '>
                                    Supplier Identifiers
                                </p>
                                <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='custom_supplier_sku'
                                    >
                                        SKU
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='custom_supplier_sku'
                                            type='text'
                                            value={selectedVariant.custom_supplier_sku}
                                            name='custom_supplier_sku'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='custom_supplier_model_number'
                                    >
                                        Model Name/Number
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='custom_supplier_model_number'
                                            type='text'
                                            value={selectedVariant.custom_supplier_model_number}
                                            name='custom_supplier_model_number'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='custom_upc'
                                    >
                                        UPC
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='custom_upc'
                                            type='text'
                                            value={selectedVariant.custom_upc}
                                            name='custom_upc'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='custom_part_number'
                                    >
                                        Part Number
                                    </label>
                                    <div className='relative w-full'>
                                        <input
                                            id='custom_part_number'
                                            type='text'
                                            value={selectedVariant.custom_part_number}
                                            name='custom_part_number'
                                            autoComplete='off'
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {edited && (
                            <button
                                className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                                onClick={() => saveIdentifiers(selectedVariant)}
                            >
                                Save Changes
                            </button>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

type VariantType = {
    id: string
    sku?: string
    barcode?: string
    custom_supplier_sku?: string
    custom_supplier_model_number?: string
    custom_upc?: string
    custom_part_number?: string
    title?: string
}
