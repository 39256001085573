import './styles/description.css'
import { useEffect, useState } from 'react'
import ProductMenu from './components/ProductMenu'
import ProductSelect from './components/ProductSelect'
import { MetafieldUpdateItem, SetupEventCreateType, SetupEventType, ShopifyProduct } from './ProductSetup.types'
import AreYouSure from './components/AreYouSure'
import Checkmarks from './steps/Checkmarks'
import Product from './steps/Product'
import { fetchAllUsers, vFetch } from '../helpers'
import Plugs from './steps/Plugs'
import Features from './steps/Features'
import Specifications from './steps/Specifications'
import Warranty from './steps/Warranty'
import Manuals from './steps/Manuals'
import CrossSells from './steps/CrossSells'
import Preview from './steps/Preview'
import Filters from './steps/Filters'
import { Link } from 'react-router-dom'
import Identifiers from './steps/Identifiers'
import PrivateEvent from '../tasks/components/PrivateEvent'
import { useSelector } from 'react-redux'
import { PrivateEventType } from '../tasks/tasks.types'
import PrivateEventModal from '../tasks/components/PrivateEventModal'
import { UserInit } from '../users/users.types'
import ProductsAllPrivateEventModal from './components/ProductsAllPrivateEventModal'
import Group from './steps/Group'
import ProductSetupEvents from './steps/ProductSetupEvents'
import Images from './steps/Images'
import { useAnalytics } from 'use-analytics'

export default function ProductSetupV2() {
    const analytics = useAnalytics()
    const user = useSelector<any, any>((state) => state.user)
    const [initialProduct, setInitialProduct] = useState<ShopifyProduct>({})
    const [product, setProduct] = useState<ShopifyProduct>({})
    const [step, setStep] = useState('product')
    const [edited, setEdited] = useState(false)
    const [savedStep, setSavedStep] = useState('')
    const [status, setStatus] = useState('pending')
    const [showHistory, setShowHistory] = useState(false)

    const [productSetupEventsPrivate, setProductSetupEventsPrivate] = useState<PrivateEventType[]>([])
    const [latestProductSetupEventsPrivate, setLatestProductSetupEventsPrivate] = useState<PrivateEventType[]>([])
    const [privateEventLogged, setPrivateEventLogged] = useState(false)
    const [showProductSetupEventsPrivate, setShowProductSetupEventsPrivate] = useState(false)
    const [showAllProductsSetupEventsPrivate, setShowAllProductsSetupEventsPrivate] = useState(false)
    const [userList, setUserList] = useState<UserInit[]>([])
    const [events, setProductEvents] = useState([])

    function refreshProductSetupEvents(productId?: number) {
        if (productId) {
            vFetch(`/productSetupEvents?product_id=${productId}`, {
                cb: (res: any) => {
                    if (res.success) {
                        setProductEvents(res.events)
                    }
                },
            })
        }
    }

    useEffect(() => {
        if (Object.entries(product).length && !privateEventLogged) {
            analytics.page()
        }
    }, [product])

    useEffect(() => {
        const sortedProduct = JSON.stringify(product).split('').sort().join('')
        const sortedInitialProduct = JSON.stringify(initialProduct).split('').sort().join('')
        if (sortedProduct !== sortedInitialProduct) {
            setEdited(true)
        } else {
            setEdited(false)
        }
    }, [product, initialProduct])

    useEffect(() => {
        fetchAllUsers(setUserList)
        vFetch(`/productSetupEventsPrivate/latest`, {
            cb: (res: any) => {
                return setLatestProductSetupEventsPrivate(res.productSetupEventsPrivate)
            },
        })
    }, [])
    const handleStepChange = (step: string, continueWithoutSaving: boolean) => {
        if (edited && !continueWithoutSaving) {
            setSavedStep(step)
        } else {
            setProduct(() => structuredClone(initialProduct))
            setSavedStep('')
            setStep(step)
        }
    }

    const handleFetchProduct = (productId?: number) => {
        if (productId) {
            setStatus('loading-product')

            vFetch(`/productSetupEventsPrivate?product_id=${productId}`, {
                cb: (res: any) => {
                    return setProductSetupEventsPrivate(res.productSetupEventsPrivate)
                },
            })

            vFetch(`/v2/products?id=${productId}`, {
                cb: (res: any) => {
                    const currentProduct = res.products[0]
                    if (!res.success) {
                        return
                    }
                    setStatus('loaded')
                    refreshProductSetupEvents(productId)
                    setProduct({
                        ...currentProduct,
                        checkmarks: JSON.parse(currentProduct.custom_checkmarks || '[]'),
                        plugs: JSON.parse(currentProduct.custom_plugs || '[]'),
                        features: JSON.parse(currentProduct.custom_features || '[]'),
                        specifications: JSON.parse(currentProduct.custom_specifications || '[]'),
                        package_contents: JSON.parse(currentProduct.custom_package_contents || '[]'),
                        warranty: currentProduct.custom_warranty,
                        manuals: JSON.parse(currentProduct.custom_manuals || '[]'),
                        cross_sells: JSON.parse(currentProduct.custom_cross_sells || '[]'),
                        custom_options: JSON.parse(currentProduct.custom_options || '[]'),
                    })
                    setInitialProduct({
                        ...currentProduct,
                        checkmarks: JSON.parse(currentProduct.custom_checkmarks || '[]'),
                        plugs: JSON.parse(currentProduct.custom_plugs || '[]'),
                        features: JSON.parse(currentProduct.custom_features || '[]'),
                        specifications: JSON.parse(currentProduct.custom_specifications || '[]'),
                        package_contents: JSON.parse(currentProduct.custom_package_contents || '[]'),
                        warranty: currentProduct.custom_warranty,
                        manuals: JSON.parse(currentProduct.custom_manuals || '[]'),
                        cross_sells: JSON.parse(currentProduct.custom_cross_sells || '[]'),
                        custom_options: JSON.parse(currentProduct.custom_options || '[]'),
                    })
                    if (!privateEventLogged) {
                        if (user && productId) {
                            vFetch(`/productSetupEventsPrivate`, {
                                skipToast: true,
                                method: 'POST',
                                body: JSON.stringify({
                                    product_id: productId,
                                    user_id: user.id,
                                    event_type: 'viewed',
                                }),
                                cb: (res: any) => {
                                    if (res.success) {
                                        setPrivateEventLogged(true)
                                    }
                                },
                            })
                        }
                    }
                },
            })
        }
    }

    const handleUpdateProduct = (changedFields: string[]) => {
        vFetch('/v2/descriptions', {
            method: 'POST',
            body: JSON.stringify({
                id: product.id,
                body_html: product.body_html,
                product_type: product.product_type,
                status: product.status,
                title: product.title,
                vendor: product.vendor,
                metafields: [
                    {
                        ownerId: `gid://shopify/Product/${product.id}`,
                        namespace: 'custom',
                        key: 'custom_note',
                        value: product.custom_custom_note,
                    },
                ],
            }),
            cb: (res: any) => {
                if (res.success) {
                    let events: SetupEventCreateType[] = []
                    changedFields.forEach((field: string) => {
                        const value = product[field as keyof typeof product]
                        const previousValue = initialProduct[field as keyof typeof initialProduct]
                        if (!(value === previousValue)) {
                            events.push({
                                step: 'product',
                                field,
                                updated_value: value ? value : '',
                                previous_value: previousValue ? previousValue : '',
                                product_id: product.id,
                                user_id: user.id,
                            })
                        }
                    })
                    vFetch(`/productSetupEvents`, {
                        method: 'POST',
                        body: JSON.stringify({
                            events: events,
                        }),
                    })
                }
                refreshProductSetupEvents(product.id)
                handleFetchProduct(product.id)
            },
        })
    }

    const handleUpdateMetafield = (metafields: MetafieldUpdateItem[], skipToast?: boolean) => {
        let events: SetupEventCreateType[] = []
        let value: any
        let previousValue: any
        if (metafields[0].key === 'cross_sells') {
            value = JSON.stringify(metafields[0].crossSellTitles) || ['']
            previousValue = JSON.stringify(metafields[0].oldCrossSellTitles)
        } else {
            const key = `custom_${metafields[0].key}`
            value = metafields[0].value
            previousValue = initialProduct[key as keyof typeof initialProduct]
        }
        if (!(value === previousValue)) {
            events.push({
                step: metafields[0].key,
                field: metafields[0].key,
                updated_value: value,
                previous_value: previousValue ? previousValue : '',
                product_id: product.id,
                user_id: user.id,
            })
        }
        vFetch(`/productSetupEvents`, {
            method: 'POST',
            body: JSON.stringify({
                events: events,
            }),
        })
        delete metafields[0].crossSellTitles
        delete metafields[0].oldCrossSellTitles
        vFetch('/v2/descriptions/metafields', {
            method: 'POST',
            body: JSON.stringify({ metafields }),
            cb: () => {
                refreshProductSetupEvents(product.id)
                handleFetchProduct(product.id)
            },
            skipToast,
        })
    }

    const handleClearProduct = () => {
        setProduct({})
        setInitialProduct({})
        setStatus('pending')
        setStep('product')
        setPrivateEventLogged(false)
        setProductSetupEventsPrivate([])
    }
    return (
        <>
            <div className=''>
                {productSetupEventsPrivate && showAllProductsSetupEventsPrivate && (
                    <ProductsAllPrivateEventModal
                        setPrivateEventLogged={setPrivateEventLogged}
                        handleFetchProduct={handleFetchProduct}
                        origin='product'
                        userList={userList}
                        setViewModal={setShowAllProductsSetupEventsPrivate}
                        events={latestProductSetupEventsPrivate}
                    />
                )}
                {productSetupEventsPrivate && showProductSetupEventsPrivate && (
                    <PrivateEventModal
                        origin='product'
                        userList={userList}
                        setViewModal={setShowProductSetupEventsPrivate}
                        events={productSetupEventsPrivate}
                    />
                )}
                <div className='flex justify-between items-center'>
                    <div className='mb-2 flex flex-col'>
                        <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize w-[100%]'>
                            Product Setup
                        </h1>
                        {(user.type === 'Admin' || user.type === 'SuperAdmin') &&
                            productSetupEventsPrivate.length > 0 && (
                                <PrivateEvent
                                    events={productSetupEventsPrivate}
                                    setViewModal={setShowProductSetupEventsPrivate}
                                    userList={userList}
                                />
                            )}
                    </div>
                    <div className='flex gap-1'>
                        {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                            <button
                                className='whitespace-nowrap py-[2px] px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkaccent dark:text-offwhite text-[12px] font-bold uppercase'
                                onClick={() => setShowAllProductsSetupEventsPrivate(true)}
                            >
                                Latest Events
                            </button>
                        )}
                        <Link
                            className='whitespace-nowrap py-[2px] px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkaccent dark:text-offwhite text-[12px] font-bold uppercase'
                            to={'/product-setup/old'}
                        >
                            Legacy Setup Screen
                        </Link>
                    </div>
                </div>
                <ProductMenu
                    step={step}
                    handleStepChange={handleStepChange}
                    productIsSelected={!!product.id}
                    showHistory={showHistory}
                    setShowHistory={setShowHistory}
                />
                <div className={`${product.id ? '' : 'shadow-small'}`}>
                    <ProductSelect
                        product={product}
                        setProduct={setProduct}
                        status={status}
                        setStatus={setStatus}
                        handleClearProduct={handleClearProduct}
                        handleFetchProduct={handleFetchProduct}
                    />
                    {product.id && (
                        <>
                            <div className={`grid ${showHistory ? 'grid-cols-[66%_33%]' : 'grid-cols-1'} gap-2`}>
                                <div>
                                    {step === 'product' && (
                                        <Product
                                            product={product}
                                            setProduct={setProduct}
                                            handleUpdateMetafield={handleUpdateMetafield}
                                            handleUpdateProduct={handleUpdateProduct}
                                            edited={edited}
                                        />
                                    )}
                                    {step === 'images' && product.id && <Images product={product} />}
                                    {step === 'identifiers' && product.id && (
                                        <Identifiers
                                            product_id={product.id}
                                            refreshProductSetupEvents={refreshProductSetupEvents}
                                        />
                                    )}
                                    {step === 'checkmarks' && (
                                        <Checkmarks
                                            product={product}
                                            setProduct={setProduct}
                                            handleUpdateMetafield={handleUpdateMetafield}
                                            edited={edited}
                                        />
                                    )}
                                    {step === 'plugs' && (
                                        <Plugs
                                            product={product}
                                            setProduct={setProduct}
                                            handleUpdateMetafield={handleUpdateMetafield}
                                            edited={edited}
                                        />
                                    )}
                                    {step === 'features' && (
                                        <Features
                                            product={product}
                                            setProduct={setProduct}
                                            handleUpdateMetafield={handleUpdateMetafield}
                                            edited={edited}
                                        />
                                    )}
                                    {step === 'specifications' && (
                                        <Specifications
                                            product={product}
                                            setProduct={setProduct}
                                            handleUpdateMetafield={handleUpdateMetafield}
                                            edited={edited}
                                        />
                                    )}
                                    {step === 'warranty' && (
                                        <Warranty
                                            product={product}
                                            setProduct={setProduct}
                                            handleUpdateMetafield={handleUpdateMetafield}
                                            edited={edited}
                                        />
                                    )}
                                    {step === 'manuals' && (
                                        <Manuals
                                            product={product}
                                            setProduct={setProduct}
                                            handleUpdateMetafield={handleUpdateMetafield}
                                            edited={edited}
                                        />
                                    )}
                                    {step === 'cross-sells' && (
                                        <CrossSells
                                            product={product}
                                            setProduct={setProduct}
                                            handleUpdateMetafield={handleUpdateMetafield}
                                            edited={edited}
                                        />
                                    )}
                                    {step === 'filters' && (
                                        <Filters
                                            product={product}
                                            refreshProductSetupEvents={refreshProductSetupEvents}
                                        />
                                    )}
                                    {step === 'preview' && <Preview product={product} />}
                                    {step === 'group' && (
                                        <Group
                                            product={product}
                                            setProduct={setProduct}
                                            handleUpdateMetafield={handleUpdateMetafield}
                                            edited={edited}
                                        />
                                    )}
                                </div>
                                {showHistory && (
                                    <ProductSetupEvents
                                        userList={userList}
                                        events={events}
                                        product={product}
                                        setShowHistory={setShowHistory}
                                        step={step}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            {savedStep && (
                <AreYouSure savedStep={savedStep} setSavedStep={setSavedStep} handleStepChange={handleStepChange} />
            )}
        </>
    )
}
