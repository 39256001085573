import { useQueryClient } from '@tanstack/react-query'
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { FaCartPlus, FaCheck, FaLayerGroup, FaPlusCircle } from 'react-icons/fa'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { MdOutlineSdCardAlert, MdPending } from 'react-icons/md'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FetchingSpinner } from '../../../custom_components/FetchingSpinner'
import { Button } from '../../../custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from '../../../custom_components/component_Basics/FilterBarSelectorButton'
import { SelectorButton } from '../../../custom_components/component_Basics/SelectorButton'
import { cn, sendToast, useDebounce } from '../../../helpers'
import { procurementQueries } from '../../api/procurementQueryKeys'
import {
    useAddVariantGrouping,
    useCompanyProductsQuery,
    useCreateProduct,
    useDeleteVariantGrouping,
    useUpsertProduct,
} from '../../api/useQueries'
import { ProcurementContext, ProcurementContextType, ProductPollingContext } from '../../helpers'
import CompanyProduct, { CompanyProductType } from '../CompanyProduct'
import { CompanyProductHeader } from '../CompanyProductHeader'
import FilterToolBar from '../FilterToolBar'
import TaskListItemSkeleton from '../listItems/TaskListItemSkeleton'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { TbAlertCircleFilled } from 'react-icons/tb'
import Select from '../Select'
import { PROCUREMENT_PRODUCT_SORT_OPTIONS } from '../../constants'
import CompanyProductSkeleton from '../CompanyProductSkeleton'

export default function ProductListV2() {
    const alertInputContainerRef = useRef<HTMLInputElement>(null)
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const [showNewAlert, setShowNewAlert] = useState(false)
    const { appState, pendingEdits, setSelectedProductId, selectedProductId } = useContext<any>(ProductPollingContext)
    const [search, setSearch] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const variantGroupingId = searchParams.get('group_id') || null
    const debouncedSearch = useDebounce(search, 500)
    const toastId = React.useRef<any>(null)
    const [showGroupDelete, setShowGroupDelete] = useState(false)
    const [cursorList, setCursorList] = useState<any>([0])
    const [selectedLastCursor, setSelectedLastCursor] = useState<any>(undefined)
    const [page, setPage] = useState(1)
    const [sortIndex, setSortIndex] = useState(0)

    const [productListLength, setProductListLength] = useState<number | undefined>(undefined)
    const [selectedFilterValues, setSelectedFilterValues] = useState<any>({
        statuses: [],
    })
    const companyProductsQuery = useCompanyProductsQuery(
        {
            ...selectedFilterValues,
            search: debouncedSearch,
            companyId: selectedCompany.id,
            related_variants_group_id: variantGroupingId || null,
            lastCursor: selectedLastCursor || undefined,
            sortBy: PROCUREMENT_PRODUCT_SORT_OPTIONS[sortIndex].value,
            limit: 20,
        },
        appState
    )
    const allCompanyProductIdsQuery = useCompanyProductsQuery(
        {
            ...selectedFilterValues,
            companyId: selectedCompany.id,
            limit: 10000,
        },
        appState
    )
    const {
        products: allProducts,
    }: {
        products: CompanyProductType[]
    } = allCompanyProductIdsQuery?.data || { products: [] }

    const { isLoading: loadingProducts, isFetching: fetchingProducts } = companyProductsQuery
    const [showMoreActions, setShowMoreActions] = useState(false)
    const { products, lastCursor }: { products: CompanyProductType[]; lastCursor: number | undefined } =
        companyProductsQuery?.data || { products: [] }

    const createProduct = useCreateProduct(selectedCompany.id)
    const upsertProducts = useUpsertProduct(selectedCompany.id)
    const addVariantGrouping = useAddVariantGrouping(selectedCompany.id)
    const deleteVariantGrouping = useDeleteVariantGrouping(selectedCompany.id)

    const lastItem = useRef<HTMLDivElement>(null)

    const handleSortChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setSortIndex(parseInt(target.value))
    }

    const filterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: [
                    {
                        value: 'calculating-margins',
                        label: 'Calculating Margins',
                        icon: MdPending,
                    },
                    {
                        value: 'ready',
                        label: `Ready To List`,
                        icon: FaPlusCircle,
                    },
                    {
                        value: 'needs-review',
                        label: `Needs Review`,
                        icon: TbAlertCircleFilled,
                    },
                    {
                        value: 'unlistable',
                        label: 'Unlistable',
                        icon: IoMdCloseCircleOutline,
                    },
                    {
                        value: 'listed',
                        label: 'Listed',
                        icon: FaCheck,
                    },
                ],
                title: 'Status',
                field: 'statuses',
                values: selectedFilterValues.statuses,
                searchToggle: false,
            },
        ],
        setFunction: setSelectedFilterValues,
        resetFunction: () => {
            setSelectedFilterValues({
                statuses: [],
            })
        },
    }

    const queryClient = useQueryClient()
    useEffect(() => {
        return () => {
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(selectedCompany.id).queryKey,
            })
        }
    }, [])

    if (productListLength && products.length > productListLength) {
        setSelectedProductId(products[products.length - 1].id)
        setProductListLength(undefined)
    }

    // function scrollToLatest() {
    //     const element = lastItem
    //     element.current?.scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'center',
    //         inline: 'start',
    //     })
    // }
    function sortByPrimaryVariant(a: any, b: any) {
        if (a.is_primary_variant) {
            a = 0
            b = 1
        } else {
            a = 1
            b = 0
        }
        return a - b
    }

    let filteredProducts = structuredClone(products)
    if (variantGroupingId) {
        filteredProducts = structuredClone(products)
            ?.filter((product: any) => product.related_variants_group_id == variantGroupingId)
            .sort(sortByPrimaryVariant)
    }
    let filteredProductIds = structuredClone(allProducts)
    if (variantGroupingId) {
        filteredProductIds = structuredClone(allProducts)
            ?.filter((product: any) => product.related_variants_group_id == variantGroupingId)
            .sort(sortByPrimaryVariant)
    }

    function handleNewVariantGroupingSetup() {
        setSearchParams((prev: any) => {
            prev.set('group_id', 1)
            return prev
        })
    }
    function handleCreateNewVariantGrouping(target: any) {
        const [sku, productId] = target.split(',')
        const relatedProduct = allProducts?.filter((product) => product.id == productId)[0]
        addVariantGrouping.mutate(
            { product: relatedProduct, sku, company_id: selectedCompany?.id },
            {
                onSuccess: (data) => {
                    setSearchParams((prev: any) => {
                        prev.set('group_id', data.variantGroupingId)
                        return prev
                    })
                },
            }
        )
    }
    function handleChangeGrouping(target: any) {
        const [sku, productId] = target.split(',')
        const relatedProduct = allProducts.filter((product) => product.id == productId)[0]
        if (!relatedProduct) {
            return
        }
        if (relatedProduct.is_primary_variant) {
            return
        }
        upsertProducts.mutate(
            {
                products: [
                    {
                        ...relatedProduct,
                        related_variants_group_id: relatedProduct.related_variants_group_id ? null : variantGroupingId,
                    },
                ],
                company_id: selectedCompany.id,
            },
            {
                onSuccess: () => {},
            }
        )
    }

    function handleDeleteVariantGroup(event: any): void {
        if (variantGroupingId) {
            setSearchParams((prev: any) => {
                prev.delete('group_id')
                return prev
            })
            deleteVariantGrouping.mutate(
                {
                    related_variants_group_id: variantGroupingId,
                    company_id: selectedCompany.id,
                },
                {
                    onSuccess: () => {
                        setShowGroupDelete(false)
                    },
                }
            )
        } else {
            return
        }
    }

    const currentCursor = lastCursor

    if (!companyProductsQuery.isFetching && !companyProductsQuery.isLoading) {
        if (currentCursor) {
            if (!cursorList.includes(currentCursor)) {
                setCursorList((prev: any[]) => {
                    return [...prev, currentCursor]
                })
            }
        }
    }

    useEffect(() => {
        setSelectedLastCursor(undefined)
        setCursorList([0])
        setPage(1)
    }, [search, selectedFilterValues, variantGroupingId, sortIndex])

    function handlePageIncrease() {
        if (!(currentCursor === null)) {
            setSelectedLastCursor(currentCursor)
            setPage((prev: any) => {
                return prev + 1
            })
        }
    }
    function handlePageDecrease() {
        setSelectedLastCursor(cursorList[page - 2] || undefined)
        setPage((prev: any) => Math.max(prev - 1, 1))
    }

    return (
        <div className=' pr-1 min-h-full'>
            <div className='w-full flex h-[48px] sticky top-10 bg-white dark:bg-darkaccent z-[7] items-start mt-[-4px]'>
                <FilterToolBar search={search} setSearch={setSearch} filterBarObject={filterBarObject}>
                    {variantGroupingId === '1' && (
                        <div className='flex gap-3 mr-20'>
                            <div className='self-center'>Creating New Variant Group</div>
                            <Button
                                onClick={() => {
                                    setSearchParams((prev: any) => {
                                        prev.delete('group_id')
                                        return prev
                                    })
                                }}
                                variant={'outline'}
                                size={'sm'}
                            >
                                <div className='flex gap-2'>
                                    <RiArrowGoBackLine className='self-center' />
                                    Back
                                </div>
                            </Button>
                        </div>
                    )}
                    {variantGroupingId && variantGroupingId !== '1' && (
                        <div className='flex gap-3 mr-20'>
                            <div className='text-[14px] self-center capitalize text-center'>
                                viewing related variants
                            </div>
                            <div className='self-center'>
                                <SelectorButton
                                    mutationTarget={upsertProducts?.variables?.products[0].id}
                                    pending={upsertProducts.isPending}
                                    align={'start'}
                                    key={'products'}
                                    searchToggle={true}
                                    title={'Select Variants'}
                                    options={allProducts
                                        .filter(
                                            (product: any) =>
                                                !product.related_variants_group_id ||
                                                (product.related_variants_group_id == variantGroupingId &&
                                                    !product.is_primary_variant)
                                        )
                                        .map((product: any) => {
                                            return {
                                                label: product.sku,
                                                value: product.id,
                                            }
                                        })}
                                    filterValues={(filteredProductIds = allProducts.filter(
                                        (product: any) => product.related_variants_group_id == variantGroupingId
                                    )).map((product: any) => product.id)}
                                    onSelect={handleChangeGrouping}
                                ></SelectorButton>
                            </div>
                            <div className='self-center'>
                                <Button
                                    onClick={() => {
                                        setSearchParams((prev: any) => {
                                            prev.delete('group_id')
                                            return prev
                                        })
                                    }}
                                    variant={'outline'}
                                    size={'sm'}
                                >
                                    <div className='flex gap-2'>
                                        <RiArrowGoBackLine className='self-center' />
                                        Back
                                    </div>
                                </Button>
                            </div>
                        </div>
                    )}
                    <FetchingSpinner className='mr-4' isFetching={fetchingProducts} />
                    {filterBarObject.params.length &&
                        filterBarObject.params.map((param: any) => {
                            return (
                                <param.component
                                    key={param.field}
                                    searchToggle={param.searchToggle}
                                    title={param.title}
                                    field={param.field}
                                    options={param.options}
                                    filterValues={param.values}
                                    setFilterValues={filterBarObject.setFunction}
                                />
                            )
                        })}
                </FilterToolBar>
                <div className='flex flex-col mt-2 border-l border-lightgrey min-w-[204px] dark:border-darkgrey ml-2 px-2'>
                    <Select id='status' name='status' onChange={handleSortChange} value={sortIndex}>
                        {PROCUREMENT_PRODUCT_SORT_OPTIONS.map((option: any, index) => {
                            return (
                                <option key={option.label + index} value={index}>
                                    {option.label}
                                </option>
                            )
                        })}
                    </Select>
                </div>
            </div>

            <div className='flex flex-col gap-[8px] min-h-[800px]'>
                <div className='flex sticky top-[88px] z-30  bg-white dark:bg-darkbg1'>
                    <CompanyProductHeader setShowNewAlert={setShowNewAlert} showNewAlert={showNewAlert} />
                    <div className='flex flex-col border-l border-lightgrey dark:border-darkgrey ml-2 pl-2 py-2'>
                        <div className='flex gap-4'>
                            <button
                                onClick={() => {
                                    if (pendingEdits || variantGroupingId === '1') {
                                        return (toastId.current = sendToast({
                                            message: 'Please Complete Pending Changes',
                                        }))
                                    }
                                    setSelectedLastCursor(undefined)
                                    setCursorList([0])
                                    setPage(1)
                                    setSortIndex(0)
                                    return createProduct.mutate(
                                        { companyId: selectedCompany.id, related_variants_group_id: variantGroupingId },
                                        {
                                            onSuccess: async (data) => {
                                                setProductListLength(products.length)
                                            },
                                        }
                                    )
                                }}
                                className='flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                            >
                                <span className='rounded-full border   border-darkgrey dark:border-offwhite'>
                                    <FaCartPlus className='text-darkgrey dark:text-offwhite p-[4px] w-[24px] h-[24px]' />
                                </span>
                                <p className='font-medium leading-[1] uppercase text-[12px] whitespace-nowrap'>
                                    +Product
                                </p>
                            </button>
                            <button
                                onClick={() => {
                                    setTimeout(() => {
                                        alertInputContainerRef.current?.querySelector('input')?.focus()
                                    }, 10)
                                    setShowNewAlert(true)
                                    setShowMoreActions(false)
                                }}
                                className='flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                            >
                                <span className='rounded-full border   border-darkgrey dark:border-offwhite'>
                                    <MdOutlineSdCardAlert className='text-darkgrey dark:text-offwhite p-[2px] w-[24px] h-[24px]' />
                                </span>
                                <p className='font-medium leading-[1] uppercase text-[12px] whitespace-nowrap'>
                                    +Alert
                                </p>
                            </button>
                            <button
                                onClick={handleNewVariantGroupingSetup}
                                className='flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                            >
                                <span className='rounded-full border   border-darkgrey dark:border-offwhite'>
                                    <FaLayerGroup className='text-darkgrey dark:text-offwhite p-[4px] w-[24px] h-[24px]' />
                                </span>
                                <p className='font-medium leading-[1] uppercase text-[12px] whitespace-nowrap'>
                                    +Group
                                </p>
                            </button>
                        </div>
                    </div>
                </div>

                {!variantGroupingId && loadingProducts && (
                    <div className='flex flex-col gap-[8px]'>
                        {[1, 2, 3, 4, 5].map((skeleton) => {
                            return <CompanyProductSkeleton key={skeleton} />
                        })}
                    </div>
                )}
                {variantGroupingId === '1' && (
                    <div
                        className={cn(
                            `border flex rounded-[4px] shadow-small text-[14px] relative min-h-[200px]  border-lightgrey dark:border-darkgrey`
                        )}
                    >
                        <div className='self-center m-auto'>
                            <SelectorButton
                                mutationTarget={addVariantGrouping?.variables?.product?.id}
                                pending={addVariantGrouping.isPending}
                                align={'start'}
                                key={'products'}
                                searchToggle={true}
                                title={'Select Primary Variant'}
                                options={allProducts
                                    .filter((product: any) => !product.related_variants_group_id)
                                    .map((product: any) => {
                                        return {
                                            label: product.sku,
                                            value: product.id,
                                        }
                                    })}
                                filterValues={(filteredProductIds = allProducts.filter(
                                    (product: any) => product.related_variants_group_id == variantGroupingId
                                )).map((product: any) => product.sku)}
                                onSelect={handleCreateNewVariantGrouping}
                            ></SelectorButton>
                        </div>
                    </div>
                )}
                {!loadingProducts && (
                    <>
                        {filteredProducts?.map((product, index) => {
                            return (
                                <CompanyProduct
                                    key={'companyproduct' + product.id + index}
                                    product={product}
                                    setSearch={setSearch}
                                />
                            )
                        })}
                        <div ref={lastItem}></div>
                    </>
                )}
                {variantGroupingId && variantGroupingId !== '1' && filteredProducts.length > 0 && (
                    <div
                        className={cn(
                            `border flex rounded-[4px] shadow-small text-[14px] relative min-h-[200px]  border-lightgrey dark:border-darkgrey`
                        )}
                    >
                        <div className='self-center flex gap-6 m-auto items-center'>
                            <div className='self-center flex flex-col gap-2 m-auto items-center'>
                                <SelectorButton
                                    mutationTarget={upsertProducts?.variables?.products[0].id}
                                    pending={upsertProducts.isPending}
                                    align={'start'}
                                    key={'products'}
                                    searchToggle={true}
                                    title={'Select Variants'}
                                    options={allProducts
                                        .filter(
                                            (product: any) =>
                                                !product.related_variants_group_id ||
                                                (product.related_variants_group_id == variantGroupingId &&
                                                    !product.is_primary_variant)
                                        )
                                        .map((product: any) => {
                                            return {
                                                label: product.sku,
                                                value: product.id,
                                            }
                                        })}
                                    filterValues={(filteredProductIds = allProducts.filter(
                                        (product: any) => product.related_variants_group_id == variantGroupingId
                                    )).map((product: any) => product.id)}
                                    onSelect={handleChangeGrouping}
                                ></SelectorButton>
                            </div>
                            <p>OR</p>
                            {showGroupDelete && (
                                <div className='flex gap-[8px] p-[8px]'>
                                    <button
                                        onClick={handleDeleteVariantGroup}
                                        className='shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase'
                                    >
                                        Delete
                                    </button>
                                    <button
                                        onClick={() => setShowGroupDelete(false)}
                                        className='text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            )}
                            {!showGroupDelete && (
                                <div className='self-center flex flex-col gap-2 m-auto items-center'>
                                    <Button
                                        className='border'
                                        variant={'destructive'}
                                        onClick={() => setShowGroupDelete(true)}
                                    >
                                        Delete Grouping
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className='flex fixed bottom-[8px] left-[calc(50%)] translate-x-[calc(-50%)] p-[8px] py-1 bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                    <>
                        <button
                            disabled={page === 1}
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageDecrease()
                            }}
                        >
                            <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </>
                    <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                    <button
                        className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                        onClick={() => {
                            handlePageIncrease()
                        }}
                        disabled={
                            companyProductsQuery.isLoading || companyProductsQuery.isFetching || products?.length < 20
                        }
                    >
                        <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                    </button>
                </div>
            </div>
        </div>
    )
}
