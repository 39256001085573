import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSections } from '../../redux/sections'

export default function WarrantyScreen() {
    const dispatch = useDispatch()
    const { warranty } = useSelector((state) => state).sections
    const handleChange = ({ target }) => {
        dispatch(setSections({ warranty: target.value.replaceAll('\n', '') }))
    }
    return (
        <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col items-center shadow-small'>
            <p className='dark:text-offwhite font-bold mb-[16px] dark:font-normal text-center font-bai'>
                Enter the warranty text below.
            </p>
            <textarea
                className='border border-grey rounded-[4px] dark:text-offwhite dark:bg-darkness dark:border-blue focus:outline-none'
                spellCheck='true'
                value={warranty}
                onChange={handleChange}
            ></textarea>
            {warranty && warranty !== 'N/A' && (
                <div className='bg-white description-preview'>
                    <div className='pdp__warranty__authorized'>
                        <div className='pdp__warranty__authorized__row'>
                            <div className='pdp__warranty__authorized__row__image-container'>
                                <img
                                    className='pdp__warranty__authorized__row__image-container__img'
                                    src='https://cdn.vectorstock.com/i/1000x1000/06/00/vendor-rubber-stamp-vector-12410600.webp'
                                    alt='vendor logo'
                                    width='auto'
                                    height='auto'
                                    loading='lazy'
                                />
                            </div>
                            <img
                                className='pdp__warranty__authorized__row__divider'
                                src='https://cdn.shopify.com/s/files/1/1163/1976/files/warranty-line.png?v=1605396259'
                                alt='divider'
                                width='2'
                                height='auto'
                                loading='lazy'
                            />
                            <div className='pdp__warranty__authorized__row__image-container'>
                                <img
                                    className='pdp__warranty__authorized__row__image-container__img'
                                    src='https://cdn.shopify.com/s/files/1/1163/1976/files/authorized-dealer-stacked.png?v=1605396129'
                                    alt='authorized dealer'
                                    width='auto'
                                    height='auto'
                                    loading='lazy'
                                />
                            </div>
                        </div>
                        <p id='warranty'>{warranty}</p>
                    </div>
                </div>
            )}
        </div>
    )
}
