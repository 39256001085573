import { useContext, useEffect, useState } from 'react'
import { FaBars, FaBuilding, FaClipboardList, FaSyncAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import RefreshButton from '../custom_components/RefreshButton'
import { Button } from '../custom_components/component_Basics/Button'
import { cn, vFetch } from '../helpers'
import MoreActions from '../processingTimes/components/MoreActions'
import { TaskFilterValues, UserSelectedCompanyFilterValues } from './Procurements.types'
import { procurementQueries } from './api/procurementQueryKeys'
import { useCompanyDetailQuery } from './api/useQueries'
import CategoryList from './components/lists/CategoryList'
import CompanyListV2 from './components/lists/CompanyListV2'
import TaskListV2 from './components/lists/TaskListV2'
import CompanyModalV2 from './components/modals/CompanyModalV2'
import CreateCategory from './components/modals/CreateCategory'
import CreateCompanyModalV2 from './components/modals/CreateCompanyModalV2'
import ParentCompanyModal from './components/modals/ParentCompanyModal'
import { CompanyProvider, ProcurementContext, ProcurementContextType } from './helpers'

export default function ProcurementCompanies() {
    const user = useSelector<any, any>((state) => state.user)
    const { view, activeModal, toggleModal, handleChangeView, categories, selectedCompany } =
        useContext<ProcurementContextType>(ProcurementContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const googleCode = searchParams.get('code')
    const scope = searchParams.get('scope')
    const urlParamTaskId = searchParams.get('task_id')
    const urlParamCompanyId = searchParams.get('company_id')
    const [tokens, setTokens] = useState(null)
    const [search, setSearch] = useState('')
    const lastViewedCompany = localStorage.getItem('lastViewedCompany') || undefined

    const [userSelectedCompanyFilterValues, setUserSelectedCompanyFilterValues] =
        useState<UserSelectedCompanyFilterValues>({
            lifecycle_statuses: [],
            lead_types: [],
            users: [user.id],
        })

    const [userSelectedTaskFilterValues, setUserSelectedTaskFilterValues] = useState<TaskFilterValues>({
        statuses: ['OPEN'],
        users: [user.id],
        due_at_options: ['custom', 'overdue'],
    })

    const companyDetailQuery = useCompanyDetailQuery(urlParamCompanyId ? parseInt(urlParamCompanyId) : undefined)
    const { companies: company } = companyDetailQuery?.data || {}
    useEffect(() => {
        if (company) {
            toggleModal('company', company[0])
        }
    }, [company])

    function linkGmailOAuth() {
        if (!googleCode && !scope) {
            vFetch(`/V2/google/auth?user_id=${user.id}`, {
                cb: (res: any) => {
                    if (res.success) setTokens(res.tokens)
                    else if (res.authorizationURL) window.location.replace(res.authorizationURL)
                },
            })
        }
    }
    const options = [
        {
            displayText: `Sync Gmail Account`,
            icon: FaSyncAlt,
            action: linkGmailOAuth,
        },
    ]

    useEffect(() => {
        if (urlParamTaskId) {
            setSearch(urlParamTaskId)
            handleChangeView('tasks')
            setUserSelectedTaskFilterValues({
                statuses: [],
                users: [],
                due_at_options: [],
            })
        }
    }, [urlParamTaskId])

    useEffect(() => {
        if (googleCode && scope && !tokens) {
            const { REACT_APP_MODE, REACT_APP_LOCAL_API_URL, REACT_APP_PRODUCTION_URL, REACT_APP_DEVELOPEMENT_URL } =
                process.env
            const API_URL =
                REACT_APP_MODE === 'production'
                    ? REACT_APP_PRODUCTION_URL
                    : REACT_APP_MODE === 'development'
                      ? REACT_APP_DEVELOPEMENT_URL
                      : REACT_APP_LOCAL_API_URL
            vFetch(`/V2/google/auth`, {
                method: 'POST',
                body: JSON.stringify({ name: 'Google', code: googleCode, user_id: user.id, email: user.email }),
                cb: (res: any) => {
                    if (res.success) {
                        setTokens(res.tokens)
                        window.history.pushState({ data: 'Token success' }, 'Token sucess', `${API_URL}/procurement`)
                    } else if (res.authorizationURL) {
                        window.location.replace(res.authorizationURL)
                    }
                },
            })
        }
    }, [])

    useEffect(() => {
        if (activeModal) {
            document.body.classList.add('overflow-y-hidden')
        } else {
            document.body.classList.remove('overflow-y-hidden')
        }
    }, [activeModal])

    return (
        <div className=''>
            <div className='flex justify-between mb-[8px] bg-bg2 relative dark:bg-darkbg2'>
                <div className='flex gap-4'>
                    <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Procurement</h1>
                </div>
                <div className='flex gap-[8px] absolute top-[-16px] right-2'>
                    <MoreActions options={options} displayContent={{ icon: FaBars }} />
                    <RefreshButton
                        queryKeys={[
                            procurementQueries.companies._def.toString(),
                            procurementQueries.tasks._def.toString(),
                        ]}
                    />
                </div>
            </div>
            <div className='flex justify-between relative bg-bg1 dark:bg-darkbg2 mb-2'>
                <div className='flex gap-1 mb-2 mt-2 bg-2'>
                    <button
                        onClick={() => {
                            handleChangeView('companies')
                        }}
                        className={`mb-[-16px] py-1 px-[16px] rounded-t-[4px] font-bold  max-h-10 ${
                            view === 'companies'
                                ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                                : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                        }`}
                    >
                        Companies
                    </button>
                    <button
                        onClick={() => {
                            handleChangeView('tasks')
                        }}
                        className={`mb-[-16px] py-1 px-[16px] rounded-t-[4px] font-bold  max-h-10 ${
                            view === 'tasks'
                                ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                                : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                        }`}
                    >
                        Tasks
                    </button>
                    <button
                        onClick={() => {
                            handleChangeView('categories')
                        }}
                        className={`mb-[-16px] py-1 px-[16px] rounded-t-[4px] font-bold  max-h-10 ${
                            view === 'categories'
                                ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                                : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                        }`}
                    >
                        Categories
                    </button>
                </div>
                <div className='absolute right-52 top-[-8px] bg-bg1 dark:bg-darkbg2 flex items-center gap-8'>
                    <Link
                        draggable={false}
                        className={cn(!lastViewedCompany && 'pointer-events-none opacity-50', '')}
                        to={`/procurement${lastViewedCompany}`}
                    >
                        <Button variant={'outline'}>Return to Last Company</Button>
                    </Link>
                </div>
                <div className='absolute right-6 top-[-10px] bg-bg1 dark:bg-darkbg2 flex items-center gap-8'>
                    <button
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                        onClick={() => {
                            toggleModal('create-company')
                        }}
                    >
                        <div className=' flex justify-center items-center text-darkgrey dark:text-offwhite p-0 w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite'>
                            <FaBuilding />
                        </div>
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Company</p>
                    </button>
                    <button
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                        onClick={() => {
                            toggleModal('create-category')
                        }}
                    >
                        <div className=' flex justify-center items-center text-darkgrey dark:text-offwhite p-0 w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite'>
                            <FaClipboardList />
                        </div>
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Category</p>
                    </button>
                </div>
            </div>
            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh] bg-bg1'>
                <>
                    {(view === 'companies' || view === 'my-companies') && (
                        <CompanyListV2
                            userSelectedCompanyFilterValues={userSelectedCompanyFilterValues}
                            setUserSelectedCompanyFilterValues={setUserSelectedCompanyFilterValues}
                            setTaskFilters={{
                                setSearch: setSearch,
                                userSelectedFiltersSetFunction: setUserSelectedTaskFilterValues,
                            }}
                        />
                    )}
                    {view === 'categories' && <CategoryList categories={categories} />}
                    {view === 'tasks' && (
                        <TaskListV2
                            company={undefined}
                            userSelectedTaskFilterValues={userSelectedTaskFilterValues}
                            setUserSelectedTaskFilterValues={setUserSelectedTaskFilterValues}
                        />
                    )}
                </>
            </div>
            {activeModal === 'create-company' && <CreateCompanyModalV2 />}
            {activeModal === 'create-category' && <CreateCategory />}
            {(activeModal === 'listing' || activeModal === 'company') && (
                <CompanyProvider>{activeModal === 'company' && <CompanyModalV2 />}</CompanyProvider>
            )}
            {activeModal === 'parent' && <ParentCompanyModal parentCompany={selectedCompany.parent_company} />}
        </div>
    )
}
