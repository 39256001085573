import { useQueryClient } from '@tanstack/react-query'
import { Dispatch, SetStateAction } from 'react'
import { GrRefresh } from 'react-icons/gr'

export default function RefreshButton({ queryKeys }: { queryKeys: string[] }) {
    const queryClient = useQueryClient()

    return (
        <button
            onClick={() => {
                queryKeys.map((key) => {
                    queryClient.resetQueries({ queryKey: [`${key}`] })
                })
            }}
            className='p-[5px] h-fit bg-lightgrey dark:bg-darkaccent rounded-[4px] hover:bg-fire transition'
        >
            <GrRefresh className='[&>*]:stroke-darkgrey [&>*]:dark:stroke-offwhite' />
        </button>
    )
}
