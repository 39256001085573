export type ScrumProjectCreate = {
    assigned_to: string[]
    description: string
    due_at: Date
    files: any[]
    project_lead: string
    status: string
    issues: []
    title: string
}
export interface ScrumProjectEdit extends ScrumProjectCreate {
    id: number
    created_at: Date
    updated_at: Date
}
export type AssignedToCreate = {
    user_email: string
}
export interface AssignedToEdit extends AssignedToCreate {
    id: number
}

export const defaultScrumProject: ScrumProjectCreate = {
    assigned_to: [],
    description: '',
    files: [],
    issues: [],
    title: '',
    project_lead: '',
    status: 'to do',
    due_at: new Date(),
}

export type ScrumIssueCreate = {
    assigned_to: string[]
    description: string
    due_at: Date
    project_id: number
    project_title?: string
    status: string
    title: string
    type: string
}

export interface ScrumIssueEdit extends ScrumIssueCreate {
    id: number
    created_at: Date
    updated_at: Date
}

export const defaultScrumIssue: ScrumIssueCreate = {
    assigned_to: [],
    description: '',
    due_at: new Date(),
    project_id: 0,
    project_title: '',
    status: 'to do',
    title: '',
    type: '',
}
