import { isPrimObject } from '../../helpers'
import { DamageClaimCreateInit, ReturnCreateInit, ReturnEditInit } from '../returns.types'

type AreYouSureYouWantToCloseReturnModalProps = {
    r: ReturnCreateInit | ReturnEditInit | DamageClaimCreateInit
    failingValues: any
    handleSubmit: any
    setShowAreYouSureYouWantToCloseReturnModal: any
}

export default function AreYouSureYouWantToCloseReturnModal({
    r,
    failingValues,
    handleSubmit,
    setShowAreYouSureYouWantToCloseReturnModal,
}: AreYouSureYouWantToCloseReturnModalProps) {
    const buttonStyle =
        'p-[8px] bg-blue hover:bg-blue/90 text-white font-semibold dark:bg-accent dark:hover:bg-accent/90 dark:text-black rounded shadow-small capitalize'

    const failingObjectValues: string[] = []
    Object.keys(failingValues)
        .filter((k: any) => isPrimObject(failingValues[k]))
        .map((v: any) =>
            Object.keys(failingValues[v])
                .filter((k) => !failingValues[v][k])
                .forEach((k) => failingObjectValues.push(k))
        )
    if ((Number(r.refund_to_customer) === 0 || isNaN(Number(r.refund_to_customer))) && r.refund_type !== 'N/A') {
        failingObjectValues.push('Refund To Customer is 0.')
    }

    return (
        <div className='absolute grid gap-[16px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] bg-white dark:bg-darkness text-black dark:text-offwhite p-[16px] border-black dark:border-grey border-[1px] rounded'>
            <h2 className='text-[20px] font-bold'>Are you sure you want to CLOSE this {r.type}?</h2>
            {failingObjectValues.length > 0 && [
                <h3 className='font-semibold text-[18px]'>Missing critical values:</h3>,
                <ul className='list-disc'>
                    {failingObjectValues
                        .concat(Object.keys(failingValues).filter((v) => !failingValues[v]))
                        .map((v) => (
                            <li className='capitalize ml-[16px]'>
                                {v.replaceAll('_', ' ')}
                                {v === 'files' && (
                                    <span className='normal-case'>(check image submission requirements)</span>
                                )}
                                {v === 'fees' && (
                                    <span className='normal-case'>
                                        {' '}
                                        (If refund type is "partial" or "exchange", at least 1 fee must be selected and
                                        must have a value &gt;0)
                                    </span>
                                )}
                            </li>
                        ))}
                </ul>,
            ]}
            <div className='flex justify-center gap-[32px] py-[16px]'>
                <button
                    className={buttonStyle + ' whitespace-nowrap'}
                    onClick={() => handleSubmit({ ...r, status: 'closed' })}
                >
                    Close {r.type}
                </button>
                <button
                    className={buttonStyle + ' whitespace-nowrap min-w-[100px]'}
                    onClick={() => setShowAreYouSureYouWantToCloseReturnModal(false)}
                >
                    No
                </button>
            </div>
        </div>
    )
}
