import { useContext, useEffect, useState } from 'react'
import { CompanyContext, ProcurementContext, ProcurementContextType } from '../../helpers'
import CompanyFile from '../listItems/CompanyFile'
import { useCompanyFilesQuery } from '../../api/useQueries'
import CompanyFileV2 from '../listItems/CompanyFileV2'
import FilterToolBar from '../FilterToolBar'

export default function FileListV2() {
    const { selectedCompany, users } = useContext<ProcurementContextType>(ProcurementContext)
    const { setShowQuickModal } = useContext<any>(CompanyContext)
    const [search, setSearch] = useState('')

    const companyFilesQuery = useCompanyFilesQuery({ companyId: selectedCompany.id })
    const { isLoading: loadingFiles, isFetching: fetchingFiles } = companyFilesQuery
    const { files }: { files: any[] } = companyFilesQuery?.data || {}

    const filteredFiles = files?.filter((file) => {
        return (
            file.name?.toLowerCase().includes(search.toLowerCase()) ||
            file.mime_type?.toLowerCase().includes(search.toLowerCase())
        )
    })

    return (
        <div className='px-[16px] pl-0'>
            <div className='mb-[8px] w-full flex gap-[16px]'>
                <FilterToolBar search={search} setSearch={setSearch} filterBarObject={undefined} />
                <button
                    onClick={() => setShowQuickModal('file')}
                    className='min-h-[32px] self-center shrink-0 text-[14px] leading-[1] py-[2px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase'
                >
                    + New File
                </button>
            </div>
            <div className='flex flex-col overflow-auto'>
                <div className='sticky top-0 grid grid-cols-[1fr_1fr_1fr_1fr_50px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Name</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Type</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>File Type</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Uploaded On</div>
                </div>
                {filteredFiles?.map((file: any) => <CompanyFileV2 key={file.id} file={file} />)}
            </div>
        </div>
    )
}
