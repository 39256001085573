import { MdCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import { cn } from '../../helpers'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export default function FetchOptionSelector({
    options,
    selectedOption,
    onClick,
    isPending,
    className,
    searchText,
    dismountingPopover,
}: {
    options: OptionType[]
    selectedOption: string
    onClick: any
    isPending: boolean
    className?: string
    searchText?: string
    dismountingPopover: boolean
}) {
    const [activated, setActivated] = useState(false)
    const [search, setSearch] = useState('')
    const filteredOptions = options.filter((option) => {
        if (typeof option === 'string') {
            return option.toLowerCase().includes(search.toLowerCase())
        }
        return (
            option.label.toLowerCase().includes(search.toLowerCase()) ||
            option.value?.toLowerCase().includes(search.toLowerCase())
        )
    })
    useEffect(() => {
        if (dismountingPopover) {
            setActivated(false)
        } else {
            setActivated(true)
        }
    }, [dismountingPopover])
    return (
        <div
            className={`${cn(
                'transition-all absolute top-[calc(100%+8px)] left-1/2 translate-x-[-50%] bg-white dark:bg-darkaccent p-2 rounded border border-darkgrey z-10 flex flex-col gap-2',
                className || ''
            )} ${
                isPending
                    ? 'after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-white dark:after:bg-darkaccent after:opacity-50 pointer-events-none'
                    : ''
            } ${activated ? '' : 'opacity-0 scale-90'}`}
        >
            {searchText && (
                <input
                    className='font-bold text-[12px] bg-transparent focus:outline-none'
                    type='text'
                    placeholder={searchText}
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                    autoFocus
                />
            )}
            {filteredOptions.map((option: any) => {
                const value = typeof option === 'string' ? option : option.value
                const label = typeof option === 'string' ? option : option.label
                const selected =
                    typeof option === 'string' ? option === selectedOption : option.value === selectedOption
                return (
                    <button
                        className='flex gap-2 items-center capitalize whitespace-nowrap hover:bg-darkness'
                        onClick={() => onClick(value)}
                    >
                        {selected ? (
                            <MdCheckBox className='text-base' />
                        ) : (
                            <MdOutlineCheckBoxOutlineBlank className='text-base' />
                        )}
                        <span>{label}</span>
                    </button>
                )
            })}
        </div>
    )
}

type OptionType = string | { label: string; value: string | null }
