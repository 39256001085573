import './App.css'
import './styles/description.css'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ProductScreen from './screens/ProductScreen'
import DescriptionScreen from './screens/DescriptionScreen'
import CheckmarksScreen from './screens/CheckmarkScreen'
import PlugsScreen from './screens/PlugScreen'
import FeaturesScreen from './screens/FeaturesScreen'
import SpecificationsScreen from './screens/SpecificationsScreen'
import WarrantyScreen from './screens/WarrantyScreen'
import ManualsScreen from './screens/ManualsScreen'
import PreviewScreen from './screens/PreviewScreen'
import CrossSellsScreen from './screens/CrossSellsScreen'
import { useNavigate } from 'react-router-dom'
import FilterValuesScreen from './screens/FilterValuesScreen'

export default function ProductSetup() {
    const [step, setStep] = useState(0)
    const { sections, user } = useSelector((state) => state)
    const navigate = useNavigate()
    useEffect(() => {
        if (!user.access.includes('product-setup') && !user.access.includes('*')) {
            navigate('/home')
        }
    }, [])
    return (
        <div className='flex flex-col items-center'>
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize w-[100%]'>Product Setup</h1>
            <div className='flex gap-[2px] mb-[16px] w-full flex-wrap justify-center dark:bg-darkaccent rounded-[4px] shadow-small p-[8px]'>
                <button
                    className={`py-[2px] px-[8px] border dark:border-[2px] border-blue dark:border-darkness font-bold text-blue dark:text-offwhite dark:font-normal rounded-[4px] text-[15px] ${
                        step === 0 ? 'bg-blue dark:bg-darkness text-white dark:!text-fire font-black' : ''
                    }`}
                    onClick={() => setStep(0)}
                >
                    Product{sections.product && ' ✓'}
                </button>
                <button
                    className={`py-[2px] px-[8px] border dark:border-[2px] border-blue dark:border-darkness font-bold text-blue dark:text-offwhite dark:font-normal rounded-[4px] text-[15px] ${
                        step === 1 ? 'bg-blue dark:bg-darkness text-white dark:!text-fire font-black' : ''
                    }`}
                    onClick={() => setStep(1)}
                >
                    Overview{sections.description.length > 0 && ' ✓'}
                </button>
                <button
                    className={`py-[2px] px-[8px] border dark:border-[2px] border-blue dark:border-darkness font-bold text-blue dark:text-offwhite dark:font-normal rounded-[4px] text-[15px] ${
                        step === 2 ? 'bg-blue dark:bg-darkness text-white dark:!text-fire font-black' : ''
                    }`}
                    onClick={() => setStep(2)}
                >
                    Checkmarks{sections.checkmarks.length > 0 && ' ✓'}
                </button>
                <button
                    className={`py-[2px] px-[8px] border dark:border-[2px] border-blue dark:border-darkness font-bold text-blue dark:text-offwhite dark:font-normal rounded-[4px] text-[15px] ${
                        step === 3 ? 'bg-blue dark:bg-darkness text-white dark:!text-fire font-black' : ''
                    }`}
                    onClick={() => setStep(3)}
                >
                    Plugs{sections.plugs.length > 0 && ' ✓'}
                </button>
                <button
                    className={`py-[2px] px-[8px] border dark:border-[2px] border-blue dark:border-darkness font-bold text-blue dark:text-offwhite dark:font-normal rounded-[4px] text-[15px] ${
                        step === 4 ? 'bg-blue dark:bg-darkness text-white dark:!text-fire font-black' : ''
                    }`}
                    onClick={() => setStep(4)}
                >
                    Features{sections.features.length > 0 && ' ✓'}
                </button>
                <button
                    className={`py-[2px] px-[8px] border dark:border-[2px] border-blue dark:border-darkness font-bold text-blue dark:text-offwhite dark:font-normal rounded-[4px] text-[15px] ${
                        step === 5 ? 'bg-blue dark:bg-darkness text-white dark:!text-fire font-black' : ''
                    }`}
                    onClick={() => setStep(5)}
                >
                    Specifications{sections.specifications.length > 0 && ' ✓'}
                </button>
                <button
                    className={`py-[2px] px-[8px] border dark:border-[2px] border-blue dark:border-darkness font-bold text-blue dark:text-offwhite dark:font-normal rounded-[4px] text-[15px] ${
                        step === 6 ? 'bg-blue dark:bg-darkness text-white dark:!text-fire font-black' : ''
                    }`}
                    onClick={() => setStep(6)}
                >
                    Filter Values{sections.filterValues.length > 0 && ' ✓'}
                </button>
                <button
                    className={`py-[2px] px-[8px] border dark:border-[2px] border-blue dark:border-darkness font-bold text-blue dark:text-offwhite dark:font-normal rounded-[4px] text-[15px] ${
                        step === 7 ? 'bg-blue dark:bg-darkness text-white dark:!text-fire font-black' : ''
                    }`}
                    onClick={() => setStep(7)}
                >
                    Warranty{sections.warranty.length > 0 && ' ✓'}
                </button>
                <button
                    className={`py-[2px] px-[8px] border dark:border-[2px] border-blue dark:border-darkness font-bold text-blue dark:text-offwhite dark:font-normal rounded-[4px] text-[15px] ${
                        step === 8 ? 'bg-blue dark:bg-darkness text-white dark:!text-fire font-black' : ''
                    }`}
                    onClick={() => setStep(8)}
                >
                    Manuals{sections.manuals.length > 0 && ' ✓'}
                </button>
                <button
                    className={`py-[2px] px-[8px] border dark:border-[2px] border-blue dark:border-darkness font-bold text-blue dark:text-offwhite dark:font-normal rounded-[4px] text-[15px] ${
                        step === 9 ? 'bg-blue dark:bg-darkness text-white dark:!text-fire font-black' : ''
                    }`}
                    onClick={() => setStep(9)}
                >
                    Cross Sells{sections.crossSells.length > 0 && ' ✓'}
                </button>
                <button
                    className={`py-[2px] px-[8px] border dark:border-[2px] border-blue dark:border-darkness font-bold text-blue dark:text-offwhite dark:font-normal rounded-[4px] text-[15px] ${
                        step === 10 ? 'bg-blue dark:bg-darkness text-white dark:!text-fire font-black' : ''
                    }`}
                    onClick={() => setStep(10)}
                >
                    Preview &amp; Submit
                </button>
            </div>

            {step === 0 && <ProductScreen />}
            {step === 1 && <DescriptionScreen />}
            {step === 2 && <CheckmarksScreen />}
            {step === 3 && <PlugsScreen />}
            {step === 4 && <FeaturesScreen />}
            {step === 5 && <SpecificationsScreen />}
            {step === 6 && <FilterValuesScreen />}
            {step === 7 && <WarrantyScreen />}
            {step === 8 && <ManualsScreen />}
            {step === 9 && <CrossSellsScreen />}
            {step === 10 && <PreviewScreen />}
        </div>
    )
}
