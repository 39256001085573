import { FC, useEffect, useRef, useState } from 'react'
import { IconType } from 'react-icons'

export default function MoreActions({
    options,
    displayContent = 'More Actions',
    align = 'right',
}: {
    options: Option[]
    displayContent?: string | { icon: IconType }
    align?: 'left' | 'right'
}) {
    const [showOptions, setShowOptions] = useState(false)
    const container = useRef(null)
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!(container.current! as HTMLElement).contains(e.target as HTMLElement)) {
                setShowOptions(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div ref={container} className='relative'>
            <button
                onClick={() => setShowOptions((previousState) => !previousState)}
                className='text-[14px] font-medium rounded-[4px] bg-lightgrey dark:bg-darkaccent dark:text-offwhite p-[6px] leading-[1]'
            >
                {typeof displayContent === 'string' && displayContent}
                {typeof displayContent === 'object' && <displayContent.icon />}
            </button>
            {showOptions && (
                <div
                    className={`flex flex-col gap-[4px] top-[32px] absolute ${align}-0 p-[8px] rounded-[4px] shadow-small z-[50] bg-white dark:bg-darkaccent`}
                >
                    {options.map((option) => (
                        <button
                            key={option?.displayText}
                            className='text-[14px] leading-[1] font-medium whitespace-nowrap flex items-center gap-[6px] text-darkgrey dark:text-offwhite'
                            onClick={() => {
                                option.action()
                                setShowOptions(false)
                            }}
                        >
                            {option.icon({ className: 'text-darkgrey dark:text-offwhite' })}
                            {option.displayText}
                        </button>
                    ))}
                </div>
            )}
        </div>
    )
}

type Option = {
    displayText: string
    icon: FC<{ className: string }>
    action: Function
}
