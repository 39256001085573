import { useEffect, useState } from 'react'
import { LoadingGear, vFetch } from '../helpers'
import GroupModal from './GroupModal'
import TagModal from './TagModal'

export default function TagManagerScreen() {
    const [loading, setLoading] = useState(true)
    const [tags, setTags] = useState([])
    const [groups, setGroups] = useState([])
    const [filteredTags, setFilteredTags] = useState([])
    const [filteredGroups, setFilteredGroups] = useState([])
    const [productTypes, setProductTypes] = useState([])
    const [view, setView] = useState('tags')
    const [tagSearch, setTagSearch] = useState('')
    const [groupSearch, setGroupSearch] = useState('')
    const [selectedTag, setSelectedTag] = useState({})
    const [selectedGroup, setSelectedGroup] = useState({})
    const [needsRefresh, setNeedsRefresh] = useState(true)
    useEffect(() => {
        if (needsRefresh) {
            setNeedsRefresh(false)
            setTagSearch('')
            setGroupSearch('')
            setSelectedGroup({})
            setSelectedTag({})
            vFetch(`/tagManager/tags`, {
                cb: (res) => {
                    if (res.success) {
                        setTags(res.tags)
                        setFilteredTags(res.tags)
                        setLoading(false)
                    }
                },
            })
            vFetch(`/tagManager/groups`, {
                cb: (res) => {
                    if (res.success) {
                        setGroups(res.groups)
                        setFilteredGroups(res.groups)
                        setLoading(false)
                    }
                },
            })
            vFetch(`/products/productTypes`, {
                cb: (res) => {
                    if (res.success) setProductTypes(res.productTypes)
                },
            })
        }
    }, [needsRefresh])
    useEffect(() => {
        setFilteredTags(
            tags.filter((t) => {
                if (t.tag_name.toLowerCase().includes(tagSearch.toLowerCase())) {
                    return true
                }
                if (t.display_name.toLowerCase().includes(tagSearch.toLowerCase())) {
                    return true
                }
                if (t.group_name.toLowerCase().includes(tagSearch.toLowerCase())) {
                    return true
                }
                if (t.limited_to.toLowerCase().includes(tagSearch.toLowerCase())) {
                    return true
                }
                if (t.excluded_from.toLowerCase().includes(tagSearch.toLowerCase())) {
                    return true
                }
                if (t.tag_name.replaceAll('-', '').toLowerCase().includes(tagSearch.toLowerCase())) {
                    return true
                }
            })
        )
    }, [tagSearch])
    useEffect(() => {
        setFilteredGroups(
            groups.filter((g) => {
                if (g.name.toLowerCase().includes(groupSearch.toLowerCase())) {
                    return true
                }
            })
        )
    }, [groupSearch])
    return (
        <>
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Tag Manager</h1>
            <div className='flex gap-[4px] mb-[8px]'>
                <button
                    onClick={() => setView('tags')}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'tags'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Tags
                </button>
                <button
                    onClick={() => setView('groups')}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'groups'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-offwhite dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Groups
                </button>
                {view === 'tags' ? (
                    <button
                        onClick={() => setSelectedTag({ is_new: true })}
                        className='ml-auto py-[4px] px-[16px] bg-blue text-white rounded-[4px] dark:bg-accent dark:text-darkness font-bold uppercase'
                    >
                        + New Tag
                    </button>
                ) : (
                    <button
                        onClick={() => setSelectedGroup({ is_new: true })}
                        className='ml-auto py-[4px] px-[16px] bg-blue text-white rounded-[4px] dark:bg-accent dark:text-darkness font-bold uppercase'
                    >
                        + New Group
                    </button>
                )}
            </div>
            {view === 'tags' ? (
                <>
                    <div className='shadow-small rounded-[4px]'>
                        <div className='rounded-[4px] rounded-tl-[0px] dark:bg-darkaccent'>
                            <div className='w-full p-[8px] border-b border-lightgrey dark:border-darkgrey flex'>
                                <input
                                    type='text'
                                    className='w-full bg-transparent dark:text-white focus:outline-none'
                                    placeholder='Search'
                                    value={tagSearch}
                                    onChange={({ target }) => setTagSearch(target.value)}
                                />
                            </div>
                        </div>
                        <div className='grid grid-cols-7 dark:bg-darkaccent'>
                            <div className='sticky top-[50px] grid grid-cols-7 col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Tag</div>
                                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>
                                    Display Name
                                </div>
                                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Group</div>
                                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>
                                    Is Feature
                                </div>
                                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>
                                    Limited To
                                </div>
                                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>
                                    Excluded From
                                </div>
                                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>
                                    Priority
                                </div>
                            </div>
                            {loading ? (
                                <div className='col-span-full h-[70vh] grid place-items-center'>
                                    <LoadingGear />
                                </div>
                            ) : (
                                <>
                                    {filteredTags.map((tag) => (
                                        <div
                                            onClick={() => setSelectedTag(tag)}
                                            className='grid grid-cols-7 col-span-full border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-faintplus cursor-pointer'
                                        >
                                            <div className='dark:text-fire p-[8px] font-bold'>{tag.tag_name}</div>
                                            <div className='dark:text-offwhite p-[8px]'>{tag.display_name}</div>
                                            <div className='dark:text-offwhite p-[8px]'>{tag.group_name}</div>
                                            <div className='dark:text-offwhite p-[8px] font-bold'>
                                                {tag.is_feature == 1 ? 'True' : 'False'}
                                            </div>
                                            <div className='dark:text-offwhite p-[8px] font-bold'>
                                                {JSON.parse(tag.limited_to).join(', ')}
                                            </div>
                                            <div className='dark:text-offwhite p-[8px] font-bold'>
                                                {JSON.parse(tag.excluded_from).join(', ')}
                                            </div>
                                            <div className='dark:text-offwhite p-[8px] font-bold'>
                                                {JSON.parse(tag.compare_values).priority}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                    <TagModal
                        selectedTag={selectedTag}
                        setSelectedTag={setSelectedTag}
                        groupList={groups.map((g) => g.name)}
                        productTypes={productTypes}
                        setNeedsRefresh={setNeedsRefresh}
                    />
                </>
            ) : (
                <>
                    <div className='shadow-small rounded-[4px]'>
                        <div className='rounded-[4px] rounded-tl-[0px] dark:bg-darkaccent'>
                            <div className='w-full p-[8px] border-b border-lightgrey dark:border-darkgrey flex'>
                                <input
                                    type='text'
                                    className='w-full bg-transparent dark:text-white focus:outline-none'
                                    placeholder='Search'
                                    value={groupSearch}
                                    onChange={({ target }) => setGroupSearch(target.value)}
                                />
                            </div>
                        </div>
                        <div className='grid grid-cols-3 dark:bg-darkaccent'>
                            <div className='sticky top-[50px] grid grid-cols-3 col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Name</div>
                                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Type</div>
                                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>
                                    Disjunctive
                                </div>
                            </div>
                            {loading ? (
                                <div className='col-span-full h-[70vh] grid place-items-center'>
                                    <LoadingGear />
                                </div>
                            ) : (
                                <>
                                    {filteredGroups.map((group) => (
                                        <div
                                            onClick={() => setSelectedGroup(group)}
                                            className='grid grid-cols-3 col-span-full border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-faintplus cursor-pointer'
                                        >
                                            <div className='dark:text-fire p-[8px] font-bold'>{group.name}</div>
                                            <div className='dark:text-offwhite p-[8px]'>
                                                {group.type.substring(0, 1).toUpperCase() +
                                                    group.type.substring(1).toLowerCase()}
                                            </div>
                                            <div className='dark:text-offwhite p-[8px]'>
                                                {group.disjunctive == 1 ? 'True' : 'False'}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                    <GroupModal
                        selectedGroup={selectedGroup}
                        setSelectedGroup={setSelectedGroup}
                        setNeedsRefresh={setNeedsRefresh}
                    />
                </>
            )}
        </>
    )
}
