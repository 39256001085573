import { sortByAlphanumeric } from '../helpers'

export const returnVariables = {
    conditions: ['New In Box', 'New Open Box', 'Used'],
    destinations: ['FactoryPure', 'Manufacturer'],
    reasons: ["buyer's remorse", 'shipping delay', 'wrong unit', 'missing pieces', 'defective'].sort((a, b) =>
        sortByAlphanumeric(a, b)
    ),
    //reasons: ["buyer's remorse", "damaged in transit", "shipping delay", "wrong unit", "missing pieces", "defective"].sort((a,b) => sortByAlphanumeric(a, b, undefined, undefined)),
    shippingTypes: ['freight', 'UPS', 'other / unspecified', 'N/A'],
    statuses: ['initialized', 'requested RMA', 'RMA sent', 'item in transit back', 'ready for processing', 'closed'],
    refundTypes: ['full', 'partial', 'N/A'],
    resolutions: ['exchange', 'replacement parts', 'return', 'refund'].sort((a, b) => sortByAlphanumeric(a, b)),
    skipItemChecklistReasons: ['damaged in transit', 'missing pieces'],
}
