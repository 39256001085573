import { MetafieldProps } from '../ProductSetup.types'
import ManualList from '../components/ManualList'

export default function Manuals({ product, setProduct, handleUpdateMetafield, edited }: MetafieldProps) {
    const parsedManuals = (product.manuals || []).map((manual) => {
        const name = manual.substring(0, manual.indexOf(':'))
        const href = manual.substring(manual.indexOf(':') + 1)
        return {
            name,
            href,
        }
    })
    return (
        <>
            <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] shadow-small overflow-hidden'>
                <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                    Manuals &amp; Documentation
                </p>
                {parsedManuals.length === 0 && (
                    <p className='dark:text-offwhite font-bold mb-[16px] dark:font-normal'>
                        Add links to any product manuals or documentation
                    </p>
                )}
                <ManualList manuals={parsedManuals} setProduct={setProduct} />
                <button
                    className='text-blue border-blue uppercase font-bold dark:text-fire border-2 dark:border-fire py-[2px] px-[8px] rounded-[4px]'
                    onClick={() =>
                        setProduct((previousState) => {
                            const newState = structuredClone(previousState)
                            if (!newState.manuals) {
                                newState.manuals = []
                            }
                            newState.manuals.push(':')
                            return newState
                        })
                    }
                >
                    + Manual
                </button>
                {edited && (
                    <button
                        className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                        onClick={() =>
                            handleUpdateMetafield([
                                {
                                    ownerId: `gid://shopify/Product/${product.id}`,
                                    namespace: 'custom',
                                    key: 'manuals',
                                    type: 'list.single_line_text_field',
                                    value: JSON.stringify(product.manuals),
                                },
                            ])
                        }
                    >
                        Save Changes
                    </button>
                )}
            </div>
            {parsedManuals.length > 0 && (
                <>
                    <h2 className='dark:text-offwhite font-light uppercase text-[24px] my-[16px]'>Manuals Preview</h2>
                    <div className='bg-white description-preview description-preview--full w-fit !m-0 dark:opacity-[0.9] dark:text-black'>
                        <div className='pdp__manuals__grid'>
                            {parsedManuals.map((m) => (
                                <a
                                    className='pdp__manuals__grid__manual'
                                    href={m.href}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <div className='pdp__manuals__grid__manual__image-container'>
                                        <img
                                            className='pdp__manuals__grid__manual__image-container__img'
                                            src='https://cdn.shopify.com/s/files/1/1163/1976/files/pdf-icon.png?v=1611684687'
                                            alt='manual icon'
                                            width='auto'
                                            height='auto'
                                            loading='lazy'
                                        />
                                    </div>
                                    <p className='pdp__manuals__grid__manual__text'>{m.name}</p>
                                </a>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
