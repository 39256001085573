import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { BsFillTrash3Fill } from 'react-icons/bs'
import { FilterType } from '../ProductSetup.types'

export default function FilterValueModal({
    productId,
    groupName,
    filterValues,
    handleSave,
    setSelectedFilterEditObject,
}: {
    productId: string | number
    groupName: string
    filterValues: (FilterType & { selected: boolean })[]
    handleSave: (product_id: string | number, filter_group_id: string | number, values: string[]) => void
    setSelectedFilterEditObject: Dispatch<SetStateAction<any>>
}) {
    const modalRef = useRef(null)
    const [editableValues, setEditableValues] = useState(structuredClone(filterValues))
    const [newFilter, setNewFilter] = useState('')

    useEffect(() => {
        setEditableValues(structuredClone(filterValues))
    }, [filterValues])

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                setSelectedFilterEditObject(null)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    const handleChangeValues = (index: number) => {
        setEditableValues((previousState) => {
            const newState = structuredClone(previousState)
            newState[index].selected = !newState[index].selected
            return newState
        })
    }

    const handleAddValue = (filterValue: FilterType & { selected: boolean }) => {
        setEditableValues((previousState) => {
            const newState = structuredClone(previousState)
            newState.push(filterValue)
            return newState
        })
    }

    const handleNewFilterChange = ({ target }: { target: HTMLInputElement }) => {
        setNewFilter(target.value)
    }
    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 z-[50] w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'
        >
            <div className='bg-white dark:bg-darkaccent p-[32px] rounded-[4px] shadow-small relative'>
                <button
                    onClick={() => setSelectedFilterEditObject(null)}
                    className='absolute top-[0px] right-[8px] text-red dark:text-lightred font-bold text-[32px] leading-[1]'
                >
                    &times;
                </button>
                <h2 className='text-[24px] font-light tracking-[2px] dark:text-offwhite uppercase leading-[1] mb-[16px]'>
                    Edit {groupName} Values
                </h2>
                <div className='flex flex-col gap-[4px]'>
                    {editableValues.map((val, index) => (
                        <div className='flex gap-[6px] otems-center'>
                            <button
                                onClick={() => handleChangeValues(index)}
                                className={`w-full py-[4px] px-[8px] gap-[6px] flex items-center rounded-[4px] font-bold ${
                                    val.selected
                                        ? 'bg-blue dark:bg-accent text-white dark:text-darkaccent'
                                        : 'bg-lightgrey dark:bg-faintplus dark:text-offwhite'
                                }`}
                            >
                                {val.selected ? (
                                    <span className='inline-block rounded-[4px] w-[16px] h-[16px] grid place-items-center bg-white dark:bg-darkaccent text-black dark:text-accent leading-[1]'>
                                        ✔
                                    </span>
                                ) : (
                                    <span className='inline-block p-[2px] rounded-[4px] w-[16px] h-[16px] grid place-items-center border border-darkgrey dark:border-offwhite leading-[1]'>
                                        &nbsp;
                                    </span>
                                )}
                                {val.value}
                            </button>
                        </div>
                    ))}
                    <div className='flex gap-[4px] items-center'>
                        <input
                            className='w-full focus:outline-none dark:bg-faintplus rounded-[4px] py-[4px] px-[8px] border border-grey dark:border-darkgrey rounded-[4px] dark:text-white'
                            type='text'
                            value={newFilter}
                            placeholder='New Filter Value'
                            onChange={handleNewFilterChange}
                        />
                        {newFilter.length > 0 && (
                            <button
                                className='block py-[4px] px-[8px] whitespace-nowrap bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] font-bold uppercase'
                                onClick={() => {
                                    handleAddValue({
                                        ...editableValues[0],
                                        id: filterValues[0].id,
                                        selected: true,
                                        value: newFilter,
                                    })
                                    setNewFilter('')
                                }}
                            >
                                + Create
                            </button>
                        )}
                    </div>
                </div>
                <button
                    className='mx-auto mt-[8px] block py-[4px] px-[8px] whitespace-nowrap bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] font-bold uppercase'
                    onClick={() =>
                        handleSave(
                            productId,
                            filterValues[0].id!,
                            editableValues.filter((v) => v.selected).map((v) => v.value)
                        )
                    }
                >
                    Save &amp; Exit
                </button>
            </div>
        </div>
    )
}
