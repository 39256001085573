import { Dispatch, SetStateAction } from 'react'
import { AdjustmentRow, ProductCostRow, VariantCostRow } from '../CostAndPriceScreen.types'

export default function ProductList({
    variants,
    handleAdjustment,
    adjustments,
}: {
    variants: VariantCostRow[]
    handleAdjustment: Function
    adjustments: AdjustmentRow[]
}) {
    const productMap: { [key: string]: ProductCostRow } = {}
    for (const variant of variants) {
        if (!productMap[variant.parent_gid]) {
            productMap[variant.parent_gid] = {
                product_title: variant.product_title,
                gid: variant.parent_gid,
                variants: [],
            }
        }
        productMap[variant.parent_gid].variants.push(variant)
    }
    const products = Object.values(productMap)
    return (
        <div>
            <div className='sticky top-[50px] grid grid-cols-5 col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>SKU</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Cost</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Price</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Compare At Price</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Last Updated</div>
            </div>
            {products.map((product) => (
                <div className='border-b border-lightgrey dark:border-darkgrey'>
                    <p className='p-[8px] py-[2px] mb-[2px] border-b-2 border-fire w-fit text-grey font-bold text-[14px] dark:text-fire'>
                        {product.product_title}
                    </p>
                    <div>
                        {product.variants.map((variant) => (
                            <div
                                className={`grid grid-cols-5 ${
                                    adjustments.find((adj) => adj.gid === variant.gid)
                                        ? 'bg-[rgb(255,165,0,0.5)]'
                                        : 'hover:bg-lightgrey dark:hover:bg-darkness'
                                }`}
                            >
                                <p className='p-[8px] font-bold text-black dark:text-offwhite'>
                                    {variant.sku || 'MISSING'}
                                </p>
                                <input
                                    name='unit_cost'
                                    className='border border-lightgrey dark:border-none dark:bg-faintplus dark:text-white rounded-[4px] focus:outline-none p-[4px] py-[2px] my-[8px] max-w-[150px] font-medium'
                                    type='number'
                                    defaultValue={variant.unit_cost}
                                    onChange={({ target }) => handleAdjustment(target, variant)}
                                />
                                <input
                                    name='price'
                                    className='border border-lightgrey dark:border-none dark:bg-faintplus dark:text-white rounded-[4px] focus:outline-none p-[4px] py-[2px] my-[8px] max-w-[150px] font-medium'
                                    type='number'
                                    defaultValue={variant.price}
                                    onChange={({ target }) => handleAdjustment(target, variant)}
                                />
                                <input
                                    name='compare_at_price'
                                    className='border border-lightgrey dark:border-none dark:bg-faintplus dark:text-white rounded-[4px] focus:outline-none p-[4px] py-[2px] my-[8px] max-w-[150px] font-medium'
                                    type='number'
                                    defaultValue={variant.compare_at_price}
                                    onChange={({ target }) => handleAdjustment(target, variant)}
                                />
                                <p className='p-[8px] dark:text-offwhite'>
                                    {variant.updated_at ? new Date(variant.updated_at).toDateString() : 'NEVER'}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}
